import React, { Component } from 'react';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Auth from '../../../utils/Auth';
import { getAllCalculatorList } from '../CommonApiActions';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { node } from 'prop-types';
class Calculators extends React.Component {
    constructor(props) {
        super(props);
        this.state = ({
            checked: [],
            calculators: [],
            expanded: [],
            id: this.props.id
        });
        //this.setState({role_type_id : props.})
    }
 componentDidMount()
 {
    this.props.getCalculatorList(this.state.id);
 }

    componentWillReceiveProps(props) {
        if(props.customer_id!= this.state.id){
            this.setState({ checked:[] })
           this.props.getCalculatorList(this.state.id);
           this.state.id = props.customer_id;
         }
    }

    // componentWillUpdate(props) {
    //     if(props.roletypeval != this.state.role_type_id){
    //        this.props.getPermissionList(props.roletypeval,0);
    //        this.state.role_type_id = props.roletypeval;
    //     }
    // }
    handleValChange = (check) => {
        this.props.onCalculatorChange(check);     
    }
    handlePerChange = (e) => {
        this.setState({ checked:e })
        this.props.onCalculatorChange(e); 
        if(document.getElementById("CalculatorError")){            
            if(e.length == 0){
                document.getElementById('CalculatorError').innerHTML = 'Please select calculator';
            }
            else{
                document.getElementById('CalculatorError').innerHTML = '';
            }
        }      
    }

    
    render() {
        var Nodedata = {
           nodes : []
        };
        let AllCalculator;
        if(this.props.allCalculators!=undefined){
             AllCalculator = this.props.allCalculators.Data;
        }
       
        var check = [];
        if(AllCalculator && AllCalculator.length > 0)
        {
            AllCalculator.map(function(item) {        
                if(item.IsSelected)
                {
                    check.push(item.CalculatorId);
                }
               
                Nodedata.nodes.push({ 
                     value : item.CalculatorId,
                     label  : item.ManufacturerName + " - " + item.CalculatorName
                 });
             });  
        }
        if(this.state.checked.length == 0)
        {
          this.state.checked=check;
          this.handleValChange(check);
        }
        //const nodes = Nodedata.nodes;
        
        // const nodes = [{
        //     value: 'mars',
        //     label: 'Mars',
        //     children: [
        //         { value: 'phobos', label: 'Phobos' },
        //         { value: 'deimos', label: 'Deimos' },
        //     ],
        // }];

        
        return (
            <CheckboxTree
            name="CustomerCalculators"
                nodes={Nodedata.nodes}
                checked={this.state.checked}
                //onCheck={checked => this.setState({ checked })}
                onCheck={this.handlePerChange}
            />
        );
    }
}

const mapProps = (state) => {
   
    return {
        allCalculators: state.common.allCalculators
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getCalculatorList: (data) => {
            return new Promise((resolve, reject) => {
                dispatch(getAllCalculatorList(data))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    };
};

export default connect(mapProps, mapDispatchToProps)(Calculators);
