import axios from 'axios';
import Auth from './Auth';
import { SERVER_ACTIONS } from './Constants';

const auth = new Auth();

//const serverLink = "http://localhost:3001";
//const serverLink = "http://192.168.4.17:8083";
//const serverLink = "http://bella-armorapi.futurismdemo.com";
 //const api = "http://192.168.4.17:8083";
//const api = "http://bella-armorapi.futurismdemo.com";

class HttpRequest {

    requestWithoutAuth = () => {

        const headers = { ...this.headers, 'Content-type': 'application/json' };

        return new Promise((resolve, reject) => {
            axios({
                baseURL: SERVER_ACTIONS.API,
                url: this.url,
                method: this.method,
                headers: headers,
                data: this.data,
                withCredentials:true
            }).then(response => {
                
                resolve(response);
            }).catch(error => {
                
                reject(error);
            });
        });
    };


    requestWithAuth = (isMultipart) => {

        isMultipart = isMultipart || false;



        const accessToken = auth.getAccessToken();

        const headers = { ...this.headers, 'Content-type': !isMultipart ? 'application/json' : undefined };

        if (accessToken) {
            headers['Authorization'] = `Bearer ${accessToken}`;
        }


        if (isMultipart) {
            var formdata = new FormData();
            var data = this.data;
            Object.keys(data).forEach(key => {
                formdata.append(key, data[key])

            });

            this.data = formdata;

        }

        return new Promise((resolve, reject) => {

            axios({
                baseURL:  SERVER_ACTIONS.API,
                url: this.url,
                method: this.method,
                headers: headers,
                data: this.data,
                withCredentials:true
            }).then(response => {

                if (response.data.Status && response.data.Status == 403) {
                  //  auth.logout();
                }
                // if (response.data.status && response.data.status == 403) {
                //     auth.logout();
                // }
                resolve(response);
            }).catch(error => {
                
                if (error.response && error.response.status == 403) {
                  //  auth.logout();
                }
                reject(error);
            });
        });
    };

    download = () => {
        window.open(SERVER_ACTIONS.API + this.url);
    }
    constructor(url, method = 'get', data = {}, headers = {}) {
        this.url = url;
        this.method = method;
        this.data = data;
        this.headers = headers;
    }

}

export default HttpRequest;
