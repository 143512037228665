import { UPDATE_CUSTOMER, ADD_CUSTOMER_TO_LIST, UPDATE_CUSTOMER_IN_LIST, DELETE_CUSTOMER_FROM_LIST } from './ManageCustomersActions';


const initialState = { customers: [] };

const manageCustomer = (state = initialState, action) => {

    switch (action.type) {
       
        case UPDATE_CUSTOMER:
            
            if (action.PageNumber === 0)
                return { ...state, customers: action.customer, totalRecords: action.totalRecords };
            else {
                let currentCustomers = [];
                // if( state.users.length != 0){
                //     let existingUsers = state.users.data.data.filter(disc => !action.user.data.data.some(newUser => newUser.user_id == disc.user_id));
                //     currentUsers= existingUsers.concat(action.user.data.data);
                // }
               
                currentCustomers = action.customer;
                return Object.assign({}, state, {
                    customers: currentCustomers,
                    totalRecords: action.totalRecords,
                    totalPages: currentCustomers.Data.totalpages
                });

            }

        case ADD_CUSTOMER_TO_LIST:
                state.customers.Data.data = [action.customer, ...state.customers.Data.data];
            const customersToAdd =  state.customers;
            return Object.assign({}, state, {
                customers: customersToAdd
            });

        case UPDATE_CUSTOMER_IN_LIST:
       
            let { customers } = state;
            const index = customers.Data.data.findIndex(customer => customer.customer_id === action.customer.customer_id);
            if (index !== -1) {
                customers.Data.data[index] = action.customer;
            }

            return Object.assign({}, state, {
                customers: customers
            });

        case DELETE_CUSTOMER_FROM_LIST:
                {
                    let  {customers}  = state;
                    customers.Data.data = customers.Data.data.filter(customer => customer.customer_id != action.customer.customer_id);
                    return Object.assign({}, state, {
                        customers: customers
                    })
                }
            
        default:
            return state;
    }
}

export default manageCustomer;