import React, { Component } from 'react';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { getAllSizeList } from '../../CommonApiActions';

class SizeDropDown extends React.Component {
    constructor(props) {
        super(props);
        //console.log(this.props.railId);
        this.state = ({
            allSizes: [],
            railId: this.props.railId,
            bellowId: this.props.bellowId
        })
    }

    componentWillReceiveProps(props) {
        if(props.bellowId!= undefined &&( props.bellowId !=this.state.bellowId)){
          this.props.getSizeList(props.railId,props.bellowId);
          this.state.bellowId = props.bellowId;
        }
        // else if(props.bellowId!= undefined && props.railId!= undefined && props.railId !=this.state.railId){
        //     this.state.railId = props.railId;
        //     this.state.bellowId = 0;
           
        // }
    }

    componentDidMount() {
        this.props.getSizeList(this.state.railId, this.state.bellowId);
    }


    render() {
        const selectedValue = this.props.selectedValue;
        
        //console.log(selectedValue);
        const sizes = this.props.allSizes;
        const options = sizes && sizes.length > 0 ? (
            sizes.map((size) => {

                return (<MenuItem name={size.SizeValue} value={size.SizeID}>
                        {size.SizeValue}
                        </MenuItem>
                );
            })
        ) : (
                <MenuItem>Loading....</MenuItem>
            );
        //console.log({ options })
        return (

            <Select value={selectedValue}
                // name= {selectedName}
                labelId="demo-simple-select-label"
                id="demo-simple-select" onChange={this.props.onSizeChange}>
                {options}
            </Select>
        );
    }

}

const mapProps = (state) => {
   
    return {
        allSizes: state.common.allSizes
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getSizeList: (railId, bellowId) => {
            return new Promise((resolve, reject) => {
                dispatch(getAllSizeList(railId, bellowId))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    };
};

export default connect(mapProps, mapDispatchToProps)(SizeDropDown);
