import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Modal, Button, SafeAnchor } from 'react-bootstrap';
import { resolve } from 'url';
import { connect } from 'react-redux';
import { TextField } from 'formik-material-ui';
import { saveManufacturerItem } from '../../ManageManufacturersApiActions';
import ImageComponent from '../../../Common/components/ImageComponent';
import ImageUploader from 'react-images-upload';
import ManufacturerFormSchema from './ManufacturerFormSchema';


class EditManufacturer extends Component {
    constructor(props) {
        super(props);

        let manufacturer = props.update ? props.manufacturer : {
            name: '',
            thumbnail: '',
            display_name: '',
            id: 0
        };
        this.state = {

            show: false,
            message: '',
            manufacturer: manufacturer,
            FileStr : ''
        };

        this.saveManufacturer = this.saveManufacturer.bind(this);

        this.state = { pictures: [] ,file:""};
         

    }

    onDrop=(picture) =>{
        //console.log({picture})
        if(picture[picture.length-1]!=undefined){
            var filename =picture[picture.length-1].name;
            this.setState({
                file: URL.createObjectURL(picture[picture.length-1])
            })
            this.getBase64(picture[picture.length-1],result=>{
                //console.log({result});
                let {manufacturer}=this.state;
                manufacturer.FileStr = result.replace("data:image/png;base64,","").replace("data:image/jpeg;base64,","").replace("data:image/jpg;base64,","").replace("data:image/gif;base64,","");
                //manufacturer.FileStr=result;
                manufacturer.FileName = filename;
                this.setState({manufacturer})
            })
            this.setState({
                pictures: this.state.pictures.concat(picture),
            });
        }
    }

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            //console.log('Error: ', error);
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ manufacturer: nextProps.manufacturer });
    }

    saveManufacturer = (data, formAction) => {
        //console.log({data});
        let {manufacturer}=this.state;
        if(manufacturer.FileStr != undefined)
        {
        data.FileStr = manufacturer.FileStr;
        }
        if(manufacturer.FileName != undefined)
        {
        data.FileName = manufacturer.FileName;
        }
        formAction.setSubmitting(true);
        this.props.saveManufacturer(data, formAction).then(response => {
            manufacturer.FileName="";
            manufacturer.FileStr="";
            this.setState({ message: response.message,manufacturer:manufacturer });
            formAction.setSubmitting(false);
           
            if (this.props.updateList && typeof this.props.updateList === 'function') {
                this.props.updateList();
            }
            this.props.onHide();
        }, error => {
            formAction.setSubmitting(false);
        });
    };
    
    render() {
        let stateData = this.state.manufacturer;

        let imgPreview
        
        if (this.state.file != "") {
            imgPreview = <img style={{width:200}} src={this.state.file} alt='' />;
        }
        else if(stateData != undefined && stateData.thumbnail){
            imgPreview = <img style={{width:200}} src={stateData.thumbnail} alt='' />;
        }
        
        return (

            <React.Fragment>

                <Modal className="manufacturer-model"
                    {...this.props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >

                    <Modal.Header color="secondary" closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Edit Manufacturer
                        </Modal.Title>
                    </Modal.Header>

                    <Formik
                        validationSchema={ManufacturerFormSchema}
                        initialValues={stateData}
                        onSubmit={this.saveManufacturer}
                        enableReinitialize={true}
                    >
                        {({ errors, status, touched, isSubmitting, setFieldValue }) => (

                            <Form>

                                <Modal.Body>
                                    <div className="row" >
                                        <div className="col-lg-6">
                                            <div className="col-lg-12">
                                            <Field type="text"
                                                name="name"
                                                placeholder="Enter Name"
                                                component={TextField}
                                                id="standard-required"
                                                label=" Name"
                                                margin="normal"
                                                // defaultValue={data.first_name}
                                                fullWidth
                                            />
</div>
<div className="col-lg-12">
<Field type="text"
                                                name="display_name"
                                                placeholder="Enter Display Name"
                                                component={TextField}
                                                id="standard-required"
                                                label="Display Name"
                                                margin="normal"
                                                // defaultValue={data.first_name}
                                                fullWidth
                                            />
</div>
                                        </div>

                                        <div className="col-lg-6">
                                        <div className="text-center">
                                            {imgPreview}
                                        </div>
                                        <ImageUploader name ="FileStr"
                                                singleImage={true}
                                                 withIcon={false}
                                                buttonText='Choose images'
                                                onChange={this.onDrop}
                                                imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                                maxFileSize={5242880}
                                                //fileSizeError='file size is too big'
                                                //fileTypeError=' is not supported file extension'
                                        />
                                        
                                        {/* <ImageUploader name ="FileStr"
                                                withIcon={true}
                                                buttonText='Choose images'
                                                onChange={this.onDrop}
                                                imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                                maxFileSize={5242880}
                                        /> */}
                                            {/* {(this.state.manufacturer && this.state.manufacturer.manufacturerImageShow && this.state.manufacturer.manufacturerImageString) ? <>
                                                <ImageComponent src={`data:image/png;base64, ${this.state.manufacturer.manufacturerImageString}`}>
                                                </ImageComponent>
                                                <Field type="hidden" name="manufacturerImagePath"  ></Field>
                                            </> :
                                                <input type="file" id="manufacturerImage"
                                                    name="manufacturerImage" className="form-control"
                                                    onChange={(e) => {
                                                        setFieldValue('manufacturerImage', e.currentTarget.files[0])
                                                    }} />
                                            }
                                            {(this.state.manufacturer && this.state.manufacturer.manufacturerImagePath && this.state.manufacturer.manufacturerImagePath.length > 0) ? <>
                                                <SafeAnchor onClick={this.toggleManufacturerImageUploadField}>
                                                    {(this.state.manufacturer.manufacturerImageShow) ? "Change Image" : "Cancel"}
                                                </SafeAnchor>
                                            </> :
                                                <ErrorMessage name="manufacturerImage" style={{ color: 'red' }} component="div" />} */}
                                        </div>
                                    </div>
                                    <Modal.Footer className="row" centered>
                                        <Button variant="contained" type="submit" className="save">Save</Button>
                                        <Button variant="contained" className="cancle" onClick={this.props.onHide}>Close</Button>
                                    </Modal.Footer>
                                </Modal.Body>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapProps = state => ({
})

const mapDispatch = (dispatch, ownProps) => {

    return {

        saveManufacturer: (manufacturer, formAction) => {

            return new Promise((resolve, reject) => {
                dispatch(saveManufacturerItem(manufacturer, formAction)).then(response => {
                    resolve(response);

                }).catch(error => {
                    reject(error);
                });
            });
        }
    }
}

export default connect(mapProps, mapDispatch)(EditManufacturer);
