import React, { Component } from 'react';
import ComingSoonImage from '../../images/coming-soon.jpg';
import { Container } from 'react-bootstrap';
import Auth from '../../utils/Auth';

let auth = new Auth();

class ComingSoon extends Component  {
    constructor(props) {
        super(props)
        this.state={
        }
    }

    render() {
        const topheight = {
            padding: '100px 0 30px',

        };
        const img = {
            width: '100%'
        }
    return (
        <React.Fragment>
            {/* <Container className="welcome comingsoon" style={topheight} fluid="true"> */}
            <Container className=" comingsoon" >
                <Container>
                    <div className="row justify-content-md-center">
                        <div className="col-lg-12 mb-2">
                            <h1 className="text-center heading">{this.state.heading}</h1>
                            <h3 style={{'textTransform':'uppercase'}} className="text-center subheading">{this.state.subheading}</h3>
                            <div className="text-center">
                                {/* <img src={this.props.Nlogo} alt=""/>
                                <img src={this.props.thklogo} alt=""/> */}
                                <img className="mt-2" style={img} src={ComingSoonImage} alt="" />
                                
                            </div>
                        </div>
                      
                    </div>
                </Container>
            </Container>
        </React.Fragment>
    )
    }

}

export default ComingSoon;