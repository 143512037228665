import React, { Component } from 'react';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { getAllCompanyTypeList } from '../../CommonApiActions';

class CompanyTypeDropDown extends React.Component {
    constructor(props) {
        super(props);

        this.state = ({
            allCompanyTypes: []
        })
    }

    componentDidMount() {
        this.props.getCompanyTypeList();
    }

    render() {
        const selectedValue = this.props.selectedValue;
        const companyTypes = this.props.allCompanyTypes;
        
        const options =
        companyTypes && companyTypes.length > 0 ? (
            companyTypes.map((companyType) => {

                return (<MenuItem value={companyType.Value}>
                    {companyType.Text}
                    </MenuItem>
               
                 );
                })
            ) : (
                    <MenuItem>Loading....</MenuItem>
                );

        return (


            <Select value={selectedValue}
                labelId="demo-simple-select-label"
                id="demo-simple-select" onChange={this.props.onCompanyChange}>
                {options}
            </Select>
            
        );
    }
}

const mapProps = (state) => {
   
    return {
        allCompanyTypes: state.common.allCompanyTypes
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getCompanyTypeList: () => {
            return new Promise((resolve, reject) => {
                dispatch(getAllCompanyTypeList())
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    };
};

export default connect(mapProps, mapDispatchToProps)(CompanyTypeDropDown);
