import React, { Component } from 'react';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { getAllMaterialList } from '../../CommonApiActions';

class MaterialDropDown extends React.Component {
    constructor(props) {
        super(props);
        //console.log(this.props.manufacturerId);
        this.state = ({
            manufacturerId: this.props.manufacturerId
        })
    }

    componentWillReceiveProps(props) {

        if(props.manufacturerId!= undefined && props.manufacturerId !=this.state.manufacturerId){
          this.props.getMaterialList(props.manufacturerId);
          this.state.manufacturerId = props.manufacturerId;
        }
    }
    componentDidMount() {
        this.props.getMaterialList(this.state.manufacturerId);
    }

    render() {
        const selectedValue = this.props.selectedValue;
        // const selectedName = this.props.selectedName;
        
        //console.log(selectedValue);
        const materials = this.props.allMaterials;

        const options = materials && materials.length > 0 ? (
            materials.map((material) => {

                return (<MenuItem name={material.Material} value={material.MaterialId}>
                        {material.Material+" ("+material.Description+")"}
                        </MenuItem>
                );
            })
        ) : (
                <MenuItem>Loading....</MenuItem>
            );
        //console.log({ options })
        return (

            <Select value={selectedValue}
                // name= {selectedName}
                labelId="demo-simple-select-label"
                id="demo-simple-select" onChange={this.props.onMaterialChange}>
                {options}
            </Select>
        );
    }

}

const mapProps = (state) => {
   
    return {
        allMaterials: state.common.allMaterials
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getMaterialList: (manufacturerId) => {
            return new Promise((resolve, reject) => {
                dispatch(getAllMaterialList(manufacturerId))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    };
};

export default connect(mapProps, mapDispatchToProps)(MaterialDropDown);
