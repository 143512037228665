
export const GET_USER_MANUFACTURERS_IMAGE = 'GET_USER_MANUFACTURERS_IMAGE';

export function getUserManufacterersImage(userManufacturers){
    return{
        type: GET_USER_MANUFACTURERS_IMAGE,
        userManufacturers
    }
}


