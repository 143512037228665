import React, { Component } from 'react'
import Container from '@material-ui/core/Container';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import certifiedico from '../../../../images/certified-ico.png';
import engico from '../../../../images/eng-ico.png';
import phico from '../../../../images/24by7-ico.png';
import mailico from '../../../../images/mail-ico.png';
import { connect } from 'react-redux';
import { getAllManufacturersList } from '../../ManageManufacturersApiActions';
import './Manufacturers.css';
import {APP_DETAILS} from '../../../../utils/Constants';
class Manufacturers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            allManufacturers: [],
            length: 0,
            responsive:{
                0: {
                    items: 1,
                },
                450: {
                    items: 2,
                },
                600: {
                    items: 3,
                },
                1000: {
                    items: 4,
                },
            },
        }
    }

    componentDidMount() {
        this.props.getManufacturersList();
    }

    renderCarousal = () => {

        if (this.props.allManufacturers && this.props.allManufacturers.length > 0) {

            return this.props.allManufacturers.map((manufacturer, index) => {
                const { thumbnail, thumbnail_alt } = manufacturer; //destructuring
                return (

                    <div key={`${index}_mfslider`} className="item"><img src={thumbnail} alt={thumbnail_alt} /></div>

                )
            })
        }
    }

    render() {
        const topheight = {
            padding: '50px 0 30px',
        };
        //console.log({ props: this.props, message: 'rendered' })

        return (
            <React.Fragment>
                 
                <Container>
               
                    {/* <h2 className="text-center heading our-manufacturer">Our Manufacturers</h2> */}
                    <h2 className="text-center heading our-manufacturer">Protective Covers for</h2>
                    {this.props.allManufacturers && this.props.allManufacturers.length > 0 ? <OwlCarousel
                        className="owl-theme"
                        loop={true}
                        margin={10}
                        dots={true}
                        nav={false}
                       // items={4}
                       responsive={this.state.responsive}
                         //center={true}
                    >
                        {this.renderCarousal()}
                    </OwlCarousel> : <><center>
                        Loading Manufacturers....
                    </center></>
                    }
                   
                    <div className="certified">
                        <p className="d-flex align-items-center"><img src={certifiedico} alt="" />&nbsp;&nbsp;Get part numbers, material specs and up to date pricing for your order requirements</p>
                    </div>
                    <div className="certified row">
                        <div className="col-md-4 col-xs-12 certified-col support-border-right">
                            <p><img src={engico} alt="" />&nbsp;&nbsp;Engineering & Design Support</p>
                        </div>
                        <div className="col-md-4 col-xs-12 certified-col  support-border-right">
                            <p><img src={phico} alt="" />&nbsp;&nbsp;<a href={`tel:${APP_DETAILS.CONTACT_NUMBER}`}>{APP_DETAILS.CONTACT_NUMBER}</a></p>
                        </div>
                        <div className="col-md-4 col-xs-12 certified-col">
                            <p><img src={mailico} alt="" />&nbsp;&nbsp;<a href={`mailto:${APP_DETAILS.SALES_EMAIL}`}>{APP_DETAILS.SALES_EMAIL}</a></p>
                        </div>
                    </div>
                    
                </Container>
               
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {

    return {
        allManufacturers: state.manufacturer.allManufacturers,
        length: state.manufacturer.allManufacturers ? state.manufacturer.allManufacturers.length : 0
    }
};



const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getManufacturersList: () => {
            return new Promise((resolve, reject) => {
                dispatch(getAllManufacturersList()).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        }
    }
}

Manufacturers = connect(mapStateToProps, mapDispatchToProps)(Manufacturers);

export default Manufacturers;