import React, { Component } from 'react'
import { SafeAnchor, Modal } from 'react-bootstrap';

import Button from '@material-ui/core/Button';
import { Field, FieldArray, Form, Formik } from 'formik';
import { FormikTextField } from 'formik-material-fields';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { TextField } from 'formik-material-ui';
import { getKRRailLengthAttributes, deleteKRRailLengthAttribute, addKRRailLength } from '../../components/ManageAttributeApiAction';
import Icon from '@material-ui/core/Icon';
import KRSeriesDropDown from '../../../Common/components/DropDown/KRSeriesDropDown';
import swal from 'sweetalert';
import {getKRMaterialByManufacturerList,getAllKRSeries} from '../ManageAttribute/EditDropDownListApi';
import Swal from 'sweetalert2';

import{StyledReactTooltip} from '../ToolTip';
const { Header, Body, Footer, Title } = Modal;

export default class EditKRRailLength extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showform: false,
            allKrSeriesList:[],
            manufacturer_name: "",
            manufacturer_id: this.props.manufacturer_id,
            railLength_id: 0,
            railLength_name: '',
            prevseries_id: 0,
            prevkrseries2_id:0,
            series_id: 0,
            krraillength: [{
                KRRailLengthID: 0,
                Length: '',
                MotorSideMin: '',
                MotorSideMax: '',
                EndSideMin: '',
                EndSideMax: '',
                Folds: '',
                UnitCost: '',
                THKStandardCost: '',
                THKReferenceName: ''
            }
            ]


        };

    }
    componentDidMount() {
        //console.log("didmount");
    }
    componentWillReceiveProps(props) {
        this.setState({ manufacturer_id: this.props.manufacturer_id });
        if(this.props.manufacturer_id){
        this.getKRSeriesDropDownList(this.props.manufacturer_id);
        }

    }
    componentDidUpdate() {
        const seriesValue=this.state.allKrSeriesList[0]!=undefined?(this.state.allKrSeriesList[0]):(0);
      
        const seriesid=seriesValue.KRSeriesID
       if(this.state.prevkrseries2_id!=seriesValue.KRSeriesID)
       {
        //    this.setId(sizeValue.SizeId);
           this.setState({series_id:seriesValue.KRSeriesID});
           this.getKRRailLenth(seriesid);
          this.setState({prevkrseries2_id:seriesValue.KRSeriesID});
        
       }

        else if (this.state.prevseries_id !== this.state.series_id) {
            this.getKRRailLenth(this.state.series_id);
            this.setState({ prevseries_id: this.state.series_id })
        }



    }

    showForm = () => {
        this.setState({ showform: true })
    }


    hideForm = () => {
        this.setState({
            showform: false,
            prevseries_id: 0,
            prevkrseries2_id:0,            
            krraillength: [{
                KRRailLengthID: 0,
                Length: '',
                MotorSideMin: '',
                MotorSideMax: '',
                EndSideMin: '',
                EndSideMax: '',
                Folds: '',
                UnitCost: '',
                THKStandardCost: '',
                THKReferenceName: ''
            }
            ]
        })
    }
    deletekrraillength = (option, arrayHelpers, i) => {
        if (option.KRRailLengthID == 0) {
            arrayHelpers.remove(i);
        }
        else{
            Swal.fire({
                //title: 'Are you sure?',
                text: 'This action will delete the record permanently from database, Are you sure?',
                // icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel',
                showCloseButton: true
              }).then((result) => {
                if (result.value) {
                
                    deleteKRRailLengthAttribute(option.KRRailLengthID).then(resp => {
                                                arrayHelpers.remove(i);
                                                            });
                  
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                  
                }
              })

        // swal("This action will delete the record permanently from database, Are you sure?", {
        //     buttons: {
        //         catch: {
        //             text: "Yes",
        //             value: "Yes",
        //         },
        //         cancel: "Cancel",
        //     },
        // })
        //     .then((value) => {
        //         switch (value) {
        //             case "Yes":
        //                     deleteKRRailLengthAttribute(option.KRRailLengthID).then(resp => {
        //                         arrayHelpers.remove(i);
        //                                     });
        //                 break;
        //             default:

        //         }
        //     });
        }
    }



    addRecord = (arrayHelpers) => {
        //let { options } = this.state.krraillength;
        arrayHelpers.push({ KRRailLengthID: 0 })
        //    this.setState({
        //          krraillength: [...this.state.krraillength, { KRRailLengthID: 0 }]
        //      })
    }

    getKRSeriesDropDownList= (manufacturerId) => {
                
        getAllKRSeries(manufacturerId).then(resp => {
            
            this.setState({

                allKrSeriesList:resp
            });

        });
    }

    getKRRailLenth = (krseries_id) => {
        krseries_id = krseries_id == undefined ? 0 : krseries_id;
        var manufacturerId = this.state.manufacturer_id == undefined ? 0 : this.state.manufacturer_id;
        const query = '?ManufacturerId=' + manufacturerId + '&KRSeriesID=' + krseries_id;
        getKRRailLengthAttributes(query).then(res => {

            this.setState({
                krraillength: res,
            })

        })
    }
    handleChangeRailDropdown = (event) => {
        this.setState({ series_id: event.target.value })
        // this.getKRRailLenth(event.target.value);
    };
    submitFormula = (data, actions) => {

        let found=false;
        for(let i=0;i<data.krraillength.length;i++){
            let rname=data.krraillength[i].Length;
        
        let foundDuplicate=data.krraillength.filter(r=>r.Length==rname).map(duplicate=>(duplicate.Length ))
            if(foundDuplicate.length>=2)
            {found=true;
                actions.setSubmitting(false)
                swal(rname+" attribute is already exist", {
                    buttons: {
                            cancel: "OK",
                    },
                })
                    .then((cancel) => {
                        switch (cancel) {
                            case "Yes":                        
                                break;
                            default:
                        }
                    });
            } 
        }
        if(found==false){
            
        actions.setSubmitting(true);
        // let updateOptions = data.options.filter(o => (this.state.updatableOptions.indexOf(o.CalulatorformulaeId) > -1 || o.CalulatorformulaeId == 0));
        let KRRailLengthAttributeValues = data.krraillength

        addKRRailLength(this.props.manufacturer_id, this.state.series_id,
            KRRailLengthAttributeValues).then(response => {

                if (response.Status && response.StatusCode === 200) {
                    let { krraillength } = this.state;
                    krraillength = krraillength.map(krraillength => {
                        let option2 = KRRailLengthAttributeValues.find(o => o.KRRailLengthID == krraillength.KRRailLengthID);
                        return option2 ? { ...krraillength, ...option2 } : krraillength;
                    })
                    this.setState({
                        krraillength
                    })

                }

            }, this.hideForm())

        }

    }

    render() {

        const manufacturer_id = this.props.manufacturer_id;
        const manufacturer_name = this.props.manufacturer_name;
        //console.log({ krraillength: this.state.krraillength })
        return (
            <>
            {!this.state.showform ?<>
                <Icon   data-tip data-for="edit"  onClick={this.showForm}>
                    edit
                 </Icon>
                 <StyledReactTooltip id="edit" effect="solid">
                 <span>Edit</span>
                </StyledReactTooltip></>
                : <>

                <Modal className="container" show={this.state.showform} onHide={this.hideForm}>
                    <Header closeButton>
                        <Title>{manufacturer_name}</Title>
                    </Header>
                    <Body>

                        <Formik
                            onSubmit={this.submitFormula}
                            initialValues={{ krraillength: this.state.krraillength }}
                            enableReinitialize={true}
                        >
                            {({ values, status, isSubmitting }) => (
                                <Form className="width-990">
                                    <div className="row">
                                        <div className="col-lg-12 popup-heading">
                                            <label >ATTRIBUTE NAME :KR Rail Length</label>
                                        </div>
                                        <div className="col-lg-4">

                                            <FormControl margin="normal" fullWidth>
                                                <div className="ManufacturerDropDown">
                                                    <InputLabel id="demo-simple-select-label">KRSeries*</InputLabel>
                                                    <KRSeriesDropDown selectedValue={this.state.series_id} enabled={true} activeOnly={true} manufacturerId={manufacturer_id} onKRSeriesChange={(e, index) => {

                                                        this.handleChangeRailDropdown(e)
                                                        this.setState({
                                                            railLength_id: e.target.value,
                                                            railLength_name: index.props.name
                                                        });

                                                    }
                                                    } />
                                                </div>
                                            </FormControl>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-lg-1 col-1">
                                            <label >Length</label>
                                        </div>
                                        <div className="col-lg-1 col-1">
                                            <label >Motor Side Min </label>
                                        </div>
                                        <div className="col-lg-1 col-1">
                                            <label >Motor Side Max </label>
                                        </div>
                                        <div className="col-lg-1 col-1">
                                            <label >End Side Min </label>
                                        </div>
                                        <div className="col-lg-1 col-1">
                                            <label >End Side Max </label>
                                        </div>
                                        <div className="col-lg-1 col-1">
                                            <label >Folds </label>
                                        </div>
                                        <div className="col-lg-1 col-1">
                                            <label >Unit Cost </label>
                                        </div>
                                        <div className="col-lg-2 col-2">
                                            <label >THK Standard Cost </label>
                                        </div>
                                        <div className="col-lg-2 col-2">
                                            <label >THK Reference Name </label>
                                        </div>

                                    </div>
                                    <FieldArray
                                        name="krraillength"

                                        render={arrayHelpers => (
                                            <div className="clacfor">

                                                {values.krraillength && values.krraillength.length > 0 ? values.krraillength.map((krraillength, i) => {
                                                    //console.log({ length: values.krraillength.length })
                                                    return (<div className="row" key={i}>
                                                        <div className="col-lg-1 col-1">

                                                            <Field

                                                                name={`krraillength[${i}.Length]`}
                                                                margin="normal"

                                                                fullWidth

                                                                component={TextField}
                                                            />
                                                            {(!krraillength.Length || krraillength.Length.length == 0) && this.state.error ?
                                                                <span style={{ color: "red" }}>Enter Length </span> : ""}

                                                        </div>
                                                        <div className="col-lg-1 col-1">

                                                            <Field

                                                                name={`krraillength[${i}.MotorSideMin]`}
                                                                margin="normal"

                                                                fullWidth

                                                                component={TextField}
                                                            />
                                                            {(!krraillength.MotorSideMin || krraillength.MotorSideMin.length == 0) && this.state.error ?
                                                                <span style={{ color: "red" }}>Enter Motor SideMin </span> : ""}
                                                        </div>
                                                        <div className="col-lg-1 col-1">

                                                            <Field

                                                                name={`krraillength[${i}.MotorSideMax]`}
                                                                margin="normal"

                                                                fullWidth

                                                                component={TextField}
                                                            />
                                                            {(!krraillength.MotorSideMax || krraillength.MotorSideMax.length == 0) && this.state.error ?
                                                                <span style={{ color: "red" }}>Enter Motor SideMax </span> : ""}
                                                        </div>
                                                        <div className="col-lg-1 col-1">

                                                            <Field

                                                                name={`krraillength[${i}.EndSideMin]`}
                                                                margin="normal"

                                                                fullWidth

                                                                component={TextField}
                                                            />
                                                            {(!krraillength.EndSideMin || krraillength.EndSideMin.length == 0) && this.state.error ?
                                                                <span style={{ color: "red" }}>Enter End SideMin </span> : ""}
                                                        </div>

                                                        <div className="col-lg-1 col-1">

                                                            <Field

                                                                name={`krraillength[${i}.EndSideMax]`}
                                                                margin="normal"

                                                                fullWidth

                                                                component={TextField}
                                                            />
                                                            {(!krraillength.EndSideMax || krraillength.EndSideMax.length == 0) && this.state.error ?
                                                                <span style={{ color: "red" }}>Enter End SideMax </span> : ""}
                                                        </div>

                                                        <div className="col-lg-1 col-1">

                                                            <Field

                                                                name={`krraillength[${i}.Folds]`}
                                                                margin="normal"

                                                                fullWidth

                                                                component={TextField}
                                                            />
                                                            {(!krraillength.Folds || krraillength.Folds.length == 0) && this.state.error ?
                                                                <span style={{ color: "red" }}>Enter Folds </span> : ""}
                                                        </div>

                                                        <div className="col-lg-1 col-1">

                                                            <Field

                                                                name={`krraillength[${i}.UnitCost]`}
                                                                margin="normal"

                                                                fullWidth

                                                                component={TextField}
                                                            />
                                                            {(!krraillength.UnitCost || krraillength.UnitCost.length == 0) && this.state.error ?
                                                                <span style={{ color: "red" }}>Enter Unit Cost </span> : ""}
                                                        </div>

                                                        <div className="col-lg-2 col-2">

                                                            <Field
                                                                name={`krraillength[${i}.THKStandardCost]`}
                                                                margin="normal"
                                                                fullWidth
                                                                component={TextField}
                                                            />
                                                            {(!krraillength.THKStandardCost || krraillength.THKStandardCost.length == 0) && this.state.error ?
                                                                <span style={{ color: "red" }}>Enter THKStandardCost </span> : ""}
                                                        </div>

                                                        <div className="col-lg-2 col-2">

                                                            <Field

                                                                name={`krraillength[${i}.THKReferenceName]`}
                                                                margin="normal"
                                                                fullWidth
                                                                component={TextField}
                                                            />
                                                            {(!krraillength.THKReferenceName || krraillength.THKReferenceName.length == 0) && this.state.error ?
                                                                <span style={{ color: "red" }}>Enter THKReferenceName </span> : ""}
                                                        </div>
                                                        <SafeAnchor className="btn btn-default">
                                                            <i className="fa fa-minus"
                                                                onClick={() => {
                                                                    this.deletekrraillength(krraillength, arrayHelpers, i);
                                                                }} /></SafeAnchor>
                                                        <br />
                                                    </div>);
                                                }) : arrayHelpers.push({ KRRailLengthID: 0 })}
                                                <SafeAnchor className="btn btn-default" onClick={e => {

                                                    if (values.krraillength && values.krraillength.length > 0
                                                        && values.krraillength.filter(v => (!v.Length || v.Length.length == 0
                                                            || !v.MotorSideMin || v.MotorSideMin.length == 0 || !v.MotorSideMax || v.MotorSideMax.length == 0
                                                            || !v.EndSideMax || v.EndSideMax.length == 0 || !v.EndSideMin || v.EndSideMin.length == 0
                                                            || !v.Folds || v.Folds.length == 0 || !v.UnitCost || v.UnitCost.length == 0
                                                            || !v.THKStandardCost || v.THKStandardCost.length == 0 || !v.THKReferenceName || v.THKReferenceName == 0)).length == 0) {
                                                        arrayHelpers.push({ KRRailLengthID: 0 });
                                                        this.setState({
                                                            error: false
                                                        })
                                                    } else {
                                                        this.setState({ error: true })
                                                    }

                                                }}><i className="fa fa-plus" /></SafeAnchor>
                                            </div>
                                        )} />
                                    {/* <input type="submit" className="submit" disabled={isSubmitting} /> */}
                                    <Modal.Footer className="row" centered>
                                        <Button variant="contained" type="submit" className="save" disabled={isSubmitting} >Submit</Button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Body>
                </Modal>

                </>}
            </>

        )
    }
}