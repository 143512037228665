import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getUserManufacturersImageList } from './AccessPageApiActions';
import Auth from '../../utils/Auth';
import { ACTIONS } from '../../utils/Constants';
import { SafeAnchor} from 'react-bootstrap';

let auth = new Auth();
class Access extends Component  {
    constructor(props){
        super(props)
        this.state={
            heading:'Welcome to the Bellows Calculator',
            //subheading:'Click on your rail Manufacturer for specific calculator',
            subheading:'Click on your rail manufacturer for specific calculator',
            id: auth.getUserId(),
            isAdmin: auth.getUserType(),
            calculatorId: 0,
            manufacturerId: 0,
            reload:false
        }
    }

    componentDidMount() {
       
        this.props.getUserManufacturerImageList(this.state.id, this.state.isAdmin);
        
        this.reloadMethod();
       
    }
    
   reloadMethod=()=>{
    
        var currentDocumentTimestamp = new Date(performance.timing.domLoading).getTime();
        // Current Time //
        var now = Date.now();
        // Total Process Lenght as Minutes //
        var tenSec = 1 * 1000;
        // End Time of Process //
        var plusTenSec = currentDocumentTimestamp + tenSec;
        if (now > plusTenSec) {
            window.location.reload();
        }
    

   }
    renderUserManufacturers = () => {

        if (this.props.userManufacturers && this.props.userManufacturers.length > 0) {

            return this.props.userManufacturers.map((manufacturer, index) => {
              
                let { Thumbnail, ManufacturerName,CalculatorName, CalculatorId, ManufacturerId,IsDeveloped } = manufacturer; //destructuring
                
                return (
                
                    <div key={`${index}_mfslider`} className="col-lg-3 col-md-4 col-sm-6 access-logo">
                        
                        
                       {IsDeveloped ? 
                        <a href= "/#/Railcalculator"   onClick={() => {
                        this.setState({
                            calculatorId: {CalculatorId},
                            manufacturerId: {ManufacturerId},
                            IsDeveloped: {IsDeveloped}
                        })
                        // console.log(CalculatorId +  " " + ManufacturerId );
                        auth.setCalculatorId(CalculatorId);
                        auth.setManufacturerId(ManufacturerId);
                    }} ><img className="img-responsive" src={Thumbnail} alt={ManufacturerName} />
            {ManufacturerName} - {CalculatorName}
                </a> : <a href=   "/#/comingsoon" onClick={() => {
                        this.setState({
                            calculatorId: {CalculatorId},
                            manufacturerId: {ManufacturerId},
                            IsDeveloped: {IsDeveloped}
                        })
                        // console.log(CalculatorId +  " " + ManufacturerId );
                        auth.setCalculatorId(CalculatorId);
                        auth.setManufacturerId(ManufacturerId);
                    }} ><img className="img-responsive" src={Thumbnail} alt={ManufacturerName} />
            {ManufacturerName} - {CalculatorName}
                    </a>}
            
                    </div>
                    
                   

                )
            })
        }
    }

    render() {
        const topheight = {
            padding: '100px 0 30px',

        };
        return (
            <React.Fragment>
                <Container className="welcome" style={topheight} fluid="true">
                    <Container>
                        <div className="row justify-content-md-center">
                            <div className="col-lg-12 mb-2">
                                <h1 className="text-center heading">{this.state.heading}</h1>
                                <h3 style={{'textTransform':'uppercase'}} className="text-center subheading">{this.state.subheading}</h3>
                                <div className="text-center">
                                    {/* <img src={this.props.Nlogo} alt=""/>
                                    <img src={this.props.thklogo} alt=""/> */}

                                    
                                </div>
                            </div>
                            {this.renderUserManufacturers()}
                        </div>
                    </Container>
                </Container>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        userManufacturers: state.accessCalculator.userManufacturers,
        length: state.accessCalculator.userManufacturers ? state.accessCalculator.userManufacturers.length : 0
    }
};



const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getUserManufacturerImageList: (id, isAdmin) => {
            return new Promise((resolve, reject) => {
                dispatch(getUserManufacturersImageList(id, isAdmin)).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        }
    }
}

Access = connect(mapStateToProps, mapDispatchToProps)(Access);

export default Access;