import React, { Component } from 'react';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { getAllBellowList } from '../../CommonApiActions';

class BellowsDropDown extends React.Component {
    constructor(props) {
        super(props);
        //console.log(this.props.railId);
        this.state = ({
            allBellows: [],
            railId: this.props.railId,
            isloaded:false,
        })
    }

    componentWillReceiveProps(props) {

        if (props.railId != undefined && props.railId != this.state.railId) {
            this.props.getBellowList(props.railId).then(res => {
                this.setState({
                    allBellows: res
                })
            })
            this.state.railId = props.railId;
        }
        else if(!this.state.isloaded)
        {
            this.props.getBellowList(props.railId).then(res => {
                this.setState({
                    allBellows: res,
                    isloaded:true
                })
            })
        }
        
    }

    componentDidMount() {
        //this.props.getBellowList(this.state.railId);
    }


    render() {
        const selectedValue = this.props.selectedValue;
        // const selectedName = this.props.selectedName;


        const bellows = this.state.allBellows;
        //console.log({ selectedValue, bellows });
        const options = bellows && bellows.length > 0 ? (
            bellows.map((bellow) => {

                return (<MenuItem selected={bellow.BellowId == selectedValue} name={bellow.BellowValue} value={bellow.BellowId}>
                    {bellow.BellowValue}
                </MenuItem>
                );
            })
        ) : (
                <MenuItem>Loading....</MenuItem>
            );
        //console.log({ options })
        return (

            <Select value={selectedValue} 
                // name= {selectedName}
                labelId="demo-simple-select-label"
                id="demo-simple-select" onChange={this.props.onBellowChange} >
                {options}
            </Select>

        );
    }

}

const mapProps = (state) => {

    return {
        allBellows: state.common.allBellows
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getBellowList: (railId) => {
            return new Promise((resolve, reject) => {
                dispatch(getAllBellowList(railId))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    };
};

export default connect(mapProps, mapDispatchToProps)(BellowsDropDown);
