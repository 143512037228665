import React, { Component } from 'react'
import './managePricing.css';
import Select from '@material-ui/core/Select';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { TextField } from 'formik-material-ui';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import ManufacturerDropDown from '../../../Common/components/DropDown/ManufacturerDropDown'
import SizeDropDown from '../../../Common/components/DropDown/SizeDropDown';
import RailsDropdown from '../../../Common/components/DropDown/RailsDropDown';
import BellowsDropDown from '../../../Common/components/DropDown/BellowsDropDown';
import MaterialDropdown from '../../../Common/components/DropDown/MaterialDropDown';
import {getAllSizeList,getSizeDetailValues,getDefaultValuesForStrokeLengthData, saveSizeItem, getRailBellowValues, savePropertyItem, getMarkupsValues, saveMarkupsItem,getAllManufacturerList} from '../../ManagePricingsApiActions';
import {managePricingSchema, managePricingRailBellowProperty, managePricingSize }from './managePricingSchema';
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import Icon from '@material-ui/core/Icon';
import Auth from '../../../../utils/Auth';
let auth = new Auth();

const StyledReactTooltip = styled(ReactTooltip)`
  background-color: white !important;
  color: black !important;
  
  &:after {
    border-top-color: white !important;
  }
`;

class ManagePricing extends Component {
    constructor(props) {
        super(props)
        this.state = {
            preManufacturerId: "",
            manufacturerId: 0,
            preMaterialId: "",
            materialId: 0,
            bellowId: 0,
            railId: 0,
            sizeId: 0,
            preSizeId: "",
            markups: [],
            cfpriceval:"false"
        }
    }

    handleChangeManufacturerDropdown = (event, index) => {
        if(event.target.value!=this.state.manufacturerId)
        {
        this.props.getMarkupsByManufacturer(event.target.value);
            
        }
        else if(event.target.value==this.state.manufacturerId)
        {
            this.props.getDefaultValuesForStrokeLength(this.state.manufacturerId).then((res) => {
                this.setState({
                    railId: res.RailId,
                    bellowId: res.BellowId,
                    sizeId: res.SizeId,
                    materialId: res.MaterialId,
                    preManufacturerId:this.state.manufacturerId
                });
            });
        }
    };
    handleChangeRailDrodown = (event) => {
   
        // this.props.getStrokeLengthProperty(this.state);
        if(event.target.value > 0)
        {
            this.props.getDefaultValuesForStrokeLength(this.state.manufacturerId,event.target.value).then((res) => {
        
                this.setState({
                    railId: res.RailId,
                    bellowId: res.BellowId,
                    sizeId: res.SizeId,
                    materialId: res.MaterialId,
                    preManufacturerId:this.state.manufacturerId
                })
            })
            this.props.getMarkupsByManufacturer(this.state.manufacturerId);
        }
        
     }
     
     handleChangeBellowDropDown=(event)=>{
        if(event.target.value > 0)
        {
            this.props.getDefaulSize(this.state.railId,event.target.value).then((res) => {
                this.setState({
                    sizeId:res[0].SizeID,
                })
            })
        }

     }

    handleChangeSizeDrodown = (event , index ) => {
        // this.props.getSizeDetail(event.target.value);
    }

    handleChangeMaterialDropdown = (event) => {
        this.props.getRailBellowProperty(this.state.sizeId, event.target.value);
    }
    CFPriceChange = ()=>{
        this.setState({cfpriceval: !this.state.cfpriceval})
    }
    
    SetCtype  = (value)=>{
        auth.setHistoryType("1")
        window.location.href="/#/pricinghistory";
    }    
    SetMaterialtype  = (value)=>{
        auth.setHistoryType("2")
        window.location.href="/#/pricinghistory";
    }
    SetMarkuptype  = (value)=>{
        auth.setHistoryType("3")
        window.location.href="/#/pricinghistory";
    }

    componentDidMount(){
        auth.setHistoryType(0)
        this.props.getManufacturerList().then(resp => {
            
            this.setState({
              
                manufacturerId:resp[0].manufacturer_id,
            
            });
            auth.setPHManId(resp[0].manufacturer_id)
        });
        
    }

    componentDidUpdate(){
         
        if(this.state.manufacturerId!=0 && this.state.preManufacturerId!=this.state.manufacturerId)
        {
            this.props.getDefaultValuesForStrokeLength(this.state.manufacturerId).then((res) => {
                this.setState({
                    railId: res.RailId,
                    bellowId: res.BellowId,
                    sizeId: res.SizeId,
                    materialId: res.MaterialId,
                    preManufacturerId:this.state.manufacturerId
                });
            });

        }
        if(this.state.preManufacturerId!==this.state.manufacturerId){
            
            this.props.getMarkupsByManufacturer(this.state.manufacturerId);
            this.setState({preManufacturerId:this.state.manufacturerId });
            auth.setPHManId(this.state.manufacturerId)
        }

        if(this.state.preSizeId!==this.state.sizeId){
            //this.props.getSizeDetail(this.state.sizeId);
            this.props.getSizeDetail(this.state.sizeId).then((res) => {
                this.setState({cfpriceval : this.props.sizes.CFPrice});
            });
           
            // if(this.props.sizes.CFPrice!=undefined)
            // {
            //     this.setState({cfpriceval : this.props.sizes.CFPrice,preSizeId:this.state.sizeId});
            // }
            // else{
             this.setState({preSizeId:this.state.sizeId});
            // }
        }

        if((this.state.preSizeId!==this.state.sizeId)|| (this.state.preMaterialId!==this.state.materialId)){
            this.props.getRailBellowProperty(this.state.sizeId, this.state.materialId);
            this.setState({
                preSizeId:this.state.preSizeId!==this.state.sizeId ? this.state.sizeId : this.state.preSizeId,
                preMaterialId: this.state.preMaterialId!==this.state.materialId ? this.state.materialId : this.state.preMaterialId
            });
        }
    }

    saveSizeDetail = (data, formAction) => {

        formAction.setSubmitting(true);
        data.CFPrice = this.state.cfpriceval;
        this.props.saveSizeDetails(data, formAction).then(response => {
            this.setState({ message: response.message });
            formAction.setSubmitting(false);
            if (this.updateList && typeof this.updateList === 'function') {
                this.updateList();
            }
            // this.handleClose();
        }, error => {
            formAction.setSubmitting(false);
        });

    };
    
    savePropertyDetail = (data, formAction) => {
        data.MaterialID= this.state.materialId;
        data.SizeID= this.state.sizeId;
        data.ManufacturerId=this.state.manufacturerId;
        formAction.setSubmitting(true);
        this.props.savePropertyDetails(data, formAction).then(response => {
            this.setState({ message: response.message });
            formAction.setSubmitting(false);
            if (this.updateList && typeof this.updateList === 'function') {
                this.updateList();
            }
            // this.handleClose();
        }, error => {
            formAction.setSubmitting(false);
        });

    };

    saveMarkupsDetail = (data, formAction) => {

        formAction.setSubmitting(true);
        this.props.saveMarkupsDetails(data, formAction).then(response => {
            this.setState({ message: response.message });
            formAction.setSubmitting(false);
            if (this.updateList && typeof this.updateList === 'function') {
                this.updateList();
            }
            // this.handleClose();
        }, error => {
            formAction.setSubmitting(false);
        });

    };
    
    updateList = () => {
        this.setState(Object.assign({}, this.state));
    };
   
    render() {
 
        let stateData = this.props.sizes
        let stateDataProperties = this.props.properties
        let stateDataMarkups = this.props.markups
        return (
           
                <React.Fragment>
                    
                    
                
                <div className="row">
                <div className="col-lg-6" style={{padding:15}}>
                    <h3 className="heading">Update Pricing/Parts</h3>
                    </div>

                    <Formik
                        
                        onSubmit={this}
                        initialValues=''
                        enableReinitialize={true}
                    >

                        {({ status, isSubmitting, setFieldValue }) => (
                             <div className="col-lg-6">
                            <Form className="Pricingform float-right" name="managePricing" noValidate autoComplete="off">
                               
                                    {/* <FormControl margin="normal" fullWidth>
                                    <div className="ManufacturerDropDown com-field">
                                        <InputLabel id="demo-simple-select-label">MANUFACTURER</InputLabel>
                                        
                                        <ManufacturerDropDown selectedValue={1}  enabled={true} activeOnly={true} onManufacturerChange={(e, index) => {
                                            setFieldValue("manufacturerId", e.target.value);
                                            setFieldValue("manufacturer_name", e.target.name);
                                            this.handleChangeManufacturerDropdown(e, index);
                                            this.setState({
                                                manufacturerId: e.target.value,
                                                railId: 0,
                                                bellowId: 0,
                                                sizeId: 0,
                                                materialId: 0,
                                                manufacturer_name: index.props.name
                                            });
                                        }
                                        } />
                                        </div>

                                    </FormControl> */}


                                    <FormControl margin="normal" fullWidth>
                                    <div className="ManufacturerDropDown">
                                        <InputLabel id="demo-simple-select-label">MANUFACTURER*</InputLabel>
                                        
                                        <ManufacturerDropDown  selectedValue={this.state.manufacturerId}   enabled={true} activeOnly={true} onManufacturerChange={(e, index) => {
                                            setFieldValue("manufacturer_list", e.target.value);
                                            setFieldValue("manufacturer_name", e.target.name);
                                            this.handleChangeManufacturerDropdown(e, index);
                                            this.setState({
                                                manufacturerId: e.target.value,
                                                railId: 0,
                                                bellowId: 0,
                                                sizeId: 0,
                                                materialId: 0,
                                                manufacturer_name: index.props.name
                                            });
                                            // this.getCalculatorList(1);
                                        }
                                        } />
                                    </div>

                                    </FormControl> 
                                 
                              
                            </Form>
                            </div>
                               )
                              }
                            </Formik>

                            </div>
                            <div className="col-lg-12">

                    <Formik
                        validationSchema={managePricingSize}
                        onSubmit={this.saveSizeDetail}
                        initialValues={stateData}
                        enableReinitialize={true}

                    >

                        {({ status, isSubmitting, setFieldValue }) => (
                            <Form className="Pricingform form-pricing" name="managePricing" noValidate autoComplete="off">
                            <div className="row main-sec">
                            <div className="col-lg-4">
                            <FormControl margin="normal" fullWidth>
                                            <InputLabel id="demo-simple-select-label">RAILS</InputLabel>
                                            <RailsDropdown enabled={true} manufacturerId={this.state.manufacturerId} selectedValue={this.state.railId}  activeOnly={true}
                                             onRailChange={e=>{
                                                 //setFieldValue("rail_id", e.target.value);    
                                                 this.setState({
                                                     railId: e.target.value,
                                                     bellowId: 0,
                                                     sizeId: 0
                                                 })  
                                                 this.handleChangeRailDrodown(e);                          
                                             }
                                             }/>
                                        </FormControl>
                                    </div>
                 
                           
                                    <div className="col-lg-4">
                                    <FormControl margin="normal" fullWidth>
                                            <InputLabel id="demo-simple-select-label">BELLOWS</InputLabel>
                                            <BellowsDropDown enabled={true} railId={this.state.railId}  selectedValue={this.state.bellowId} activeOnly={true} onBellowChange={e=>{
                                                 //setFieldValue("bellow_id", e.target.value);    
                                                 this.setState({
                                                     bellowId: e.target.value,
                                                     sizeId: 0
                                                 }) 
                                                 this.handleChangeBellowDropDown(e);                           
                                             }
                                             }/>
                                        </FormControl>

                                            {/* <FormControl margin="normal" fullWidth >
                                                <InputLabel id="demo-simple-select-label">BELLOWS</InputLabel>
                                                <BellowsDropDown enabled={true} railId={this.state.railId} 
                                                selectedValue={this.state.bellowId} activeOnly={true} onBellowChange={e => {
                                                     
                                                    this.setState({
                                                        bellowId: e.target.value,
                                                        sizeId: 0
                                                    })
                                                   
                                                }
                                                } />
                                            </FormControl> */}
                                    </div>

                                    <div className="col-lg-4">
                                    {/* <FormControl margin="normal" fullWidth>
                                            <InputLabel id="demo-simple-select-label">SIZES</InputLabel>
                                            <SizeDropDown enabled={true} railId={this.state.railId} selectedValue={""} bellowId={this.state.bellowId} activeOnly={true} onSizeChange={(e,index)=>{
                                                 setFieldValue("SizeID", e.target.value);                                
                                                 this.handleChangeSizeDrodown(e, index);
                                                 this.setState({
                                                     sizeId: e.target.value
                                                 })
                                                }
                                            }/>
                                        </FormControl> */}
                                        <FormControl margin="normal" fullWidth  disabled={this.state.disableSize}>
                                                <InputLabel id="demo-simple-select-label">SIZES</InputLabel>
                                                <SizeDropDown enabled={true} railId={this.state.railId} 
                                                selectedValue={this.state.sizeId} bellowId={this.state.bellowId} activeOnly={true} onSizeChange={(e, index) => {
                                                    //  setFieldValue("SizeID", e.target.value);  
                                                    // this.txtStroke.value ="";
                                                    // this.txtRail.value="";                              
                                                    this.handleChangeSizeDrodown(e, index);
                                                    this.setState({
                                                        sizeId: e.target.value,
                                                                                          
                                                    })
                                                }
                                                } />
                                            </FormControl>
                                    </div>

                                    <div className="col-lg-2">

                                        
                                          <Field type="text"
                                                defaultValue="0" 
                                                name="FlangeThickness"
                                                placeholder="Enter value"
                                                id="standard-required"
                                                label="FT"
                                                margin="normal"
                                                fullWidth
                                                component={TextField}
                                                error />
                                    </div>                                  

                                    <div className="col-lg-2">
                                          <Field  type="text" 
                                          defaultValue="0" 
                                                name="GasketThickness"
                                                placeholder="Enter value"
                                                id="standard-required"
                                                label="GT"
                                                margin="normal"
                                                fullWidth
                                                
                                                component={TextField}
                                                error />
                                    </div>

                                    <div className="col-lg-2">
                                          <Field type="text" 
                                                defaultValue="0" 
                                                name="VelcroThickness"
                                                placeholder="Enter value"
                                                id="standard-required"
                                                label="VT"
                                                margin="normal"
                                                fullWidth
                                                component={TextField}
                                                error />
                                    </div>

                                    <div className="col-lg-3">
                                          <Field type="text"
                                                defaultValue="0" 
                                                name="Pitch"
                                                placeholder="Enter value"
                                                id="standard-required"
                                                label="PITCH"
                                                margin="normal"
                                                fullWidth
                                                component={TextField}
                                                error />
                                    </div>
                                    
                                    <div className="col-lg-3">
                                          <Field type="text"
                                                defaultValue="0" 
                                                name="OpenPitch"
                                                placeholder="Enter value"
                                                id="standard-required"
                                                label="OP"
                                                margin="normal"
                                                fullWidth
                                                component={TextField}
                                                error />
                                    </div>
                                   
                                                 <div className="col-lg-3 mt-2">
                                    <Checkbox
                                    name="CFPrice"
                                                        
                                    defaultChecked={this.state.cfpriceval} 
                                    checked={this.state.cfpriceval}
                                    onChange={this.CFPriceChange}  
                                                        size="small"
                                                       // value={this.state.CFPrice}
                                                        fullWidth
                                                        inputProps={{ 'aria-label': 'checkbox with small size' }}
                                                    />Call for price
                                          
                                    </div>         
                                    <div className="col-lg-6 d-flex">
                                          <Field type="text"
                                                defaultValue="" 
                                                name="comment"
                                                placeholder="Enter Comment"
                                                id="standard-required"
                                                label="Comment"
                                                margin="normal"
                                                fullWidth
                                                component={TextField}
                                                error />
                                                <div className="mt-2">
                                                
                                                <Icon onClick={(e)=>
                                                    this.SetCtype(e)}>
                                                        <abbr title="View History">
                                                    <span className="fa fa-history"></span>
                                                    </abbr>
                                                </Icon>

                                                </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <Button type="submit" color="primary" className="submit-btn  mt-2">
                                        SAVE
                                       </Button>
                                    </div>
                                    </div>
                                    </Form>
                               )
                              }
                            </Formik>
                                


                    <Formik
                        validationSchema={managePricingRailBellowProperty}        
                        onSubmit={this.savePropertyDetail}
                        initialValues={stateDataProperties}
                        enableReinitialize={true}

                    >

                        {({ status, isSubmitting, setFieldValue }) => (

                                <Form noValidate autoComplete="off" className="form-pricing">
                                <div className="row main-sec">
                                    <div className="col-lg-3">
                                    <FormControl margin="normal" fullWidth>
                                            <InputLabel id="demo-simple-select-label">MATERIALS </InputLabel>
                                            <MaterialDropdown enabled={true} manufacturerId={this.state.manufacturerId} selectedValue={this.state.materialId} activeOnly={true} onMaterialChange={e=>{
                                                //  setFieldValue("rail_id", e.target.value); 
                                                 this.handleChangeMaterialDropdown(e);   
                                                 this.setState({
                                                     materialId: e.target.value,
                                                     markups: []
                                                 })                            
                                             }
                                             }/>
                                        </FormControl>
                                    </div>
                                    
                                    <div className="col-lg-3">
                                          <Field name="Cost"
                                                defaultValue="0" 
                                                placeholder="Enter value"
                                                id="standard-required"
                                                label="T COST"
                                                margin="normal"
                                                fullWidth
                                                component={TextField}
                                                error />
                                    </div>

                                    <div className="col-lg-3">
                                          <Field name="LMin"
                                                defaultValue="0" 
                                                placeholder="Enter value"
                                                id="standard-required"
                                                label="LMIN"
                                                margin="normal"
                                                fullWidth
                                                component={TextField}
                                                error />
                                    </div>


                                    <div className="col-lg-3">
                                          <Field name="Fold"
                                                defaultValue="0" 
                                                placeholder="Enter value"
                                                id="standard-required"
                                                label="$/FOLD"
                                                margin="normal"
                                                fullWidth
                                                component={TextField}
                                                error />
                                    </div>
                                    <div className="col-lg-9 d-flex">
                                          <Field type="text"
                                                defaultValue="" 
                                                name="comment"
                                                placeholder="Enter Comment"
                                                id="standard-required"
                                                label="Comment"
                                                margin="normal"
                                                fullWidth
                                                component={TextField}
                                                error />
                                                <div className="mt-2">
                                                    <Icon onClick={(e)=>
                                                        this.SetMaterialtype(e)}>
                                                    <abbr title="View History">
                                                    <span className="fa fa-history"></span>
                                                    </abbr>
                                                    </Icon>
                                                   
                                                </div>
                                    </div>

                                    <div className="col-lg-3">
                                        <Button color="primary" type="submit" className="submit-btn mt-2">SAVE</Button>
                                    </div>
                              
                                </div>
                                </Form>
                               )
                              }
                            </Formik>



                    <Formik
                         validationSchema={managePricingSchema}
                         onSubmit={this.saveMarkupsDetail}
                         initialValues={stateDataMarkups}
                         enableReinitialize={true}

                    >

                        {({ status, isSubmitting, setFieldValue }) => (

                                <Form noValidate autoComplete="off" className="form-pricing">

                                <div className="row main-sec">
                                    
                                    <div className="col-lg-2">
                                          <Field type="text"
                                                defaultValue="0" 
                                                name="OEMRatio"
                                                placeholder="Enter value"
                                                id="standard-required"
                                                label="OEM MU"
                                                margin="normal"
                                                fullWidth
                                                component={TextField}
                                                error />
                                    </div>
                                    <div className="col-lg-2">
                                          <Field type="text"
                                                defaultValue="0" 
                                                name="ListRatio"
                                                placeholder="Enter value"
                                                id="standard-required"
                                                label="LIST MU"
                                                margin="normal"
                                                fullWidth
                                                component={TextField}
                                                error />
                                    </div>
                                    <div className="col-lg-2">
                                          <Field type="text"
                                                defaultValue="0" 
                                                name="DistributorRatio"
                                                placeholder="Enter value"
                                                id="standard-required"
                                                label="DIST MU"
                                                margin="normal"
                                                fullWidth
                                                component={TextField}
                                                error />
                                    </div>                               

                                    <div className="col-lg-3">
                                          <Field type="text"
                                                defaultValue="0" 
                                                name="SalesRatio"
                                                placeholder="Enter value"
                                                id="standard-required"
                                                label="SALE MU"
                                                margin="normal"
                                                fullWidth
                                                component={TextField}
                                                error />
                                    </div>                                   
                                   
                                    <div className="col-lg-3">
                                          <Field type="text"
                                                defaultValue="0" 
                                                name="Setup"
                                                placeholder="Enter value"
                                                id="standard-required"
                                                label="SETUP"
                                                margin="normal"
                                                fullWidth
                                                component={TextField}
                                                error />
                                    </div>

                                    <div className="col-lg-9 d-flex">
                                          <Field type="text"
                                                defaultValue="" 
                                                name="comment"
                                                placeholder="Enter Comment"
                                                id="standard-required"
                                                label="Comment"
                                                margin="normal"
                                                fullWidth
                                                component={TextField}
                                                error />
                                                <div className="mt-2">
                                                    <Icon onClick={(e)=>
                                                        this.SetMarkuptype(e)}>
                                                         <abbr title="View History">
                                                    <span className="fa fa-history"></span>
                                                    </abbr>
                                                    </Icon>
                                                </div>
                                    </div>
                                     
                                    <div className="col-lg-3">
                                        <Button type="submit" color="primary" className="submit-btn mt-2">SAVE</Button>
                                    </div>

                                </div>
                                

                                </Form>
                               )
                              }
                            </Formik>

                                <div className="row hidden">
                                <div className="col-lg-5 main-sec">
                                    <div className="col-lg-12">
                                    <input type="file" 
                                        className="filepond"
                                        name="filepond"
                                        disabled
                                        data-max-file-size="3MB"
                                        data-max-files="3" />
                                    </div>
                                    </div>
                                    <div className="col-lg-2"></div>
                                    <div className="col-lg-5 main-sec">
                                    <div className="col-lg-12">
                                    <input type="file" 
                                        className="filepond"
                                        name="filepond"
                                        disabled
                                        data-max-file-size="3MB"
                                        data-max-files="3" />
                                    </div>
                                    </div>
                                    </div>                             
                                
                            
                            </div>
              
               
            </React.Fragment>
       
           
        )
    }
}

const mapStateToProps = state => {
    return {
        sizes: state.managePricing.sizes,
        properties: state.managePricing.properties,
        markups: state.managePricing.markups
    }
};


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getManufacturerList: () => {
            return new Promise((resolve, reject) => {
               getAllManufacturerList().then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        getDefaultValuesForStrokeLength: (manufacturerId,railId) => {
            return new Promise((resolve, reject) => {
                getDefaultValuesForStrokeLengthData(manufacturerId,railId)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        getSizeDetail : (sizeId) => {
            return new Promise((resolve, reject) => {
                dispatch(getSizeDetailValues(sizeId)).then(response => {
                    resolve(response);
                }).catch(error => {
                    window.location.href="#/page403"
                    reject(error);
                });
            });
        },
        
        getMarkupsByManufacturer : (manufacturerId) => {
            return new Promise((resolve, reject) => {
                dispatch(getMarkupsValues(manufacturerId)).then(response => {
                    resolve(response);
                }).catch(error => {
                   /// reject(error);
                   window.location.href="#/page403"
                });
            });
        },

        
        getRailBellowProperty: (sizeId, materialId)=>{
        return new Promise((resolve, reject) => {
            dispatch(getRailBellowValues(sizeId, materialId)).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
       },

       saveSizeDetails: (data, formAction) => {

        return new Promise((resolve, reject) => {
            dispatch(saveSizeItem(data, formAction)).then(response => {

                resolve(response);
                // if (response.status == 200 && !response.data.errorCode) {
                //     window.Message('Saved Successfully', discount.id > 0 ? `${discount.name} Updated....` : `${discount.name} Created....`);
                // }
            }).catch(error => {
                reject(error);
            });
        });
    },
       savePropertyDetails: (data, formAction) => {

        return new Promise((resolve, reject) => {
            dispatch(savePropertyItem(data, formAction)).then(response => {

                resolve(response);
                // if (response.status == 200 && !response.data.errorCode) {
                //     window.Message('Saved Successfully', discount.id > 0 ? `${discount.name} Updated....` : `${discount.name} Created....`);
                // }
            }).catch(error => {
                reject(error);
            });
        });
       },
       getDefaulSize: (railId,bellowId) => {
        return new Promise((resolve, reject) => {
            getAllSizeList(railId,bellowId)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },


       saveMarkupsDetails: (data, formAction) => {

        return new Promise((resolve, reject) => {
            dispatch(saveMarkupsItem(data, formAction)).then(response => {

                resolve(response);
                // if (response.status == 200 && !response.data.errorCode) {
                //     window.Message('Saved Successfully', discount.id > 0 ? `${discount.name} Updated....` : `${discount.name} Created....`);
                // }
            }).catch(error => {
                reject(error);
            });
        });
       },
       
       
    }
}

ManagePricing = connect(mapStateToProps, mapDispatchToProps)(ManagePricing);

export default ManagePricing;