
import {DELETE_FROM_PRICING_LIST,ADD_PHistory_TO_LIST,UPDATE_HISTORY, UPDATE_SIZES, UPDATE_RAIL_BELLOWS, UPDATE_MARKUPS} from './ManagePricingsActions';

const initialState = {history:[], sizes: [], properties: [], markups: [] };


const managePricing = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_HISTORY:
            {
                if (action.PageNumber === 0)
                    return { ...state, history: action.history, totalRecords: action.totalRecords };
                else {
                    let currentHistory = [];
                    
                    currentHistory = action.history;
                    return Object.assign({}, state, {
                        history: currentHistory,
                        totalRecords: action.totalRecords,
                        totalPages: currentHistory.Data.totalpages
                    });
    
                }
            }
       case UPDATE_SIZES:
            {

                return Object.assign({}, state, {
                    sizes: action.sizes
                });
            } 
        case UPDATE_RAIL_BELLOWS:
            {

                return Object.assign({}, state, {
                    properties: action.properties
                });
            } 
        case UPDATE_MARKUPS: 
        {
            return Object.assign({}, state, {
                markups: action.markups
            })
        }
        case ADD_PHistory_TO_LIST:
        {
            let { history } = state;
            const index = history.Data.data.findIndex(user=>user.id === action.user.id);
            if (index !== -1) {
                history.Data.data[index] = action.user;
            }
    
            return Object.assign({}, state, {
                history: history
            });
    

        }
        case DELETE_FROM_PRICING_LIST:
            {
                let  {history}  = state;
                history.Data.data = history.Data.data.filter(user => user.id != action.user.id);
                return Object.assign({}, state, {
                    history: history
                })
            }
       
        
        default:
            return state;
    }
}

export default managePricing;