import React, { Component } from 'react'
import { SafeAnchor, Modal } from 'react-bootstrap';
// import { Formik, Form, Field } from 'formik';
import Button from '@material-ui/core/Button';
import { Field, FieldArray, Form, Formik } from 'formik';
import { FormikTextField } from 'formik-material-fields';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { TextField } from 'formik-material-ui';
import { getAccessoryAttributes, deleteAccessoryAttribute, addAccessory } from '../../components/ManageAttributeApiAction';
import Icon from '@material-ui/core/Icon';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import{StyledReactTooltip} from '../ToolTip';

const { Header, Body, Footer, Title } = Modal;

export default class EditAccessory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showform: false,
            manufacturer_name: "",

            accessory: [{
                AccessoryId: 0,
                AccessoryValue: ''
            }
            ]


        };

    }
    componentDidMount() {
        //console.log("didmount");
    }
    componentWillReceiveProps(props) {
        this.setState({ manufacturer_id: this.props.manufacturer_id });

    }


    showForm = () => {
        this.setState({ showform: true })
    }


    hideForm = () => {
        this.setState({ showform: false })
    }
    deleteAccessory = (option, arrayHelpers, i) => {

        if (option.AccessoryId == 0) {
            arrayHelpers.remove(i);
        }
        else{

            Swal.fire({
                //title: 'Are you sure?',
                text: 'This action will delete the record permanently from database, Are you sure?',
                // icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel',
                showCloseButton: true
              }).then((result) => {
                if (result.value) {
                
                    deleteAccessoryAttribute(option.AccessoryId).then(resp => {
                                            arrayHelpers.remove(i);
                                        });
                  
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                  
                }
              })


            // swal("This action will delete the record permanently from database, Are you sure?", {
            //     buttons: {
            //         catch: {
            //             text: "Yes",
            //             value: "Yes",
            //         },
            //         cancel: "Cancel",
            //     },
            // })
            // .then((value) => {
            //     switch (value) {
            //         case "Yes":
            //             {
            //                 deleteAccessoryAttribute(option.AccessoryId).then(resp => {
            //                     arrayHelpers.remove(i);
            //                 });
            //             }
            //             break;
            //         default:
            //     }
            // });
        }
    }

    getAccessory = () => {

        getAccessoryAttributes(this.props.manufacturer_id).then(res => {

            this.setState({


                accessory: res,
                showform: true
            })

        })
    }
    submitFormula = (data, actions) => {
        let found=false;
        for(let i=0;i<data.accessory.length;i++){
            let rname=data.accessory[i].AccessoryValue;
        
        let foundDuplicate=data.accessory.filter(r=>r.AccessoryValue==rname).map(duplicate=>(duplicate.AccessoryValue ))
            if(foundDuplicate.length>=2)
            {found=true;
                actions.setSubmitting(false)
                swal(rname+" attribute is already exist", {
                    buttons: {
                            cancel: "OK",
                    },
                })
                    .then((cancel) => {
                        switch (cancel) {
                            case "Yes":                        
                                break;
                            default:
                        }
                    });
            } 
        }
        if(found==false){
            actions.setSubmitting(true);
            // let updateOptions = data.options.filter(o => (this.state.updatableOptions.indexOf(o.CalulatorformulaeId) > -1 || o.CalulatorformulaeId == 0));
            let accessoryAttributeValueModel = data.accessory
    
            addAccessory(this.props.manufacturer_id,
                accessoryAttributeValueModel).then(response => {
    
                    if (response.Status && response.StatusCode === 200) {
                        let { accessory } = this.state;
    
                        // options = options.map(o => {
                        //     if (updateOptions.map(oo => oo.CalulatorformulaeId == o.CalulatorformulaeId).length > 0) {
                        //         o = updateOptions.filter(o => o.CalulatorformulaeId === o.CalulatorformulaeId)[0];
                        //     }
                        //     return o;
                        // })
    
                        accessory = accessory.map(accessory => {
                            let option2 = accessoryAttributeValueModel.find(o => o.AccessoryId == accessory.AccessoryId);
                            return option2 ? { ...accessory, ...option2 } : accessory;
                        })
    
                        //console.log({ accessoryAttributeValueModel: accessory });
                        this.setState({
                            accessory
                        })
    
                    }
    
                }, this.hideForm())

        }

    }



    render() {

        const manufacturer_id = this.props.manufacturer_id;
        const manufacturer_name = this.props.manufacturer_name;
        return (
            <>
            {!this.state.showform ?<>
                <Icon data-tip data-for="edit" onClick={this.getAccessory}>
                    edit
                 </Icon>
                  <StyledReactTooltip id="edit" effect="solid">
                  <span>Edit</span>
                 </StyledReactTooltip></>
                : <>
                <Modal className="calculatorPopup" show={this.state.showform} onHide={this.hideForm}>
                    <Header closeButton>
                        <Title>{manufacturer_name}</Title>
                    </Header>
                    <Body>

                        <Formik
                            onSubmit={this.submitFormula}
                            initialValues={{ accessory: this.state.accessory }}
                        >
                            {({ values, status, isSubmitting }) => (
                                <Form>
                                    <div className="row">
                                        <div className="col-lg-12 popup-heading">

                                            <label >ATTRIBUTE NAME : ACCESSORY</label>


                                        </div>


                                        <div className="col-lg-8 col-8">
                                            <label >Accessory Value</label>
                                        </div>
                                    </div>
                                    <FieldArray
                                        name="accessory"

                                        render={arrayHelpers => (
                                            <div className="clacfor">

                                                {values.accessory && values.accessory.length > 0 ? values.accessory.map((accessory, i) => {

                                                    return (<div className="row" key={i}>
                                                        <div className="col-lg-8  col-8">

                                                            <Field

                                                                name={`accessory[${i}.AccessoryValue]`}
                                                                margin="normal"

                                                                fullWidth

                                                                component={TextField}
                                                            // onChange={() => {
                                                            //     this.onFieldChange(option)
                                                            // }} AccessoryValue
                                                            />
                                                            {(!accessory.AccessoryValue || accessory.AccessoryValue.length == 0) && this.state.error ?
                                                            <span style={{ color: "red"}}> Enter Accessory Value </span> : ""}

                                                        </div>




                                                        <SafeAnchor className="btn btn-default">
                                                            <i className="fa fa-minus" 
                                                            onClick={() => { this.deleteAccessory(accessory, arrayHelpers, i);
                                                             }} /></SafeAnchor>
                                                        <br />
                                                    </div>);
                                                }) : arrayHelpers.push({ AccessoryId: 0 })}
                                                <SafeAnchor className="btn btn-default">
                                                <i className="fa fa-plus" onClick={() => {

                                                    if (values.accessory && values.accessory.length  > 0 && values.accessory.filter(
                                                        v => (!v.AccessoryValue || v.AccessoryValue.length ==0)).length == 0)
                                                    {
                                                        arrayHelpers.push({ AccessoryId: 0 });
                                                        this.setState({
                                                            error : false
                                                        })
                                                    } else {
                                                        this.setState({ error: true })
                                                    }
                                                    
                                                   
                                                }} /></SafeAnchor>
                                            </div>
                                        )} />
                                    <Modal.Footer className="row" centered>
                                        <Button variant="contained" type="submit" className="save" disabled={isSubmitting} >Submit</Button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Body>
                </Modal>

                </>}
            </>

        )
    }
}