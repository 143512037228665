import React, { Component } from 'react';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { getAllRailsList } from '../../CommonApiActions';

class RailsDropDown extends React.Component {
    constructor(props) {
        super(props);
        //console.log(this.props.manufacturerId);
        this.state = ({
            allRails: [],
            manufacturerId: this.props.manufacturerId
        })
    }

     componentWillReceiveProps(props) {

        if(props.manufacturerId!= undefined && props.manufacturerId !=this.state.manufacturerId){
          this.props.getRailList(props.manufacturerId);
          this.state.manufacturerId = props.manufacturerId;
        }
    }

    componentDidMount() {
        this.props.getRailList(this.state.manufacturerId);
    }

    render() {
        const selectedValue = this.props.selectedValue;
        // const selectedName = this.props.selectedName;
        
        //console.log(selectedValue);
        const rails = this.props.allRails;

        const options = rails && rails.length > 0 ? (
            rails.map((rail,index) => {
                
                return (<MenuItem key={index} name={rail.RailName} value={rail.RailId}>
                        {rail.RailName}
                        </MenuItem>
                );
            })
        ) : (
                <MenuItem>Loading....</MenuItem>
            );
        //console.log({ options })
        return (
//className="rail-dropdown"
            <Select  value={selectedValue}
                // name= {selectedName}
                labelId="demo-simple-select-label"
                id="demo-simple-select" onChange={this.props.onRailChange}>
                {options}
            </Select>
        );
    }

}

const mapProps = (state) => {
   
    return {
        allRails: state.common.allRails
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getRailList: (manufacturerId) => {
            return new Promise((resolve, reject) => {
                dispatch(getAllRailsList(manufacturerId))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    };
};

export default connect(mapProps, mapDispatchToProps)(RailsDropDown);
