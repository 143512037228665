
import { GET_ATTRIBUTE} from './ManageAttributeAction';

const initialState = {attributeList:[] };


const attribute = (state = initialState, action) => {
    switch (action.type) {

        case GET_ATTRIBUTE:
       
     {
        let currentAttribute= [];
               
        currentAttribute = action.attributeList;
        return Object.assign({}, state, {
            data: currentAttribute
            
        });
    }
    default:
            return state;
    }
}

export default attribute;
