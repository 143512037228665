import { SERVER_ACTIONS } from '../../../utils/Constants';
import HttpRequest from '../../../utils/HttpRequest';
import Auth from '../../../utils/Auth';
// import { calculatorlist, calculatorStatus, editCalculator, deleteCalculator, formulaList } from './CalculatorActions';
import {attributeList} from './ManageAttributeAction';

const auth = new Auth();



export function getAttributeList(data) {
    return (dispatch) => {
        data = data == undefined ? 0 : data;
        // document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(`${SERVER_ACTIONS.API + SERVER_ACTIONS.GET_ATTRIBUTES}`, 'get', {})
            .requestWithAuth()
            .then(response => {
                
                // document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 ) {
                     dispatch(attributeList(response.data));
                    return Promise.resolve(response.data);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
                // document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);


            });
        }

    

}
export function addRails(manufacturerId,data) {
    
    const parameters = { manufacturerId: '', RailAttributeValueModel: {}, Created_By: '' };
    parameters.manufacturerId = manufacturerId;
    parameters.RailAttributeValueModel = data;
    parameters.Created_By = 1;
    document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.POST_RAILS_ATTRIBUTES,'POST',parameters)
        .requestWithAuth()
        .then(response => {
            document.getElementById("loaderdiv").classList.add("d-none");
            if (response.status === 200 && !response.data.errorCode) {
            
                
                return Promise.resolve(response.data);
            }
            else {
                return Promise.reject(response.data);
            }


        }, error => {
            document.getElementById("loaderdiv").classList.add("d-none");
            return Promise.reject(error);


        });
    



}



export function getRailsAttributes(manufacturerId) {
    manufacturerId = manufacturerId == undefined ? 0 : manufacturerId;
            
    // document.getElementById("loaderdiv").classList.remove("d-none");
    return new HttpRequest(`${SERVER_ACTIONS.API + SERVER_ACTIONS.GET_RAILS_ATTRIBUTES}?ManufacturerId=${manufacturerId}`, 'get', {})
            .requestWithAuth()
            .then(response => {
                
                // document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                
                    
                    return Promise.resolve(response.data.data.RailAttributeValueModel);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);


            });
        

    

}
export function deleteRailsAttribute(id) {
 
    let params ={};
        params.id =id;
        params.modified_by = 0;
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.DELETE_RAIL_ATTRIBUTE,'DELETE', params)
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                    return Promise.resolve(response.data);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);


            });
        

    

}


///////////////////////////Bellow API////////////////////////
export function addBellow(manufacturerId,data) {
    
    const parameters = { manufacturerId: '', BellowAttributeValueModel : {}, Created_By: '' };
    parameters.manufacturerId = manufacturerId;
    parameters.BellowAttributeValueModel  = data;
    parameters.Created_By = 1;
    document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.POST_BELLOW_ATTRIBUTES,'POST',parameters)
        .requestWithAuth()
        .then(response => {
            document.getElementById("loaderdiv").classList.add("d-none");
            if (response.status === 200 && !response.data.errorCode) {
            
                
                return Promise.resolve(response.data);
            }
            else {
                return Promise.reject(response.data);
            }


        }, error => {
            document.getElementById("loaderdiv").classList.add("d-none");
            return Promise.reject(error);


        });
    



}



export function getBellowAttributes(manufacturerId) {
    
    manufacturerId = manufacturerId == undefined ? 0 : manufacturerId;
    // document.getElementById("loaderdiv").classList.remove("d-none");
    return new HttpRequest(`${SERVER_ACTIONS.API + SERVER_ACTIONS.GET_BELLOW_ATTRIBUTES}?ManufacturerId=${manufacturerId}`, 'get', {})
            .requestWithAuth()
            .then(response => {
                // document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                
                    
                    return Promise.resolve(response.data.data.BellowAttributeValueModel);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);


            });
        

    

}
export function deleteBellowAttribute(id) {
 
    let params ={};
        params.id =id;
        params.modified_by = 0;
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.DELETE_BELLOW_ATTRIBUTE,'DELETE', params)
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                    return Promise.resolve(response.data);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);


            });
        

    

}

/////////////////////////////////////Size API//////////////////////////////////////////

export function addSize(manufacturerId,railBellow_Id,data) {
    
    const parameters = { ManufacturerId: '',RailBellowId:'', SizeAttributeValueModel: {} };
    parameters.ManufacturerId = manufacturerId;
    parameters.RailBellowId=railBellow_Id;
    parameters.SizeAttributeValueModel = data;
    parameters.CreatedBy = 1;
    document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.POST_SIZE_ATTRIBUTES,'POST',parameters)
        .requestWithAuth()
        .then(response => {
            document.getElementById("loaderdiv").classList.add("d-none");
            if (response.status === 200 && !response.data.errorCode) {
            
                
                return Promise.resolve(response.data);
            }
            else {
                return Promise.reject(response.data);
            }


        }, error => {
            document.getElementById("loaderdiv").classList.add("d-none");
            return Promise.reject(error);


        });
    



}



export function getSizeAttributes(railBellowId) {
    railBellowId = railBellowId == undefined ? 0 : railBellowId;
        
    // document.getElementById("loaderdiv").classList.remove("d-none");
    return new HttpRequest(`${SERVER_ACTIONS.API + SERVER_ACTIONS.GET_SIZE_ATTRIBUTES}?railbellowid=${railBellowId}`, 'get', {})
            .requestWithAuth()
            .then(response => {
                // document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                
                    
                    return Promise.resolve(response.data.data);
                }
                else {
                    return Promise.reject(response.data.data);
                }


            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);


            });
        

    

}
export function deleteSizeAttribute(id) {
 
    let params ={};
        params.id =id;
        params.modified_by = 0;
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.DELETE_SIZE_ATTRIBUTE,'DELETE', params)
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                    return Promise.resolve(response.data);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);


            });
        

    

            
}

/////////////////////////////////////Block API//////////////////////////////////////////

export function addBlock(manufacturerId,data) {
    
    const parameters = { ManufacturerId: '', BlockAttributeValueModel: {}, Created_By: '' };
    parameters.ManufacturerId = manufacturerId;
    parameters.BlockAttributeValueModel = data;
    parameters.CreatedBy = 1;
    document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.POST_BLOCK_ATTRIBUTES,'POST',parameters)
        .requestWithAuth()
        .then(response => {
            document.getElementById("loaderdiv").classList.add("d-none");
            if (response.status === 200 && !response.data.errorCode) {
            
                
                return Promise.resolve(response.data);
            }
            else {
                return Promise.reject(response.data);
            }


        }, error => {
            document.getElementById("loaderdiv").classList.add("d-none");
            return Promise.reject(error);


        });
    



}



export function getBlockAttributes(manufacturerId) {
    manufacturerId = manufacturerId == undefined ? 0 : manufacturerId;
    
    // document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(`${SERVER_ACTIONS.API + SERVER_ACTIONS.GET_BLOCK_ATTRIBUTES}?ManufacturerId=${manufacturerId}`, 'get', {})
            .requestWithAuth()
            .then(response => {
                // document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                
                    
                    return Promise.resolve(response.data.data.BlockAttributeValueModel);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);


            });
        
}
export function deleteBlockAttribute(id) {
 
    let params ={};
        params.id =id;
        params.modified_by = 0;
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.DELETE_BLOCK_ATTRIBUTE,'DELETE', params)
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                    return Promise.resolve(response.data);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);


            });


}


/////////////////////////////////////Accessory API//////////////////////////////////////////

export function addAccessory(manufacturerId,data) {
    
    const parameters = { ManufacturerId: '', AccessoryAttributeValueModel: {}, Created_By: '' };
    parameters.ManufacturerId = manufacturerId;
    parameters.AccessoryAttributeValueModel = data;
    parameters.CreatedBy = 1;
    document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.POST_ACCESSORY_ATTRIBUTES,'POST',parameters)
        .requestWithAuth()
        .then(response => {
            document.getElementById("loaderdiv").classList.add("d-none");
            if (response.status === 200 && !response.data.errorCode) {
            
                
                return Promise.resolve(response.data);
            }
            else {
                return Promise.reject(response.data);
            }


        }, error => {
            document.getElementById("loaderdiv").classList.add("d-none");
            return Promise.reject(error);


        });
    



}



export function getAccessoryAttributes(manufacturerId) {
    manufacturerId = manufacturerId == undefined ? 0 : manufacturerId;
            
    // document.getElementById("loaderdiv").classList.remove("d-none");
    return new HttpRequest(`${SERVER_ACTIONS.API + SERVER_ACTIONS.GET_ACCESSORY_ATTRIBUTES}?ManufacturerId=${manufacturerId}`, 'get', {})
            .requestWithAuth()
            .then(response => {
                // document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                
                    
                    return Promise.resolve(response.data.data.AccessoryAttributeValueModel);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);


            });
        
}
export function deleteAccessoryAttribute(id) {
 
    let params ={};
        params.id =id;
        params.modified_by = 0;
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.DELETE_ACCESSORY_ATTRIBUTE,'DELETE', params)
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                    return Promise.resolve(response.data);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);


            });


}

/////////////////////////////////////Material API//////////////////////////////////////////

export function addMaterial(manufacturerId,data) {
    
    const parameters = { ManufacturerId: '',MaterialAttributeValueModel: {}, Created_By: '' };
    parameters.ManufacturerId = manufacturerId;
    parameters.MaterialAttributeValueModel = data;
    parameters.CreatedBy = 1;
    document.getElementById("loaderdiv").classList.remove("d-none");
    return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.POST_MATERIAL_ATTRIBUTES,'POST',parameters)
        .requestWithAuth()
        .then(response => {
            document.getElementById("loaderdiv").classList.add("d-none");
            if (response.status === 200 && !response.data.errorCode) {
            
                
                return Promise.resolve(response.data);
            }
            else {
                return Promise.reject(response.data);
            }


        }, error => {
            document.getElementById("loaderdiv").classList.add("d-none");
            return Promise.reject(error);


        });
    



}



export function getMaterialAttributes(manufacturerId) {
    manufacturerId = manufacturerId == undefined ? 0 : manufacturerId;
            
    // document.getElementById("loaderdiv").classList.remove("d-none");
    return new HttpRequest(`${SERVER_ACTIONS.API + SERVER_ACTIONS.GET_MATERIAL_ATTRIBUTES}?ManufacturerId=${manufacturerId}`, 'get', {})
            .requestWithAuth()
            .then(response => {
                // document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                
                    
                    return Promise.resolve(response.data.data.MaterialAttributeValueModel);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);


            });
        
}
export function deleteMaterialAttribute(id) {
 
    let params ={};
        params.id =id;
        params.modified_by = 0;
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.DELETE_MATERIAL_ATTRIBUTE,'DELETE', params)
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                    return Promise.resolve(response.data);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);


            });


}

/////////////////////////////////////RailBellow API//////////////////////////////////////////

export function addRailBellow(manufacturerId,rail_id,data) {
    
    const parameters = { ManufacturerId: '',RailId:'', CreatedBy: '',RailBellowMappingValueModel: {} };
    parameters.ManufacturerId = manufacturerId;
    parameters.RailId=rail_id;
    parameters.RailBellowMappingValueModel = data;
    parameters.CreatedBy = 1;
    document.getElementById("loaderdiv").classList.remove("d-none");
    return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.POST_RAILBELLOW_ATTRIBUTES,'POST',parameters)
        .requestWithAuth()
        .then(response => {
            document.getElementById("loaderdiv").classList.add("d-none");
            if (response.status === 200 && !response.data.errorCode) {
            
                
                return Promise.resolve(response.data);
            }
            else {
                return Promise.reject(response.data);
            }


        }, error => {
            document.getElementById("loaderdiv").classList.add("d-none");
            return Promise.reject(error);


        });
    



}



export function getRailBellowAttributes(query) {
    
        
    // document.getElementById("loaderdiv").classList.remove("d-none");
    return new HttpRequest(`${SERVER_ACTIONS.API + SERVER_ACTIONS.GET_RAILBELLOW_ATTRIBUTES}${query}`, 'GET', {})
            .requestWithAuth()
            .then(response => {
                // document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                
                    
                    return Promise.resolve(response.data.data.RailBellowMappingValueModel);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);


            });
        
}
export function deleteRailBellowAttribute(id) {
 
    let params ={};
        params.id =id;
        params.modified_by = 0;
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.DELETE_RAILBELLOW_ATTRIBUTE,'DELETE', params)
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                    
                    return Promise.resolve(response.data);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);


            });


}



//////////////////////////////// KRSeries //////////////////////////

export function addKRSeries(manufacturerId,data) {
    
    const parameters = { manufacturerId: '', KRSeriesAttributeValues: {}, Created_By: '' };
    parameters.manufacturerId = manufacturerId;
    parameters.KRSeriesAttributeValues = data;
    parameters.Created_By = 1;
    document.getElementById("loaderdiv").classList.remove("d-none");
    return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.POST_KRSERIES_ATTRIBUTES,'POST',parameters)
        .requestWithAuth()
        .then(response => {
            document.getElementById("loaderdiv").classList.add("d-none");
            if (response.status === 200 && !response.data.errorCode) {
            
                
                return Promise.resolve(response.data);
            }
            else {
                return Promise.reject(response.data);
            }


        }, error => {
            document.getElementById("loaderdiv").classList.add("d-none");
            return Promise.reject(error);


        });
    



}



export function getKRSeriesAttributes(manufacturerId) {
    
        
    // document.getElementById("loaderdiv").classList.remove("d-none");
    return new HttpRequest(`${SERVER_ACTIONS.API + SERVER_ACTIONS.GET_KRSERIES_ATTRIBUTES}?ManufacturerId=${manufacturerId}`, 'get', {})
            .requestWithAuth()
            .then(response => {
                // document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                
                    
                    return Promise.resolve(response.data.data.KRSeriesAttributeValues);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);


            });
        

    

}
export function deleteKRSeriesAttribute(id) {
 
    let params ={};
        params.id =id;
        params.modified_by = 0;
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.DELETE_KRSERIES_ATTRIBUTE,'DELETE', params)
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                    return Promise.resolve(response.data);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);

            });
        
}
//////////////////////////////// KRMaterial //////////////////////////

export function addKRMaterial(manufacturerId,data) {
    
    const parameters = { manufacturerId: '', KRMaterialAttributeValues: {}, Created_By: '' };
    parameters.manufacturerId = manufacturerId;
    parameters.KRMaterialAttributeValues = data;
    parameters.Created_By = 1;
    document.getElementById("loaderdiv").classList.remove("d-none");
    return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.POST_KRMATERIAL_ATTRIBUTES,'POST',parameters)
        .requestWithAuth()
        .then(response => {
            document.getElementById("loaderdiv").classList.add("d-none");
            if (response.status === 200 && !response.data.errorCode) {
            
                
                return Promise.resolve(response.data);
            }
            else {
                return Promise.reject(response.data);
            }


        }, error => {
            document.getElementById("loaderdiv").classList.add("d-none");
            return Promise.reject(error);

        });
    
}

export function getKRMaterialAttributes(manufacturerId) {
    
        
    // document.getElementById("loaderdiv").classList.remove("d-none");
    return new HttpRequest(`${SERVER_ACTIONS.API + SERVER_ACTIONS.GET_KRMATERIAL_ATTRIBUTES}?ManufacturerId=${manufacturerId}`, 'get', {})
            .requestWithAuth()
            .then(response => {
                // document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                
                    
                    return Promise.resolve(response.data.data.KRMaterialAttributeValues);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);


            });
        
}
export function deleteKRMaterialAttribute(id) {
 
    let params ={};
        params.id =id;
        params.modified_by = 0;
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.DELETE_KRMATERIAL_ATTRIBUTE,'DELETE', params)
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                    return Promise.resolve(response.data);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);


            });
      
}


//////////////////////////////// KRRailLength //////////////////////////

export function addKRRailLength(manufacturerId,series_id,data) {
    
    const parameters = { manufacturerId: '', series_id:'',KRRailLengthValues: {}, Created_By: '' };
    parameters.manufacturerId = manufacturerId;
    parameters.KRSeriesID=series_id;
    parameters.KRRailLengthValues = data;
    parameters.Created_By = 1;
    document.getElementById("loaderdiv").classList.remove("d-none");
    return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.POST_KRRAILLENGTH_ATTRIBUTES,'POST',parameters)
        .requestWithAuth()
        .then(response => {
            document.getElementById("loaderdiv").classList.add("d-none");
            if (response.status === 200 && !response.data.errorCode) {
            
                
                return Promise.resolve(response.data);
            }
            else {
                return Promise.reject(response.data);
            }


        }, error => {
            document.getElementById("loaderdiv").classList.add("d-none");
            return Promise.reject(error);

        });
    
}

export function getKRRailLengthAttributes(query) {
    
        
    // document.getElementById("loaderdiv").classList.remove("d-none");
    return new HttpRequest(`${SERVER_ACTIONS.API + SERVER_ACTIONS. GET_KRRAILLENGTH_ATTRIBUTES}${query}`,'get', {})
            .requestWithAuth()
            .then(response => {
                // document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                
                    
                    return Promise.resolve(response.data.data.KRRailLengthValues);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);


            });
        
}
export function deleteKRRailLengthAttribute(id) {
 
    let params ={};
        params.id =id;
        params.modified_by = 0;
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.DELETE_KRRAILLENGTH_ATTRIBUTE,'DELETE', params)
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                    return Promise.resolve(response.data);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);


            });
      
}

/////////////////////////////////////Rail Block/////////////////////////////////////

export function addRailBlock(manufacturerId,rail_Id,data) {
    
    const parameters = { ManufacturerId: '',RailId:'', Created_By: '',RailBlockMappingValueModel: {} };
    parameters.ManufacturerId = manufacturerId;
    parameters.RailId=rail_Id;
    parameters.CreatedBy = 1;
    parameters.RailBlockMappingValueModel = data;
    
    document.getElementById("loaderdiv").classList.remove("d-none");
    return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.POST_RAILBLOCK_ATTRIBUTES,'POST',parameters)
        .requestWithAuth()
        .then(response => {
            document.getElementById("loaderdiv").classList.add("d-none");
            if (response.status === 200 && !response.data.errorCode) {
            
                
                return Promise.resolve(response.data);
            }
            else {
                return Promise.reject(response.data);
            }


        }, error => {
            document.getElementById("loaderdiv").classList.add("d-none");
            return Promise.reject(error);


        });
    



}



export function getRailBlockAttributes(query) {
    
        
    // document.getElementById("loaderdiv").classList.remove("d-none");
    return new HttpRequest(`${SERVER_ACTIONS.API + SERVER_ACTIONS.GET_RAILBLOCK_ATTRIBUTES}${query}`, 'get', {})
            .requestWithAuth()
            .then(response => {
                // document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                
                    
                    return Promise.resolve(response.data.data.RailBlockMappingValueModel);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);


            });
        
}
export function deleteRailBlockAttribute(id) {
 
    let params ={};
        params.id =id;
        params.modified_by = 0;
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.DELETE_RAILBLOCK_ATTRIBUTE,'DELETE', params)
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                    
                    return Promise.resolve(response.data);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);


            });

}

/////////////////////////////////////KR Series Material/////////////////////////////////////------>Pending API

export function addKRSeriesMaterial(manufacturerId,krseries_Id,data) {
    
    const parameters = { ManufacturerId: '',KRSeriesId:'',KRSeriesMaterialValues: {} };
    parameters.ManufacturerId = manufacturerId;
    parameters.KRSeriesId=krseries_Id;
    parameters.KRSeriesMaterialValues = data;
    
    document.getElementById("loaderdiv").classList.remove("d-none");
    return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.POST_KRSERIES_MATERIAL_ATTRIBUTES,'POST',parameters)
        .requestWithAuth()
        .then(response => {
            document.getElementById("loaderdiv").classList.add("d-none");
            if (response.status === 200 && !response.data.errorCode) {
            
                
                return Promise.resolve(response.data);
            }
            else {
                return Promise.reject(response.data);
            }


        }, error => {
            document.getElementById("loaderdiv").classList.add("d-none");
            return Promise.reject(error);


        });
    



}



export function getKRSeriesMaterialAttributes(query) {
    
        
    // document.getElementById("loaderdiv").classList.remove("d-none");
    return new HttpRequest(`${SERVER_ACTIONS.API + SERVER_ACTIONS.GET_KRSERIES_MATERIAL_ATTRIBUTES}${query}`, 'get', {})
            .requestWithAuth()
            .then(response => {
                // document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                
                    
                    return Promise.resolve(response.data.data.KRSeriesMaterialValues);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);


            });
        
}
export function deleteKRSeriesMaterialAttribute(id) {
 
    let params ={};
        params.id =id;
        params.modified_by = 0;
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.DELETE_KRSERIES_MATERIAL_ATTRIBUTE,'DELETE', params)
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                    
                    return Promise.resolve(response.data);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);


            });

}

/////////////////////////////////////Size Block/////////////////////////////////////

export function addSizeBlock(manufacturerId,size_Id,data) {
    
    const parameters = { ManufacturerId: '',SizeId:'',CreatedBy: '',SizeBlockMappingValueModel: {} };
    parameters.ManufacturerId = manufacturerId;
    parameters.SizeId=size_Id;
    parameters.CreatedBy = 1;
    parameters.SizeBlockMappingValueModel = data;
    
    document.getElementById("loaderdiv").classList.remove("d-none");
    return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.POST_SIZEBLOCK_ATTRIBUTES,'POST',parameters)
        .requestWithAuth()
        .then(response => {
            document.getElementById("loaderdiv").classList.add("d-none");
            if (response.status === 200 && !response.data.errorCode) {
            
                
                return Promise.resolve(response.data);
            }
            else {
                return Promise.reject(response.data);
            }


        }, error => {
            document.getElementById("loaderdiv").classList.add("d-none");
            return Promise.reject(error);


        });
    



}



export function getSizeBlockAttributes(query) {
    
        
    // document.getElementById("loaderdiv").classList.remove("d-none");
    return new HttpRequest(`${SERVER_ACTIONS.API + SERVER_ACTIONS.GET_SIZEBLOCK_ATTRIBUTES}${query}`, 'get', {})
            .requestWithAuth()
            .then(response => {
                // document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                
                    
                    return Promise.resolve(response.data.data.SizeBlockMappingValueModel);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);


            });
        
}
export function deleteSizeBlockAttribute(id) {
 
    let params ={};
        params.id =id;
        params.modified_by = 0;
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.DELETE_SIZEBLOCK_ATTRIBUTE,'DELETE', params)
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                    
                    return Promise.resolve(response.data);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);


            });
}


/////////////////////////////////////Size Accessory/////////////////////////////////////------>Pending API

export function addSizeAccessory(manufacturerId,size_Id,data) {
    
    const parameters = { ManufacturerId: '',SizeId:'',CreatedBy:'',SizeAccessoryMappingValueModel: {} };
    parameters.ManufacturerId = manufacturerId;
    parameters.SizeId=size_Id;
    parameters.CreatedBy="";
    parameters.SizeAccessoryMappingValueModel = data;
    
    document.getElementById("loaderdiv").classList.remove("d-none");
    return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.POST_SIZE_ACCESSORY_ATTRIBUTES,'POST',parameters)
        .requestWithAuth()
        .then(response => {
            document.getElementById("loaderdiv").classList.add("d-none");
            if (response.status === 200 && !response.data.errorCode) {
            
                
                return Promise.resolve(response.data);
            }
            else {
                return Promise.reject(response.data);
            }


        }, error => {
            document.getElementById("loaderdiv").classList.add("d-none");
            return Promise.reject(error);


        });
    



}



export function getSizeAccessoryAttributes(query) {
    
        
    // document.getElementById("loaderdiv").classList.remove("d-none");
    return new HttpRequest(`${SERVER_ACTIONS.API + SERVER_ACTIONS.GET_SIZE_ACCESSORY_ATTRIBUTES}${query}`, 'get', {})
            .requestWithAuth()
            .then(response => {
                // document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                
                    
                    return Promise.resolve(response.data.data.SizeAccessoryMappingValueModel);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);


            });
        
}
export function deleteSizeAccessoryAttribute(id) {
 
    let params ={};
        params.id =id;
        params.modified_by = 0;
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.DELETE_SIZE_ACCESSORY_ATTRIBUTE,'DELETE', params)
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                    
                    return Promise.resolve(response.data);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);


            });

}


////////////////////////////////Length//////////////////////////////////////////

export function addLength(manufacturerId,size_Id,data) {
    
    const parameters = { ManufacturerId: '',SizeId:'',CreatedBy:'',LengthAttributeValueModel: {} };
    parameters.ManufacturerId = manufacturerId;
    parameters.SizeId=size_Id;
    parameters.CreatedBy="";
    parameters.LengthAttributeValueModel = data;
    
    document.getElementById("loaderdiv").classList.remove("d-none");
    return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.POST_LENGTH_ATTRIBUTES,'POST',parameters)
        .requestWithAuth()
        .then(response => {
            document.getElementById("loaderdiv").classList.add("d-none");
            if (response.status === 200 && !response.data.errorCode) {
            
                
                return Promise.resolve(response.data);
            }
            else {
                return Promise.reject(response.data);
            }


        }, error => {
            document.getElementById("loaderdiv").classList.add("d-none");
            return Promise.reject(error);


        });
    



}



export function getLengthAttributes(query) {
    
        
    // document.getElementById("loaderdiv").classList.remove("d-none");
    return new HttpRequest(`${SERVER_ACTIONS.API + SERVER_ACTIONS.GET_LENGTH_ATTRIBUTES}${query}`, 'get', {})
            .requestWithAuth()
            .then(response => {
                // document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                
                    
                    return Promise.resolve(response.data.data.LengthAttributeValueModel);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);


            });
        
}
export function deleteLengthAttribute(id) {
 
    let params ={};
        params.id =id;
        params.modified_by = 0;
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.DELETE_LENGTH_ATTRIBUTE,'DELETE', params)
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                    
                    return Promise.resolve(response.data);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);


            });

}

//////////////////////////Dropdown RailBlock/////////////////////////
export function getBlockByManufacturerList(manufacturerId) {
    // 
    //     const queryString = '?ManufacturerId='+manufacturerId;
    //     return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_BLOCK_ATTRIBUTES}${queryString}`, 'get', {}).requestWithAuth().then(
    //         (response) => {
                
    //             //dispatch(getAllBlockTypes(response.data.data.BlockAttributeValueModel));
    //             return Promise.resolve(response.data.data.BlockAttributeValueModel);
    //         },
    //         (error) => {
                
    //             return Promise.reject(error);
    //         }
    //     );

    const queryString = '?ManufacturerId='+manufacturerId;
    return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_BLOCK_ATTRIBUTES}${queryString}`, 'get', {})
            .requestWithAuth()
            .then(response => {
               
                if (response.status === 200 && !response.data.errorCode) {
                
                    
                    return Promise.resolve(response.data.data.BlockAttributeValueModel);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
               
                return Promise.reject(error);


            });
        
}





/////////////////////////////////////Bellow Mapping API//////////////////////////////////////////

export function addBellowsMapping(manufacturerId,data) {
    
    const parameters = { ManufacturerId: '',bellowsMappingAttributes: {} };
    parameters.ManufacturerId = manufacturerId;
    
    parameters.bellowsMappingAttributes = data;
    parameters.CreatedBy = 1;
    debugger
    document.getElementById("loaderdiv").classList.remove("d-none");
    return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.POST_BELLOWSMAPPING_ATTRIBUTES,'POST',parameters)
        .requestWithAuth()
        .then(response => {
            document.getElementById("loaderdiv").classList.add("d-none");
            if (response.status === 200 && !response.data.errorCode) {
                        return Promise.resolve(response.data);
            }
            else {
                return Promise.reject(response.data);
            }
        }, error => {
            document.getElementById("loaderdiv").classList.add("d-none");
            return Promise.reject(error);
        });
}

export function getBellowsMappingAttributes(query) {
    // document.getElementById("loaderdiv").classList.remove("d-none");
    return new HttpRequest(`${SERVER_ACTIONS.API + SERVER_ACTIONS.GET_BELLOWSMAPPING_ATTRIBUTES}${query}`, 'GET', {})
            .requestWithAuth()
            .then(response => {
                // document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {   
                    return Promise.resolve(response.data.data.bellowsMappingAttributes);
                }
                else {
                    return Promise.reject(response.data);
                }
            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);
            });       
}

export function deleteBellowsMappingAttribute(id) {
    let params ={};
        params.id =id;
        params.modified_by = 0;
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.DELETE_BELLOWSMAPPING_ATTRIBUTE,'DELETE', params)
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {   
                    return Promise.resolve(response.data);
                }
                else {
                    return Promise.reject(response.data);
                }
            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);
            });
}













