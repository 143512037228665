import React, { Component } from 'react';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { getAllRoleList } from '../../CommonApiActions';

class RoleDropDown extends React.Component {
    constructor(props) {
        super(props);
        //console.log(this.props.isAdmin);
        this.state = ({
            allRoles: [],
            isAdmin: this.props.isAdmin,
            isGuest: this.props.isGuest
        })
    }

    componentWillReceiveProps(props) {

        if(props.isGuest!= undefined){
            if(props.isGuest != this.state.isGuest)
            {
                this.props.getRoleList(props.isAdmin, props.isGuest);
                this.state.isGuest = props.isGuest;
            }
            else if(props.isAdmin == true){
                this.props.getRoleList(props.isAdmin, props.isGuest);
            }
        }
    }

    componentDidMount(){
        
        this.props.getRoleList(this.state.isAdmin, this.state.isGuest);

    }

    render() {
        const selectedValue = this.props.selectedValue;
        // const selectedName = this.props.selectedName;
        
        //console.log(selectedValue);
        const roles = this.props.allRoles;

        const options = roles && roles.length > 0 ? (
            roles.map((role) => {

                return (<MenuItem name={role.name} value={role.role_id}>
                        {role.name}
                        </MenuItem>
                );
            })
        ) : (
                <MenuItem>Loading....</MenuItem>
            );
        //console.log({ options })
        return (

            <Select defaultValue={selectedValue}
                // name= {selectedName}
                labelId="demo-simple-select-label"
                id="demo-simple-select" onChange={this.props.onRoleChange}>
                {options}
            </Select>
        );
    }

}

const mapProps = (state) => {
   
    return {
        allRoles: state.common.allRoles
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getRoleList: (isAdmin, isGuest) => {
            return new Promise((resolve, reject) => {
                dispatch(getAllRoleList(isAdmin, isGuest))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    };
};

export default connect(mapProps, mapDispatchToProps)(RoleDropDown);
