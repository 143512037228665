import React, { Component } from 'react'
import { SafeAnchor, Modal } from 'react-bootstrap';
// import { Formik, Form, Field } from 'formik';
import Button from '@material-ui/core/Button';
import { Field, FieldArray, Form, Formik } from 'formik';
import { FormikTextField } from 'formik-material-fields';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { TextField } from 'formik-material-ui';
import {getSizeBlockAttributes,deleteSizeBlockAttribute,addSizeBlock} from '../../components/ManageAttributeApiAction';
import Icon from '@material-ui/core/Icon';
import SizeByManufacturer from '../../../Common/components/DropDown/SizeByManufacturer';
import BlockByManufacturerDropDown from '../../../Common/components/DropDown/BlockByManufacturerDropDown';
import {getBlockByManufacturerList,getSizeListByManufacturer} from '../ManageAttribute/EditDropDownListApi';
import swal from 'sweetalert';
import{StyledReactTooltip} from '../ToolTip';
import Swal from 'sweetalert2';

const { Header, Body, Footer, Title } = Modal;

export default class EditSizeBlock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showform: false,
            manufacturer_name: "",
            manufacturer_id: this.props.manufacturer_id,
            size_id: 0,
            size_name: '',
            prev_size_id: 0,
            prev_size2_id: 0,
            size_id: 0,
            blocks:[{
                BlockID: 0 ,
                BlockType: "",
            }],
            defaultSizeValue:0,
            sizeByManufacturer:[],
            selectedValue:0,
            sizeBlock:  [{
                SizeBlockId: 0,
                BellowId:"",
                DefaultSelection:"",
                        
                        }
                   ]
        };

    }
    componentDidMount() {
        this.getSize(this.props.manufacturer_id);
        //console.log(this.state.sizeByManufacturer);
        
    }
    componentWillReceiveProps(props) {
        this.setState({ manufacturer_id: this.props.manufacturer_id });
        if(this.props.manufacturer_id!=undefined){
        this.getBlock(this.props.manufacturer_id );
        this.getSize(this.props.manufacturer_id);
        }
       
        //console.log("componentWillReceiveProps");

    }
    componentDidUpdate() {
        const sizeValue=this.state.sizeByManufacturer[0]!=undefined?(this.state.sizeByManufacturer[0]):(0);
        const sizeid=sizeValue.SizeId
       if(this.state.prev_size2_id!=sizeValue.SizeId && sizeValue.SizeId!=undefined)
       {
        //    this.setId(sizeValue.SizeId);
           this.setState({size_id:sizeValue.SizeId});
           this.getSizeBlock(sizeid);
          this.setState({prev_size2_id:sizeValue.SizeId});
        
       }
        else if(this.state.prev_size_id!==this.state.size_id){
            this.getSizeBlock(this.state.size_id);
            this.setState({prev_size_id:this.state.size_id})
        }
        
       

       // console.log("componentDidUpdate");

    }
    setId=(sizeValue)=>{
        this.setState({size_id:sizeValue})

    }
    getBlock = (manufacturerId) => {
                
        getBlockByManufacturerList(manufacturerId).then(resp => {
            
            this.setState({

                blocks:resp
            });

        });
    }
    getSize=(manufacturerId)=>{
        getSizeListByManufacturer(manufacturerId).then(resp=>{
            this.setState({

                sizeByManufacturer:resp,
                
            });
        });
    }

    showForm = () => {
        this.setState({ showform: true });
        this.getBlock(this.state.manufacturer_id);
    }


    hideForm = () => {
        this.setState({ showform: false,prev_size2_id:0,size_id: 0,
            sizeBlock:  [{
                SizeBlockId: 0,
                BellowId:"",
                DefaultSelection:"",
                        
                        }
                   ] })
    }
    deleteSizeBlock = (option, arrayHelpers, i) => {
        if (option.SizeBlockId == 0) {
            arrayHelpers.remove(i);
        }
        else {
            Swal.fire({
                //title: 'Are you sure?',
                text: 'This action will delete the record permanently from database, Are you sure?',
                // icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel',
                showCloseButton: true
              }).then((result) => {
                if (result.value) {
                    deleteSizeBlockAttribute(option.SizeBlockId).then(resp => {
                                                arrayHelpers.remove(i);
                                             });
                  
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                  
                }
              })


        // swal("This action will delete the record permanently from database, Are you sure?", {
        //     buttons: {
        //         catch: {
        //             text: "Yes",
        //             value: "Yes",
        //         },
        //         cancel: "Cancel",
        //     },
        // })
        //     .then((value) => {
        //         switch (value) {
        //             case "Yes":
                       
        //                     deleteSizeBlockAttribute(option.SizeBlockId).then(resp => {
        //                         arrayHelpers.remove(i);
        //                      });
                        
        //                 break;
        //             default:

        //         }
        //     });
        }
    }
    // deleteSizeBlock=(option, arrayHelpers, i)=>{
    //     if (option.SizeBlockId == 0) {
    //         arrayHelpers.remove(i);
    //     }
    //     else {


    //         deleteSizeBlockAttribute(option.SizeBlockId).then(resp => {
               
    //             arrayHelpers.remove(i);
               

    //          });
    //     }
    // }
    getSizeBlock = (size_id) =>  {
        size_id = size_id == undefined ? 0 : size_id;
        var manufacturerId = this.state.manufacturer_id == undefined ? 0 : this.state.manufacturer_id;
            const query='?ManufacturerId='+manufacturerId+'&SizeId='+size_id;          
            getSizeBlockAttributes(query).then(res => {
                
                this.setState({
    
                    
                    sizeBlock:res,
                   
                   
                })
                
            })
        }
    handleChangeRailDropdown = (event) => {
        this.setState({ size_id: event.target.value })
        // this.getKRRailLenth(event.target.value);
    };

    // submitFormula = (data, actions) => {
    //     swal("Are you sure want to save?", {
    //         buttons: {
    //             catch: {
    //                 text: "Yes",
    //                 value: "Yes",
    //             },
    //             cancel: "Cancel",
    //         },
    //     })
    //         .then((value) => {
    //             switch (value) {
    //                 case "Yes":
                        
    //                     actions.setSubmitting(true);
    //                     // let updateOptions = data.options.filter(o => (this.state.updatableOptions.indexOf(o.CalulatorformulaeId) > -1 || o.CalulatorformulaeId == 0));
    //                     let sizeBlockAttributeValueModel= data.sizeBlock
                
    //                     addSizeBlock(this.props.manufacturer_id,this.state.size_id,
    //                         sizeBlockAttributeValueModel).then(response => {
                
    //                             if (response.Status && response.StatusCode === 200) {
    //                                 let { sizeBlock } = this.state;
                         
                
    //                                 sizeBlock = sizeBlock.map(sizeBlock => {
    //                                     let option2 = sizeBlockAttributeValueModel.find(o => o.SizeBlockId == sizeBlock.SizeBlockId);
    //                                     return option2 ? { ...sizeBlock, ...option2 } : sizeBlock;
    //                                 })
                                 
    //                                 this.setState({
    //                                     sizeBlock
    //                                 })
                
    //                             }
                
    //                         }, this.hideForm())

                     
    //                     break;
    //                 case "Cancel":
    //                     this.hideForm()
    //                     break;
    //                 default:

    //             }
    //         });
    // }

    submitFormula = (data, actions) => {
        let found=false;
        for(let i=0;i<data.sizeBlock.length;i++){
            
            let rname
            let secondField           
           
                 rname=data.sizeBlock[i].Length;
                 secondField=data.sizeBlock[i].BlockId;
                   
        let foundDuplicate=data.sizeBlock.filter(r=>r.Length==rname && r.BlockId==secondField).map(duplicate=>(duplicate.Length ))
            if(foundDuplicate.length>=2)
            {found=true;
                actions.setSubmitting(false)
                swal(rname+" attribute is already exist", {
                    buttons: {
                            cancel: "OK",
                    },
                })
                    .then((cancel) => {
                        switch (cancel) {
                            case "Yes":                        
                                break;
                            default:
                        }
                    });
            } 
        }
        if(found==false){
            actions.setSubmitting(true);
            // let updateOptions = data.options.filter(o => (this.state.updatableOptions.indexOf(o.CalulatorformulaeId) > -1 || o.CalulatorformulaeId == 0));
            let sizeBlockAttributeValueModel= data.sizeBlock
    
            addSizeBlock(this.props.manufacturer_id,this.state.size_id,
                sizeBlockAttributeValueModel).then(response => {
    
                    if (response.Status && response.StatusCode === 200) {
                        let { sizeBlock } = this.state;
             
    
                        sizeBlock = sizeBlock.map(sizeBlock => {
                            let option2 = sizeBlockAttributeValueModel.find(o => o.SizeBlockId == sizeBlock.SizeBlockId);
                            return option2 ? { ...sizeBlock, ...option2 } : sizeBlock;
                        })
                     
                        this.setState({
                            sizeBlock
                        })
    
                    }
    
                }, this.hideForm())
        }
       
       

    }
    callchild=(child)=>{
        this.setState({size_id:child})

    }

    render() {

        const manufacturer_id = this.props.manufacturer_id;
        const manufacturer_name = this.props.manufacturer_name;
        const blocks=this.state.blocks;
        let defaultBlockType=0;
        if(this.state.blocks.length>0)
        {
         defaultBlockType=this.state.blocks[0].BlockID!=undefined?(this.state.blocks[0].BlockID):(0);

        }
        const options = blocks && blocks.length > 0 ? (
            blocks.map((blocks) => {

                return (<option   value={blocks.BlockID}>
                    {blocks.BlockType}
                </option>
                );
            })
        ) : (
                <option>Loading....</option>
            );
        //console.log({ krraillength: this.state.krraillength })
        return (
            <>
                {!this.state.showform ?<>
                    <Icon   data-tip data-for="edit" onClick={this.showForm}>
                        edit
                 </Icon>
                 <StyledReactTooltip id="edit" effect="solid">
                  <span>Edit</span>
                 </StyledReactTooltip></>
                    : <>
                        <Modal className="calculatorPopup" show={this.state.showform} onHide={this.hideForm}>
                            <Header closeButton>
                                <Title>{manufacturer_name}</Title>
                            </Header>
                            <Body>

                                <Formik
                                    onSubmit={this.submitFormula}
                                    initialValues={{ sizeBlock: this.state.sizeBlock }}
                                    enableReinitialize={true}
                                >
                                    {({ values, status, isSubmitting,setFieldValue }) => (
                                        <Form>
                                            <div className="row">
                                                <div className="col-lg-12 popup-heading">
                                                    <label >ATTRIBUTE NAME :SIZE-BLOCK</label>
                                                </div>
                                                <div className="col-lg-4">
                                               
                                                    <FormControl  margin="normal" fullWidth>
                                                    <div className="ManufacturerDropDown">
                                                        <InputLabel id="demo-simple-select-label">Size*</InputLabel>
                                                        <SizeByManufacturer selectedValue={this.state.size_id} enabled={true} activeOnly={true} manufacturerId={manufacturer_id} onSizeChange={(e, index) => {
                                                          
                                                           this.handleChangeRailDropdown(e)
                                                          
                                                            this.setState({
                                                                size_id: e.target.value,
                                                                size_name: index.props.name
                                                                
                                                            });
                                                            
                                                        }
                                                        } />
                                                        </div>
                                                    </FormControl>
                                                </div> 
                                                </div>
                                           

                                            <div className="row">
                                        <div className="col-lg-4  col-sm-3  col-4">
                                        <label >Length</label>
                                        </div> 
                                        <div className="col-lg-4  col-sm-4  col-6">
                                        <label >Block Type</label>
                                        </div> 
                                       
                                        </div> 
                                            <FieldArray
                                                name="sizeBlock"

                                                render={arrayHelpers => (
                                                    <div className="clacfor">

                                                        {values.sizeBlock && values.sizeBlock.length > 0 ? values.sizeBlock.map((sizeBlock, i) => {

                                                            return (<div className="row" key={i}>
                                                                <div className="col-lg-4  col-sm-3  col-4">
                      
                                                                                                
                                                                <FormikTextField                            
                                                                    name={`sizeBlock[${i}].Length`}
                                                                    defaultValue={sizeBlock.Length}
                                                                    margin="normal"
                                                                    fullWidth                  
                                                                />
                                                            </div>   
                                                             <div className="col-lg-4  col-sm-4  col-6">

                                                             <FormControl  className="FormulaDropdown" margin="normal" fullWidth>
                                                                <div className="ManufacturerDropDown">
                                                       
                                                                {/* <BlockByManufacturerDropDown  dropdownname={`sizeBlock[${i}.BlockId]`}
                                                                 selectedValue={sizeBlock.BlockId} enabled={true} 
                                                                 activeOnly={true}  manufacturerId={manufacturer_id} 
                                                                 onBlockChange={(e, index) => {
                                                                  setFieldValue(`sizeBlock[${i}.BlockId]`, e.target.value);
                                                                
                                                            
                                                        }
                                                        } /> */}

                                                        <Field component="select" style={{width:140}} defaultValue={sizeBlock.BlockId} 
                                                        name={`sizeBlock[${i}.BlockId]`}
                                                        labelId="demo-simple-select-label"
                                                          id="demo-simple-select" onChange={(e, index) => {
                                                            setFieldValue(`sizeBlock[${i}.BlockId]`, e.target.value);
                                                            defaultBlockType= e.target.value;
                                                  }
                                                  }>
                                                           {options}
                                                        </Field>
                                                        </div>
                                                    </FormControl>

                                                            </div>                                                                

                                                                <SafeAnchor className="btn btn-default">
                                                                    <i className="fa fa-minus" onClick={() => { this.deleteSizeBlock(sizeBlock, arrayHelpers, i) }} /></SafeAnchor>
                                                                <br />
                                                            </div>);
                                                        }) : arrayHelpers.push({ SizeBlockId: 0})}
                                                        <SafeAnchor className="btn btn-default" onClick={e => {
                                                            arrayHelpers.push({SizeBlockId: 0 ,BlockId: defaultBlockType})
                                                        }}><i className="fa fa-plus" /></SafeAnchor>
                                                    </div>
                                                )} />
                                            {/* <input type="submit" className="submit" disabled={isSubmitting} /> */}
                                            <Modal.Footer className="row" centered>
                                              <Button variant="contained" type= "submit"  className="save" disabled={isSubmitting} >Submit</Button>
                                            </Modal.Footer>
                                        </Form>
                                    )}
                                </Formik>
                            </Body>
                        </Modal>

                    </>}
            </>

        )
    }
}