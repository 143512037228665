import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Modal, Button, Col, Container, Row, SafeAnchor, ButtonGroup } from 'react-bootstrap';
import { saveManufacturerItem } from '../../ManageManufacturersApiActions';
import ManufacturerFormSchema from './ManufacturerFormSchema';
import ImageComponent from '../../../Common/components/ImageComponent';

const { Header, Body, Footer, Title } = Modal;

class ManufacturerForm extends React.Component {

    constructor(props) {
        super(props)

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);

        let manufacturer = props.update ? props.manufacturer : {
            email: '',
            first_name: '',
            last_name: '',
            role:''
        };
         
        
        this.state = {

            show: false,
            message: '',
            manufacturer: manufacturer
        };
        this.saveManufacturer = this.saveManufacturer.bind(this);
    }

    saveManufacturer = (data, formAction) => {

        formAction.setSubmitting(true);
        this.props.saveManufacturer(data, formAction).then(response => {
            this.setState({ message: response.message });
            formAction.setSubmitting(false);
            if (this.props.updateList && typeof this.props.updateList === 'function') {
                this.props.updateList();
            }
            this.handleClose();
        }, error => {
            formAction.setSubmitting(false);
        });

    };


    handleClose() {
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true, message: '' });
    }

    onChange = date => this.setState({ date })

    render() {

        return (
            <>
                {
                    !this.props.update ?

                        <span className="input-group-btn" style={{ float:"right" }}>

                            <Button onClick={this.handleShow} variant="secondary">
                                <i className="fa fa-plus"></i>&nbsp;
                                Add Manufacturer
            </Button>
                        </span> :


                        <SafeAnchor className='edit-user' onClick={this.handleShow}>
                            <i className="fa fa-edit"></i>
                        </SafeAnchor>

                }

                <Modal show={this.state.show} onHide={this.handleClose} backdrop="static">
                    <Header closeButton>
                        <Title>
                            {this.props.title}
                        </Title>
                    </Header>
                    <Body>
                    <Container>
                            <div className="row">
                                <div className="col-md-12">
                                    <span className="red small right" style={{ float: "right" }}>* marked fields are mandatory</span>
                                </div>
                            </div>
                        <Formik
                            validationSchema={ManufacturerFormSchema}
                            initialValues={this.state.manufacturer}
                            onSubmit={this.saveManufacturer}
                            enableReinitialize={true}
                        >
                            {({ errors, status, touched, isSubmitting, setFieldValue }) => (
                                <Form>
                                    <div className="card-body">

                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-6 mb-1">
                                                <div className="form-group">
                                                    <label>
                                                    Manufacturer Name
                                                        <i className="red">*</i>
                                                        </label>
                                                    <div className="controls">
                                                        <Field type="text" name="name" className="form-control"
                                                            placeholder="Enter Manufacturer Name"></Field>
                                                        <ErrorMessage name="name" style={{ color: 'red' }} component="div" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-6 col-lg-6 col-md-6 mb-1">
                                                <div className="form-group">
                                                    <label>
                                                        Display Name
                                                        <i className="red">*</i>
                                                        </label>
                                                    <div className="controls">
                                                        <Field type="text" name="display_name" className="form-control"
                                                            placeholder="Enter Manufacturer Name"></Field>
                                                        <ErrorMessage name="display_name" style={{ color: 'red' }} component="div" />
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-xl-6 col-lg-6 col-md-6 mb-1">
                                                <div className="form-group">
                                                    <label>
                                                    short description
                                                        <i className="red">*</i>
                                                        </label>
                                                    <div className="controls">
                                                        <Field type="text" name="short_description" className="form-control"
                                                            placeholder="Enter Manufacturer Name"></Field>
                                                        <ErrorMessage name="short_description" style={{ color: 'red' }} component="div" />
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="col-xl-6 col-lg-6 col-md-6 mb-1">
                                                <div className="form-group">
                                                    <label>
                                                    long description
                                                        <i className="red">*</i>
                                                        </label>
                                                    <div className="controls">
                                                        <Field type="text" name="long_description" className="form-control"
                                                            placeholder="Enter Manufacturer Name"></Field>
                                                        <ErrorMessage name="long_description" style={{ color: 'red' }} component="div" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-6 col-lg-6 col-md-6 mb-1">
                                                <div className="form-group">
                                                    <label>
                                                    Sort Order
                                                        <i className="red">*</i>
                                                        </label>
                                                    <div className="controls">
                                                        <Field type="text" name="sort_order" className="form-control"
                                                            placeholder="Enter Sort Order"></Field>
                                                        <ErrorMessage name="sort_order" style={{ color: 'red' }} component="div" />
                                                    </div>
                                                </div>
                                            </div>
                                            

                                            <div className="col-xl-6 col-lg-6 col-md-6 mb-1">
                                                <div className="form-group mb-1">
                                                    <label>
                                                        Image
                                                        <i className="red">*</i>
                                                    </label>
                                                    <div className="controls">

                                                        {(this.state.manufacturer.manufacturerImageShow && this.state.manufacturer.manufacturerImageString) ? <>
                                                            <ImageComponent src={`data:image/png;base64, ${this.state.manufacturer.manufacturerImageString}`}>

                                                            </ImageComponent>

                                                            <Field type="hidden" name="manufacturerImagePath"  ></Field>

                                                        </> :
                                                            <input type="file" id="manufacturerImage"
                                                                name="manufacturerImage" className="form-control"
                                                                onChange={(e) => {
                                                                    setFieldValue('manufacturerImage', e.currentTarget.files[0])
                                                                }} />

                                                        }

                                                        {(this.state.manufacturer.manufacturerImagePath && this.state.manufacturer.manufacturerImagePath.length > 0) ? <>
                                                            <SafeAnchor onClick={this.toggleManufacturerImageUploadField}>
                                                                {(this.state.manufacturer.manufacturerImageShow) ? "Change Image" : "Cancel"}
                                                            </SafeAnchor>
                                                        </> :
                                                            <ErrorMessage name="manufacturerImage" style={{ color: 'red' }} component="div" />}
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="col-xl-6 col-lg-6 col-md-6 mb-1">
                                                <div className="form-group">
                                                    <label>
                                                    Image alt name
                                                        <i className="red">*</i>
                                                        </label>
                                                    <div className="controls">
                                                        <Field type="text" name="thumbnail_alt" className="form-control"
                                                            placeholder="Enter Sort Order"></Field>
                                                        <ErrorMessage name="thumbnail_alt" style={{ color: 'red' }} component="div" />
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-xl-6 col-lg-6 col-md-6 mb-1">
                                                <Field type="hidden" name="id" value={this.state.manufacturer.manufacturer_id} readOnly>

                                                </Field>
                                                {status && status.msg && <div style={{ color: 'red' }}>{status.msg}</div>}
                                            </div>
                                        </div>
                                        <div style={{ float: "right" }}>
                                                <ButtonGroup >
                                                    <Button type="submit" disabled={isSubmitting} variant={"primary"}>
                                                        <i className="ft-unlock" /> Save</Button>
                                                    &nbsp;
                                                <Button type="reset" disabled={isSubmitting} variant={"secondary"}>
                                                        <i className="fa fa-undo" /> Reset</Button>
                                                </ButtonGroup>
                                            </div>
                                    </div>
                                </Form>
                            )}

                        </Formik>
                        </Container>
                    </Body>
                </Modal>

            </>
        )
    }

}



const mapProps = state => ({
})

const mapDispatch = (dispatch, ownProps) => {

    return {
        saveManufacturer: (manufacturer, formAction) => {

            return new Promise((resolve, reject) => {
                dispatch(saveManufacturerItem(manufacturer, formAction)).then(response => {

                    resolve(response);
                    // if (response.status == 200 && !response.data.errorCode) {
                    //     window.Message('Saved Successfully', discount.id > 0 ? `${discount.name} Updated....` : `${discount.name} Created....`);
                    // }
                }).catch(error => {
                    reject(error);
                });
            });
        }
    }
}

export default connect(mapProps, mapDispatch)(ManufacturerForm);
