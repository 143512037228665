import Auth from '../../utils/Auth';
import { SIGN_IN, REGISTER_CUSTOMER, GUEST_SIGN_IN, GUEST_REGISTER, EDIT_PROFILE, SHOW_PROFILE_DATA, CHANGE_PASSWORD, FORGOT_PASSWORD, EDIT_ADMIN_PROFILE } from './AuthenticationActions';

const auth = new Auth();

const initialState = { isLoggedIn: auth.isLoggedIn(), profile: {} };

const AuthenticationReducer = (state = initialState, action) => {
 
    switch (action.type) {
        case SIGN_IN:
            return Object.assign({}, state, {
                isLoggedIn: action.isLoggedIn
            });
        case REGISTER_CUSTOMER:
            return Object.assign({}, state, {
                profile: action.profile
            });

        case EDIT_ADMIN_PROFILE:
            return Object.assign({}, state, {
                profile: action.adminProfile
            });

        default:
            return state;
    }
}

export default AuthenticationReducer;