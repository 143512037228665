import {SERVER_ACTIONS} from '../../utils/Constants';
import HttpRequest from '../../utils/HttpRequest';
import Auth from '../../utils/Auth';
import { updateRoles, addRoleToList, updateRoleInList, deleteRoleFromListItem,getAllAttribute} from './ManageRolesActions';

const auth  = new Auth();

export function getRoleList({query, PageNumber, defaultPageSize}) {

    return (dispatch) => {
        const queryString = (query && query.trim().length > 0) ? `?${query}` : '';
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.POST_ROLES}${queryString}`, 'post', {})
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {

                dispatch(updateRoles(response.data, Math.ceil(response.data.totalrecord / defaultPageSize), PageNumber));
                return Promise.resolve(response.data);
                }
                else{
                    return Promise.reject(response.data);
                }
            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);
            });
    }
}


export function saveRoleItem(role, formAction) {
    formAction.setSubmitting(true)
    return (dispatch) => {
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(`${SERVER_ACTIONS.POST_CREATE_ROLE}`, 'post', role)
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                formAction.setSubmitting(false);
                if (response.status === 200 && !response.data.errorCode) {
                    window.Message(response.data.message, 'Success');

                    if (role.role_id == 0) {

                        dispatch(addRoleToList(response.data.data));

                    } else {
                        dispatch(updateRoleInList(response.data.data));
                    }

                    return Promise.resolve(response);
                } else {
                    formAction.setStatus({ msg: response.data.message });
                    return Promise.reject(response);
                }
            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (error.response.data) {
                    formAction.setStatus({ msg: error.response.data.message });
                }
                //window.Message(error.response.data.message, 'Error',true);
                formAction.setSubmitting(false);
                return Promise.reject(error);
            });
    }
}


export function toggleStatusRoleItem(id) {
    let params = {};
    params.id = id;


    return (dispatch) => {
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(SERVER_ACTIONS.API+SERVER_ACTIONS.POST_STATUS_ROLE, 'POST', params)
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                    window.Message(response.data.message, 'Success');
                    
                    dispatch(updateRoleInList(response.data.data));
                }

                return Promise.resolve(response);
            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);
            });
    }
}

export function deleteRoleFromList(id) {
    return (dispatch) => {
        let params ={};
        params.id =id;
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(SERVER_ACTIONS.API+SERVER_ACTIONS.DELETE_ROLE, 'DELETE', params).requestWithAuth().then(
            (response) => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                    window.Message(response.data.message, 'Success');
                    dispatch(deleteRoleFromListItem(response.data.data));
                }

                return Promise.resolve(response.data);
            },
            (error) => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);
            }
        );
    };
}


//Get ALL OUTPUT Attribute By Calculator and ROLE_Id
export function getAllAttributeList(RoleId,CalculatorId) {
    return (dispatch) => {
        
        const queryString = '?RoleId='+RoleId+'&CalculatorId='+CalculatorId;
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_ROLE_ATTRIBUTE}${queryString}`, 'get', {}).requestWithAuth().then(
            (response) => {
                document.getElementById("loaderdiv").classList.add("d-none");
                dispatch(getAllAttribute(response.data));
                return Promise.resolve(response.data);
            },
            (error) => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);
            }
        );
    };
}


export function saveRoleAttribute(roleAttribute, formAction) {
    formAction.setSubmitting(true)
    return (dispatch) => {
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(`${SERVER_ACTIONS.POST_ROLE_ATTRIBUTE}`, 'post', roleAttribute)
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                formAction.setSubmitting(false);
                if (response.status === 200 && !response.data.errorCode) {
                    window.Message(response.data.message, 'Success');

                    return Promise.resolve(response);
                } else {
                    formAction.setStatus({ msg: response.data.message });
                    return Promise.reject(response);
                }
            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (error.response.data) {
                    formAction.setStatus({ msg: error.response.data.message });
                }
                //window.Message(error.response.data.message, 'Error',true);
                formAction.setSubmitting(false);
                return Promise.reject(error);
            });
    }
}
