export const UPDATE_MENU = 'UPDATE_MENU';
export const UPDATE_CUSTOMER_COUNT = 'GET_CUSTOMER_COUNT';
export const UPDATE_TOP_CUSTOMERS = 'GET_TOP_CUSTOMERS';
export const UPDATE_ORDER_COUNT = 'UPDATE_ORDER_COUNT';
export const UPDATE_RECENT_ORDER = 'UPDATE_RECENT_ORDER';
export const UPDATE_FORM_RESPONSE = 'UPDATE_FORM_RESPONSE';

export function updateMenu(menu) {
    return {
        type: UPDATE_MENU,
        menu
    }
}

export function updateCustomerCount(count){
    return{
        type: UPDATE_CUSTOMER_COUNT,
        count
    }
}

export function updateTopCustomers(customers){
    return{
        type: UPDATE_TOP_CUSTOMERS,
        customers
    }
}

export function updateOrderCount(count){
    return{
        type: UPDATE_ORDER_COUNT,
        count
    }
}

export function updateRecentOrders(orders){
    return{
        type: UPDATE_RECENT_ORDER,
        orders
    }
}

export function updateFormResponse(count){
    return{
        type: UPDATE_FORM_RESPONSE,
        count
    }
}