
import {MANUFACTURER_LIST, ADD_CALCULATOR_IN_LIST,UPDATE_CALCULATOR, UPDATE_CALCULATOR_STATUS_IN_LIST,DELETE_CALCULATOR_BY_ID,FORMULA_BY_CALCULATOR_ID } from './CalculatorActions';

const initialState = { calculatorList: [] ,getCalculatorFormula:[],manufacturerList:[]};


const calculator = (state = initialState, action) => {
    switch (action.type) {

        case UPDATE_CALCULATOR:
        if (action.PageNumber === 0)
        return { ...state, calculatorList: action.calculatorList, totalRecords: action.totalRecords };
    else {
        let currentCalculator = [];
        // if( state.users.length != 0){
        //     let existingUsers = state.users.data.data.filter(disc => !action.user.data.data.some(newUser => newUser.user_id == disc.user_id));
        //     currentUsers= existingUsers.concat(action.user.data.data);
        // }
       
        currentCalculator = action.calculatorList;
        return Object.assign({}, state, {
            calculatorList: currentCalculator,
            totalRecords: currentCalculator.totalrecords,
            // totalPages: 
        });
    }
    
   
        
        case ADD_CALCULATOR_IN_LIST:
            
            state.calculatorList.data.data = [action.data, ...state.calculatorList.data.data];
            const CalculatorToAdd =  state.calculatorList;
            return Object.assign({}, state, {
                calculatorList: CalculatorToAdd
            });


        case DELETE_CALCULATOR_BY_ID:
            {
                
                let  {calculatorList}  = state;
                calculatorList.data.data = calculatorList.data.data.filter(calcList => calcList.CalculatorId != action.calculator);
                
                var x = calculatorList;
                return Object.assign({}, state, {
                    calculatorList: calculatorList
                })
            }
        
            
        case UPDATE_CALCULATOR_STATUS_IN_LIST:

            let { calculatorList } = state;
            const index = calculatorList.data.data.findIndex(calcList=>calcList.CalculatorId === action.calculatorList.CalculatorId);
            if (index !== -1) {
                calculatorList.data.data[index] = action.calculatorList;
            }

            return Object.assign({}, state, {
                calculatorList: calculatorList
            });

        case MANUFACTURER_LIST:

            let { currentManufacturer } = [];
            currentManufacturer= action.manufacturerList[0].manufacturer_id;
            return Object.assign({}, state, {
                manufacturerList: currentManufacturer
            });


       

        default:
            return state;
    }
}

export default calculator;