import React, { Component } from 'react';
import { Formik, ErrorMessage, Form, Field } from 'formik';
import { GuestRegisterRequest } from '../../AuthenticationApiActions';
import { connect } from 'react-redux';
import Auth from '../../../../utils/Auth';
import RequestOTP from '../GuestLogin/RequestOTP';
import Button from '@material-ui/core/Button';
import { TextField } from 'formik-material-ui';
import { CardContent } from '@material-ui/core';
import RequestGuestLoginSchema from './RequestGuestLoginSchema';
//import InputLabel from '@material-ui/core/InputLabel';
//import FormControl from '@material-ui/core/FormControl';
import { SafeAnchor } from 'react-bootstrap';
import GuestLogin from '../GuestLogin/GuestLogin'
import './GuestLogin.css';
import { getCalculatorNameByManufacturer } from '../../../ManageManufacturers/ManageManufacturersApiActions';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
let auth = new Auth();

class RequestGuestLogin extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            mfgCalc: []
        };
    }

    loginUser = (data, actions) => {
        if(document.getElementsByName("calculators").length > 0){
        data.calculator_id = document.getElementsByName("calculators")[0].value;
        }
        auth.setGuestUserEmail(data.email);
        this.props.onGuestRegister(data, actions).then(response => {
            this.setState({ visible: true });
        }, error => {
            //TODO: Show message for incorrect credentials
        })
    }
    componentDidMount() {
        getCalculatorNameByManufacturer().then(res => {

            if (res && res.Data && res.Data.length > 0) {

                this.setState({
                    mfgCalc: res.Data.map(r => {
                        let o = {};
                        o.value = r.CalculatorId;
                        o.key = r.name + ' -- ' + r.CalculatorName;
                        return o;
                    })
                })
                // console.log({
                //     mfgCalc: this.state
                // })
            }
        })
    }

    handleClick = () => {
        window.location.reload();
    }
    render() {
        return (
            <React.Fragment>
                {this.state.visible ? <RequestOTP /> :
                    <CardContent>
                        <h3 className="subheading">Register as Guest</h3>

                        <Formik
                            validationSchema={RequestGuestLoginSchema}
                            onSubmit={this.loginUser}
                            initialValues={{
                                name: '',
                                company: '',
                                email: '',
                                lastname:'',
                            }}
                        >

                            {({ errors, status, touched, isSubmitting, setFieldValue }) => (
                                <Form name="loginForm" noValidate>
                                    <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 com-field" >
                                        <Field type="text" name="name"
                                            placeholder="First Name"
                                            id="standard-required"
                                            label="First Name"
                                            className="mt-0"
                                            margin="normal"
                                            fullWidth
                                            inputProps={{
                                                maxLength:50,
                                              }}
                                            component={TextField}
                                            error
                                        />

                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 com-field" >
                                         <Field type="text" name="lastname"
                                            placeholder="Last Name"
                                            id="standard-required"
                                            label="Last Name"
                                            className="mt-0"
                                            margin="normal"
                                            fullWidth
                                            inputProps={{
                                                maxLength:50,
                                              }}
                                            component={TextField}
                                            error
                                        /> 

                                    </div>
                                   
                                    </div>
                                     <div className="com-field" >
                                        <Field type="text" name="company"
                                            placeholder="company"
                                            id="standard-required"
                                            label="Company"
                                            margin="normal"
                                            fullWidth
                                            inputProps={{
                                                maxLength:50,
                                              }}
                                            component={TextField}
                                            error
                                        />

                                    </div>

                                    <div className="com-field">
                                        <Field type="text" name="email"
                                            placeholder="email"
                                            id="standard-required"
                                            label="Email"
                                            margin="normal"
                                            fullWidth
                                            component={TextField}
                                            error
                                        />

                                    </div>

                                    <div className="com-field MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth">
                                    <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled" data-shrink="true" for="standard-required" id="standard-required-label">Calculator</label>
                                        <select name="calculators"  labelId="demo-simple-select-label">
                                            { this.state.mfgCalc!= undefined && this.state.mfgCalc && this.state.mfgCalc.length > 0 ?
                                                this.state.mfgCalc.map(c => {
                                                    return <option value={c.value}>{c.key}</option>
                                                })
                                                : ""}
                                        </select>

                                    </div>

                                    <div className="sub-btn">
                                        <Button type="submit" color="primary" className="submit-btn">
                                            Continue
                                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                        {/* { <p className="regi-para">To apply for the guest login <SafeAnchor className="register"
                            onClick={this.handleClick}>Click Here</SafeAnchor></p> } */}
                             { <p className="regi-para">Back to guest login <SafeAnchor className="register"
                            onClick={this.handleClick}>Click Here</SafeAnchor></p> }
                    </CardContent>
                }

            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {

    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onGuestRegister: (data, actions) => {
            return new Promise((resolve, reject) => {
                dispatch(GuestRegisterRequest(data, actions)).then(response => {
                    resolve(response);
                }).catch(error => {

                    reject(error);
                });
            });
        },



    }
}

RequestGuestLogin = connect(mapStateToProps, mapDispatchToProps)(RequestGuestLogin);
export default RequestGuestLogin;