import React, { Component } from 'react'
import NavLink from 'react-bootstrap/NavLink';
import { Nav, Navbar, NavDropdown, Container } from 'react-bootstrap';
import Auth from '../../../../utils/Auth';
import { Link } from 'react-router-dom';
let auth = new Auth();

export default class Navigation extends Component {

    constructor(props) {
        super(props)
        this.state = {
            LoginUserName : auth.getUser() !=undefined ? "Welcome " + auth.getUser() : "",
        }
    }
    componentWillReceiveProps()
    {
        this.setState({LoginUserName:auth.getUser() !=undefined ? "Welcome " + auth.getUser() : ""})
    }

    render() {      
      
        const navbarshadow = {
            boxShadow: '0 8px 6px -6px #999',
        }

        return (
           
            <React.Fragment>
                
                <Navbar collapseOnSelect expand="lg" bg="white" fixed="top" style={navbarshadow}>
                    <Container className="HeaderContainer">
                   
                    
                        
                    <Navbar.Brand href="/#/home"><img style={{'width':'200px'}} className="img-responsive" src={this.props.Logo} alt="logo"/></Navbar.Brand>
   

                    <Navbar.Toggle aria-controls="responsive-navbar-nav float-right" />
                    <Navbar.Collapse id="responsive-navbar-nav  float-right">
                    
                        <Nav className="ml-auto">
                        <span className="nav-link loginusername" id="loginusername">{this.state.LoginUserName}</span>
                                <span className="nav-link nav-pipe">|</span>

                             <NavLink href="/#/home"> Home</NavLink>
                             <NavLink href="/#/personalinformation"> Dashboard</NavLink>
                             <NavLink className="LastMenuItem" onClick={(e) => {
                                    auth.logout();

                                }}> Logout</NavLink>
                        </Nav>
                    </Navbar.Collapse>
                   
                    </Container>
                    </Navbar>
                   
            </React.Fragment>
        )
    }
}
