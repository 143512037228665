import React, { Component } from 'react'
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import './calculator.css';
import calculatorimg1 from '../../../../../src/images/calculator-img1.jpg';
import partnumbercodinimg from '../../../../../src/images/part-number-coding2.0.png';
import KRSeriesDropDown from '../../../Common/components/DropDown/KRSeriesDropDown';
import RailLengthDropDown from '../../../Common/components/DropDown/RailLengthDropDown';
import KrMaterialDropDown from '../../../Common/components/DropDown/KrMaterialDropDown';
import Auth from '../../../../utils/Auth';
import { connect } from 'react-redux';
import { getKrSeriesData, getDefaultValuesKrSeriesData,SaveKRSeriesPartNumber,getSavePartNumberDetail } from '../../CalculatorOutputApiActions';
import Container from 'react-bootstrap/Container';
import FormHelperText from '@material-ui/core/FormHelperText';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import { APP_DETAILS } from '../../../../utils/Constants';
//import { exportDefaultSpecifier } from 'babel-types';

let auth = new Auth();
//let LoginLimitExceed = false;
class KrSeriesbellows extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isDataFetched:false,
            UserId:  auth.getUserId(),
            IsAdmin: auth.getUserType(),
            UserName:auth.getUser(),
            SavedPartNumberId:auth.getSavedPartId(), 

            krSeriesid: 0,
            preKrSeriesid: "",

            krlength: 0,
            preKrLength: "",

            krMaterial:0,
            preKrMaterial: "",

            manufacturerId: auth.getManufacturerId(),
            preManufacturerId: auth.getManufacturerId(),

            calculatorId: auth.getCalculatorId(),

            defaultValues: [],
            KRPartNumber:"",
            InputData:"",
            Username : "",
            krMaterialIsvalid:1,
            Isvalid : 1,
        }
    }

    componentWillUpdate(props){
        if(props.manufacturerId!= undefined && props.manufacturerId !=this.state.manufacturerId){
            this.props.getDefaultValuesForKrSeries(props.manufacturerId).then((res)=>{

                this.setState({
                    krSeriesid: res.KRSeriesID,
                    krMaterial: res.KRMaterialID
                })
            }
            )
            this.state.manufacturerId = props.manufacturerId;
          }
    }
    componentDidMount(props){

        if(this.state.SavedPartNumberId > 0)
        {
            getSavePartNumberDetail(this.state.SavedPartNumberId).then(response => {

                this.setState({ InputData: response.InputData, SavedPartNumberId:0 });
                var Inputdata = JSON.parse(this.state.InputData)
                this.setState({
                    krSeriesid: Inputdata.KRSeriesId,
                    krMaterial: Inputdata.KRMaterialId,
                    krlength : Inputdata.KRRaillengthId,
                    isDataFetched:true
                })
                if(this.state.IsAdmin == "true")
                {
                    this.setState({
                        Username : "-By " +response.UserName
                    })
                }
                auth.setSavedPartId(0);

            }, error => {
                this.setState({ allPartDetails: [] });
            });
        }
        else
        {
            this.props.getDefaultValuesForKrSeries(this.props.manufacturerId).then((res)=>{

                this.setState({
                    krSeriesid: res.KRSeriesID,
                    krMaterial: res.KRMaterialID,
                    isDataFetched:true
                })
            })
        }

        
        
    }

    componentDidUpdate(props){
        if(this.state.preKrSeriesid!==this.state.krSeriesid){
           
            this.props.getkrSeriesProperty(this.state);

            this.setState({
                preKrSeriesid:this.state.krSeriesid,
                krMaterial:this.props.defaultValues != undefined ? this.props.defaultValues.KRMaterialID : this.state.krSeriesid,
            });
        }

        if(this.state.preKrLength!==this.state.krlength){
            this.props.getkrSeriesProperty(this.state);
            this.setState({preKrLength:this.state.krlength});
        }

        if(this.state.preKrMaterial!==this.state.krMaterial){
            this.props.getkrSeriesProperty(this.state);
            this.setState({preKrMaterial:this.state.krMaterial});
        }
        if(this.state.isDataFetched){
            if(this.state.preManufacturerId!==this.props.manufacturerId){
                this.props.getDefaultValuesForKrSeries(this.props.manufacturerId).then((res)=>{
                   
                    this.setState({
                        krSeriesid: res.KRSeriesID,
                        krMaterial: res.KRMaterialID,
                        preManufacturerId:this.props.manufacturerId
                    })
                }
                )
            }
        }

        //this.setState({KRPartNumber:this.props.allKrSeriesData.PartNumber});
    }
    registercustomer=()=>{
        auth.setRegisterComponent(true);
        auth.logout();
    }
    printPage=()=> {
        if (this.state.krMaterial > 0 && this.state.krSeriesid > 0 && this.state.krlength > 0) 
      {
        var btns = document.getElementsByClassName('print-btn');
        for(var i=0; i<btns.length; i++) { 
            btns[i].style.display='none';
        }

        var html = '<HTML>\n<HEAD>\n';
        if (document.getElementsByTagName != null){
        var headTags = document.getElementsByTagName("head");
        if (headTags.length > 0) html += headTags[0].innerHTML;
        }

        html += '\n</HEAD>\n<BODY>\n';
        var printReadyElem = document.getElementById("PrintDiv");

        if (printReadyElem != null) html += printReadyElem.innerHTML;
        else{
        return;
        }
        var btns = document.getElementsByClassName('print-btn');
        for(var i=0; i<btns.length; i++) { 
            btns[i].style.display='block';
        }

        html += '\n</BODY>\n</HTML>';
        var printWin = window.open("","processPrint");
        printWin.document.open();
        printWin.document.write(html);
        printWin.document.close();
        
        printWin.onload=function(){
            printWin.focus(); printWin.print(); printWin.close();
        };
      }
      else
      {
          if(this.state.krlength==0)
          {
            this.setState({
                Isvalid:0
            }) 
          }
          else if(this.state.krMaterial==0){
            this.setState({
                krMaterialIsvalid:0
            }) 
          }
      
      }        
    
   }
    saveCalculatorPartNumber = () => {
        if (this.state.krMaterial > 0 && this.state.krSeriesid > 0 && this.state.krlength > 0) {
          Swal.fire({
            //title: 'Are you sure?',
            text: 'Are you sure want to save?',
            // icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
            showCloseButton: true
          }).then((result) => {
            if (result.value) {
                this.props.savePartNumber(this.state,document.getElementById("KRPartNumber").innerText);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              
            }
          })
        }
        else{
            if(this.state.krlength == 0)
            {
                this.setState({
                    Isvalid:0
                }) 
         
            // swal("Please select Rail Length input to save record");
               
             }
             else if(this.state.krMaterial==0)
             {  
                this.setState({
                    krMaterialIsvalid:0
                }) 
         
             }
             else{

                swal("Please select valid input to save record");
             }
        }
            // if (this.state.krMaterial > 0 && this.state.krSeriesid > 0 && this.state.krlength > 0) {
            //     this.props.savePartNumber(this.state,document.getElementById("KRPartNumber").innerText);
            // }
    }

    renderTableHeader = (OPParam) => {

        if(OPParam!= undefined){
        return OPParam.map((data) => {
             
             const { DisplayName } = data;
        return (
            <TableCell>{DisplayName}</TableCell>
        )
        })
    }
    }

    renderTableData = (OPParam) => {

        if(OPParam!= undefined){
        return OPParam.map((data) => {
             
             const { DisplayName, Value } = data;
        return (
            <TableCell>{Value}</TableCell>
        )
        })
    }
    }

    renderSecondTableHeader = (OPPricing) => {
        if(OPPricing!= undefined){
            return OPPricing.map((data) => {
                 
                 const { DisplayName, Value } = data;
            return (
                <TableCell>{DisplayName}</TableCell>
            )
            })
        }
    }

    renderSecondTableData = (OPPricing) => {
        if(OPPricing!= undefined){        
            return OPPricing.map((data) => {
                 
                 const { DisplayName, Value } = data;
            return (
                <TableCell>{Value}</TableCell>
            )
            })
        }
    }

    render() {
        
        if(!this.state.isDataFetched)
        {
            return (<div className="loader-parent" id="loaderdiv">
            <div className="loader loadercenter">
                <i className="fa fa-spinner fa-spin" />
            </div>
            </div>);
        }
        else
        {
            const defaultValues = this.props.defaultValues;
            let KRSeriesID, KRMaterialID
            if(defaultValues!=undefined){
                KRSeriesID = defaultValues.KRSeriesID;
                KRMaterialID = defaultValues.KRMaterialID;
            }
            else{
                KRSeriesID = this.state.krSeriesId;
                KRMaterialID = this.state.KRMaterialID;

            }
            const krSeriesData = this.props.allKrSeriesData;
            //console.log({krSeriesData: krSeriesData});
            let OPParam = krSeriesData.OPParam;
            let OPPricing = krSeriesData.OPPricing;
            let THKPartNumber = krSeriesData.THKPartNumber
            let imagePath = krSeriesData.ImagePath;
            let KRPartnumber = krSeriesData.PartNumber;
            let FormulaError = krSeriesData.FormulaError;
            let OPParamLength =0;
            if(OPParam!=undefined){
                OPParamLength = OPParam.length;
            }
            let OPPricingLength =0;
            if(OPPricing!=undefined){
                OPPricingLength = OPPricing.length-1;
            }
           var LoginLimitExceed = krSeriesData.LoginLimitExceed;
            const saveButton = [];
            if(this.state.IsAdmin == "false"){
            saveButton.push(<button className="print-btn mt-1"  onClick={this.saveCalculatorPartNumber}>Save</button>)
            }
            
            return (

                <React.Fragment>
                    
                <Container id="PrintDiv">
                    <div className="row  pb-30">
                        <div className="col-lg-3 col-md-4 cal-sidebar">
                            <Card>
                                <CardContent>
                                    <div className="parameter dotted-border">
                                        <p className="calculator-headings">Parameters</p>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <FormControl margin="normal" fullWidth>
                                                <InputLabel id="demo-simple-select-label">KR SERIES</InputLabel>
                                                <KRSeriesDropDown enabled={true} manufacturerId={this.props.manufacturerId} 
                                                selectedValue={this.state.krSeriesid}
                                                //selectedValue={KRSeriesID}
                                                activeOnly={true} 

                                                onKRSeriesChange={e=>{
                                                    this.setState({
                                                        Isvalid:1,
                                                        krMaterialIsvalid:1
                                                    }) 

                                                    if(e.target.value==this.state.krSeriesid)
                                                    {
                                                        this.setState({
                                                            
                                                            krlength: 0,
                                                          
                                                        })   

                                                    }
                                                    else{
                                                        this.setState({
                                                            krSeriesid: e.target.value,
                                                            krlength: 0,
                                                            krMaterial:0
                                                        })   
                                                    }
                                                                          
                                                }
                                                }/>
                                                 
                                                </FormControl>
                                                <FormControl margin="normal" fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Rail Length *</InputLabel>
                                                    <RailLengthDropDown enabled={true} krSeriesId={this.state.krSeriesid} 
                                                    selectedValue={this.state.krlength} 
                                                    activeOnly={true} onRailLengthChange={e=>{
                                                    this.setState({
                                                        krlength:  e.target.value
                                                    }) 
                                                    if(this.state.krlength!=0)
                                                    {
                                                        this.setState({
                                                            Isvalid:1
                                                        }) 
                                                    }
                                                                           
                                                }
                                                }/>
                                                  {(this.state.krlength==0 && this.state.Isvalid==0)?<div className="selecterrormsg">
                                                   please select rail length
                                                   </div>:<></>}                                             
                                                  

                                                 {/* <FormHelperText>Error</FormHelperText> */}
                                                 {/* {errors.krlength && touched.krlength && (
                                                   <div className="selecterrormsg">
                                                   please select rail length
                                                   </div>
                                                )}  */}

                                                </FormControl>
                                                <FormControl margin="normal" fullWidth>
                                                    <InputLabel id="demo-simple-select-label">MATERIAL</InputLabel>
                                                    <KrMaterialDropDown enabled={true} krSeriesId={this.state.krSeriesid} 
                                                    selectedValue={this.state.krMaterial} 
                                                    activeOnly={true}
                                                    onKrMaterialChange={e=>{
                                                    this.setState({
                                                        krMaterial:  e.target.value
                                                    })   
                                                    if(this.state.krMaterial!=0)
                                                    {
                                                        this.setState({
                                                            krMaterialIsvalid:1
                                                        }) 
                                                    }                      
                                                }
                                                }/>
                                                 {(this.state.krMaterial==0 && this.state.krMaterialIsvalid==0)?<div className="selecterrormsg">
                                                   please select material length
                                                   </div>:<></>}  
                                                </FormControl>


                                            </div>

                                        </div>
                                    </div>
                                    <div className="pricing-details">
                                        <h4>Contact an engineer for pricing details/model specs for:</h4>
                                        <ul>
                                            <li>KR models with custom distance between blocks </li>
                                            <li>Older KR models</li>
                                        </ul>
                                        <h5 className="float-right">{this.state.Username}</h5>
                                    </div>
                                </CardContent>
                            </Card>

                        </div>
                        <div className="col-lg-9 col-md-8 calculator-rightside">

                            <Card>
                                <CardContent>
                                        {(OPParamLength>0)?( 
                                    <div className="dotted-border">
{(OPParamLength>0)?(
                                    <table className="responsiveTable"><tr><td>KR Parameters</td></tr>
                                    </table>
         ):(<></>)}          {(OPParamLength>0)?(                      
                                        <div className="responsiveTable">
                                            <Table>
                                                <TableHead className="grayheader">
                                                  
                                                {this.renderTableHeader(OPParam)}

                                                </TableHead>
                                                <TableRow>
                                                
                                                {this.renderTableData(OPParam)}
                                                </TableRow>
                                                <TableBody>

                                                </TableBody>
                                            </Table>
                                            <label style={{color: "red"}}>{FormulaError}</label> 
                                        </div>  ):(<></>)} 

                                    </div>
                                     ):(<></>)}   
                                    <div className="dotted-border">

                                    

                                        
                                        <div className="responsiveTable">
                                        

                                            <Table>
                                                <TableHead className="grayheader">
                                                    <TableCell></TableCell>
                                                   
                                                    {this.renderSecondTableHeader(OPPricing)}
                                                </TableHead>
                                                <TableRow>
                                                <TableCell className="grayheader">KR Bellows</TableCell>
                                               
                                                {this.renderSecondTableData(OPPricing)}
                                                </TableRow>
                                                <TableRow>
                                                <TableCell className="grayheader">THK P/N</TableCell>
                                                <TableCell>{THKPartNumber}</TableCell>						   
                                                <TableCell colSpan={OPPricingLength}></TableCell>	             
                                                
                                                </TableRow>
                                                <TableBody>

                                                </TableBody>
                                            </Table>
                                            {(LoginLimitExceed == false || LoginLimitExceed == undefined)?(<></>):
//(<p style={{color: "red"}}> Dear {this.state.UserName}, your guest login count limit is exceeded, to get know about the pricing of specific bellows, please <a onClick={this.registercustomer} className="register" style={{"color":"white"}}>register</a>  or contact on <a href="tel:800-650-2015">800-650-2015</a></p>)
(<p style={{color: "red"}}>  Dear {this.state.UserName}, Are you a Distributor or Manf. Rep.? Become an Authorized User online
or call <a href={`tel:${APP_DETAILS.CONTACT_NUMBER}`}>{APP_DETAILS.CONTACT_NUMBER}</a>.<br></br>
Authorized Users receive multi level pricing, priority shipping and more.To Apply: <a onClick={this.registercustomer} className="register" style={{"color":"white"}}>Register Here</a> </p>)
}
                                        <button className="print-btn mt-1" onClick={this.printPage}>Print</button> 
                                        {saveButton}
                                        {/* <button className="print-btn"  onClick={this.saveCalculatorPartNumber}>Save</button> */}
                                        {/* <button className="print-danger" onClick={() => {if(window.confirm('Are you sure want to save?')){this.saveCalculatorPartNumber};}}>Save</button> */}
                                        </div>
                                    </div>
                                    <div className="calc-image  dotted-border">
                                        <div hidden id="KRPartNumber">{KRPartnumber}</div>
                                        <img className="img-calculator"  alt="output-image"  src={imagePath} />
                                    </div>
                                
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                    </Container>
                </React.Fragment>
            )
        }
}
}

const mapProps = (state) => {
   
    return {
      
        allKrSeriesData: state.calculatorOutput.allKrSeriesData,
        defaultValues: state.calculatorOutput.defaultValues
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {

        savePartNumber:(data,PartNumber)=>{
            return new Promise((resolve, reject) => {
                dispatch(SaveKRSeriesPartNumber(data,PartNumber))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        getkrSeriesProperty: (data) => {
            return new Promise((resolve, reject) => {
                dispatch(getKrSeriesData(data))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        getDefaultValuesForKrSeries: (manufacturerId) => {
            return new Promise((resolve, reject) => {
                dispatch(getDefaultValuesKrSeriesData(manufacturerId))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });

        }
    };
};

export default connect(mapProps, mapDispatchToProps)(KrSeriesbellows);