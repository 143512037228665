import React, { Component } from 'react'
import { SafeAnchor, Modal } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import Button from '@material-ui/core/Button';
import './CalculatorList.css';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { TextField } from 'formik-material-ui';
import CalculatorSchema from './CalculatorSchema';

const { Header, Body, Footer, Title } = Modal;

export default class AddCalculator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showform: false,
            manufacturer_name:""

        };
       
    }
    componentDidMount(){
        //console.log("didmount");
    }

    showForm = () => {
        this.setState({ showform: true })
    }


    hideForm = () => {
        this.setState({ showform: false })
    }

    submitFormula = (data,actions) => {        
        actions.setSubmitting(true);
        this.props.addCalculator(data)        
          
            this.hideForm();
            // this.handleClose();
     

    }

    render() { 
     
        const manufacturer_id = this.props.manufacturer_id;
        const manufacturer_name = this.props.manufacturer_name;
        const adduser = {
            clear: 'both',
            float: 'right',
            borderRadius: '20px',
            background: '#01524B',
            color: '#fff',
            fontWeight: 'normal'
        }
        const handleChange = event => {
            this.setState({ value: event.target.value });
        };
        
        return (
            <>
            

                {!this.state.showform ?
                <Button style={adduser} onClick={this.showForm}>Add Calculator</Button>
                    : <>
                        <Modal className="calculatorPopup" show={this.state.showform} onHide={this.hideForm}>
                            <Header closeButton>
                                <Title>{manufacturer_name}</Title>
                            </Header>
                            <Body>
                                <Formik
                                  validationSchema={CalculatorSchema}
                                    onSubmit={this.submitFormula}
                                    initialValues={{
                                        ManufacturerId:manufacturer_id,
                                        CalculatorName:"",
                                        IsDeveloped:""
                                       
                                    }}
                                    enableReinitialize={true}
                                >
                                    {({ status,errors, isSubmitting,setFieldValue }) => (
                                        <Form noValidate>
                                            <div className="row">
                                             <div className="col-lg-6">
                                                     <Field type="text" name="CalculatorName"  id="standard-required" 
                                                        label="TITLE"
                                                        margin="normal"
                                                        fullWidth
                                                        component={TextField}
                                                        // placeholder="Calculator Name"
                                                         />
                                            </div>
                                            <div className="col-lg-6">  
                                            <FormControl style={{ marginTop: 6}} error={errors.IsDeveloped}>  
                                                <InputLabel id="demo-simple-select-label">Published</InputLabel>                                                                          
                                                <Select
                                                className="role_dropdown"            
                                                labelId="demo-controlled-open-select-label"
                                                id="standard-required"
                                                label="Is Developed"
                                                key=''
                                                data-attribute='IsDeveloped'
                                                name='IsDeveloped'
                                                onChange = {(e)=>
                                                    { 
                                                    setFieldValue('IsDeveloped', e.target.value) 
                                                    }
                                                }
                                                                      
                                                > 
                                                <MenuItem value="" disabled>
                                                   <em>select the value</em>
                                                </MenuItem>
                                                <MenuItem value={true}>Yes</MenuItem>
                                                <MenuItem value={false}>No</MenuItem>

                                                </Select>
                                               <div className="selecterrormsg">
                                               {errors.IsDeveloped}

                                               </div>
                                            </FormControl>
                                                                 
                                                              
                                                     <Field type="hidden" name="ManufacturerId"

                                                        error />                                                     


                                            </div>
                                            <div className="col-lg-6">
                                                                   </div>


                                             </div>
                                                                                    
                                            {/* <input className="submit" type="Submit" disabled={isSubmitting} /> */}
                                            <Modal.Footer className="row" centered>
                                            {/* <Button variant="contained" type= "submit"  className="save">Save</Button> */}
                                             <Button variant="contained" type= "submit"  className="save" disabled={isSubmitting} >Save</Button>
                                            </Modal.Footer>
                                        </Form>
                                    )}
                                </Formik>
                            </Body>
                        </Modal>

                    </>}
            </>
        )
    }
}
