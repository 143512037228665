import * as Yup from "yup";
import { REGEX, REGEX_ERROR } from "../../../../utils/Constants";

const AddEditUserSchema = Yup.object().shape({
    email_id: Yup.string()
    .matches(REGEX.EMAIL, REGEX_ERROR.EMAIL_ERROR)
    .required('Please Enter Email'),

    first_name: Yup.string()
    .matches(REGEX.ALPHABETS_WITH_SPACE, REGEX_ERROR.ALPHABETS_WITH_SPACE_ERROR)
    .required('Please Enter First Name'),

    last_name: Yup.string()
    .matches(REGEX.ALPHABETS_WITH_SPACE, REGEX_ERROR.ALPHABETS_WITH_SPACE_ERROR)
    .required('Please Enter Last Name'),
 
    // role_id: Yup.string()
    // .required('Please Enter Last Name'),

    role_id: Yup
    .mixed()
    .test('', 'Please Select Role', value => value && !isNaN(value) && parseInt(value) > 0), 

})

export default AddEditUserSchema;