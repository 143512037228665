import React, { Component } from 'react';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { getAllAccessoryList } from '../../CommonApiActions';

class AccessoryDropDown extends React.Component {
    constructor(props) {
        super(props);
        //console.log(this.props.sizeId);
        this.state = ({
            allAccessories: [],
            sizeId: this.props.sizeId
        })
    }

    componentWillReceiveProps(props) {

        if(props.sizeId!= undefined && props.sizeId !=this.state.sizeId){
          this.props.getAccessoryList(props.sizeId);
          this.state.sizeId = props.sizeId;
        }
    }

    componentDidMount() {
        this.props.getAccessoryList(this.state.sizeId);
    }


    render() {
        const selectedValue = this.props.selectedValue;
        // const selectedName = this.props.selectedName;
        
        //console.log(selectedValue);
        const accessories = this.props.allAccessories;

        const options = accessories && accessories.length > 0 ? (
            accessories.map((accessory,index) => {

                return (<MenuItem key={index} name={accessory.AccessoryValue} value={accessory.AccessoryId}>
                        {accessory.AccessoryValue}
                        </MenuItem>
                );
            })
        ) : (
                <MenuItem>Loading....</MenuItem>
            );
        //console.log({ options })
        return (

            <Select value={selectedValue}
                // name= {selectedName}
                labelId="demo-simple-select-label"
                id="demo-simple-select" onChange={this.props.onAccessoryChange}>
                {options}
            </Select>
        );
    }

}

const mapProps = (state) => {
   
    return {
        allAccessories: state.common.allAccessories
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getAccessoryList: (sizeId) => {
            return new Promise((resolve, reject) => {
                dispatch(getAllAccessoryList(sizeId))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    };
};

export default connect(mapProps, mapDispatchToProps)(AccessoryDropDown);
