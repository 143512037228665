import { UPDATE_ROLE, ADD_ROLE_TO_LIST, UPDATE_ROLE_IN_LIST,DELETE_ROLE_FROM_LIST,GET_ROLE_ATTRIBUTE_LIST } from './ManageRolesActions';


const initialState = { roles: [] ,allAttributes : []};

const role = (state = initialState, action) => {
    switch (action.type) {
       
        case UPDATE_ROLE:
            if (action.PageNumber === 0)
            return { ...state, roles: action.role, totalRecords: action.totalRecords };
        else {
            let currentRoles = [];
          
             currentRoles = action.role;
            return Object.assign({}, state, {
                roles: currentRoles,
                totalRecords: action.totalRecords,
            });

        }
        case ADD_ROLE_TO_LIST:
            state.roles.data.data = [action.role, ...state.roles.data.data];
        const RolesToAdd =  state.roles;
        return Object.assign({}, state, {
            roles: RolesToAdd
        });

    case UPDATE_ROLE_IN_LIST:
   
        let { roles } = state;
        const index = roles.data.data.findIndex(user => user.role_id === action.role.role_id);
        
        if (index !== -1) {
            roles.data.data[index] = action.role;
        }

        return Object.assign({}, state, {
            roles: roles
        });

    case DELETE_ROLE_FROM_LIST:
            {
                let  {roles}  = state;
                roles.data.data = roles.data.data.filter(user => user.role_id != action.role.role_id);
                return Object.assign({}, state, {
                    roles: roles
                })
            }
    case GET_ROLE_ATTRIBUTE_LIST:
        {
            return Object.assign({}, state,
                {
                    allAttributes: action.Attributes
                });
        }
        default:
            return state;
    }
}

export default role;