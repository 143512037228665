import React, { Component } from 'react';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { getAllRailLengthList } from '../../CommonApiActions';

class RailLengthDropDown extends React.Component {
    constructor(props) {
        super(props);
        //console.log(this.props.krSeriesId);
        this.state = ({
            allRailLength: [],
            krSeriesId: this.props.krSeriesId
        })
    }

     componentWillReceiveProps(props) {

        if(props.krSeriesId!= undefined && props.krSeriesId !=this.state.krSeriesId){
          this.props.getRailLengthList(props.krSeriesId);
          this.state.krSeriesId = props.krSeriesId;
        }
    }

    componentDidMount() {
        this.props.getRailLengthList(this.state.krSeriesId);
    }

    render() {
        const selectedValue = this.props.selectedValue;
        // const selectedName = this.props.selectedName;
        
        //console.log(selectedValue);
        const rails = this.props.allRailLength;

        const options = rails && rails.length > 0 ? (
            rails.map((rail,index) => {
                
                return (<MenuItem key={index} name={rail.Length} value={rail.KRRailLengthID}>
                        {rail.Length}
                        </MenuItem>
                );
            })
        ) : (
                <MenuItem>Loading....</MenuItem>
            );
        //console.log({ options })
        return (

            <Select value={selectedValue}
                // name= {selectedName}
                labelId="demo-simple-select-label"
                id="demo-simple-select" onChange={this.props.onRailLengthChange}>
                {options}
            </Select>
        );
    }

}

const mapProps = (state) => {
   
    return {
        allRailLength: state.common.allRailLength
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getRailLengthList: (krSeriesId) => {
            return new Promise((resolve, reject) => {
                dispatch(getAllRailLengthList(krSeriesId))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    };
};

export default connect(mapProps, mapDispatchToProps)(RailLengthDropDown);
