import React, { Component } from 'react'
import { connect } from 'react-redux';
import Container from '@material-ui/core/Container';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import { ACTIONS } from '../../../../utils/Constants';
import { SetPasswordRequest } from '../../AuthenticationApiActions';
import logo from '../../../../images/logo.png';
import thkLogo from '../../../../images/thk.png';
import { ErrorMessage, Field, Form, Formik } from "formik";
import { TextField } from 'formik-material-ui';
import Header from '../../../Authentication/components/Navigation/Header';
import './PasswordSignIn.css';
import SetPasswordSchema from './SetPasswordSchema';
import Footer from '../../../Common/components/Footer/Footer';


const queryString = require('query-string');
class SetPassword extends Component {
  constructor(props) {
    super(props)
    const parsed = queryString.parse(props.location.search);
    //console.log({ parsed })
    this.state = {
          token : parsed,
          logo: logo,
          thkLogo: thkLogo,
    }
  }
  componentDidMount() {
  }
  setpassword = (data, actions) =>{
  actions.setSubmitting(true);
    this.props.setPassword(data, actions).then(response => {
      actions.setSubmitting(false);
    }, error => {
      actions.setSubmitting(false);
        //TODO: Show message for incorrect credentials
    })
  }
  render() {
      
    const topheight = {
        padding: '100px 0 30px'

    };
   const token = this.props.token;
  
        return (
            <React.Fragment>
                <Header Logo={this.state.logo}></Header>
                <Container >
                    <h2 style={topheight} className="text-center heading">Your Linear Motion - Bellows calculator</h2>
                    <Card className="admin-card">

                        <div className="row">
                            <div className="col-lg-12 user-login">
                                <CardContent>
                                    <h3 className="subheading">New Password</h3>
                                    <Formik
                                        validationSchema={SetPasswordSchema}
                                        onSubmit={this.setpassword}
                                        initialValues={{
                                            password: '',
                                            confirmPassword: '' ,
                                            token: this.state.token
                                        }}
                                    >


                                        {({ errors, status, touched, isSubmitting, setFieldValue }) => (
                                            //  <h3 className="subheading">Authorized User Login</h3>

                                            <Form name="SetPassword" className="form-horizontal form-simple" noValidate>

                                                <fieldset >
                                                    <Field type="password" name="password" id="standard-required"
                                                        label="Password*"
                                                        margin="normal"
                                                        fullWidth
                                                        component={TextField}
                                                        placeholder="Password"
                                                        error />

                                                    {/* <ErrorMessage name="username" style={{ color: 'red' }} component="div" /> */}

                                                </fieldset>

                                                <fieldset >

                                                    <Field type="password"
                                                        component={TextField}
                                                        id="standard-password-input"
                                                        label="Confirm Password*"
                                                        margin="normal"
                                                        fullWidth
                                                        placeholder="Confirm Password"
                                                        name="confirmPassword" onChange={(e) => setFieldValue("confirmPassword", e.currentTarget.value)}
                                                        error />

                                                </fieldset>
                                               
                                                <Button type="submit" color="primary" className="submit-btn">
                                                    Submit
                                            </Button> 
                                            </Form>


                                        )}
                                    </Formik>
                                </CardContent>
                            </div>
                        </div>
                    </Card>
                </Container>
                <br/>
                <Footer/>
            </React.Fragment>
        )
    
    // }

}
}

const mapStateToProps = state => {
  return {
  }
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setPassword: (data, actions) => {
        return new Promise((resolve, reject) => {
            dispatch(SetPasswordRequest(data, actions)).then(response => {
              window.Message(response.data.Message, 'Success');
             
              window.location.href = "/#"+ ACTIONS.SIGN_IN;
              window.location.reload();
                resolve(response);
            }).catch(error => {
              window.Message(error.response.data.Message,'Error',true);
                reject(error);
            });
        });
    }
  }
}
SetPassword = connect(mapStateToProps, mapDispatchToProps)(SetPassword);

export default SetPassword
