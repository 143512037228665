import React, { Component } from "react";
import { Modal, ModalBody, Image, SafeAnchor, ModalTitle } from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";



export default class ImageComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show: false,
            src: props.src || "",
            height: props.height || "75px",
            width: props.width || "75px",
        };

        this.showImage = this.showImage.bind(this);
        this.hideImage = this.hideImage.bind(this);
    }

    showImage() {
        this.setState({
            show: true
        })
    }
    hideImage() {
      
        this.setState({
            show: false
        })
    }
    render() {

        const img = <img src={this.state.src} height={this.state.height || "75px"}
            width={this.state.width || "75px"} onClick={this.showImage}
        />;
 
        return (
            <>
                {img}
                <Modal show={this.state.show} onHide={this.hideImage} size="xl">
                    <ModalHeader closeButton={true} onHide={this.hideImage}>
                        <ModalTitle>
                            <h2>Image Preview</h2>
                        </ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <img src={this.state.src} width="100%" height="auto" />
                    </ModalBody>
                </Modal>
            </>
        );
    }
}
