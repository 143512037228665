import {SERVER_ACTIONS} from '../../utils/Constants';
import HttpRequest from '../../utils/HttpRequest';
import Auth from '../../utils/Auth';
import { updateCustomers, addCustomerToList, updateCustomerInList, deleteCustomerFromListItem } from './ManageCustomersActions';

const auth  = new Auth();

export function getRegisteredCustomerList({query, PageNumber, defaultPageSize}) {

    return (dispatch) => {
        const queryString = (query && query.trim().length > 0) ? `?${query}` : '';
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.POST_REGISTERED_CUSTOMERS}${queryString}`, 'post', {})
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {

                dispatch(updateCustomers(response.data, Math.ceil(response.data.totalrecord / defaultPageSize), PageNumber));
                return Promise.resolve(response.data);
                }
                else{
                    return Promise.reject(response.data);
                }
            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);
            });
    }
}

export function getGuestCustomerList({query, PageNumber, defaultPageSize}) {

    return (dispatch) => {
        const queryString = (query && query.trim().length > 0) ? `?${query}` : '';
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.POST_GUEST_CUSTOMERS}${queryString}`, 'post', {})
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                dispatch(updateCustomers(response.data, Math.ceil(response.data.totalrecord / defaultPageSize), PageNumber));
                return Promise.resolve(response.data);
                }
                else{
                    return Promise.reject(response.data);
                }
            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error); 
            });
    }
}


export function saveCustomerItem(customer, formAction) {
    formAction.setSubmitting(true)
    return (dispatch) => {
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.POST_UPDATE_CUSTOMER}`, 'post', customer)
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                formAction.setSubmitting(false);
                if (response.status === 200 && !response.data.errorCode) {
                    window.Message(response.data.message, 'Success');

                    if (customer.customer_id == 0) {

                        dispatch(addCustomerToList(response.data.Data));

                    } else {
                        dispatch(updateCustomerInList(response.data.Data));
                    }

                    return Promise.resolve(response);
                } else {
                    formAction.setStatus({ msg: response.data.message });
                    return Promise.reject(response);
                }
            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (error.response.data) {
                    formAction.setStatus({ msg: error.response.data.message });
                }
                formAction.setSubmitting(false);
                window.Message(error.response.data.Message, 'Error',true);
                return Promise.reject(error);
            });
    }
}


export function toggleStatusCustomertItem(id, modified_by) {
    let params = {};
    params.id = id;
    params.modified_by = modified_by;

    return (dispatch) => {
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(SERVER_ACTIONS.API+SERVER_ACTIONS.POST_STATUS_CUSTOMER, 'PUT', params)
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                    window.Message(response.data.Message, 'Success');
                    dispatch(updateCustomerInList(response.data.Data));
                }

                return Promise.resolve(response);
            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                
                return Promise.reject(error);
            });
    }
}

export function deleteCustomerFromList(id, modified_by) {
    return (dispatch) => {
        let params ={};
        params.id =id;
        params.modified_by = modified_by;

        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(SERVER_ACTIONS.API+SERVER_ACTIONS.DELETE_CUSTOMER, 'DELETE', params).requestWithAuth().then(
            (response) => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                    window.Message(response.data.message, 'Success');
                    dispatch(deleteCustomerFromListItem(response.data.Data));
                }

                return Promise.resolve(response.data);
            },
            (error) => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);
            }
        );
    };
}
