import * as Yup from "yup";
import { REGEX, REGEX_ERROR } from "../../../../utils/Constants";

const EditProfileSchema = Yup.object().shape({
    first_name: Yup.string()
    .matches(REGEX.ALPHABETS_WITH_SPACE, REGEX_ERROR.ALPHABETS_WITH_SPACE_ERROR)
    .required('Please Enter First Name')
    .max(100),
    last_name: Yup.string()
    .matches(REGEX.ALPHABETS_WITH_SPACE, REGEX_ERROR.ALPHABETS_WITH_SPACE_ERROR)
    .required('Please Enter Last Name')
   //.nullable()
   .max(100),
     email: Yup.string()
    .matches(REGEX.EMAIL, REGEX_ERROR.EMAIL_ERROR)
    .required('Please Enter Email'),
     contact_no: Yup.string()
    .matches(REGEX.PHONE,REGEX_ERROR.PHONE_ERROR)
    .required('Please Enter Contact Number')
    .nullable(),
     
    company_name: Yup.string()
     .matches(REGEX.ALPHANUMERIC_WITH_SPACE, REGEX_ERROR.ALPHANUMERIC_WITH_SPACE_ERROR)
     .required('Please Enter Company Name'),
    
     address: Yup.string()
     .matches(REGEX.ALPHANUMERIC_WITH_SPACE, REGEX_ERROR.ALPHANUMERIC_WITH_SPACE_ERROR)
     .nullable()
     .required('Please Enter Address'),     
    
     postal_code: Yup.string()
     .min(5)
     .max(6)
     .nullable()
     .required('Please Enter Postal Code')
     .matches(REGEX.WHOLE_NUMBER, REGEX_ERROR.WHOLE_OR_DECIMAL_ERROR),
     
    company_type: Yup
    .mixed()
    .test('registered value', 'Please Select Company Role', value => value && !isNaN(value) && parseInt(value) > 0), 

    country: Yup
    .mixed()
    .test('registered value', 'Please Select Country', value => value && !isNaN(value) && parseInt(value) > 0), 

    state: Yup
    .mixed()
    .test('registered value', 'Please Select State', value => value && !isNaN(value) && parseInt(value) > 0), 
    
    custtype: Yup
    .mixed()
    .test('registered value', 'Please Select Customer Type', value => value && !isNaN(value) && parseInt(value) > 0), 

});

export default EditProfileSchema;