import React, { Component } from 'react';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { getAllKrMaterialList } from '../../CommonApiActions';

class KrMaterialDropDown extends React.Component {
    constructor(props) {
        super(props);
        //console.log(this.props.krSeriesId);
        this.state = ({
            krSeriesId: this.props.krSeriesId
        })
    }

    componentWillReceiveProps(props) {

        if(props.krSeriesId!= undefined && props.krSeriesId !=this.state.krSeriesId){
          this.props.getKrMaterialList(props.krSeriesId);
          this.state.krSeriesId = props.krSeriesId;
        }
    }
    componentDidMount() {
        this.props.getKrMaterialList(this.state.krSeriesId);
    }

    render() {
        const selectedValue = this.props.selectedValue;
        // const selectedName = this.props.selectedName;
        
        //console.log(selectedValue);
        const materials = this.props.allkrMaterials;

        const options = materials && materials.length > 0 ? (
            materials.map((material) => {

                return (<MenuItem name={material.Material} value={material.KRMaterialID}>
                        {material.Material+" ("+material.Description+")"}
                        </MenuItem>
                );
            })
        ) : (
                <MenuItem>Loading....</MenuItem>
            );
        //console.log({ options })
        return (

            <Select value={selectedValue}
                // name= {selectedName}
                labelId="demo-simple-select-label"
                id="demo-simple-select" onChange={this.props.onKrMaterialChange}>
                {options}
            </Select>
        );
    }

}

const mapProps = (state) => {
   
    return {
        allkrMaterials: state.common.allkrMaterials
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getKrMaterialList: (krSeriesId) => {
            return new Promise((resolve, reject) => {
                dispatch(getAllKrMaterialList(krSeriesId))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    };
};

export default connect(mapProps, mapDispatchToProps)(KrMaterialDropDown);
