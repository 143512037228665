import React, { Component } from 'react'
import { TextField } from 'formik-material-ui';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Modal, SafeAnchor, ButtonGroup, Container } from 'react-bootstrap';
import './addmanufacturer.css';
import RoleDropDown from '../../../Common/components/DropDown/RoleDropDown';
import { connect } from 'react-redux';
import { saveManufacturerItem } from '../../ManageManufacturersApiActions';
import ImageUploader from 'react-images-upload';
import ManufacturerFormSchema from './ManufacturerFormSchema';


class AddManufacturer extends Component {
    
    constructor(props) {
        super(props);
        let manufacturer =  {
            name: '',
            display_name: '',
            thumbnail: '',
            FileStr : '',
            FileName : ''
        };

        this.state = {
            manufacturer: manufacturer,
            file: '',//this.props.file
        }

    }
    componentDidMount(){
        this.setState({file:''});
    }
    componentWillReceiveProps()
    {
        this.setState({file:''})
    }
    
    fileChangedHandler = (event) => {
        const file = event.target.files[0]
        
      }

    onDrop=(picture) =>{
        //console.log({picture})
        if(picture[picture.length-1]!=undefined){
            this.setState({
                file: URL.createObjectURL(picture[picture.length-1])
            })
            var filename =picture[picture.length-1].name;
            this.getBase64(picture[picture.length-1],result=>{
                //console.log({result});
                let {manufacturer}=this.state;
                manufacturer.FileStr = result.replace("data:image/png;base64,","").replace("data:image/jpeg;base64,","").replace("data:image/jpg;base64,","").replace("data:image/gif;base64,","");
                manufacturer.FileName = filename;
                this.setState({manufacturer})
                //console.log(this.state);
            })
        }
    }

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            //console.log('Error: ', error);
        };
    }

    saveManufacturer = (data, formAction) => {
        
        let {manufacturer}=this.state;
        manufacturer.manufacturer_id = 0;
        data.manufacturer_id = 0
        if(manufacturer.FileStr != undefined)
        {
        data.FileStr = manufacturer.FileStr;
        }
        if(manufacturer.FileName != undefined)
        {
        data.FileName = manufacturer.FileName;
        }
        formAction.setSubmitting(true);
        this.props.saveManufacturer(data, formAction).then(response => {
            this.setState({ message: response.message ,file:''});
            formAction.setSubmitting(false);
            manufacturer.FileName="";
            manufacturer.FileStr="";
            if (this.props.updateList && typeof this.props.updateList === 'function') {
                this.setState({ message: response.message ,file:'',manufacturer:manufacturer});
                this.props.updateList();
            }
            this.props.onHide();
             //this.handleClose();
        }, error => {
            formAction.setSubmitting(false);
        });

    };
 handleClose = () => {  
  
  
 }
    render() {
        // const handleChange = event => {
        //     this.setState({ value: event.target.value });
        // };
        // const handleChangeCheck = (e) =>{
        //     this.setState({[e.target.name]: e.target.checked})
        // }
        
        let imgPreview
        if (this.state.file) {
            imgPreview = <img style={{width:200}} src={this.state.file} alt='' />;
        }
        return (

            <React.Fragment>
                <Modal className="manufacturer-model"
                    {...this.props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                   
                >
                    <Modal.Header color="secondary" closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Add Manufacturer
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Formik
                            validationSchema={ManufacturerFormSchema}
                            initialValues={this.state.manufacturer}
                            onSubmit={this.saveManufacturer}
                            enableReinitialize={true}
                        >
                            {({ errors, status, touched, isSubmitting, setFieldValue }) => (
                                <Form autoComplete="off" noValidate>
                        <div className="row">
                            <div className="col-lg-6">
                            <div className="col-lg-12"> 
                            <Field type="text"
                                name="name"
                                placeholder="Enter Name"
                                component = {TextField}
                                id="standard-required"
                                label=" Name"
                                margin="normal"
                                fullWidth
                                />
                                </div>
                            <div className="col-lg-12">
                            <Field type="text"
                                name="display_name"
                                placeholder="Enter Display Name"
                                component = {TextField}
                                id="standard-required"
                                label="Display Name"
                                margin="normal"
                                // defaultValue={data.first_name}
                                fullWidth
                                />
                            </div>
                            </div>

                            <div className="col-lg-6">
                            <div className="text-center">
                                            {imgPreview}
                                        </div>
                                        <ImageUploader name ="FileStr"
                                                singleImage={true}
                                                multipleImage={false}
                                                 withIcon={false}
                                                buttonText='Choose images'
                                                onChange={this.onDrop}
                                                imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                                maxFileSize={5242880}
                                        />
                                        
                                        </div>
                                        
                        </div>
                        <Modal.Footer className="row" centered>
                        {/* <Button variant="contained" type="submit" className="save">Save</Button> */}
                        <Button variant="contained" type= "submit"  className="save" disabled={isSubmitting} >Save</Button>
                        <Button variant="contained" className="cancle"onClick={this.props.onHide}>Close</Button>
                    </Modal.Footer>
                        </Form>
                            )}

                        </Formik>
                    </Modal.Body>
                    
                </Modal>
            </React.Fragment>
        )
    }
}

const mapProps = state => ({
})

const mapDispatch = (dispatch, ownProps) => {

    return {


        saveManufacturer: (manufacturer, formAction) => {

            return new Promise((resolve, reject) => {
                dispatch(saveManufacturerItem(manufacturer, formAction)).then(response => {

                    resolve(response);
                    // if (response.status == 200 && !response.data.errorCode) {
                    //     window.Message('Saved Successfully', discount.id > 0 ? `${discount.name} Updated....` : `${discount.name} Created....`);
                    // }
                }).catch(error => {
                    reject(error);
                });
            });
        }
    }
}
export default connect(mapProps, mapDispatch)(AddManufacturer);