import * as Yup from "yup";
import { REGEX, REGEX_ERROR } from "../../../../utils/Constants";

const EditGuestProfileSchema = Yup.object().shape({
  first_name: Yup.string()
  .matches(REGEX.ALPHABETS_WITH_SPACE, REGEX_ERROR.ALPHABETS_WITH_SPACE_ERROR)
  .required('Please Enter First Name')
  .max(100),
  last_name: Yup.string()
  .matches(REGEX.ALPHABETS_WITH_SPACE, REGEX_ERROR.ALPHABETS_WITH_SPACE_ERROR)
  .required('Please Enter Last Name')
 //.nullable()
  .max(100),
  
   email: Yup.string()
  .matches(REGEX.EMAIL, REGEX_ERROR.EMAIL_ERROR)
  .required('Please Enter Email'),

  contact_no: Yup.string()
  .matches(REGEX.PHONE,REGEX_ERROR.PHONE_ERROR)
  .nullable()
  .required('Please Enter Contact Number')
  ,

});
export default EditGuestProfileSchema;