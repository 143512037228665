import React, { Component } from 'react';
import { connect } from 'react-redux';
import { tokenRequest, adminSignInRequest } from '../../AuthenticationApiActions';
import Container from '@material-ui/core/Container';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import { ACTIONS } from '../../../../utils/Constants';
import { Redirect } from 'react-router-dom';
import Footer from '../../../Common/components/Footer/Footer';
import Navigation from '../Navigation/Navigation';
import logo from '../../../../images/logo.png';
import thkLogo from '../../../../images/thk.png';
import { ErrorMessage, Field, Form, Formik } from "formik";
import SignInSchema from './SignInSchema';
import { TextField } from 'formik-material-ui';
import Header from '../../../Authentication/components/Navigation/Header';
import Manufacturers from '../../../ManageManufacturers/components/Manufacturers/Manufacturers';
import './adminSignin.css';
import ForgotAdminPassword from '../ForgotAdmin/ForgotAdminPassword';
import { SafeAnchor } from 'react-bootstrap';
import {APP_DETAILS} from '../../../../utils/Constants'
class AdminSignin extends Component {

    constructor(props) {
        super(props);

        let { from } = props.location.state || { from: { pathname: ACTIONS.ACCESS_PAGE } };
        var d = new Date();
        this.state = {
            currentyear :d.getFullYear(),
            visible: true,
            logo: logo,
            whichComponent: "AdminSignIn",
            thkLogo: thkLogo,
            submitting:false,
            showForgot: false,
            buttonDisable:false,
            from
        };

    }


    componentDidMount() {

    }

    //let mybutton = document.getElementById("myBtn");

    // When the user scrolls down 20px from the top of the document, show the button
   

    loginUser = (data, actions) => {
   
        this.setState({ buttonDisable:true })
        this.props.onLogin(data, actions).then(response => {
            setTimeout(() => this.setState({ buttonDisable:false }), 5000);
        }, error => {
            //TODO: Show message for incorrect credentials
            setTimeout(() => this.setState({ buttonDisable:false }), 5000);
        })
    }
    handleClick = () => {
        this.setState({ showForgot: false });
    }
    handleForgotPassword = () => {
        this.setState({ showForgot: true })
        //this.props.handleChild();
    }



    handleForgotClick = () => {
        this.setState({ showForgot: true });
       // this.props.handleChild();
    }

    render() {
      
        const topheight = {
            padding: '100px 0 30px'

        };
       
        if (this.props.isLoggedIn) {
            return <Redirect to={this.state.from} />
        } else {
            return (
                <React.Fragment>
                    <Header Logo={this.state.logo}></Header>
                    <Container >
                        <h2 className="text-center heading signin-heading">Your Linear Motion - Bellows calculator</h2>
                        <Card className="admin-card">

                            <div className="row">
                                <div className="col-lg-12 col-sm-12 user-login">
                                    <CardContent>
                                    {this.state.showForgot ? <ForgotAdminPassword /> : 
                                       <><h3 className="subheading">Authorized Admin Login</h3>
                                        <Formik
                                            validationSchema={SignInSchema}
                                            onSubmit={this.loginUser}
                                            initialValues={{
                                                username: '',
                                                password: '',
                                                IsAdmin : true
                                            }}
                                        >


                                            {({ errors, status, touched, isSubmitting, setFieldValue }) => (
                                                //  <h3 className="subheading">Authorized User Login</h3>

                                                <Form name="loginForm" className="form-horizontal form-simple" noValidate>

                                                    <fieldset className="com-field">
                                                        <Field type="text" name="username" id="standard-required"
                                                            label="User Name"
                                                            margin="normal"
                                                            fullWidth
                                                            component={TextField}
                                                            placeholder="User Name"
                                                           
                                                            error />

                                                        {/* <ErrorMessage name="username" style={{ color: 'red' }} component="div" /> */}

                                                    </fieldset>

                                                    <fieldset className="com-field">

                                                        <Field type="password"
                                                            component={TextField}
                                                            id="standard-password-input"
                                                            label="Password"
                                                            margin="normal"
                                                            fullWidth
                                                            placeholder="Password"
                                                            name="password" onChange={(e) => setFieldValue("password", e.currentTarget.value)}
                                                           
                                                            error />

                                                    </fieldset>
                                                    <div className="forgot-pass">
                                                     {this.props.showForgot ? <ForgotAdminPassword /> :
                                                <SafeAnchor style={{ 'color': '#01524B' }} onClick={this.handleForgotPassword}>
                                                    Forgot Password
                                        </SafeAnchor>} 
                                                  

                                                    <Button type="submit" disabled={this.state.buttonDisable} color="primary" className="submit-btn admin-signin">
                                                        Login
                                                </Button> 
                                                </div>
                                                </Form>

                                            )}
                                        </Formik></>
                                        }
                                    </CardContent>
                                </div>
                            </div>
                        </Card>
                    </Container>
                    < Manufacturers />
                    <div className="loader-parent d-none" id="loaderdiv">
                            <div className="loader loadercenter">
                                <i className="fa fa-spinner fa-spin" />
                            </div>
                        </div>
                    <div className="main-footer">
                    <Container fluid="true" className="footer-copyright footer-max-width" >
            <Container>
                <div className="row">
                    <div className="col-lg-12">
                    <p className="footer-copyright">Copyright © {new Date().getFullYear()} {APP_DETAILS.APPLICATION_NAME_FOOTER}, <span>All Rights Reserved</span></p>
                    </div>
                </div>
                <button onclick="topFunction()" id="myBtn" className="TopButton" title="Go to top">Top</button>
                </Container>
            </Container>
            </div>
                </React.Fragment>
            )
        }
        // }
 
    }
}



const mapStateToProps = state => {
    return {
        isLoggedIn: state.auth.isLoggedIn
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {

        onLogin: (data, actions) => {
            return new Promise((resolve, reject) => {
                dispatch(adminSignInRequest(data, actions)).then(response => {

                    resolve(response);
                }).catch(error => {

                    reject(error);
                });
            });
        }
    }
}

AdminSignin = connect(mapStateToProps, mapDispatchToProps)(AdminSignin);

export default AdminSignin;