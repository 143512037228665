import * as Yup from "yup";
import { REGEX, REGEX_ERROR } from "../../../../utils/Constants";

const calculatorOutputSchema = Yup.object().shape({
    FlangeThickness: Yup.string()
    .matches(REGEX.ALPHANUMERIC_WITHOUT_SPACE, REGEX_ERROR.ALPHANUMERIC_WITHOUT_SPACE_ERROR)
    .required('Please Enter Value'),

    GasketThickness: Yup.string()
    .matches(REGEX.ALPHANUMERIC_WITHOUT_SPACE, REGEX_ERROR.ALPHANUMERIC_WITHOUT_SPACE_ERROR)
    .required('Please Enter Value'),

    VelcroThickness: Yup.string()
    .matches(REGEX.ALPHANUMERIC_WITHOUT_SPACE, REGEX_ERROR.ALPHANUMERIC_WITHOUT_SPACE_ERROR)
    .required('Please Enter Value'),

    Pitch: Yup.string()
    .matches(REGEX.ALPHANUMERIC_WITHOUT_SPACE, REGEX_ERROR.ALPHANUMERIC_WITHOUT_SPACE_ERROR)
    .required('Please Enter Value'),

    OpenPitch: Yup.string()
    .matches(REGEX.ALPHANUMERIC_WITHOUT_SPACE, REGEX_ERROR.ALPHANUMERIC_WITHOUT_SPACE_ERROR)
    .required('Please Enter Value'),

    Cost: Yup.string()
    .matches(REGEX.ALPHANUMERIC_WITHOUT_SPACE, REGEX_ERROR.ALPHANUMERIC_WITHOUT_SPACE_ERROR)
    .required('Please Enter Value'),


    LMin: Yup.string()
    .matches(REGEX.ALPHANUMERIC_WITHOUT_SPACE, REGEX_ERROR.ALPHANUMERIC_WITHOUT_SPACE_ERROR)
    .required('Please Enter Value'),

    Fold: Yup.string()
    .matches(REGEX.ALPHANUMERIC_WITHOUT_SPACE, REGEX_ERROR.ALPHANUMERIC_WITHOUT_SPACE_ERROR)
    .required('Please Enter Value'),

    OEMRatio: Yup.string()
    .matches(REGEX.ALPHANUMERIC_WITHOUT_SPACE, REGEX_ERROR.ALPHANUMERIC_WITHOUT_SPACE_ERROR)
    .required('Please Enter Value'),

    ListRatio: Yup.string()
    .matches(REGEX.ALPHANUMERIC_WITHOUT_SPACE, REGEX_ERROR.ALPHANUMERIC_WITHOUT_SPACE_ERROR)
    .required('Please Enter Value'),

    DistributorRatio: Yup.string()
    .matches(REGEX.ALPHANUMERIC_WITHOUT_SPACE, REGEX_ERROR.ALPHANUMERIC_WITHOUT_SPACE_ERROR)
    .required('Please Enter Value'),

    SalesRatio: Yup.string()
    .matches(REGEX.ALPHANUMERIC_WITHOUT_SPACE, REGEX_ERROR.ALPHANUMERIC_WITHOUT_SPACE_ERROR)
    .required('Please Enter Value'),

    Setup: Yup.string()
    .matches(REGEX.ALPHANUMERIC_WITHOUT_SPACE, REGEX_ERROR.ALPHANUMERIC_WITHOUT_SPACE_ERROR)
    .required('Please Enter Value'),

});

export default calculatorOutputSchema;