import { func } from "prop-types";

export const GET_ALL_USERTYPE = 'GET_ALL_USERTYPE';
export const GET_ALL_COUNTRIES = 'GET_ALL_COUNTRIES';
export const GET_ALL_COMPANY_TYPES = 'GET_ALL_COMPANY_TYPES';
export const GET_ALL_STATES = 'GET_ALL_STATES';
export const GET_ALL_ROLES = 'GET_ALL_ROLES';
export const GET_ALL_MANUFACTURERS = 'GET_ALL_MANUFACTURERS';
export const GET_ALL_PERMISSIONS = 'GET_ALL_PERMISSIONS';
export const GET_ALL_CALCULATORS = 'GET_ALL_CALCULATORS';
export const GET_ALL_RAILS = 'GET_ALL_RAILS';
export const GET_ALL_BELLOWS = 'GET_ALL_BELLOWS';
export const GET_ALL_SIZES = 'GET_ALL_SIZES';
export const GET_MANUFACTURER_SIZES = 'GET_MANUFACTURER_SIZES';
export const GET_BELLOWS_SIZES = 'GET_BELLOWS_SIZES';
export const GET_ALL_MATERIALS = 'GET_ALL_MATERIALS';
export const GET_ALL_SERIES = 'GET_ALL_SERIES';
export const GET_ALL_TYPES = 'GET_ALL_TYPES';
export const GET_ALL_ACCESSORIES = 'GET_ALL_ACCESSORIES';
export const GET_ALL_RAIL_LENGTH = 'GET_ALL_RAIL_LENGTH';
export const GET_ALL_KR_MATERIALS = 'GET_ALL_KR_MATERIALS';
export const GET_ALL_RAILBELLOW='GET_ALL_RAILBELLOW';

export function getAllUserType(allUserTypes) {
    return {
        type: GET_ALL_USERTYPE,
        allUserTypes
    }
}

export function getAllCountries(allCountries) {
    return {
        type: GET_ALL_COUNTRIES,
        allCountries
    }
}

export function getAllManufacturers(allManufacturers) {
    return {
        type: GET_ALL_MANUFACTURERS,
        allManufacturers
    }
}

export function getAllCompanyTypes(allCompanyTypes)  {
     return {
         type: GET_ALL_COMPANY_TYPES,
         allCompanyTypes
     }
}

export function getAllStates( allStates) {
    return {
        type: GET_ALL_STATES,
        allStates
    }
}

export function getAllRoles(allRoles)  {
    return {
        type: GET_ALL_ROLES,
        allRoles
    }
}

export function getAllMaterials(allMaterials)  {
    return {
        type: GET_ALL_MATERIALS,
        allMaterials
    }
}

export function getAllKrMaterials(allkrMaterials)  {
    return {
        type: GET_ALL_KR_MATERIALS,
        allkrMaterials
    }
}

export function getAllRails(allRails)  {
    return {
        type: GET_ALL_RAILS,
        allRails
    }
}

export function getAllBellows(allBellows) {

    return {
        type: GET_ALL_BELLOWS,
        allBellows
    }
}

export function getAllBlockTypes(allTypes) {

    return {
        type: GET_ALL_TYPES,
        allTypes
    }
}
export function getAllSizes(allSizes) {
     return {
         type: GET_ALL_SIZES,
         allSizes
     }
}
export function getManufacturerSizes(manufacturerSizes) {
    return {
        type: GET_MANUFACTURER_SIZES,
        manufacturerSizes
    }
}
export function getBellowsSizes(bellowsSizes) {
    return {
        type: GET_BELLOWS_SIZES,
        bellowsSizes
    }
}
export function getAllPermissions(allPermissions)  {
    return {
        type: GET_ALL_PERMISSIONS,
        allPermissions
    }
}
export function getAllCalculators(allCalculators)  {
    return {
        type: GET_ALL_CALCULATORS,
        allCalculators
    }
}

export function getAllSeries(allSeries)  {
    return {
        type: GET_ALL_SERIES,
        allSeries
    }
}

export function getAllAccessories(allAccessories){

    return {
        type: GET_ALL_ACCESSORIES,
        allAccessories
    }
}

export function getAllRailLengths(allRailLength){

    return {
        type: GET_ALL_RAIL_LENGTH,
        allRailLength
    }
}

export function getAllRailBellowTypes(allRailBellows){
    return{

        type:GET_ALL_RAILBELLOW,
        allRailBellows
    }
}
