import React, { Component } from 'react'
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import './calculator.css';
import calculatorimg1 from '../../../../../src/images/calculator-img1.jpg';
import partnumbercodinimg from '../../../../../src/images/part-number-coding2.0.png';
import Container from 'react-bootstrap/Container';
import { Field, FieldArray, Form, Formik } from 'formik';
import { SafeAnchor, Modal } from 'react-bootstrap';
import IKnowPartNumberForm from './IKnowPartNumberForm';
import Auth from '../../../../utils/Auth';
import { getDefaultValuesForIKnowPartNumberData } from '../../CalculatorOutputApiActions';

let auth = new Auth();
export default class IKnowPartNumbercalculator extends Component {

    test = () => {
        //console.debug("");
    }

    constructor(props) {

        super(props);
        this.state = {
            IsAdmin: auth.getUserType(),
            IsChildLoaded: false,
            noOfClick: 1,
            calculatorId: auth.getCalculatorId(),
            manufacturerId: auth.getManufacturerId(),
            bellowsId: 0,
            sizeId: 0,
            bellowsText: "",
            sizeText: ""
        }
    }
    componentDidMount(props) {
        this.getDefaultValues()
    }
    getDefaultValues = () => {
        getDefaultValuesForIKnowPartNumberData(this.state.manufacturerId, this.state.bellowsId).then(response => {
            this.setState({
                IsChildLoaded: true,
                bellowsId: response.BellowId,
                sizeId: response.SizeId,
                bellowsText: response.BellowsText,
                sizeText: response.SizeText
            })
        }, error => {

        });
    }
    handleAddMoreButtonClick = (event) => {
        this.setState({
            noOfClick: this.state.noOfClick + 1
        })
    }

    printPage = () => {
        var btns = document.getElementsByClassName('print-btn');
        for (var i = 0; i < btns.length; i++) {
            btns[i].style.display = 'none';
        }
        btns = document.getElementsByClassName('submit-btn');
        for (var i = 0; i < btns.length; i++) {
            btns[i].style.display = 'none';
        }
        var npdiv = document.getElementsByClassName('no-print');
        for (var i = 0; i < npdiv.length; i++) {
            npdiv[i].classList.add("d-none");
        }
        var prdiv = document.getElementsByClassName('print-ele');
        for (var i = 0; i < prdiv.length; i++) {
            prdiv[i].classList.remove("d-none");
        }
        var html = '<HTML>\n<HEAD>\n';
        if (document.getElementsByTagName != null) {
            var headTags = document.getElementsByTagName("head");
            if (headTags.length > 0) html += headTags[0].innerHTML;
        }
        html += '\n<style type="text/css" media="print">';
        html += '\n@page port {size: portrait;}';
        html += '\n@page land {size: landscape;}';
        html += '\n.portrait {page: port;}';
        html += '\n.landscape {page: land;}';
        html += '\n.page-break { display: block; page-break-before: auto; }';
        html += '\n</style>';
        html += '\n</HEAD>\n<BODY  class="landscape">\n';

        var printReadyElem = document.getElementById("PrintAllElement");
        if (printReadyElem != null) html += printReadyElem.innerHTML;
        else {
            return;
        }
        var btns = document.getElementsByClassName('print-btn');
        for (var i = 0; i < btns.length; i++) {
            btns[i].style.display = 'block';
        }
        btns = document.getElementsByClassName('submit-btn');
        for (var i = 0; i < btns.length; i++) {
            btns[i].style.display = 'block';
        }
        var npdiv = document.getElementsByClassName('no-print');
        for (var i = 0; i < npdiv.length; i++) {
            npdiv[i].classList.remove("d-none");
        }
        var prdiv = document.getElementsByClassName('print-ele');
        for (var i = 0; i < prdiv.length; i++) {
            prdiv[i].classList.add("d-none");
        }

        html += '\n</BODY>\n</HTML>';
        var printWin = window.open("", "processPrint");

        printWin.document.open();
        printWin.document.write(html);
        printWin.document.close();

        printWin.onload = function () {
            printWin.focus();
            printWin.print();
            printWin.close();
        };
    }
    // renderForms = () => {
    //     return (
    //         <IKnowPartNumberForm bellowsId={this.state.bellowsId} sizeId={this.state.sizeId} 
    //         bellowsText = {this.state.bellowsText} sizeText = {this.state.sizeText}
    //         calculatorId={this.state.calculatorId} id={this.state.noOfClick} manufacturerId={this.state.manufacturerId}/>
    //     )
    // }

    render() {
        if (!this.state.IsChildLoaded) {
            return (<div className="loader-parent" id="loaderdiv">
                <div className="loader loadercenter">
                    <i className="fa fa-spinner fa-spin" />
                </div>
            </div>);
        }

        const children = [];

        for (var i = 0; i < this.state.noOfClick; i += 1) {
            children.push(<IKnowPartNumberForm
                bellowsId={this.state.bellowsId} sizeId={this.state.sizeId}
                bellowsText={this.state.bellowsText} sizeText={this.state.sizeText}
                key={i} number={i} manufacturerId={this.state.manufacturerId}
                calculatorId={this.state.calculatorId} />);
        }

        return (
            <React.Fragment>
                <Container id="PrintDiv">
                    <div className="row  pb-30">
                        <div className="col-lg-3 col-md-4 cal-sidebar">
                            <Card className="part-number-coding">
                                <CardContent>
                                    <div className="parameter dotted-border">
                                        <div className="row">
                                            <p className="calculator-headings">Cross reference table</p>
                                        </div>
                                        <div className="row tablerow">
                                            <div className="col-lg-12 table-responsive mt-2">
                                                {/* <img className="partnumbercodinimg" src={partnumbercodinimg} /> */}
                                                <table className="table">
                                                    <thead>
                                                        <tr className="odd-row"><th>Rail</th><th>THK P/N</th><th>Nabell P/N</th></tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr><td rowSpan={'2'}>HSR</td><td>JH</td><td>HSR</td></tr>
                                                        <tr><td>DH</td><td>LHSR</td></tr>

                                                        <tr className="odd-row"><td rowSpan={'2'}>SHS</td><td>JSH</td><td>SHS</td></tr>
                                                        <tr className="odd-row"><td>DSH</td><td>LSHS</td></tr>

                                                        <tr><td rowSpan={'2'}>SSR</td><td>JSSR</td><td>SSR</td></tr>
                                                        <tr><td>DSSR</td><td>LSSR</td></tr>

                                                        <tr className="odd-row"><td>NR/NRS</td><td>JN</td><td>NR</td></tr>
                                                        <tr><td>HRW</td><td>JHRW</td><td>HRW</td></tr>
                                                        <tr className="odd-row"><td>NSR</td><td>J</td><td>NSR</td></tr>

                                                        <tr><td rowSpan={'2'}>SR</td><td>JS</td><td>SR</td></tr>
                                                        <tr><td>DS</td><td>LSR</td></tr>

                                                        <tr className="odd-row"><td rowSpan={'2'}>SHW</td><td>JSHW</td><td>SHW</td></tr>
                                                        <tr className="odd-row"><td>DSHW</td><td>LSHW</td></tr>

                                                        <tr><td>SNR/SNS</td><td>JSN</td><td>SNR</td></tr>
                                                        <tr className="odd-row"><td>SRG</td><td>JSRG</td><td>SRG</td></tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                </CardContent>
                            </Card>

                        </div>
                        <div className="col-lg-9 col-md-8 calculator-rightside" id="PrintAllElement">

                            <Card>
                                <CardContent>
                                    {children}
                                    <div className="pt-10 text-right">
                                        <SafeAnchor className="btn btn-default">
                                            <i className="fa fa-plus"
                                                onClick={(e) => {
                                                    this.handleAddMoreButtonClick(e);
                                                }} />
                                        </SafeAnchor>
                                    </div>
                                    <div className="text-right">
                                        <button className="print-btn mt-1" onClick={this.printPage}>Print</button>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </Container>
            </React.Fragment>
        )
    }
}