import { SERVER_ACTIONS } from '../../../utils/Constants';
import HttpRequest from '../../../utils/HttpRequest';
import Auth from '../../../utils/Auth';
import { calculatorlist, calculatorStatus, addCalculatorInList,editCalculator, deleteCalculator, formulaList,manufacturerList } from './CalculatorActions';
const auth = new Auth();


export function addCalculator(manufacturerId, calculatorName, created_By,IsDeveloped) {
    return (dispatch) => {
        const parameters = {};
        parameters.CalculatorId = 0;
        parameters.ManufacturerId = manufacturerId;
        parameters.CalculatorName = calculatorName;
        parameters.Created_On = '';
        parameters.Created_By = created_By;
        parameters.Modified_On = '';
        parameters.Modified_By = '';
        parameters.Is_Active = 1;
        parameters.Is_Logical_Deleted = 0;
        parameters.IsDeveloped = IsDeveloped;

        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(`${SERVER_ACTIONS.API + SERVER_ACTIONS.POST_ADD_CALCULATOR}`, 'post', parameters)
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                    
                        dispatch(addCalculatorInList(response.data.data));
                   
                    // dispatch(calculatorStatus(response.data.data));
                    //dispatch(calculatorlist(response.data.data, 5, 1));
                    return Promise.resolve(response.data);
                }
                else {
                    return Promise.reject(response.data);
                }

            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);


            });


    }

}




export function getCalculatorList({ PageNumber, defaultPageSize, query }) {
    return (dispatch) => {
        const queryString = (query && query.trim().length > 0) ? `?${query}` : '';
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(`${SERVER_ACTIONS.API + SERVER_ACTIONS.POST_CALCULATORS}${queryString}`, 'post', {})
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                    dispatch(calculatorlist(response.data, Math.ceil(response.data.data.totalrecord / defaultPageSize), PageNumber));
                    return Promise.resolve(response.data);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);


            });

    }

}

export function getCalculatorListByManufacturerId({PageNumber, defaultPageSize, query }) {
    return (dispatch) => {
        const queryString = (query && query.trim().length > 0) ? `?${query}` : '';
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(`${SERVER_ACTIONS.API + SERVER_ACTIONS.GET_CALCULATOR_BY_MANUFACTURER_ID}${queryString}`, 'post', {})
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                    dispatch(calculatorlist(response.data, Math.ceil(response.data.data.totalrecord / defaultPageSize), PageNumber));
                    return Promise.resolve(response.data);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);


            });

    }

}

export function changeCalculatorStatus(calculatorId) {
    return (dispatch) => {
        const params = {};
        params.id = calculatorId;
        params.modified_by = 1;
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(`${SERVER_ACTIONS.API + SERVER_ACTIONS.POST_CALCULATOR_STATUS}`, 'post', params)
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {

                    dispatch(calculatorStatus(response.data.data));
                    
                    window.Message(response.data.message, 'Success');
                    return Promise.resolve(response.data);
                }
                else {
                    return Promise.reject(response.data);
                }

            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);


            });


    }

}

export function editCalculatorById(calculatorId, manufacturerId, calculatorName, modified_By,IsDeveloped) {
    return (dispatch) => {
        const parameters = {};
        parameters.CalculatorId = calculatorId;
        parameters.ManufacturerId = manufacturerId;
        parameters.CalculatorName = calculatorName;
        parameters.Created_On = '';
        parameters.Created_By = 0;
        parameters.Modified_On = '';
        parameters.Modified_By = modified_By;
        parameters.Is_Active = 1;
        parameters.Is_Logical_Deleted = 1;
        parameters.IsDeveloped = IsDeveloped;
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(`${SERVER_ACTIONS.API + SERVER_ACTIONS.PUT_EDIT_CALCULATOR}`, 'put', parameters)
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                 
                   
                        dispatch(calculatorStatus(response.data.data));
                    
                    return Promise.resolve(response.data);
                }
                else {
                    return Promise.reject(response.data);
                }

            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);


            });


    }

}

export function deleteCalculatorById(calculatorId, modified_By) {
    return (dispatch) => {
        const params = {};
        params.id = calculatorId;
        params.modified_by = modified_By;
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.DELETE_CALCULATOR, 'DELETE', params).requestWithAuth().then(
            (response) => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                    window.Message(response.data.message, 'Success');
                    dispatch(deleteCalculator(calculatorId));
                }

                return Promise.resolve(response);
            },
            (error) => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);
            }
        );


    }



}
export function getFormulaByCalculatorId(CalculatorId) {

    document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(`${SERVER_ACTIONS.API + SERVER_ACTIONS.GET_FORMULA_BY_CALCULATOR_ID}?calculatorId=${CalculatorId}`, 'get', {})
        .requestWithAuth()
        .then(response => {
            document.getElementById("loaderdiv").classList.add("d-none");
            //console.log('formula list received');
            if (response.status === 200 && !response.data.errorCode) {
                // dispatch(formulaList(response.data));
                return Promise.resolve(response.data.data);
            }
            else {
                return Promise.reject(response.data);
            }


        }, error => {
            document.getElementById("loaderdiv").classList.add("d-none");
            return Promise.reject(error);


        });


}

export function createCalculatorFormula(calculatorId, data, actions) {
   
    const parameters = { CalculatorId: '', CalculatorAttributeValueModel: {}, Created_By: '' };
    parameters.CalculatorId = calculatorId;
    parameters.CalculatorAttributeValueModel = data;
    parameters.Created_By = 1;

    document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(`${SERVER_ACTIONS.API + SERVER_ACTIONS.POST_CREATE_CALCULATOR_FORMULA}`, 'post', parameters)
        .requestWithAuth()
        .then(response => {
            document.getElementById("loaderdiv").classList.add("d-none");
            actions.setSubmitting(false);
            if (response.status === 200 && !response.data.errorCode) {
                return Promise.resolve(response.data);
            }
            else {
                return Promise.reject(response.data);
            }

        }, error => {
            document.getElementById("loaderdiv").classList.add("d-none");
            actions.setSubmitting(false);
            return Promise.reject(error);
        });


}

export function deleteCalculatorFormulaById(calculatorFormulaId) {


    document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(`${SERVER_ACTIONS.API + SERVER_ACTIONS.DELETE_CALCULATOR_FORMULA_BY_ID}?formulaId=${calculatorFormulaId}`, 'DELETE', {}).requestWithAuth().then(
        (response) => {
            document.getElementById("loaderdiv").classList.add("d-none");
            if (response.status === 200 && !response.data.errorCode) {
                window.Message(response.data.message, 'Success');
                // dispatch(deleteCalculator(calculatorId));
            }

            return Promise.resolve(response.data);
        },
        (error) => {
            document.getElementById("loaderdiv").classList.add("d-none");
            return Promise.reject(error);
        }
    );


}

// export function getAllManufacturerList(manufacturerId) {
//     const queryString = '?ManufacturerId='+manufacturerId;
//     return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_ALL_MANUFACTURERS}${queryString}`, 'get', {})
//             .requestWithAuth()
//             .then(response => {
//                debugger
//                 if (response.status === 200 && !response.data.errorCode) {
//                 console.log({api:response.data})
//                     debugger
//                     return Promise.resolve(response.data);
//                 }
//                 else {
//                     return Promise.reject(response.data);
//                 }


//             }, error => {
               
//                 return Promise.reject(error);


//             });
// } 
export function getAllManufacturerList(manufacturerId) {
    return (dispatch) => {
        const queryString = '';
 
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_ALL_MANUFACTURERS}${queryString}`, 'get', {})
            .requestWithAuth()
            .then(response => {
               
                if (response.status === 200 && !response.data.errorCode) {
                    dispatch(manufacturerList(response.data));
                    return Promise.resolve(response.data);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);


            });

    }

}




// return (dispatch) => {
//     return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_ALL_COUNTRIES}`, 'get', {}).requestWithAuth().then(
//         (response) => {

//             dispatch(getAllCountries(response.data));
//             return Promise.resolve(response.data);
//         },
//         (error) => {
//             return Promise.reject(error);
//         }
//     );
// };

