import React, { Component } from 'react'
import { SafeAnchor, Modal } from 'react-bootstrap';
// import { Formik, Form, Field } from 'formik';
import Button from '@material-ui/core/Button';
import { Field, FieldArray, Form, Formik } from 'formik';
import { FormikTextField } from 'formik-material-fields';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { TextField } from 'formik-material-ui';
import { getRailsAttributes, deleteRailsAttribute, addRails } from '../../components/ManageAttributeApiAction';
import Icon from '@material-ui/core/Icon';
import ManageAttributeSchema from './ManageAttributeSchema';
import{StyledReactTooltip} from '../ToolTip';
import './ManageAttribute.css';
import Swal from 'sweetalert2'
import swal from 'sweetalert';


const { Header, Body, Footer, Title } = Modal;

export default class EditRails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showform: false,
            manufacturer_name: "",

            rail: [{
                RailId: 0,
                RailName: ''
            }
            ]


        };

    }
    componentDidMount() {
        //console.log("didmount");
    }
    componentWillReceiveProps(props) {
        this.setState({ manufacturer_id: this.props.manufacturer_id });

    }


    showForm = () => {
        this.setState({ showform: true })
    }


    hideForm = () => {
        this.setState({ showform: false })
    }
    deleterail = (option, arrayHelpers, i) => {
        if (option.RailId == 0) {
            arrayHelpers.remove(i);
        }
        else {

            Swal.fire({
                //title: 'Are you sure?',
                text: 'This action will delete the record permanently from database, Are you sure?',
                // icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel',
                showCloseButton: true
              }).then((result) => {
                if (result.value) {
                
                        deleteRailsAttribute(option.RailId).then(resp => {
                                        //console.log({ response: resp.Data, list: this.state.rail });
                                        let { options } = this.state.rail;
                                        options = this.state.rail.filter(u => u.RailId != resp.Data.RailId);
                                        this.setState({
                                            rail: options,
                                        })
                                        arrayHelpers.remove(i);
                                    });
                  
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                  
                }
              })

            // const value=  Swal.fire({
            //     title: 'This action will delete the record permanently from database',               
            //     confirmButtonText:'Yes',
            //     //  {
            //     //             catch: {
            //     //                 text: "Yes",
            //     //                 value: "Yes",
            //     //             }},
            //     cancelButtonText: 'Cancel',
            //     showCancelButton: true,
            //     showCloseButton: true
            //   })
            //   debugger
            //             switch (value) {
            //                 case "Yes":
                                
            //                         // deleteRailsAttribute(option.RailId).then(resp => {
            //                         //     //console.log({ response: resp.Data, list: this.state.rail });
            //                         //     let { options } = this.state.rail;
            //                         //     options = this.state.rail.filter(u => u.RailId != resp.Data.RailId);
            //                         //     this.setState({
            //                         //         rail: options,
            //                         //     })
            //                         //     arrayHelpers.remove(i);
            //                         // });
                                
            //                     break;
            //                 default:
        
            //             }
                  

        //     Swal.fire("This action will delete the record permanently from database, Are you sure?", {
        //    title:"Here's a title!",
        //    showCloseButton: true,
        //     confirmButtonText: {
        //         catch: {
        //             text: "Yes",
        //             value: "Yes",
        //         },
        //         cancel: "Cancel",
        //     },
        // })
        //     .then((value) => {
        //         switch (value) {
        //             case "Yes":
                        
        //                     deleteRailsAttribute(option.RailId).then(resp => {
        //                         //console.log({ response: resp.Data, list: this.state.rail });
        //                         let { options } = this.state.rail;
        //                         options = this.state.rail.filter(u => u.RailId != resp.Data.RailId);
        //                         this.setState({
        //                             rail: options,
        //                         })
        //                         arrayHelpers.remove(i);
        //                     });
                        
        //                 break;
        //             default:

        //         }
        //     });
        }
    }

    getRails = () => {

        getRailsAttributes(this.props.manufacturer_id).then(res => {
            this.setState({

                rails: res,
                rail: res,
                showform: true
            })

        })
    }
    submitFormula = (data, actions) => {

let found=false;
for(let i=0;i<data.rail.length;i++){
    let rname=data.rail[i].RailName;

let foundDuplicate=data.rail.filter(r=>r.RailName==rname).map(duplicate=>(duplicate.RailName ))
    if(foundDuplicate.length>=2)
    {found=true;
        actions.setSubmitting(false)
        swal(rname+" attribute is already exist", {
            buttons: {
                    cancel: "OK",
            },
        })
            .then((cancel) => {
                switch (cancel) {
                    case "Yes":                        
                        break;
                    default:
                }
            });
    } 
}
     if(found==false)
     {
        actions.setSubmitting(true);
        // let updateOptions = data.options.filter(o => (this.state.updatableOptions.indexOf(o.CalulatorformulaeId) > -1 || o.CalulatorformulaeId == 0));
        let railAttributeValueModel = data.rail

        addRails(this.props.manufacturer_id,
            railAttributeValueModel).then(response => {

                if (response.Status && response.StatusCode === 200) {
                    let { rail } = this.state;

                    // options = options.map(o => {
                    //     if (updateOptions.map(oo => oo.CalulatorformulaeId == o.CalulatorformulaeId).length > 0) {
                    //         o = updateOptions.filter(o => o.CalulatorformulaeId === o.CalulatorformulaeId)[0];
                    //     }
                    //     return o;
                    // })

                    rail = rail.map(rail => {
                        let option2 = railAttributeValueModel.find(o => o.RailId == rail.RailId);
                        return option2 ? { ...rail, ...option2 } : rail;
                    })

                    //console.log({ railAttributeValueModel: rail });
                    this.setState({
                        rail
                    })

                }

            }, this.hideForm())
        }
     
      

    }



    render() {

        const manufacturer_id = this.props.manufacturer_id;
        const manufacturer_name = this.props.manufacturer_name;
        return (
            <>
            {!this.state.showform ?<>
                <Icon    data-tip data-for="edit" onClick={this.getRails}>
                    edit
                 </Icon>
                  <StyledReactTooltip id="edit" effect="solid">
                  <span>Edit</span>
                 </StyledReactTooltip></>
                : <>
                <Modal className="calculatorPopup" show={this.state.showform} onHide={this.hideForm}>
                    <Header closeButton>
                        <Title>{manufacturer_name}</Title>
                    </Header>
                    <Body>

                        <Formik
                            validationSchema={ManageAttributeSchema}
                            onSubmit={this.submitFormula}
                            initialValues={{ rail: this.state.rails }}
                        >
                            {({ values, status, isSubmitting }) => (
                                <Form>
                                    <div className="row">
                                        <div className="col-lg-12 popup-heading">
                                            <label >ATTRIBUTE NAME : RAIL</label>


                                        </div>



                                        <div className="col-lg-8 col-8">
                                            <label >Rail Name</label>

                                        </div>
                                    </div>
                                    <FieldArray
                                        name="rail"

                                        render={arrayHelpers => (
                                            <div className="clacfor">

                                                {values.rail && values.rail.length > 0 ? values.rail.map((rail, i) => {
                                                    //console.log({ rail: rail })


                                                    return (<div className="row" key={i}>
                                                        <div className="col-lg-8 col-8">

                                                            <Field

                                                                name={`rail[${i}.RailName]`}
                                                                margin="normal"

                                                                fullWidth

                                                                component={TextField}
                                                                // onChange={() => {
                                                                //     this.onFieldChange(option)
                                                                // }}
                                                            />
                                                            { (!rail.RailName || rail.RailName.length == 0) && this.state.error ?
                                                                <span style={{ color: "red" }}>Enter Rail Value</span> : ""}

                                                        </div>




                                                        <SafeAnchor className="btn btn-default">
                                                            <i className="fa fa-minus"
                                                                onClick={() => {
                                                                    this.deleterail(rail, arrayHelpers, i);

                                                                }} /></SafeAnchor>
                                                        <br />
                                                    </div>);
                                                }) : arrayHelpers.push({ RailId: 0 })}

                                                <SafeAnchor className="btn btn-default">
                                                    <i className="fa fa-plus" onClick={() => {

                                                        //check if count of rails with railid=0 and empty railname is 0
                                                        if (values.rail && values.rail.length > 0 && values.rail.filter(v => (!v.RailName || v.RailName.length == 0)).length == 0) {
                                                            arrayHelpers.push({ RailId: 0 });
                                                            this.setState({
                                                                error: false
                                                            })
                                                        } else {
                                                            this.setState({ error: true })
                                                        }


                                                    }} />
                                                </SafeAnchor>

                                            </div>
                                        )} />
                                    {/* <input type="submit"  className="submit" disabled={isSubmitting} /> */}
                                    <Modal.Footer className="row" centered>

                                        <Button variant="contained" type="submit" className="save" disabled={isSubmitting} >Submit</Button>

                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Body>
                </Modal>

                </>}
            </>

        )
    }
}