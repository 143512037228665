import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField } from 'formik-material-ui';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Modal } from 'react-bootstrap';
import { saveCustomerItem } from '../../ManageCustomersApiActions';
import RoleDropDown from '../../../Common/components/DropDown/RoleDropDown';
import Button from '@material-ui/core/Button';
import Calculators from '../../../Common/components/Calculators';
import CompanyTypeDropDown from '../../../Common/components/DropDown/CompanyTypeDropDown';
import CountryDropDown from '../../../Common/components/DropDown/CountryDropDown';
import UserTypeDropDown from '../../../Common/components/DropDown/UserTypeDropDown';
import StateDropDown from '../../../Common/components/DropDown/StateDropDown';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import RegisteredCustomerSchema from './CustomerRegisterSchema';

class EditRegisteredCustomer extends Component {
    constructor(props) {
        super(props);
        
        let registeredCustomer = props.update ? props.registeredCustomer : {
            email: '',
            first_name: '',
            last_name: '',
            role_id:'',
            id:'',
            company_name: '',
            company_type: '',
            contact_no:'',
            address:'',
            country:'',
            state:'',
            custtype:''
        };
        
        this.state = {
            country:'',
            state:'',
            registeredCustomer: registeredCustomer,
            calculatorData : []
        }
    }
    componentWillReceiveProps(props) {
        if(props.customer != undefined){
            this.state.country =  props.customer.country;
            this.state.state =  props.customer.state;                
            var x = this.state;
            
        }
    }
    saveRegisteredCustomer = (data, formAction) => {

        data.CustomerCalculator  = this.state.calculatorData;
        if(data.CustomerCalculator.length == 0){
            document.getElementById('CalculatorError').innerHTML = 'Please select calculator';
            return false;
        }
        else{
            document.getElementById('CalculatorError').innerHTML = '';
        }
        formAction.setSubmitting(true);
        this.props.saveRegisteredCustomer(data, formAction).then(response => {
            this.setState({ message: response.message });
            formAction.setSubmitting(false);
            if (this.props.updateList && typeof this.props.updateList === 'function') {
                this.props.updateList();
            }
            this.props.onHide();
            // this.handleClose();
        }, error => {
            formAction.setSubmitting(false);
        });
    };

    handleTree = (selectedper) => {
        this.setState({calculatorData: selectedper});
    }

    renderCalculators = (customer_id) => {      
        return(<Calculators id={customer_id} onCalculatorChange={this.handleTree} calculator_id={this.state.calculator_id} roleidval={this.state.role_id}>
        </Calculators>)
}
    render() {
        let stateData = this.props.customer;
        return (

            <React.Fragment>

                <Modal className="container"
                    {...this.props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header color="secondary" closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Edit Registered Customer
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Formik
                            validationSchema={RegisteredCustomerSchema}
                            onSubmit={this.saveRegisteredCustomer}
                            initialValues={stateData}
                            enableReinitialize={true}
                        >
                            {({ errors, status, touched, isSubmitting, setFieldValue }) => (
                                <Form  noValidate>

                        <div className="row">
                            <div className="col-lg-4 com-field">                                
                            <Field type="text"
                                name="email"
                                disabled
                                placeholder="Enter Email"
                                component = {TextField}
                                id="standard-required"
                                label="User Name"
                                margin="normal"
                                defaultValue={stateData.email}
                                fullWidth
                                />
                            </div>

                            <div className="col-lg-4  com-field">
                            <Field type="text"
                                name="first_name"
                                placeholder="Enter First Name"
                                component = {TextField}
                                id="standard-required"
                                label="First Name"
                                margin="normal"
                                defaultValue={stateData.first_name}
                                fullWidth
                                />
                            </div>

                            <div className="col-lg-4 com-field">
                            <Field type="text"
                                name="last_name"
                                placeholder="Enter Last Name"
                                component = {TextField}
                                id="standard-required"
                                label="Last Name"
                                margin="normal"
                                defaultValue={stateData.last_name}
                                fullWidth
                                />
                            </div>                               
                            
                            <div className="col-lg-4 com-field">
                            <Field type="text"
                                name="company_name"
                                placeholder="Enter Company Name"
                                component = {TextField}
                                id="standard-required"
                                label="Company Name"
                                margin="normal"
                                // defaultValue={data.first_name}
                                fullWidth
                                />
                            </div>

                            <div className="col-lg-4 com-field">
                                       <FormControl margin="normal" fullWidth>
                                             <InputLabel id="demo-simple-select-label">Company Role</InputLabel>
                                             <CompanyTypeDropDown enabled={true} isAdmin={false} activeOnly={true} 
                                             selectedValue={stateData.company_type} 
                                             onCompanyChange={e=>{
                                                 setFieldValue("company_type", e.target.value);     
                                                 stateData.company_type = e.target.value;                          
                                             }
                                             }/>
                                             <ErrorMessage
                                                    name="company_type"
                                                    style={{ color: "#f44336", fontSize: 11 }}
                                                    component="div"
                                                />
                                        </FormControl>
                            </div>
                            <div className="col-lg-4 com-field">
                            <Field type="text"
                                name="contact_no"
                                placeholder="Enter Phone"
                                component = {TextField}
                                id="standard-required"
                                label="Phone"
                                margin="normal"
                                fullWidth
                                />
                            </div>
                            <div className="col-lg-4 com-field">
                            <Field type="text"
                                name="address"
                                placeholder="Enter Address"
                                component = {TextField}
                                id="standard-required"
                                label="Address"
                                margin="normal"
                                // defaultValue={data.first_name}
                                fullWidth
                                />
                            </div>

                            <div className="col-lg-4 com-field">

                                       <FormControl margin="normal" fullWidth>
                                             <InputLabel id="demo-simple-select-label">Country</InputLabel>
                                             <CountryDropDown enabled={true} activeOnly={true}  selectedValue={stateData.country} 
                                                onCountryChange={e => {
                                                    setFieldValue("country", e.target.value);
                                                    // auth.setCountry(e.target.value);
                                                    stateData.country = e.target.value;
                                                    stateData.state = '';       
                                                    setFieldValue("state", e.target.value);
                                                }
                                                } />
                                                <ErrorMessage
                                                    name="country"
                                                    style={{ color: "#f44336", fontSize: 11 }}
                                                    component="div"
                                                />
                                        </FormControl>
                            </div>
                            <div className="col-lg-4 com-field">

                                       <FormControl margin="normal" fullWidth>
                                             <InputLabel id="demo-simple-select-label">State</InputLabel>
                                             <StateDropDown country={stateData.country} selectedValue={stateData.state}  enabled={true} activeOnly={true} onStateChange={e => {
                                                setFieldValue("state", e.target.value);
                                                stateData.state = e.target.value;
                                            }
                                            } />
                                            <ErrorMessage
                                                    name="state"
                                                    style={{ color: "#f44336", fontSize: 11 }}
                                                    component="div"
                                                />
                                        </FormControl>
                            </div>

                            <div className="col-lg-4 com-field">
                            <Field type="text"
                                name="postal_code"
                                placeholder="Enter Postal Code"
                                component = {TextField}
                                id="standard-required"
                                label="Postal Code"
                                margin="normal"
                                // defaultValue={data.first_name}
                                fullWidth
                                />
                            </div>

                            <div className="col-lg-4 com-field">
                                       <FormControl margin="normal" fullWidth>
                                             <InputLabel id="demo-simple-select-label">Role</InputLabel>
                                             <RoleDropDown isAdmin={false} isGuest={false} name = "role_id"  value = {stateData.role_id} selectedValue={stateData.role_id} selectedName={stateData.role_name} enabled={true} activeOnly={true} onRoleChange={(e, index)=>{
                                                 setFieldValue("role_id", e.target.value); 
                                                 setFieldValue("role_name", index.props.name);      
                                             }
                                             }/>
                                        </FormControl>
                                        <ErrorMessage
                                                    name="role_id"
                                                    style={{ color: "#f44336", fontSize: 11 }}
                                                    component="div"
                                                />
                            </div> 
                            <div className="col-lg-4  com-field">
                            <FormControl margin="normal" fullWidth>  
                                    <InputLabel id="demo-simple-select-label">Active</InputLabel>                                                                          
                                    <Select
                                    className="role_dropdown"
                                    defaultValue = {stateData.is_active}
                                    labelId="demo-controlled-open-select-label"
                                    id="is_active"
                                    key=''
                                    data-attribute='is_active' name='is_active'
                                    onChange = {(e, index)=>{ 
                                    setFieldValue('is_active', e.target.value);
                                    }}
                                    >
                                     <MenuItem value={true}>Yes</MenuItem>
                                     <MenuItem value={false} >No</MenuItem>

                                    </Select>
                                    </FormControl>
                                    </div>
                                    <div className="col-lg-4 com-field">
                                            <FormControl margin="normal"  error={errors.custtype} fullWidth>
                                                <InputLabel id="demo-simple-select-label">I AM A</InputLabel>
                                                
                                                <UserTypeDropDown enabled={true} name="custtype" value = {stateData.custtype} selectedValue={stateData.custtype} activeOnly={true} onCustTypeChange={e => {
    
                                                    setFieldValue("custtype", e.target.value);
                                                }
                                                } />

                                            </FormControl>
                                            <ErrorMessage
                                                name="custtype"
                                                style={{ color: "#f44336", fontSize: 11 }}
                                                component="div"
                                            />
                                        </div>
                            <div className="col-lg-4">
                            <InputLabel id="demo-simple-select-label">MFG Calculators <label className="red">*</label></InputLabel>   
                            {/* Permissions */}

                            {
                             this.renderCalculators(stateData.customer_id)
                            }
                            <label id="CalculatorError" className="red"></label>
                            </div>

                               {/* <div className="col-lg-4">
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel id="demo-simple-select-label">MFG. Calculators</InputLabel>
                                                <CalculatorDropDown parentCallback = {this.callbackFunction} id={stateData.customer_id} name = "calculator"  value = {stateData.CalculatorName} enabled={true} activeOnly={true} onCalculatorSelection={(e, index)=>{
                                                         this.handleCheckBox(e,index)
                                                }
                                             }/>

                                            </FormControl>
                               </div>     */}

                        </div>
                        <Modal.Footer  className="row" centered>
                        <Button variant="contained" type= "submit"  className="save">Save</Button>
                        <Button variant="contained" className="cancle" onClick={this.props.onHide}>Close</Button>
                    </Modal.Footer>
                        </Form>
                            )}

                        </Formik>

                    </Modal.Body>
                    
                </Modal>
            </React.Fragment>
        )
    }
}

const mapProps = (state) => {
    return{
    }
};

const mapDispatch = (dispatch, ownProps) => {

    return {
        saveRegisteredCustomer: (registeredCustomer, formAction) => {

            return new Promise((resolve, reject) => {
                dispatch(saveCustomerItem(registeredCustomer, formAction)).then(response => {

                    resolve(response);
                 
                }).catch(error => {
                    reject(error);
                });
            });
        }
    }
}

export default connect(mapProps, mapDispatch)(EditRegisteredCustomer);
