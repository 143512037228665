import React, { Component } from 'react'
import { SafeAnchor, Modal } from 'react-bootstrap';
// import { Formik, Form, Field } from 'formik';
import Button from '@material-ui/core/Button';
import { Field, FieldArray, Form, Formik } from 'formik';
import { FormikTextField } from 'formik-material-fields';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { TextField } from 'formik-material-ui';
import {getSizeAccessoryAttributes,deleteSizeAccessoryAttribute,addSizeAccessory} from '../../components/ManageAttributeApiAction';
import Icon from '@material-ui/core/Icon';
import SizeByManufacturerDropDown from '../../../Common/components/DropDown/SizeByManufacturer';
import AccessoryByManufacturerDropDown from '../../../Common/components/DropDown/AccessoryByManufacturerDropDown'
import {getSizeListByManufacturer,getAllAccessoryByManufacturerList} from '../ManageAttribute/EditDropDownListApi';
import{StyledReactTooltip} from '../ToolTip';
import swal from 'sweetalert';
import Swal from 'sweetalert2';

const { Header, Body, Footer, Title } = Modal;

export default class EditSizeAccesory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showform: false,
            manufacturer_name: "",
            manufacturer_id:this.props.manufacturer_id,
            sizeAccessory_Id: 0,
            sizeAccessory_name: '',
            prev_sizeAccessory_id: 0,
            prev_sizeAccessory2_id:0,
            sizeAccessory_id: 0,
            accessoryList:[{
                SizeAccessoryId:0,
                Length:0,
                AccessoryId:"",
            }],
            sizeByManufacturer:[],
            sizeAccesory:  [{
            SizeAccessoryId: 0,
            Length:0,
            AccessoryId:0,
                        
                        }
                   ]


        };

    }
    componentDidMount() {
        //console.log("didmount");
    }
    componentWillReceiveProps(props) {
        this.setState({ manufacturer_id: this.props.manufacturer_id });
        if(this.props.manufacturer_id!=undefined){
        this.getAccessoryList(this.props.manufacturer_id );
        this.getSize(this.props.manufacturer_id);
        }

    }
    componentDidUpdate() {
        const sizeValue=this.state.sizeByManufacturer[0]!=undefined?(this.state.sizeByManufacturer[0]):(0);
        const sizeid=sizeValue.SizeId
        
       if(this.state.prev_sizeAccessory2_id!=sizeValue.SizeId && sizeValue.SizeId!=undefined)
       {
        //    this.setId(sizeValue.SizeId);
           this.setState({sizeAccessory_Id:sizeValue.SizeId});
           this.getSizeAccessory(sizeid);
          this.setState({prev_sizeAccessory2_id:sizeValue.SizeId});
        
       }
        if(this.state.prev_sizeAccessory_id!==this.state.sizeAccessory_Id){
            this.getSizeAccessory(this.state.sizeAccessory_Id);
            this.setState({prev_sizeAccessory_id:this.state.sizeAccessory_Id})
        }

    }
    getSize=(manufacturerId)=>{
        getSizeListByManufacturer(manufacturerId).then(resp=>{
            this.setState({
                sizeByManufacturer:resp,
            });
        });
    }
    getAccessoryList = (manufacturerId) => {
        getAllAccessoryByManufacturerList(manufacturerId).then(resp => {
            this.setState({
                accessoryList:resp
            });
        });
    }

    showForm = () => {
        this.setState({ showform: true });
        this.getAccessoryList(this.state.manufacturer_id);
        
    }


    hideForm = () => {
        this.setState({ showform: false })
        this.setState({ sizeAccessory_Id: 0 ,prev_sizeAccessory2_id:0})
        this.setState({ sizeAccesory:  [{
            SizeAccessoryId: 0,
            Length:0,
            AccessoryId:0,
                        
                        }
                   ]})
        
    }
    deleteSizeAccessory = (option, arrayHelpers, i) => {

        Swal.fire({
            //title: 'Are you sure?',
            text: 'This action will delete the record permanently from database, Are you sure?',
            // icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
            showCloseButton: true
          }).then((result) => {
            if (result.value) {
            
                if (option.SizeAccessoryId == 0) {
                                        arrayHelpers.remove(i);
                                    }
                                    else {
                                        deleteSizeAccessoryAttribute(option.SizeAccessoryId).then(resp => {
                                            arrayHelpers.remove(i);
                                         });
                                    }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              
            }
          })
        // swal("This action will delete the record permanently from database, Are you sure?", {
        //     buttons: {
        //         catch: {
        //             text: "Yes",
        //             value: "Yes",
        //         },
        //         cancel: "Cancel",
        //     },
        // })
        //     .then((value) => {
        //         switch (value) {
        //             case "Yes":
        //                 if (option.SizeAccessoryId == 0) {
        //                     arrayHelpers.remove(i);
        //                 }
        //                 else {
        //                     deleteSizeAccessoryAttribute(option.SizeAccessoryId).then(resp => {
        //                         arrayHelpers.remove(i);
        //                      });
        //                 }
        //                 break;
        //             default:

        //         }
        //     });
    }

    // deleteSizeAccessory=(option, arrayHelpers, i)=>{
        
    //     if (option.SizeAccessoryId == 0) {
    //         arrayHelpers.remove(i);
    //     }
    //     else {


    //         deleteSizeAccessoryAttribute(option.SizeAccessoryId).then(resp => {
               
    //             arrayHelpers.remove(i);
               

    //          });
    //     }
    // }
    getSizeAccessory = (SizeAccessory_Id) =>  {
        var manufacturerId = this.state.manufacturer_id == undefined ? 0 : this.state.manufacturer_id;
        SizeAccessory_Id = SizeAccessory_Id == undefined ? 0 : SizeAccessory_Id;
    
            const query='?ManufacturerId='+manufacturerId+'&SizeId='+SizeAccessory_Id;          
            getSizeAccessoryAttributes(query).then(res => {
                
                this.setState({
    
                    
                    sizeAccessory:res,
                   
                   
                })
                
            })
        }
    handleChangeRailDropdown = (event) => {
        this.setState({ sizeAccessory_Id: event.target.value })
    };
    submitFormula = (data, actions) => {

        let found=false;
        for(let i=0;i<data.sizeAccessory.length;i++){
          
            let rname
            let secondField
            if(data.sizeAccessory[0].SizeAccessoryId==0)
            {
                 rname=parseInt(data.sizeAccessory[i].Length);
                 secondField=parseInt(data.sizeAccessory[i].AccessoryId);
            }
            else{
                 rname=data.sizeAccessory[i].Length;
                 secondField=data.sizeAccessory[i].AccessoryId;

            }
                   
        let foundDuplicate=data.sizeAccessory.filter(r=>r.Length==rname && r.AccessoryId==secondField).map(duplicate=>(duplicate.Length ))
            if(foundDuplicate.length>=2)
            {found=true;
                actions.setSubmitting(false)
                swal(rname+" attribute is already exist", {
                    buttons: {
                            cancel: "OK",
                    },
                })
                    .then((cancel) => {
                        switch (cancel) {
                            case "Yes":                        
                                break;
                            default:
                        }
                    });
            } 
        }
        if(found==false){
            
        actions.setSubmitting(true);
        // let updateOptions = data.options.filter(o => (this.state.updatableOptions.indexOf(o.CalulatorformulaeId) > -1 || o.CalulatorformulaeId == 0));
        let SizeAccessoryMappingValueModel= data.sizeAccessory

        addSizeAccessory(this.props.manufacturer_id,this.state.sizeAccessory_Id,
            SizeAccessoryMappingValueModel).then(response => {

                if (response.Status && response.StatusCode === 200) {
                    let { sizeAccesory } = this.state;
         

                    sizeAccesory = sizeAccesory.map(sizeAccesory => {
                        let option2 = SizeAccessoryMappingValueModel.find(o => o.SizeAccessory_Id == sizeAccesory.SizeAccessory_Id);
                        return option2 ? { ...sizeAccesory, ...option2 } : sizeAccesory;
                    })
                 
                    this.setState({
                        sizeAccesory
                    })

                }

            }, this.hideForm())
        }
       

    }

    render() {

        const manufacturer_id = this.props.manufacturer_id;
        const manufacturer_name = this.props.manufacturer_name;
       
        const accessorylist=this.state.accessoryList;

        let defaultAccessoryId=0;
        if(this.state.accessoryList.length>0)
        {
            defaultAccessoryId=this.state.accessoryList[0].AccessoryId!=undefined?(this.state.accessoryList[0].AccessoryId):(0);

        }
        const options = accessorylist && accessorylist.length > 0 ? (
            accessorylist.map((accessorylist) => {

                return (<option   value={accessorylist.AccessoryId}>
                    {accessorylist.AccessoryValue}
                </option>
                );
            })
        ) : (
                <option>Loading....</option>
            );
        //console.log({ krraillength: this.state.krraillength })
        return (
            <>
                {!this.state.showform ?<>
                    <Icon data-tip data-for="edit" onClick={this.showForm}>
                        edit
                 </Icon>
                  <StyledReactTooltip id="edit" effect="solid">
                  <span>Edit</span>
                 </StyledReactTooltip></>
                    : <>
                        <Modal className="calculatorPopup" show={this.state.showform} onHide={this.hideForm}>
                            <Header closeButton>
                                <Title>{manufacturer_name}</Title>
                            </Header>
                            <Body>

                                <Formik
                                    onSubmit={this.submitFormula}
                                    initialValues={{ sizeAccessory: this.state.sizeAccessory }}
                                    enableReinitialize={true}
                                >
                                    {({ values, status, isSubmitting,setFieldValue }) => (
                                        <Form>
                                            <div class="row">
                                                <div className="col-lg-12 popup-heading">
                                                    <label >ATTRIBUTE NAME :SIZE ACCESSORY</label>
                                                </div>
                                                <div className="col-lg-4">
                                               
                                                    <FormControl  margin="normal" fullWidth>
                                                    <div class="ManufacturerDropDown">
                                                        <InputLabel id="demo-simple-select-label">Size *</InputLabel>
                                                        <SizeByManufacturerDropDown selectedValue={this.state.sizeAccessory_Id} enabled={true} activeOnly={true} manufacturerId={manufacturer_id} onSizeChange={(e, index) => {
                                                            // setFieldValue("rail_id", e.target.value);
                                                            // setFieldValue("rail_name", e.target.name);
                                                            // this.handleChangeDropdown(e, index);

                                                           this.handleChangeRailDropdown(e)
                                                          
                                                            this.setState({
                                                                SizeAccessory_Id: e.target.value,
                                                                sizeAccessory_name: index.props.name
                                                                
                                                            });
                                                            
                                                        }
                                                        } />
                                                        </div>
                                                    </FormControl>
                                                </div> 
                                                </div>
                                          

                                            <div className="row">
                                        <div className="col-lg-4  col-sm-3  col-4">
                                        <label >Length</label>
                                        </div> 
                                        <div className="col-lg-4  col-sm-4  col-6">
                                        <label >Accessory</label>
                                        </div> 
                                       
                                        </div> 
                                            <FieldArray
                                                name="sizeAccessory"

                                                render={arrayHelpers => (
                                                    <div className="clacfor">

                                                        {values.sizeAccessory && values.sizeAccessory.length > 0 ? values.sizeAccessory.map((sizeAccessory, i) => {

                                                            return (<div className="row" key={i}>
                                                                <div className="col-lg-4  col-sm-3  col-4">
                      
                                                                                                
                                                                <FormikTextField                            
                                                                    name={`sizeAccessory[${i}].Length`}
                                                                    defaultValue={sizeAccessory.Length}
                                                                    margin="normal"
                                                                    fullWidth                  
                                                                />
                                                            </div>   
                                                               <div className="col-lg-4  col-sm-4  col-6">
                                                               <FormControl  class="FormulaDropdown" margin="normal" fullWidth>
                                                                <div class="ManufacturerDropDown">
                                                       
                                                                {/* { <AccessoryByManufacturerDropDown  dropdownname={`sizeAccessory[${i}.AccessoryId]`}
                                                                 selectedValue={sizeAccessory.AccessoryId} enabled={true} 
                                                                 activeOnly={true}  manufacturerId={manufacturer_id} 
                                                                 onAccessoryChange={(e, index) => {
                                                                  setFieldValue(`sizeAccessory[${i}.AccessoryId]`, e.target.value);
                                                        }
                                                        } /> 
                                                         */}
                                                        <Field component="select" style={{width:140}} defaultValue={sizeAccessory.AccessoryId} 
                                                        name={`sizeAccessory[${i}.AccessoryId]`}
                                                        labelId="demo-simple-select-label"
                                                          id="demo-simple-select" onChange={(e, index) => {
                                                            setFieldValue(`sizeAccessory[${i}.AccessoryId]`, e.target.value);
                                                            defaultAccessoryId=e.target.value;
                                                  }
                                                  }>
                                                           {options}
                                                        </Field>
                                                        </div>
                                                    </FormControl>
                      
                                                                                                
                                                                {/* <FormikTextField                            
                                                                    name={`sizeAccessory[${i}].AccessoryId`}
                                                                    defaultValue={sizeAccessory.AccessoryId}
                                                                    margin="normal"
                                                                    fullWidth                  
                                                                /> */}
                                                            </div>   

                                                                <SafeAnchor className="btn btn-default">
                                                                    <i className="fa fa-minus" onClick={() => { this.deleteSizeAccessory(sizeAccessory, arrayHelpers, i) }} /></SafeAnchor>
                                                                <br />
                                                            </div>);
                                                        }) : arrayHelpers.push({ SizeAccessoryId: 0 ,AccessoryId:defaultAccessoryId})}
                                                        <SafeAnchor className="btn btn-default" onClick={e => {
                                                           
                                                            arrayHelpers.push({ SizeAccessoryId: 0 ,AccessoryId:defaultAccessoryId})
                                                        }}><i className="fa fa-plus" /></SafeAnchor>
                                                    </div>
                                                )} />
                                            {/* <input type="submit" className="submit" disabled={isSubmitting} /> */}
                                            <Modal.Footer className="row" centered>
                                              <Button variant="contained" type= "submit"  className="save" disabled={isSubmitting} >Submit</Button>
                                            </Modal.Footer>
                                        </Form>
                                    )}
                                </Formik>
                            </Body>
                        </Modal>

                    </>}
            </>

        )
    }
}