import React, { Component } from 'react'
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import './calculator.css';
import BlockBySizeManufacturerDropDown from '../../../Common/components/DropDown/BlockBySizeManufacturerDropDown';
import SizeDropDown from '../../../Common/components/DropDown/SizeDropDown';
import RailsDropdown from '../../../Common/components/DropDown/RailsDropDown';
import BellowsDropDown from '../../../Common/components/DropDown/BellowsDropDown';
import MaterialDropDownCalculator from '../../../Common/components/DropDown/MaterialDropDownForCalculator';
import BlockTypeDropDown from '../../../Common/components/DropDown/BlockTypeDropDown';
import AccessoryDropDown from '../../../Common/components/DropDown/AccessoryDropDown';
import AccessoryByManufacturerSizeIdDropDownList from '../../../Common/components/DropDown/AccessoryByManufacturerSizeIdDropDownList';
import {getAllSizeList,getStrokeLengthListForState,getStrokeLengthList, getDefaultValuesForStrokeLengthData,SaveIKnowStrokeRailPartNumber,getSavePartNumberDetail } from '../../CalculatorOutputApiActions';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Auth from '../../../../utils/Auth';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import { APP_DETAILS,SERVER_ACTIONS } from '../../../../utils/Constants';
let auth = new Auth();
let IsRequested = false;
class Railcalculator extends Component {

    constructor(props) {
        super(props)

        this.textCL = React.createRef();
        this.state = {
            allstroke:[],

            // calculatorId: auth.getCalculatorId(),
            UserId: auth.getUserId(),
            IsAdmin: auth.getUserType(),
            UserName:auth.getUser(),
            manufacturerId: auth.getManufacturerId(),
            SavedPartNumberId:auth.getSavedPartId(), 
            
            preManufacturerId: auth.getManufacturerId(),

            materialId: 0,
            preMaterialId: "",

            railId: 0,
            preRailId: "",

            bellowId: 0,
            preBellowId: "",

            sizeId: 0,
            preSizeId: "",

            BlockOneType: 0,
            preBlockOneType: "",

            BlockOneAccessory: 0,
            preBlockOneAccessory: "",

            BlockTwoType: 0,
            preBlockTwoType: "",

            BlockTwoAccessory: 0,
            preBlockTwoAccessory: "",

            BlockTwoAccessoryName:"",
            preBlockTwoAccessoryName: "",

            defaultCenterLineValue:0,
            preDefaultCenterLineValue:"",
            centerLineValue: 0,
            preCenterLineValue: "",

            strokeLength: 0,
            preStrokeLength: "",

            railLength: 0,
            preRailLength: "",

            calculatorId: auth.getCalculatorId(),

            enableBlockTwo: true,
            disableleStrokeLength: false,
            LengthType : "stroke",

            disableRailLength: true,
            disableAccessory: true,
            disableAccessoryTwo: true,
            
            disableBellows:true,
            disableSize:true,
            disableMaterial:true,
            disableBlockOneType:true,

            selected: "",

            TwoBellow: false,
            preTwoBellow: "",

            IsRailLength: false,
            CLDist:0,
            PriceSecondTableColor:"gray",
            isDataFetched:false,
            InputData:"",
            Username : "",
            SecondCheckboxChecked : false,

            Centerlineblur:false,
            preCenterlineblur : false

        }
    }

    componentDidMount(props){

        if(this.state.SavedPartNumberId > 0)
        {
            getSavePartNumberDetail(this.state.SavedPartNumberId).then(response => {

               // this.setState({ InputData: response.InputData, SavedPartNumberId:"0" });
                var Inputdata = JSON.parse(response.InputData)
                this.setState({
                    railId: Inputdata.RailId,
                    bellowId: Inputdata.BellowId,
                    sizeId: Inputdata.SizeId,
                    materialId: Inputdata.MaterialId,
                    BlockOneType: Inputdata.BlockOneType,
                    BlockOneAccessory: Inputdata.BlockOneAccessory,
                    BlockTwoType: Inputdata.BlockTwoType,
                    BlockTwoAccessory: Inputdata.BlockTwoAccessory,
                    CLDist: Inputdata.CentralineDist,
                    centerLineValue: Inputdata.CentralineDist,
                    defaultCenterLineValue:Inputdata.CentralineDist,
                    IsRailLength : Inputdata.IsRailLength,
                    Length : Inputdata.Length,
                    TwoBellow : Inputdata.TwoBellow,
                    isDataFetched:true,

                    railLength : Inputdata.IsRailLength == true ? Inputdata.Length : "",
                    strokeLength : Inputdata.IsRailLength == false ? Inputdata.Length :"",
                    LengthType :  Inputdata.IsRailLength == true ? "rail" : "stroke",
                    disableRailLength : Inputdata.IsRailLength == true ? false : true,
                    Username : this.state.IsAdmin == "true" ? "-By " + response.UserName : "",
                    SecondCheckboxChecked : Inputdata.TwoBellow,
                   
                    disableBlockOneType : Inputdata.RailId > 0 ? false : true,
                    disableBellows : Inputdata.BellowId > 0 ? false:true,
                    disableSize : Inputdata.SizeId > 0 ? false : true,
                    disableMaterial : Inputdata.MaterialId > 0 ? false : true,
                    disableAccessory : Inputdata.BlockOneType > 0 ? false : true,
                    enableBlockTwo : Inputdata.BlockOneType > 0 ? false : true,
                    disableAccessoryTwo : Inputdata.BlockTwoType >0?false:true,
                })
                // if(this.state.railId>0) { this.setState({ disableBlockOneType : false }) }
                // if(this.state.bellowId>0) { this.setState({ disableBellows : false }) }
                // if(this.state.sizeId>0) { this.setState({ disableSize : false }) }
                // if(this.state.materialId>0) { this.setState({ disableMaterial : false }) }
                // if(this.state.BlockOneType>0) { this.setState({ disableAccessory : false }) }
                // if(this.state.BlockOneType>0){ this.setState({ enableBlockTwo:false }) }
                // if(this.state.BlockTwoType>0) { this.setState({ disableAccessoryTwo : false }) }

                if(this.state.LengthType == "stroke"){
                    this.txtStroke.value = this.state.Length;
                }
                else{
                    this.txtRail.value = this.state.Length;
                }

                auth.setSavedPartId(0);

            }, error => {
                this.setState({ allPartDetails: [] });
            });
        }
        else
        {
            this.props.getDefaultValuesForStrokeLength(this.props.manufacturerId).then((res) => {
                this.setState({
                    railId: res.RailId,
                    bellowId: res.BellowId,
                    sizeId: res.SizeId,
                    materialId: res.MaterialId,
                    defaultMaterial:res.MaterialId,
                    BlockOneType: res.BlockTypeId,
                    BlockOneAccessory: res.AccessoryId,
                    isDataFetched:true
                })
                if(this.state.railId>0) { this.setState({ disableBlockOneType : false }) }
                if(this.state.bellowId>0) { this.setState({ disableBellows : false }) }
                if(this.state.sizeId>0) { this.setState({ disableSize : false }) }
                if(this.state.materialId>0) { this.setState({ disableMaterial : false }) }
                
            })

        } 
    }
    registercustomer=()=>{
        auth.setRegisterComponent(true);
        auth.logout();
    }
    getStrokeLength = (inputs) =>  {
        // this.props.getStrokeLengthProperty(inputs).then((res) => {
        //     this.setState({
        //         allstroke:res,
        //         // centerLineValue:res.CL,
        //         defaultCenterLineValue:res.CL
        //         })
    //});
    
      getStrokeLengthListForState(inputs).then(res => {
      //  IsRequested=false;
            this.setState({
                allstroke:res,
                // centerLineValue:res.CL,
                defaultCenterLineValue:res.CL
                })    
        })  
    }

    // getSizesList= (railId,bellowId) => {
                
    //     getAllSizeList(railId,bellowId).then(resp => {
           
    //         this.setState({
    //             sizeId:resp[0].SizeID,
               
    //         });

    //     });
    // }
   

    handleChangeRailDrodown = (event) => {
     
        // this.props.getStrokeLengthProperty(this.state);
        if(event.target.value > 0)
        {
            this.props.getDefaultValuesForStrokeLength(this.props.manufacturerId,event.target.value).then((res) => {
                
                this.setState({
                    railId: res.RailId,
                    bellowId: res.BellowId,
                    sizeId: res.SizeId,
                    materialId: res.MaterialId,
                    defaultMaterial:res.MaterialId,
                    BlockOneType: res.BlockTypeId,
                    BlockOneAccessory: res.AccessoryId,
                    isDataFetched:true
                })

                if(this.state.railId>0) {
                     this.setState(
                         { 
                             disableBlockOneType : false,enableBlockTwo: true,
                              disableAccessory: true, disableAccessoryTwo: true,
                              BlockTwoType:0,BlockTwoAccessory:0,
                              centerLineValue : 0, BlockOneAccessory: 0,
                              strokeLength:0,railLength:0                             
                        })
                     }

                if(this.state.bellowId>0) { this.setState({ disableBellows : false }) }
                if(this.state.sizeId>0) { this.setState({ disableSize : false }) }
                if(this.state.materialId>0) { this.setState({ disableMaterial : false }) }


            // this.setState({
            //     disableBellows: false, disableBlockOneType:false,disableSize:true,disableMaterial:true, 
            //     disableAccessory:true,disableAccessoryTwo:true

            // })
        })

        }
        else{
            this.setState({
                disableBellows: true, disableBlockOneType:true,disableSize:true,disableMaterial:true, 
                disableAccessory:true,disableAccessoryTwo:true
            })
        }
    }

    handleChangeSizeDrodown = (event, index) => {
        if(event.target.value > 0)
        {
            
            this.setState({
                sizeId: event.target.value,
                disableMaterial:false,
                disableBlockOneType : false,
                enableBlockTwo: true,
                disableAccessory: true, disableAccessoryTwo: true,
                BlockOneType:0,
                BlockTwoType:0,BlockTwoAccessory:0,
                centerLineValue : 0, BlockOneAccessory: 0,
                strokeLength:0,railLength:0
              
            })
            
            if(this.state.sizeId==this.state.preSizeId)
            {
                this.getStrokeLength(this.state);
            }
        }
        else{
            this.setState({
                sizeId: event.target.value,
                disableMaterial:true
            })
        }
    
      
    }

    handleChangeMaterialDropdown = (event) => {
        this.setState({
            materialId: event.target.value,
            disableBlockOneType : false,enableBlockTwo: true,
            disableAccessory: true, disableAccessoryTwo: true,
            BlockOneType:0,
            BlockTwoType:0,BlockTwoAccessory:0,
            centerLineValue : 0, BlockOneAccessory: 0,
            strokeLength:0,railLength:0
        })
        if(event.target.value==this.state.preMaterialId)
        {
            this.getStrokeLength(this.state);
        }
        // this.props.getRailBellowProperty(this.state.sizeId, event.target.value);
    }
     wait = (ms) => {
        var start = new Date().getTime();
        var end = start;
        while(end < start + ms) {
          end = new Date().getTime();
       }
     }
    handleCenterLineChange = (e) => {
        ////document.getElementsByClassName("main-calc-btn")[0].focus();
        if(e.target.value==""){
         this.setState({
             centerLineValue:0,
             preCenterLineValue:1,
             Centerlineblur : true,
         })
         //this.getStrokeLength(this.state);    
        }
        else if(e.target.value >= 0){
            this.setState({
                centerLineValue:e.target.value,
                preCenterLineValue:"",
            })
           // this.getStrokeLength(this.state);    
        }
        
        // if(e.target.value>=0)
        // {
        //  this.setState({
        //      centerLineValue:this.textCL.value
        //  })
        //  this.getStrokeLength(this.state);
        // }
        // else if(e.target.value=="")                                                
        //  {
        //      this.getStrokeLength(this.state);
        //  }
        //  else{            
        //      this.setState({
        //          centerLineValue:0
        //      })
        //      this.textCL.defaultValue = "";
        //      this.textCL.value="";
        //  }



    }

    handleRailLengthTextChange = (strokeLengthData) => {
        this.txtStroke.value =strokeLengthData;

    }

    handleChangeRadioButton = (event) => {
        this.setState({
            selected: event.target.value
        })
        this.txtRail.value ="";
        this.txtStroke.value = "";
           

        if (event.target.value == "stroke") {
            document.getElementsByClassName("StrokeIn")[0].style.display = "none";
            document.getElementsByClassName("StrokeIn")[1].style.display = "none";
            document.getElementsByClassName("RailIn")[0].style.display = "";
            document.getElementsByClassName("RailIn")[1].style.display = "";
            this.setState({
                centerLineValue:document.getElementById("centerLine").value,//this.state.preDefaultCenterLineValue,
                LengthType:"stroke", disableleStrokeLength: false,
                disableRailLength: true, railLength:"",
                strokeLength: event.target.value, IsRailLength: false
            })
        }

        if (event.target.value == "rail") {
            document.getElementsByClassName("StrokeIn")[0].style.display = "";
            document.getElementsByClassName("StrokeIn")[1].style.display = "";
            document.getElementsByClassName("RailIn")[0].style.display = "none";
            document.getElementsByClassName("RailIn")[1].style.display = "none";
            this.setState({
                //centerLineValue:this.state.preDefaultCenterLineValue,
                centerLineValue:document.getElementById("centerLine").value,
                LengthType:"rail", disableleStrokeLength: true,
                disableRailLength: false, strokeLength:"",
                railLength: event.target.value, IsRailLength: true
            })
        }
    }
    handleBellows = (event)=>{
        if (event.target.value < 1) {
            this.setState({
                disableSize: true,disableMaterial:true,disableAccessoryTwo: true,disableAccessoryOne: true
            })
        }
        else {
           this.props.getDefaulSize(this.state.railId,event.target.value).then((res) => {
                this.setState({
                sizeId:res[0].SizeID,
                disableSize: false,
                disableBlockOneType : false,enableBlockTwo: true,
                disableAccessory: true, disableAccessoryTwo: true,
                BlockOneType:0,
                BlockTwoType:0,BlockTwoAccessory:0,
                centerLineValue : 0, BlockOneAccessory: 0,
                strokeLength:0,railLength:0
               
            })
        })
        }
    }
    handleBlockTypeOne = (event) => {
        if (event.target.value < 1) {
            this.setState({
                BlockOneType: event.target.value,
                BlockOneAccessory: 0,
                centerLineValue:"",
                enableBlockTwo: true, disableAccessory: true,disableAccessoryTwo:true,
                centerLineValue : 0,BlockTwoType:"",BlockTwoAccessory:0
            })
            //this.textCL.defaultValue = "0";
            this.textCL.value="0";
        }
        else {
            
            this.setState({
               
                BlockOneAccessory: 0,
                centerLineValue:"",
                enableBlockTwo: false, disableAccessory: false,
                preDefaultCenterLineValue:"",
                BlockOneType:event.target.value
            })
          
        }
        if(event.target.value==this.state.preBlockOneType)
        {
            this.getStrokeLength(this.state);
        }
       
    }

    handleBlockTypeTwo = (event) => {

        // this.setState({
        //     BlockTwoType: e.target.value,
        //     BlockTwoAccessory: 0,
        //     disableAccessoryTwo: false
        // })

        if (event.target.value < 1) {
            this.setState({
                BlockTwoType: event.target.value,
                BlockTwoAccessory: 0,
                disableAccessoryTwo: true,
            })
          
            //this.txtCL.value="";
            
        }
        else {
            this.setState({
                BlockTwoType: event.target.value,
                BlockTwoAccessory: 0,
                disableAccessoryTwo: false,
                centerLineValue :"",
                defaultCenterLineValue:"",
                preDefaultCenterLineValue:""
            })
           
           // this.textCL.defaultValue = this.props.allStrokeData.CL;
            //this.textCL.value =  this.textCL.defaultValue;
        }
        if(event.target.value==this.state.preBlockTwoType)
        {
            this.getStrokeLength(this.state);
        }
    }

    componentWillUpdate(props) {
        if (props.manufacturerId != undefined && props.manufacturerId != this.state.manufacturerId) {
            this.props.getDefaultValuesForStrokeLength(props.manufacturerId,this.state.railId).then((res) => {
                this.setState({
                    railId: res.RailId, bellowId: res.BellowId,
                    sizeId: res.SizeId, materialId: res.MaterialId,
                    BlockOneType: res.BlockTypeId, BlockOneAccessory: res.AccessoryId,
                    manufacturerId : props.manufacturerId,
                    preManufacturerId:props.manufacturerId,
                })
            })
            this.getStrokeLength(this.state);
            //this.state.manufacturerId = props.manufacturerId;
        }

    }

    async componentDidUpdate(props) {

        if (this.state.preManufacturerId !== this.state.manufacturerId) {

            this.props.getDefaultValuesForStrokeLength(props.manufacturerId,this.state.railId).then((res) => {
                this.setState({
                    railId: res.RailId,
                    bellowId: res.BellowId,
                    sizeId: res.SizeId,
                    materialId: res.materialId,
                    BlockOneType: res.BlockTypeId,
                    BlockOneAccessory: res.AccessoryId,
                    preManufacturerId : this.state.manufacturerId
                })
               // this.props.getStrokeLengthProperty(this.state);
                this.getStrokeLength(this.state);
            })
            
        }

        if (this.state.preManufacturerId !== this.state.manufacturerId) {
            // this.props.getStrokeLengthProperty(this.state);
            this.getStrokeLength(this.state);
            this.setState({ preManufacturerId: this.state.manufacturerId });
        }

        if (this.state.preRailId !== this.state.railId) {
            // this.props.getStrokeLengthProperty(this.state);
            this.getStrokeLength(this.state);
            this.setState({ preRailId: this.state.railId });
        }

        if (this.state.preBellowId !== this.state.bellowId) {
            //this.props.getStrokeLengthProperty(this.state);
            this.getStrokeLength(this.state);
            this.setState({ preBellowId: this.state.bellowId });
        }

        if (this.state.preSizeId !== this.state.sizeId) {
            //this.props.getStrokeLengthProperty(this.state);
            this.getStrokeLength(this.state);
            this.setState({ preSizeId: this.state.sizeId,materialId:this.state.defaultMaterial });
        }

        if (this.state.preMaterialId !== this.state.materialId) {
           // this.props.getStrokeLengthProperty(this.state);
           this.getStrokeLength(this.state);
            this.setState({ preMaterialId: this.state.materialId });
        }

        if (this.state.preBlockOneType !== this.state.BlockOneType) {
          //  this.props.getStrokeLengthProperty(this.state);
          if( this.state.BlockOneType==0)
          {
            this.setState({BlockTwoType:0})
          }
          this.getStrokeLength(this.state);
          this.setState({ preBlockOneType: this.state.BlockOneType});
        }

        if (this.state.preBlockOneAccessory !== this.state.BlockOneAccessory) {
         //   this.props.getStrokeLengthProperty(this.state);
            this.getStrokeLength(this.state);
            this.setState({ preBlockOneAccessory: this.state.BlockOneAccessory });
        }

        if (this.state.preBlockTwoType !== this.state.BlockTwoType) {
           // this.props.getStrokeLengthProperty(this.state);
            this.getStrokeLength(this.state);
            this.setState({ preBlockTwoType: this.state.BlockTwoType });
        }


        if (this.state.preBlockTwoAccessory !== this.state.BlockTwoAccessory||this.state.BlockTwoAccessoryName!=this.state.preBlockTwoAccessoryName) {
          //  this.props.getStrokeLengthProperty(this.state);
          this.getStrokeLength(this.state);
          this.setState({ preBlockTwoAccessory: this.state.BlockTwoAccessory,preBlockTwoAccessoryName:this.state.BlockTwoAccessoryName});
        }
//
        if (this.state.preDefaultCenterLineValue !== this.state.defaultCenterLineValue 
            && this.state.defaultCenterLineValue!="") 
        {
            this.textCL.defaultValue = this.state.defaultCenterLineValue;
            this.textCL.value=this.state.defaultCenterLineValue;
            this.getStrokeLength(this.state);

            this.setState({ preDefaultCenterLineValue: this.state.defaultCenterLineValue ,
                preCenterLineValue:this.state.defaultCenterLineValue});
        }

        if (this.state.preCenterLineValue !== this.state.centerLineValue 
            && this.state.centerLineValue!="") 
            {
          //  this.props.getStrokeLengthProperty(this.state);
          if(this.state.defaultCenterLineValue==this.state.centerLineValue)
          {
              this.setState({centerLineValue:""})
          }
          
          this.getStrokeLength(this.state);
          this.textCL.defaultValue = this.state.centerLineValue;
          this.textCL.value=this.state.centerLineValue;
          this.setState({ preCenterLineValue: this.state.centerLineValue });
        }

        if (this.state.preCenterlineblur !== this.state.Centerlineblur 
            && this.state.centerLineValue=="") 
            {
          //  this.props.getStrokeLengthProperty(this.state);
          this.setState({Centerlineblur : false});
          this.getStrokeLength(this.state);
          
          this.setState({ preCenterlineblur : this.state.Centerlineblur });
        }

        if (this.state.preStrokeLength !== this.state.strokeLength) {
          //  this.props.getStrokeLengthProperty(this.state);
          this.getStrokeLength(this.state);
          this.setState({ preStrokeLength: this.state.strokeLength });
        }

        if (this.state.preRailLength !== this.state.railLength) {
          //  this.props.getStrokeLengthProperty(this.state);
          this.getStrokeLength(this.state);
            this.setState({ preRailLength: this.state.railLength });
        }

        if (this.state.preTwoBellow !== this.state.TwoBellow) {
           // this.props.getStrokeLengthProperty(this.state);
           this.getStrokeLength(this.state);
            this.setState({ preTwoBellow: this.state.TwoBellow });
        }

        if(this.state.LengthType == "stroke"){
        
        if(this.state.allstroke.RLOut>0){
            this.txtRail.value = this.state.allstroke.RLOut;
        }
        else{
            this.txtRail.value ="";
        }
        }
        else{

            if(this.state.allstroke.RLOut==""||this.state.allstroke.RLOut==0)
            {
                this.txtStroke.value ="";
            }
            else{
                 this.txtStroke.value = this.state.allstroke.SLOut;
            }
           
        }
    }

    //#region save and print
    saveCalculatorPartNumber = () => {
        var PartNumber = document.getElementById("BellowPartNumber").innerText;
        var MBPartNumber = document.getElementById("MBPartNumber").innerText;
        var PN = "";
        if(PartNumber!="")
        {
            PN = PartNumber + (MBPartNumber!=""? ("," +MBPartNumber) : "");
        }
        else
        {
            PN = MBPartNumber;
        }
       // if(PartNumber!=""){
        Swal.fire({
            //title: 'Are you sure?',
            text: 'Are you sure want to save?',
            // icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
            showCloseButton: true
          }).then((result) => {
            if (result.value) {
                this.props.savePartNumber(this.state,PN);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              
            }
          })
    }

    printPage=()=> {

        //this.state.preDefaultCenterLineValue;
        
        if(this.state.disableRailLength == true){
            document.getElementsByClassName("rail-ele")[0].classList.add("d-none");
        }
        else{
            document.getElementsByClassName("stroke-ele")[0].classList.add("d-none");
        }
        if(this.state.disableAccessoryTwo == true){
            document.getElementsByClassName("centerline-dist-label")[0].innerHTML = "0";
            //document.getElementsByClassName("centerlinedistance-print-ele")[0].classList.add("d-none");
        }
        var btns = document.getElementsByClassName('print-btn');
        for(var i=0; i<btns.length; i++) { 
            btns[i].style.display='none';
        }        
        var npdiv = document.getElementsByClassName('no-print');
        for(var i=0; i<npdiv.length; i++) { 
            npdiv[i].classList.add("d-none");
        }
        var prdiv = document.getElementsByClassName('print-ele');
        for(var i=0; i<prdiv.length; i++) { 
            prdiv[i].classList.remove("d-none");
        }

        var html = '<HTML>\n<HEAD>\n';
        if (document.getElementsByTagName != null){
        var headTags = document.getElementsByTagName("head");
        if (headTags.length > 0) html += headTags[0].innerHTML;
        }
        html+='\n<style type="text/css" media="print">';
        html+='\n@page port {size: portrait;}';
        html+='\n@page land {size: landscape;}';
        html+='\n.portrait {page: port;}';
        html+='\n.landscape {page: land;}';
        html+='\n.page-break { display: block; page-break-before: auto; }';
        html+='\n</style>';
        html += '\n</HEAD>\n<BODY  class="landscape">\n';

        var printReadyElem = document.getElementById("PrintDiv");
        
        if (printReadyElem != null) html += printReadyElem.innerHTML;
        else{
        return;
        }
        html += '\n</BODY>\n</HTML>';
        var btns = document.getElementsByClassName('print-btn');
        for(var i=0; i<btns.length; i++) { 
            btns[i].style.display='block';
        }
        var npdiv = document.getElementsByClassName('no-print');
        for(var i=0; i<npdiv.length; i++) { 
            npdiv[i].classList.remove("d-none");
        }
        var prdiv = document.getElementsByClassName('print-ele');
        for(var i=0; i<prdiv.length; i++) { 
            prdiv[i].classList.add("d-none");
        }
        if(this.state.disableRailLength == true){
            document.getElementsByClassName("rail-ele")[0].classList.remove("d-none");
        }
        else{
            document.getElementsByClassName("stroke-ele")[0].classList.remove("d-none");
        }
        if(this.state.disableAccessoryTwo == true){
            document.getElementsByClassName("centerline-dist-label")[0].innerHTML  = "0";
            //document.getElementsByClassName("centerlinedistance-print-ele")[0].classList.remove("d-none");
        }
        var printWin = window.open("","processPrint");
        printWin.document.open();
        
        printWin.document.write(html); 
        printWin.document.close();

        printWin.onload=function(){
            printWin.focus(); printWin.print(); printWin.close();
        };
    
    }
    //#endregion

    //#region render calculator output
    renderTableHeader = (OPParam,DbParamCount) => {
        if (OPParam != undefined) {
            return OPParam.map((data) => {
                const { DisplayName } = data;
                return (
                    <TableCell>{DisplayName}</TableCell>
                )
            })
        }
    }

    renderFirstRowTableData = (OPParam,DbParamCount) => {
        let checkBlock=1;
       
        if (OPParam != undefined) {
            return OPParam.map((data) => {
          
                
                const { DisplayName, Value } = data;
               
                if(checkBlock==1)
                {
                    return (                  
                        <TableCell>{Value}</TableCell>
                    )
                }
                else
                { 
                    if(DisplayName=="OP"||DisplayName=="LMin"||DisplayName=="Ft"||DisplayName=="Gt"||DisplayName=="Vt"||DisplayName=="S"||DisplayName=="B1"||DisplayName=="B2")
                    {
                        return (
                  
                            <TableCell>{Value}</TableCell>
                        )

                    }
                
                }
                    
            })
        }
    }
   
    renderSecondRowTableData = (OPParam,DbParamCount) => {
        if (OPParam != undefined) {
            OPParam = OPParam.splice(DbParamCount, OPParam.length);
            return OPParam.map((data) => {
                const { DisplayName, ValueSecond } = data;
                return (
                    
                    <TableCell>{ValueSecond}</TableCell>
                )
            })
        }
    }
     
    rendorMessage = (Message) => {
        if (Message != "") {
                return (
                    <TableCell>{Message}</TableCell>
                )
        }
    }
   
    renderSecondTableHeader = (OPPricing) => {
        if (OPPricing != undefined) {
            return OPPricing.map((data) => {
                const { DisplayName, Value } = data;
                return (
                    <TableCell>{DisplayName}</TableCell>
                )
            })
        }
    }

    renderSecondTableData = (OPPricing, PTMessageOne, Message) => {

        if (OPPricing != undefined) {
            if (Message == "") {
                if (PTMessageOne == "") {
                    return OPPricing.map((data) => {
                        const { DisplayName, Value } = data;
                        return (
                            <TableCell>{Value}</TableCell>
                        )
                    })
                }
                else {
                    return (<TableCell colSpan={OPPricing.length} style={{ color: "red" }}>{PTMessageOne}</TableCell>)
                }
            }
            else {
                return (<TableCell colSpan={OPPricing.length} ></TableCell>)
            }
        }

    }

    renderSecondTableDataRelipca = (OPPricing, PTMessageOne, Message) => {
        if (OPPricing != undefined) {
            if (Message == "") {
                if (PTMessageOne == "") {
                    return OPPricing.map((data) => {
                        const { DisplayName, Value } = data;
                        return (
                            <TableCell style={{color:this.state.PriceSecondTableColor}}>{Value}</TableCell>
                        )
                    })
                }
            }
        }
    }
    
    renderSecondTableThirdRowData = (MBOPPricing, PTMessageTwo, Message,OPPricing,MBPartnumber) => {

        if (MBOPPricing != undefined) {
            if(MBPartnumber == ""){
                if(PTMessageTwo != "" &&  (Message == "")){
                    return (<TableCell colSpan={OPPricing.length} style={{ color: "red" }}>{PTMessageTwo}</TableCell>)
                }
                else{
                    if(PTMessageTwo=="" &&  (Message == ""))
                    {
                        return (<TableCell colSpan={OPPricing.length}></TableCell>)

                    }
                    else{
                        return (<TableCell colSpan="8"></TableCell>)

                    }
                    
                }
            }
            if (Message == "") {
                if (PTMessageTwo == "") {
                    return MBOPPricing.map((data) => {
                        const { Value } = data;
                        return (
                            <TableCell>{Value}</TableCell>
                        )
                    })
                }
                else {
                    return (<TableCell colSpan={OPPricing.length} style={{ color: "red" }}>{PTMessageTwo}</TableCell>)
                }
            }
            else {
                return (<TableCell colSpan={OPPricing.length} ></TableCell>)
            }
        }
    }
    //#endregion
    
    handleCheckboxSelection = (e) => {
        
        if(e.target.checked){
            this.setState({ 
                PriceSecondTableColor: "black",
                TwoBellow: e.target.checked,
                SecondCheckboxChecked : e.target.checked,
                centerLineValue:document.getElementById("centerLine").value//this.state.preDefaultCenterLineValue
            });
        }
        else{
            this.setState({ 
                PriceSecondTableColor: "gray",
                TwoBellow: e.target.checked,
                SecondCheckboxChecked : e.target.checked,
                //centerLineValue:this.state.preDefaultCenterLineValue
                centerLineValue:document.getElementById("centerLine").value
             });
        }
        
    }

    render() {

        if(!this.state.isDataFetched)
        {
            return (<div className="loader-parent" id="loaderdiv">
            <div className="loader loadercenter">
                <i className="fa fa-spinner fa-spin" />
            </div>
            </div>);
        }
        else
        {

        //console.log("allStrokeData");
        //console.debug('allstrokedata', this.props.allStrokeData);

        //const strokeData = this.props.allStrokeData;
        const strokeData = this.state.allstroke;
        let DbParamCount = strokeData.DbParamCount; 
        let OPParam = strokeData.OPParam;
        let OPParamLength =0;
        if(OPParam!=undefined){
            OPParamLength = OPParam.length;
        }
        let OPPricing = strokeData.OPPricing;
        let MBOPPricing = strokeData.MBOPPricing;
        let disableMessage = "d-none";
        let disableCheckbox = "d-none";
        let disablesecondcheckbox = "d-none";
        let BellowPartnumber = strokeData.PartNumber;
        let MBPartnumber = strokeData.MBPartNumber;
        let imagePath = strokeData.ImagePath;
        if(imagePath==undefined){
            imagePath = SERVER_ACTIONS.API + "/Media/OutputImages/IKnowLength/JSH45-Bare-Rail.png"
        }
        let LRangeMin = strokeData.LRangeMin;
        let LRangeMax = strokeData.LRangeMax;
        let SRangeMin = strokeData.SRangeMin;
        let SRangeMax = strokeData.SRangeMax;

        let SLOut = strokeData.SLOut;
        let SLRange = strokeData.SLRange;
        let RLOut = strokeData.RLOut;
        let RLRange = strokeData.RLRange;


        let LBMessage = strokeData.LBMessage;

        let PTMessageOne = strokeData.PTMessageOne;
        let PTMessageTwo = strokeData.PTMessageTwo;

        
        let minCL = strokeData.minCL;
        var CL = this.state.defaultCenterLineValue;
        var LoginLimitExceed = strokeData.LoginLimitExceed;
       // console.log("CL : " + CL);
       
    
       if(RLOut==""||RLOut==0)
       {
        RLRange="";
        SLRange="";
        SLOut="";
       }
        if(CL === undefined)
        {
           // CLDISTANCE = 0;
        }
        // console.debug('CL', this.textCL.current ? this.textCL.current.getInputNode() : "");
        if (this.textCL.current) {
            this.textCL.current.defaultValue = CL;
        }
        let Message;
        let FormulaError = strokeData.FormulaError;
        
        if (strokeData.Message !== "") {
            disableMessage = "";
            disableCheckbox = "d-none"
            Message = strokeData.Message;
            
        }
        else {
            disableMessage = "d-none";
            disableCheckbox = ""
            Message = strokeData.Message;
        }
        

        if(strokeData.PTMessageOne !="")
        {
            disablesecondcheckbox = "d-none";
        }
        else{
            disablesecondcheckbox = "";
        }

        const saveButton = [];
        if(this.state.IsAdmin == "false"){
        saveButton.push(<button className="print-btn mt-1"  onClick={this.saveCalculatorPartNumber}>Save</button>)
        }

        return (
            <React.Fragment>
                 <Container id="PrintDiv">
                <div className="row pb-30">
                    <div className="col-lg-3 col-md-4 cal-sidebar">
                        <Card>
                            <CardContent>
                                <div className="parameter dotted-border">
                                    <p className="calculator-headings">Parameters</p>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel id="demo-simple-select-label">RAIL</InputLabel>
                                                <RailsDropdown enabled={true} manufacturerId={this.state.manufacturerId} selectedValue={this.state.railId} activeOnly={true} 
                                                onRailChange={e => {
                                                    //  setFieldValue("rail_id", e.target.value); 
                                                    this.setState({
                                                        railId: e.target.value,
                                                        bellowId: 0,
                                                        sizeId: 0,
                                                        strokeLength:0,railLength:0
                                                    })
                                                    this.txtStroke.value ="";
                                                    this.txtRail.value="";
                                                    this.handleChangeRailDrodown(e);
                                                }} />
                                            </FormControl>                                            
                                        </div>
                                        <div className="col-lg-6">
                                        <FormControl margin="normal" fullWidth  disabled={this.state.disableBellows}>
                                                <InputLabel id="demo-simple-select-label">BELLOWS</InputLabel>
                                                <BellowsDropDown enabled={true} railId={this.state.railId} 
                                                selectedValue={this.state.bellowId} activeOnly={true} onBellowChange={e => {                                                     
                                                    this.setState({
                                                        bellowId: e.target.value,
                                                        sizeId: 0
                                                    })
                                                    this.txtStroke.value ="";
                                                    this.txtRail.value="";
                                                    this.handleBellows(e);
                                                }} />
                                            </FormControl>
                                           
                                        </div>
                                        <div className="col-lg-6">
                                            <FormControl margin="normal" fullWidth  disabled={this.state.disableSize}>
                                                <InputLabel id="demo-simple-select-label">SIZE</InputLabel>
                                                <SizeDropDown enabled={true} railId={this.state.railId} 
                                                selectedValue={this.state.sizeId} bellowId={this.state.bellowId} activeOnly={true} onSizeChange={(e, index) => {
                                                    //  setFieldValue("SizeID", e.target.value);  
                                                    this.txtStroke.value ="";
                                                    this.txtRail.value="";                              
                                                    this.handleChangeSizeDrodown(e, index);                                                    
                                                }} />
                                            </FormControl>
                                        </div>
                                        <div className="col-lg-6">
                                            <FormControl margin="normal" fullWidth  disabled={this.state.disableMaterial}>
                                                <InputLabel id="demo-simple-select-label">MATERIAL </InputLabel>
                                                <MaterialDropDownCalculator enabled={true} sizeId={this.state.sizeId} 
                                                selectedValue={this.state.materialId} activeOnly={true} onMaterialChange={e => {
                                                    
                                                    // this.setState({
                                                    //     materialId: e.target.value,

                                                    // })
                                                    this.handleChangeMaterialDropdown(e);
                                                }
                                                } />
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                                <div className="parameter dotted-border">
                                    <p className="calculator-headings">Block 1</p>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <FormControl margin="normal" fullWidth  disabled={this.state.disableBlockOneType}>
                                                <InputLabel id="demo-simple-select-label">TYPE</InputLabel>
                                                <BlockBySizeManufacturerDropDown enabled={true} 
                                                manufacturerId={this.props.manufacturerId} 
                                                sizeId={this.state.sizeId}
                                                    selectedValue={this.state.BlockOneType} 
                                                    activeOnly={true} onTypeChange={e => {                                                    
                                                    // this.setState({
                                                    //     BlockOneType: e.target.value,
                                                    //     BlockOneAccessory: 0,
                                                    //     centerLineValue:""
                                                    // })
                                                    this.handleBlockTypeOne(e);
                                                }
                                                } />
                                            </FormControl>
                                        </div>
                                        <div className="col-lg-6">
                                            <FormControl className="rail-cal-form-control" disabled={this.state.disableAccessory} margin="normal" fullWidth>
                                                <InputLabel id="demo-simple-select-label">ACCESSORY</InputLabel>
                                                <AccessoryDropDown enabled={true} sizeId={this.state.sizeId}                                                 
                                                    selectedValue={this.state.BlockOneAccessory} activeOnly={true}
                                                    onAccessoryChange={(e,index) => {                                                    
                                                        //  setFieldValue("bellow_id", e.target.value);    
                                                        this.setState({                                                            
                                                            BlockOneAccessory: e.target.value,
                                                           // BlockOneAccessoryName:index.props.name,
                                                            centerLineValue:""
                                                        })
                                                        if(e.target.value==this.state.preBlockOneAccessory)
                                                        {
                                                            this.getStrokeLength(this.state);
                                                        }
                                                    }
                                                    } />

                                                {/* <AccessoryByManufacturerSizeIdDropDownList sizeId={this.state.sizeId}
                                                 manufacturerId={this.state.manufacturerId}
                                                    selectedValue={this.state.BlockOneAccessory}
                                                    activeOnly={true} onAccessoryChange={e => {                                                        
                                                        this.setState({
                                                            BlockOneAccessory: e.target.value,
                                                            enableBlockTwo: false,
                                                            centerLineValue:""
                                                        })
                                                        if(e.target.value==this.state.preBlockOneAccessory)
                                                        {
                                                            this.getStrokeLength(this.state);
                                                        }
                                                    }
                                                    } /> */}
                                            </FormControl>

                                        </div>
                                    </div>
                                </div>
                                <div className="parameter dotted-border">
                                    <p className="calculator-headings">Block 2</p>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <FormControl disabled={this.state.enableBlockTwo} margin="normal" fullWidth>
                                                <InputLabel id="demo-simple-select-label">TYPE</InputLabel>
                                                <BlockBySizeManufacturerDropDown enabled={true} manufacturerId={this.props.manufacturerId} sizeId={this.state.sizeId}
                                                    selectedValue={this.state.BlockTwoType} activeOnly={true} onTypeChange={e => {                                                     
                                                    // this.setState({
                                                    //     BlockTwoType: e.target.value,
                                                    //     BlockTwoAccessory: 0,
                                                    //     disableAccessoryTwo: false
                                                    // })
                                                    this.handleBlockTypeTwo(e);
                                                }
                                                } />
                                            </FormControl>
                                        </div>
                                        <div className="col-lg-6">
                                            <FormControl className="rail-cal-form-control" disabled={this.state.disableAccessoryTwo} margin="normal" fullWidth>
                                                <InputLabel id="demo-simple-select-label">ACCESSORY</InputLabel>
                                                {/* <AccessoryByManufacturerSizeIdDropDownList sizeId={this.state.sizeId} manufacturerId={this.state.manufacturerId}
                                                    selectedValue={this.state.BlockTwoAccessory}
                                                    activeOnly={true} 
                                                    onAccessoryChange={e => {                                                        
                                                        this.setState({
                                                            BlockTwoAccessory: e.target.value,
                                                            enableBlockTwo: false,
                                                            centerLineValue:""
                                                        })
                                                        if(e.target.value==this.state.preBlockTwoAccessory)
                                                        {
                                                            this.getStrokeLength(this.state);
                                                        }
                                                    }
                                                    } /> */}                                                    
                                                <AccessoryDropDown enabled={true} sizeId={this.state.sizeId} 
                                                    selectedValue={this.state.BlockTwoAccessory} activeOnly={true}
                                                    onAccessoryChange={(e,index) => {                                                    
                                                        //  setFieldValue("bellow_id", e.target.value);    
                                                        this.setState({                                                            
                                                            BlockTwoAccessory: e.target.value,
                                                            BlockTwoAccessoryName:index.props.name,
                                                            centerLineValue:""
                                                        })
                                                        if(e.target.value==this.state.preBlockTwoAccessory)
                                                        {
                                                            this.getStrokeLength(this.state);
                                                        }
                                                    }} />
                                            </FormControl>
                                        </div>
                                        <div className="col-lg-12 centerlinedistance">
                                        <label class="Custom-Label MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled" 
                                             data-shrink="true" id="demo-simple-select-label">Centerline Dist</label>
                                             <div className="no-print">
                                            <FormControl className="rail-cal-form-control" id="centerlineLabel" disabled={this.state.disableAccessoryTwo} contentEditable={false} margin="normal" fullWidth>
                                            
                                                <TextField
                                                    disabled={this.state.disableAccessoryTwo}
                                                    id="centerLine"
                                                    // label="Centerline Dist."
                                                    margin="normal"
                                                    
                                                    inputRef={(input) =>  this.textCL = input}
                                                    min={1}
                                                    contentEditable={false}
                                                    defaultValue={CL}
                                                   // value ={CL}
                                                    type="number"
                                                    //onChange={e=>{
                                                    //    this.handleCenterLineChange(e)
                                                    //}}
                                                    onKeyUp={e=>{
                                                     //       this.handleCenterLineChange(e)
                                                        }}
                                                    onBlur={e => {
                                                       
                                                     this.handleCenterLineChange(e)
                                                       
                                                        

                                                        // if(e.target.value == "")
                                                        // {
                                                        //     this.setState({
                                                        //         centerLineValue:0
                                                        //     })
                                                        //     this.getStrokeLength(this.state);
                                                        // }
                                                        // else if(e.target.value>=0)
                                                        // {
                                                        //     this.setState({
                                                        //         centerLineValue:this.textCL.value
                                                        //     })
                                                        //     //this.getStrokeLength(this.state);
                                                        // }
                                                        // else{
                                                        //     this.setState({
                                                        //         centerLineValue:0
                                                        //     })
                                                        //     this.textCL.defaultValue = "";
                                                        //     this.textCL.value="";
                                                        // }
                                                    }}
                                                    fullWidth
                                                />
                                            </FormControl>
                                            </div>
                                               <div className="print-ele d-none"><div class="MuiFormControl-root rail-cal-form-control MuiFormControl-marginNormal MuiFormControl-fullWidth" id="centerlineLabel" contenteditable="false"><div class="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth" min="1" contenteditable="false"><div class="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl"><label className="centerline-dist-label">{this.state.preDefaultCenterLineValue}</label></div></div></div></div>
                                            {minCL==0?<></>:<> <div className="block2p"><p>Minimum of {minCL} mm is required for Middle Bellows</p>
                                            <p style={{color:"red"}}>{LBMessage}</p>
                                            </div></>}
                                        </div>
                                    </div>
                                </div>
                                <div className="print-ele d-none">
                                   <div className="row stroke-ele">
                                       <div className="col-lg-6">
                                                <label>Stroke Length : {this.state.strokeLength}</label>
                                       </div>
                                       <div className="col-lg-6">
                                       <label>Rail Length Range: {RLRange}</label>
                                       </div>
                                    </div>
                                    <div className="row rail-ele">
                                        <div className="col-lg-6">
                                            <label>Stroke Length Range: {SLRange}</label>
                                        </div>
                                        <div className="col-lg-6">
                                            <label>Rail Length: {this.state.railLength}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="parameter dotted-border no-print">
                                    <p className="calculator-headings">Choose One</p>
                                    <div className="row railcalradio">   
                                         <RadioGroup contentEditable={false} aria-label="position" name="position" 
                                         value={this.state.LengthType} onChange={(e) => { this.handleChangeRadioButton(e) }} row>                                                   
                                            <div className="col-lg-6">
                                            <FormControlLabel
                                                    value="stroke"
                                                    control={<Radio color="primary"/>}
                                                    label="Stroke Length"
                                                    labelPlacement="end"
                                                />
                                                <TextField
                                                    id="strokeLength" margin="normal"
                                                    inputRef={(input) => this.txtStroke = input}
                                                    contentEditable={false}
                                                    type="number"
                                                    className="RailIn"
                                                    defaultValue={SLOut}
                                                    onChange={e => {
                                                        if(e.target.value>0){
                                                            this.setState({
                                                                strokeLength: e.target.value,
                                                                centerLineValue:this.state.preDefaultCenterLineValue
                                                            })
                                                        }
                                                        else{
                                                            this.setState({
                                                                strokeLength:0,
                                                                centerLineValue:this.state.preDefaultCenterLineValue
                                                            })
                                                            this.txtStroke.value="";
                                                            this.txtStroke.defaultValue="";
                                                        }
                                                    }}
                                                    disabled={this.state.disableleStrokeLength}
                                                    fullWidth
                                                />
                                                <div className="StrokeIn" style={{display:"none"}}>
                                                <TextField
                                                    id="standard-old-password"
                                                    margin="normal"
                                                    defaultValue=""
                                                    value={SLRange}
                                                    disabled
                                                    type="text"
                                                    fullWidth
                                                />
                                                 Range
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                            <FormControlLabel value="rail"
                                                    control={<Radio color="primary" />}
                                                    label="Rail Length" labelPlacement="end"
                                                />
                                                <TextField
                                                    id="railLength"
                                                    margin="normal"
                                                    inputRef={(input) => this.txtRail = input}
                                                    contentEditable={false}
                                                    className="StrokeIn"
                                                    type="number"
                                                    style={{display:"none"}}
                                                    defaultValue={RLOut}
                                                    disabled={this.state.disableRailLength}
                                                    onChange={e => {
                                                        if( e.target.value>0){
                                                            this.handleRailLengthTextChange(SLOut);
                                                            this.setState({
                                                                railLength: e.target.value,
                                                                centerLineValue:this.state.preDefaultCenterLineValue
                                                            })

                                                        }
                                                        else{
                                                            this.setState({
                                                                railLength:0,
                                                                centerLineValue:this.state.preDefaultCenterLineValue
                                                            })
                                                            this.txtRail.value="";
                                                            this.txtRail.defaultValue="";
                                                        }
                                                    }}
                                                    fullWidth
                                                />
                                                
                                                {/* <div className="d-none print-rlength"><div class="MuiFormControl-root MuiTextField-root RailIn MuiFormControl-marginNormal MuiFormControl-fullWidth" contenteditable="false"><div class="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl"><label>{this.state.railLength}</label></div></div></div> */}
                                            <div className="RailIn">
                                                <TextField
                                                    id="standard-old-password"
                                                    margin="normal"
                                                    //disabled={this.state.disableRailLength}
                                                    disabled
                                                    className="RailIn"
                                                    
                                                    value={RLRange}
                                                    defaultValue=""
                                                    type="text"
                                                    fullWidth
                                                />
                                                  Range
                                            </div>
                                            </div>
                                            </RadioGroup>
                                    </div>
                                </div>
                                <h5 className="float-right">{this.state.Username}</h5>
                            </CardContent>
                        </Card>
                    </div>
                    <div className="col-lg-9 col-md-8">
                        <Card>
                            <CardContent>
                            {OPParamLength !=0 ?(
                                <div className=" dotted-border">
                                {OPParamLength !=0 ? (<table className="responsiveTable"><tr><td>Bellows Parameters</td></tr></table>):(<></>)}
                                    {/* <div className="parameter-head responsiveTable">
                                        <Table>
                                            <TableRow>
                                                <TableCell rowSpan="2">
                                                    Bellows Parameter
                                            </TableCell>
                                                <TableCell className="grayheader">Min</TableCell>
                                                <TableCell>{SRangeMin}</TableCell>
                                                <TableCell>{LRangeMin}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className="grayheader">Max</TableCell>
                                                <TableCell>{SRangeMax}</TableCell>
                                                <TableCell>{LRangeMax}</TableCell>
                                            </TableRow>
                                        </Table>
                                    </div> */}
                                    <div className="responsiveTable bellowParamDiv">
                                        {OPParamLength !=0 ? ( <Table>
                                            <TableHead className="grayheader">
                                            <TableRow>
                                            {DbParamCount == 0 ? (<TableCell></TableCell>):(<></>)}
                                                {this.renderTableHeader(OPParam,DbParamCount)}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            <TableRow>
                                                {DbParamCount == 0 ? (<TableCell></TableCell>):(<></>)}
                                                {this.renderFirstRowTableData(OPParam,DbParamCount)}
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={DbParamCount}><strong>MB</strong> Middle Bellows:</TableCell>
                                                {this.renderSecondRowTableData(OPParam,DbParamCount)}
                                            </TableRow>
                                            </TableBody>
                                        </Table>):(<></>)}
                                       
                                       <label style={{color: "red"}}>{FormulaError}</label> 
                                    </div>
                                </div>
                            ):(<></>)}
                              <div className="dotted-border">
                                    <div className="responsiveTable">
                                        <Table>
                                            <TableHead className="grayheader">
                                                <TableRow>
                                                <TableCell ></TableCell>
                                                {this.renderSecondTableHeader(OPPricing)}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            <TableRow>
                                                {this.rendorMessage(Message)}
                                                <TableCell className={disableCheckbox}>
                                                    <Checkbox
                                                        disabled
                                                        defaultChecked = {true}
                                                        size="small"
                                                        value="small"
                                                        inputProps={{ 'aria-label': 'checkbox with small size' }}
                                                    />End Bellows
                                                </TableCell>
                                                {this.renderSecondTableData(OPPricing,PTMessageOne,Message)}
                                            </TableRow>
                                            <TableRow className={disablesecondcheckbox}>                                                    
                                                <TableCell style={{color:this.state.PriceSecondTableColor}} className={disableCheckbox}>
                                                    <Checkbox
                                                        defaultChecked = {this.state.SecondCheckboxChecked}
                                                        size="small"
                                                        value="true"
                                                        inputProps={{ 'aria-label': 'checkbox with small size' }}
                                                        onChange={
                                                            e => {
                                                                 this.handleCheckboxSelection(e);                                                                
                                                            }
                                                        }
                                                    /> End Bellows
                                                </TableCell>                                                
                                                {this.renderSecondTableDataRelipca(OPPricing,PTMessageOne,Message)}
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={disableCheckbox}> Middle Bellows:</TableCell>                                                
                                                {this.renderSecondTableThirdRowData(MBOPPricing,PTMessageTwo,Message,OPPricing,MBPartnumber)}
                                            </TableRow>                                        
                                            </TableBody>
                                        </Table>
{(LoginLimitExceed == false || LoginLimitExceed == undefined)?(<></>):
// (<p style={{color: "red"}}>  Dear {this.state.UserName}, your guest login count limit is exceeded, 
//to get know about the pricing of specific bellows, please 
//<a onClick={this.registercustomer} className="register" style={{"color":"white"}}>register</a>  or contact on <a href="tel:800-650-2015">800-650-2015</a></p>)
(<p style={{color: "red"}}>  Dear {this.state.UserName}, Are you a Distributor or Manf. Rep.? Become an Authorized User online
or call <a href={`tel:${APP_DETAILS.CONTACT_NUMBER}`}>{APP_DETAILS.CONTACT_NUMBER}</a>.<br></br>
Authorized Users receive multi level pricing, priority shipping and more.To Apply: <a onClick={this.registercustomer} className="register" style={{"color":"white"}}>Register Here</a> </p>)
}
                                        <button className="print-btn mt-1" onClick={this.printPage}>Print</button>
                                        {saveButton}
                                         {/* <button className="print-btn" onClick={this.saveCalculatorPartNumber}>Save</button> */}
                                    </div>
                                </div>
                                <div className="calc-image dotted-border page-break">
                                    <img className="img-calculator" id="calculatorImage" alt="output-image" src={imagePath} />
                                    <div hidden id="BellowPartNumber">{BellowPartnumber}</div>
                                    <div hidden id="MBPartNumber">{MBPartnumber}</div>
                                </div>
                            </CardContent>
                        </Card>
                    </div>               
                </div>
                </Container>
            </React.Fragment>
        )
    }
    }
}


const mapProps = (state) => {
    return {
        allStrokeData: state.calculatorOutput.allStrokeData
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        savePartNumber:(data,PartNumbers)=>{
            return new Promise((resolve, reject) => {
                dispatch(SaveIKnowStrokeRailPartNumber(data,PartNumbers))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        getStrokeLengthProperty: (data) => {
            return new Promise((resolve, reject) => {
                dispatch(getStrokeLengthList(data))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        getDefaultValuesForStrokeLength: (manufacturerId,railId) => {
            return new Promise((resolve, reject) => {
                dispatch(getDefaultValuesForStrokeLengthData(manufacturerId,railId))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
 
        getDefaulSize: (railId,bellowId) => {
            return new Promise((resolve, reject) => {
                getAllSizeList(railId,bellowId)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    };
};

export default connect(mapProps, mapDispatchToProps)(Railcalculator);