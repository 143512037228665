import {UPDATE_MENU , UPDATE_CUSTOMER_COUNT, UPDATE_TOP_CUSTOMERS, UPDATE_ORDER_COUNT, UPDATE_RECENT_ORDER} from './DashboardActions';

const initialState = { menu: {} , customers: [] , orders:[] };

const DashboardReducer = (state = initialState, action) => {
    switch(action.type) {
        case UPDATE_MENU:
            return {
                menu: action.menu
            };
        case UPDATE_CUSTOMER_COUNT:
            return Object.assign({}, state, {
                customers: action.customers
            });
        case UPDATE_TOP_CUSTOMERS:
            return Object.assign({}, state, {
                customers: action.customers
            });
        case UPDATE_ORDER_COUNT:
            return Object.assign({}, state, {
                orders: action.orders
            });
        case UPDATE_RECENT_ORDER:
            return Object.assign({}, state, {
                orders: action.orders
            });
        

        default:
            return state;
    }
}

export default DashboardReducer;