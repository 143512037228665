import {SERVER_ACTIONS} from '../../utils/Constants';
import HttpRequest from '../../utils/HttpRequest';
import Auth from '../../utils/Auth';
import { updateSavedPartNumber } from './SavedPartNumberActions';

const auth  = new Auth();

export function getSavedPartNumberList({query, PageNumber, defaultPageSize}) {
    
    return (dispatch) => {
        const queryString = (query && query.trim().length > 0) ? `?${query}` : '';
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.POST_SAVED_PART_NUMBER}${queryString}`, 'post', {})
        .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {

                   dispatch(updateSavedPartNumber(response.data.data.data, Math.ceil(response.data.totalrecord / defaultPageSize), PageNumber));
                    return Promise.resolve(response.data);
                }
                else{
                    return Promise.reject(response.data);
                    }
                }, error => {
                    document.getElementById("loaderdiv").classList.add("d-none");
                    return Promise.reject(error);
                });
        }
    }