import React, { Component } from 'react'
import { SafeAnchor, Modal } from 'react-bootstrap';
// import { Formik, Form, Field } from 'formik';
import Button from '@material-ui/core/Button';
import { Field, FieldArray, Form, Formik } from 'formik';
import { FormikTextField } from 'formik-material-fields';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { TextField } from 'formik-material-ui';
import { getKRSeriesAttributes, deleteKRSeriesAttribute,deleteRailsAttribute,addKRSeries } from '../../components/ManageAttributeApiAction';
import Icon from '@material-ui/core/Icon';
import swal from 'sweetalert';
import{StyledReactTooltip} from '../ToolTip';
import Swal from 'sweetalert2';

const { Header, Body, Footer, Title } = Modal;

export default class EditKRSeries extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showform: false,
            manufacturer_name: "",

            krseries: [{
                KRSeriesID: 0,
                KRSeriesValue: '',
                BlockLength: 0
            }
            ]


        };

    }
    componentDidMount() {
       
    }
    componentWillReceiveProps(props) {
        this.setState({ manufacturer_id: this.props.manufacturer_id });

    }


    showForm = () => {
        this.setState({ showform: true })
    }


    hideForm = () => {
        this.setState({ showform: false })
    }
    deletekrseries = (option, arrayHelpers, i) => {
        if (option.KRSeriesID == 0) {
            arrayHelpers.remove(i);
        }
        else{

            Swal.fire({
                //title: 'Are you sure?',
                text: 'This action will delete the record permanently from database, Are you sure?',
                // icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel',
                showCloseButton: true
              }).then((result) => {
                if (result.value) {
                    deleteKRSeriesAttribute(option.KRSeriesID).then(resp => {
                               
                
                                let { options } = this.state.krseries;
                                options = this.state.krseries.filter(u => u.KRSeriesID != resp.Data.KRSeriesID);
                             
                                this.setState({
                                    krseries: options,
                                })
                                arrayHelpers.remove(i);
                                            });
                  
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                  
                }
              })

        // swal("This action will delete the record permanently from database, Are you sure?", {
        //     buttons: {
        //         catch: {
        //             text: "Yes",
        //             value: "Yes",
        //         },
        //         cancel: "Cancel",
        //     },
        // })
        //     .then((value) => {
        //         switch (value) {
        //             case "Yes":
                    
                      
        //                 deleteKRSeriesAttribute(option.KRSeriesID).then(resp => {
                               
                
        //         let { options } = this.state.krseries;
        //         options = this.state.krseries.filter(u => u.KRSeriesID != resp.Data.KRSeriesID);
             
        //         this.setState({
        //             krseries: options,
        //         })
        //         arrayHelpers.remove(i);
        //                     });
                        
        //                 break;
        //             default:

        //         }
        //     });
        }
    }



    getKRSeries = () => {

        getKRSeriesAttributes(this.props.manufacturer_id).then(res => {

            this.setState({
                krseries: res,
                showform: true
            })

        })
    }
    submitFormula = (data, actions) => {
        let found=false;
        for(let i=0;i<data.krseries.length;i++){
            let rname=data.krseries[i].KRSeriesValue;
        
        let foundDuplicate=data.krseries.filter(r=>r.KRSeriesValue==rname).map(duplicate=>(duplicate.KRSeriesValue ))
            if(foundDuplicate.length>=2)
            {found=true;
                actions.setSubmitting(false)
                swal(rname+" attribute is already exist", {
                    buttons: {
                            cancel: "OK",
                    },
                })
                    .then((cancel) => {
                        switch (cancel) {
                            case "Yes":                        
                                break;
                            default:
                        }
                    });
            } 
        }
        if(found==false){
            actions.setSubmitting(true);
            // let updateOptions = data.options.filter(o => (this.state.updatableOptions.indexOf(o.CalulatorformulaeId) > -1 || o.CalulatorformulaeId == 0));
            let KRSeriesAttributeValues = data.krseries
    
            addKRSeries(this.props.manufacturer_id,
                KRSeriesAttributeValues).then(response => {
    
                    if (response.Status && response.StatusCode === 200) {
                        let { krseries } = this.state;
    
                        // options = options.map(o => {
                        //     if (updateOptions.map(oo => oo.CalulatorformulaeId == o.CalulatorformulaeId).length > 0) {
                        //         o = updateOptions.filter(o => o.CalulatorformulaeId === o.CalulatorformulaeId)[0];
                        //     }
                        //     return o;
                        // })
    
                        krseries = krseries.map(krseries => {
                            let option2 = KRSeriesAttributeValues.find(o => o.KRSeriesID == krseries.KRSeriesID);
                            return option2 ? { ...krseries, ...option2 } : krseries;
                        })
    
                        this.setState({
                            krseries
                        })
    
                    }
    
                }, this.hideForm())
        }

    }



    render() {

        const manufacturer_id = this.props.manufacturer_id;
        const manufacturer_name = this.props.manufacturer_name;
        return (
            <>
            {!this.state.showform ?<>
                <Icon   data-tip data-for="edit" onClick={this.getKRSeries}>
                    edit
                 </Icon>
                 <StyledReactTooltip id="edit" effect="solid">
                 <span>Edit</span>
                </StyledReactTooltip></>
                : <>
                <Modal className="calculatorPopup" show={this.state.showform} onHide={this.hideForm}>
                    <Header closeButton>
                        <Title>{manufacturer_name}</Title>
                    </Header>
                    <Body>

                        <Formik
                            onSubmit={this.submitFormula}
                            initialValues={{ krseries: this.state.krseries }}
                        >
                            {({ values, status, isSubmitting }) => (
                                <Form>
                                    <div className="col-lg-6">
                                        <div className="row popup-heading" >
                                            <label >ATTRIBUTE NAME : KRSeries</label>

                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-4 col-sm-4 col-6">
                                            <label >KR Series</label>

                                        </div>
                                        <div className="col-lg-4 col-sm-4  col-4">
                                            <label > Block Length</label>
                                        </div>
                                    </div>
                                    <FieldArray
                                        name="krseries"

                                        render={arrayHelpers => (
                                            <div className="clacfor">

                                                {values.krseries && values.krseries.length > 0 ? values.krseries.map((krseries, i) => {
                                                   
                                                    return (<div className="row" key={i}>
                                                        <div className="col-lg-4 col-sm-4  col-6">

                                                            <Field

                                                                name={`krseries[${i}.KRSeriesValue]`}
                                                                margin="normal"

                                                                fullWidth

                                                                component={TextField}
                                                            // onChange={() => {
                                                            //     this.onFieldChange(option)
                                                            // }}
                                                            />
                                                            { (!krseries.KRSeriesValue || krseries.KRSeriesValue.length == 0)
                                                                && this.state.error ?
                                                                <span style={{ color: "red" }}>Enter KRSeries Value</span> : ""}


                                                        </div>
                                                        <div className="col-lg-4 col-sm-4  col-4">

                                                            <Field

                                                                name={`krseries[${i}.BlockLength]`}
                                                                margin="normal"

                                                                fullWidth

                                                                component={TextField}
                                                            // onChange={() => {
                                                            //     this.onFieldChange(option)
                                                            // }}
                                                            />

                                                        </div>




                                                        <SafeAnchor className="btn btn-default">
                                                            <i className="fa fa-minus"
                                                                onClick={() => {
                                                                    this.deletekrseries(krseries, arrayHelpers, i);
                                                                }} /></SafeAnchor>
                                                        <br />
                                                    </div>);
                                                }) : arrayHelpers.push({ KRSeriesID: 0 })}
                                                <SafeAnchor className="btn btn-default">
                                                    <i className="fa fa-plus" onClick={() => {
                                                        if (values.krseries && values.krseries.length > 0 && values.krseries.filter
                                                            (v => (!v.KRSeriesValue || v.KRSeriesValue.length == 0)).length == 0) {
                                                            arrayHelpers.push({ KRSeriesID: 0 })
                                                            this.setState({
                                                                error: false
                                                            })
                                                        } else {
                                                            this.setState({ error: true })
                                                        }


                                                    }} /></SafeAnchor>
                                            </div>
                                        )} />
                                    {/* <input type="submit"  className="submit" disabled={isSubmitting} /> */}
                                    <Modal.Footer className="row" centered>
                                        <Button variant="contained" type="submit" className="save" disabled={isSubmitting} >Submit</Button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Body>
                </Modal>

                </>}
            </>

        )
    }
}