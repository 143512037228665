
export const UPDATE_STROKE_LENGTH_DATA = 'UPDATE_STROKE_LENGTH_DATA';
export const UPDATE_KR_SERIES_DATA = 'UPDATE_KR_SERIES_DATA';
export const DEFAULT_VALUES_KR_SERIES = 'DEFAULT_VALUES_KR_SERIES';
export const UPDATE_PART_DETAILS_DATA = 'UPDATE_PART_DETAILS_DATA';

export function updateStrokeLength(allStrokeData) {
    return {
        type: UPDATE_STROKE_LENGTH_DATA,
        allStrokeData
    }
}

export function updatePartDetailsData(allPartDetails) {
    return {
        type: UPDATE_PART_DETAILS_DATA,
        allPartDetails
    }
}

export function updateKrSeriesData(allKrSeriesData) {
    return {
        type: UPDATE_KR_SERIES_DATA,
        allKrSeriesData
    }
}

export function updateDefaultValuesKrSeriesData(defaultValues) {

    return {
        type: DEFAULT_VALUES_KR_SERIES,
        defaultValues
    }
}



