import {SERVER_ACTIONS} from '../../utils/Constants';
import HttpRequest from '../../utils/HttpRequest';
import {deletePricingHistoryFromList,updatePricingHistory, updateSizeDetails, updateRailBellowDetails, updateMarkupsDetails,editPricingHistory } from './ManagePricingsActions';


export function getPricingHistoryList({query, PageNumber, defaultPageSize}) {

    return (dispatch) => {
        const queryString = (query && query.trim().length > 0) ? `?${query}` : '';
        //document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.POST_PRICING_HISTORY}${queryString}`, 'post', {})
            .requestWithAuth()
            .then(response => {
          //      document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                dispatch(updatePricingHistory(response.data, Math.ceil(response.data.Data.totalrecord / defaultPageSize), PageNumber));
                return Promise.resolve(response.data);
                }
                else{
                    return Promise.reject(response.data);
                }
            }, error => {
            //    document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error); 
            });
    }
}

export function getSizeDetailValues(sizeId) {

    return (dispatch) => {
        const queryString =  '?sizeid='+sizeId;
        // document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_SIZE_DETAIL}${queryString}`, 'get', {})
            .requestWithAuth()
            .then(response => {
                // document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {

                dispatch(updateSizeDetails(response.data.data));
                return Promise.resolve(response.data.data);
                }
                else{
                    return Promise.reject(response.data);
                }
            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);
            });
    }
}

export function getRailBellowValues(sizeId, materialId) {

    return (dispatch) => {
        const queryString =  '?SizeID='+sizeId+'&MaterialId='+materialId;
        // document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_RAIL_BELLOW_PROPERTY}${queryString}`, 'get', {})
            .requestWithAuth()
            .then(response => {
                // document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {

                dispatch(updateRailBellowDetails(response.data.data));
                return Promise.resolve(response.data.data);
                }
                else{
                    return Promise.reject(response.data);
                }
            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);
            });
    }
}

export function getMarkupsValues(manufacturerId) {

    return (dispatch) => {
        const queryString =  '?ManufacturerId='+manufacturerId;
        // document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_MARKUPS_BY_MANUFACTURER}${queryString}`, 'get', {})
            .requestWithAuth()
            .then(response => {
                // document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {

                dispatch(updateMarkupsDetails(response.data.data));
                return Promise.resolve(response.data.data);
                }
                else{
                    return Promise.reject(response.data);
                }
            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);
            });
    }
}


export function saveSizeItem(data, formAction) {
    formAction.setSubmitting(true)
    return (dispatch) => {
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.POST_UPDATE_SIZE_DETAIL}`, 'post', data)
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                formAction.setSubmitting(false);
                if (response.status === 200 && !response.data.errorCode) {
                    window.Message(response.data.message, 'Success');

                        dispatch(updateSizeDetails(response.data.data));

                    return Promise.resolve(response);
                } else {
                    formAction.setStatus({ msg: response.data.message });
                    return Promise.reject(response);
                }
            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (error.response.data) {
                    formAction.setStatus({ msg: error.response.data.message });
                }
                formAction.setSubmitting(false);
                return Promise.reject(error);
            });
    }
}


export function savePropertyItem(data, formAction) {
    formAction.setSubmitting(true)
    return (dispatch) => {
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.POST_RAIL_BELLOW_PROPERTY}`, 'post', data)
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                formAction.setSubmitting(false);
                if (response.status === 200 && !response.data.errorCode) {
                    window.Message(response.data.message, 'Success');

                        dispatch(updateRailBellowDetails(response.data.data));

                    return Promise.resolve(response);
                } else {
                    formAction.setStatus({ msg: response.data.message });
                    return Promise.reject(response);
                }
            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (error.response.data) {
                    formAction.setStatus({ msg: error.response.data.message });
                }
                formAction.setSubmitting(false);
                return Promise.reject(error);
            });
    }
}

export function saveMarkupsItem(data, formAction) {
    formAction.setSubmitting(true)
    return (dispatch) => {
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.POST_MARKUPS}`, 'post', data)
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                formAction.setSubmitting(false);
                if (response.status === 200 && !response.data.errorCode) {
                    window.Message(response.data.message, 'Success');

                        dispatch(updateMarkupsDetails(response.data.data));

                    return Promise.resolve(response);
                } else {
                    formAction.setStatus({ msg: response.data.message });
                    return Promise.reject(response);
                }
            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (error.response.data) {
                    formAction.setStatus({ msg: error.response.data.message });
                }
                formAction.setSubmitting(false);
                return Promise.reject(error);
            });
    }
}

export function getAllManufacturerList(manufacturerId) {
   
        const queryString = '';
 
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_ALL_MANUFACTURERS}${queryString}`, 'get', {})
            .requestWithAuth()
            .then(response => {
               
                if (response.status === 200 && !response.data.errorCode) {
                    // dispatch(manufacturerList(response.data));
                    return Promise.resolve(response.data);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);


            });
}

export function getDefaultValuesForStrokeLengthData(manufacturerId,railId) {
        const queryString =  '?ManufacturerId='+manufacturerId+'&RailId='+railId;
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.POST_DEFAULT_VALUES_FOR_STROKE_LENGTH}${queryString}`, 'post', {})
            .requestWithAuth()
            .then(response => {
              
                if (response.status === 200 && !response.data.errorCode) {
                return Promise.resolve(response.data.Data);
                }
                else{
                    return Promise.reject(response.data);
                }
            }, error => {
                return Promise.reject(error);
            });
}

/////////////////////////////////List of size by railId and BellowId//////////////////////
export function getAllSizeList(raildId, bellowId) {
   
    const queryString = '?railid='+raildId+'&bellowid='+bellowId;
    return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_ALL_SIZES}${queryString}`, 'get', {}).requestWithAuth().then(
        (response) => {
            if (response.status === 200 && !response.data.errorCode) {
                return Promise.resolve(response.data.data);
                }
                else{
                    return Promise.reject(response.data);
                }
        },
        (error) => {
            
            return Promise.reject(error);
        }
    );
}
////////////////////////////////Update Pricing History comment////////////
export function updatePricingHistoryComment(user, formAction) {
    formAction.setSubmitting(true)
    return (dispatch) => {
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.POST_PRICING_HISTORY_UPDATE}`, 'post', user)
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                formAction.setSubmitting(false);
                if (response.status === 200 && !response.data.errorCode) {
                    window.Message(response.data.message, 'Success');

                    // if (user.user_id == 0) {

                        dispatch(editPricingHistory(response.data.Data));

                    // } else {
                    //     dispatch(updateUserInList(response.data.data));
                    // }

                    return Promise.resolve(response);
                } else {
                    formAction.setStatus({ msg: response.data.message });
                    return Promise.reject(response);
                }
            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (error.response.data) {
                    formAction.setStatus({ msg: error.response.data.message });
                }
                window.Message(error.response.data.message, 'Error',true);
                formAction.setSubmitting(false);
                return Promise.reject(error);
            });
    }
}

export function deletePricingHistory(id, modified_by) {
    return (dispatch) => {
        let params ={};
        params.id =id;
        params.modified_by = 0;
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(SERVER_ACTIONS.API+SERVER_ACTIONS.POST_PRICING_HISTORY_DELETE, 'DELETE', params).requestWithAuth().then(
            (response) => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                    window.Message(response.data.message, 'Success');
                     dispatch(deletePricingHistoryFromList(response.data.Data));
                }

                return Promise.resolve(response.data);
            },
            (error) => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);
            }
        );
    };
}
///////////////////////search string////////////
export function getSearchedPricingHistoryList({query, PageNumber, defaultPageSize}) {

    return (dispatch) => {
        const queryString = (query && query.trim().length > 0) ? `?${query}` : '';
        //document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.POST_PRICING_HISTORY}${queryString}`, 'post', {})
            .requestWithAuth()
            .then(response => {
               // document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                dispatch(updatePricingHistory(response.data, Math.ceil(response.data.Data.totalrecord / defaultPageSize), PageNumber));
                return Promise.resolve(response.data);
                }
                else{
                    return Promise.reject(response.data);
                }
            }, error => {
               // document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error); 
            });
    }
}

