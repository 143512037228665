import React, { Component } from 'react';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { getAllKRSeries } from '../../CommonApiActions';

class KRSeriesDropDown extends React.Component {
    constructor(props) {
        super(props);
        this.state = ({
            allKRSeries: [],
            manufacturerId: this.props.manufacturerId
        })
    }

     componentWillReceiveProps(props) {

        if(props.manufacturerId!= undefined && props.manufacturerId !=this.state.manufacturerId){
          this.props.getKRSeriesList(props.manufacturerId);
          this.state.manufacturerId = props.manufacturerId;
        }
    }

    componentDidMount() {
        this.props.getKRSeriesList(this.state.manufacturerId);
    }

    render() {
        const selectedValue = this.props.selectedValue;
        const series = this.props.allKRSeries;

        const options = series && series.length > 0 ? (
            series.map((series,index) => {
                
                return (<MenuItem key={index} name={series.KRSeriesValue} value={series.KRSeriesID}>
                       {series.KRSeriesValue}
                        </MenuItem>
                );
            })
        ) : (
                <MenuItem>Loading....</MenuItem>
            );
       
        return (

            <Select className="krseries-dropdown" defaultValue={selectedValue}
                // name= {selectedName}
                labelId="demo-simple-select-label"
                id="demo-simple-select" onChange={this.props.onKRSeriesChange}>
                {options}
            </Select>
        );
    }

}

const mapProps = (state) => {
   
    return {
        allKRSeries: state.common.allSeries
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getKRSeriesList: (manufacturerId) => {
            return new Promise((resolve, reject) => {
                dispatch(getAllKRSeries(manufacturerId))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    };
};

export default connect(mapProps, mapDispatchToProps)(KRSeriesDropDown);
