import React, { Component } from 'react';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { getAllStateList } from '../../CommonApiActions';
import Auth from '../../../../utils/Auth';

class StateDropDown extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = ({
            allStates: [],
            country: this.props.country
        });
    }
 
componentDidMount(){
    this.props.getStateList(this.state.country);
    
}

    componentWillReceiveProps(props) {

        if(props.country!= undefined && props.country !=this.state.country){
          this.props.getStateList(props.country);
          this.state.country = props.country;
        }
    }

    render() {
        const selectedValue = this.props.selectedValue;
        const states = this.props.allStates;
        const options =
        states && states.length > 0 ? (
            states.map((state) => {

                return (<MenuItem value={state.state_province_id}>
                    {state.state_name}
                    </MenuItem>
                 );
                    
                })
            ) : (
                    <MenuItem>Loading....</MenuItem>
                );

        return (
            <Select value={selectedValue}
                labelId="demo-simple-select-label"
                id="demo-simple-select" onChange={this.props.onStateChange}>
                {options}
            </Select>
        );
    }
}

const mapProps = (state) => {
   
    return {
        allStates: state.common.allStates
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getStateList: (country) => {
            return new Promise((resolve, reject) => {
                dispatch(getAllStateList(country))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    };
};

export default connect(mapProps, mapDispatchToProps)(StateDropDown);
