import React from 'react';
import { Link } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Footer from '../../../Common/components/Footer/Footer';

const Unauthorized = () => {
  return (
    <React.Fragment>
        <Container >
      <div className="mt-10 text-center">
        <h1>403</h1>
        <h2>Access Denied </h2>
        <p>sorry, but you don't have permission to access this page</p>
        <br></br>
        <p><Link to='/home'>Back to Home</Link></p>
      </div>
      
      <br/>
               
    </Container>
    {/* <Footer/> */}
    </React.Fragment>
  )
}

export default Unauthorized;