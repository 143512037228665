import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ErrorMessage, Field, Form, Formik } from "formik";
import {GuestLoginRequest} from '../../AuthenticationApiActions';
import Button from '@material-ui/core/Button';
import { TextField } from 'formik-material-ui';
import CardContent from '@material-ui/core/CardContent';
import RequestGuestLogin from './RequestGuestLogin';
import GuestLoginSchema from './GuestLoginSchema'; 
import { SafeAnchor} from 'react-bootstrap';
import './GuestLogin.css';
class GuestLogin extends Component {
  constructor(props){
    super(props);
    this.state={
      visible : false,
      
  };
  }
    
    loginUser = (data, actions) => {
    
      this.props.onGuestLogin(data, actions).then(response => {
    
      }, error => {
          //TODO: Show message for incorrect credentials
      })
    }

    
    handleGuest=()=>{
      this.setState({visible:true});
    }
    render(){
     
      return(
        <React.Fragment>
           {this.state.visible ? <RequestGuestLogin/>: <>
        
             
                                        <CardContent> <h3 className="subheading">Guest Login</h3>
                                            <Formik
                                                validationSchema={GuestLoginSchema}
                                                onSubmit={this.loginUser}
                                                initialValues={{
                                                  email: ''
                                                }}
                                                >
    
                                                {({ errors, status, touched, isSubmitting, setFieldValue }) => (
                                                    <Form name="loginForm" className="form-horizontal form-simple" noValidate>
                                               
                                                        <fieldset className="form-group position-relative has-icon-left mb-0 com-field"> 
                                                            <Field type="text" name="email"
                                                            label="Email"
                                                                //className="form-control "
                                                                className="mt-0"
                                                                margin="normal"
                                                                fullWidth
                                                                component={TextField}
                                                                placeholder="Email"
                                                                error />                                                          
    
                                                        </fieldset>
    
                                                        <div className="form-group sub-btn">
    
                                                        <Button type="submit" color="primary" className="submit-btn">
                                                             Login
                                                        </Button>
    
                                                        </div>
                                                    </Form>
                                                   
                                                )}
                                                
                                            </Formik><p style={{'clear':'both'}}>For guest login : <SafeAnchor className="register"   onClick={this.handleGuest}>Register Here</SafeAnchor></p>
   
                                            </CardContent>

                                    </> }
        </React.Fragment>
      );
    }
  }

const mapStateToProps = state => {
    return {
        
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onGuestLogin: (data, actions) => {
            return new Promise((resolve, reject) => {
                dispatch(GuestLoginRequest(data, actions)).then(response => {

                    resolve(response);
                }).catch(error => {

                    reject(error);
                });
            });
        }
    }
}

GuestLogin = connect(mapStateToProps, mapDispatchToProps)(GuestLogin);
export default GuestLogin;