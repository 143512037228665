
import { UPDATE_STROKE_LENGTH_DATA, UPDATE_KR_SERIES_DATA, DEFAULT_VALUES_KR_SERIES, UPDATE_PART_DETAILS_DATA } from './CalculatorOutputActions';

const initialState = { allStrokeData: [], allKrSeriesData: [] };

const calculatorOutput = (state = initialState, action) => {
    switch (action.type) {

       case UPDATE_STROKE_LENGTH_DATA:
            {
                return Object.assign({}, state, {
                    allStrokeData: action.allStrokeData
                });
            } 

        case UPDATE_PART_DETAILS_DATA:
            {
                return Object.assign({}, state, {
                    allPartDetails: action.allPartDetails
                })
            }

        case UPDATE_KR_SERIES_DATA: 
            {
                return Object.assign({}, state, {
                    allKrSeriesData: action.allKrSeriesData
                })
            }

        case DEFAULT_VALUES_KR_SERIES:
            {
                return Object.assign({}, state, {
                    defaultValues: action.defaultValues
                })
            }
        default:
            return state;
    }
}

export default calculatorOutput;