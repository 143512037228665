import React, { Component } from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Switch from '@material-ui/core/Switch';
import Pagination from 'react-bootstrap/Pagination';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import AddRole from './AddRole';
import EditRole from './EditRole';
// import Adduser from './Adduser';
// import Edituser from './edituser';
import { getRoleList, deleteRoleFromList, toggleStatusRoleItem } from '../../ManageRolesApiActions';
import { connect } from 'react-redux';
import './ManageRole.css';
import { SafeAnchor } from 'react-bootstrap';
import swal from 'sweetalert';
import RoleAttribute from './RoleAttribute';
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import Swal from 'sweetalert2';
const StyledReactTooltip = styled(ReactTooltip)`
  background-color: white !important;
  color: black !important;
  
  &:after {
    border-top-color: white !important;
  }
`;

class ManageRole extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            active: false,
            checked: true,
            addModalShow: false,
            defaultPageSize: 10,
            PageNumber: 1,
            totalPages: 1,
            pageheading: 'Manage Roles',
            data: {},
            editRoleModalShow: false,
            update:true,
            role : {}
        }
    }
    componentDidMount() {
        this.getRoleList(this.state.PageNumber);
    }

    getRoleList(PageNumber) {
        const { defaultPageSize } = this.state;
        this.state.PageNumber = PageNumber;

        if (PageNumber > 0) {
            
            const query = 'PageNumber=' + PageNumber + '&PageSize=' + defaultPageSize + (this.state.query !== '' ? '&q=' + this.state.query : '');
            this.props.getRoleList({ query, PageNumber, defaultPageSize }).then(resp => {
                
                const numberOfRecords = this.props.roles.data.length;
                const totalPages = this.props.totalPages;
                let data = this.props.roles.data;

                this.setState({
                    numberOfRecords,
                    totalPages,
                    data: data
                });
              
               // window.location.reload();

            }).catch(error => {
            window.location.href="#/page403"
            //window.location.reload();
            });
        }
    }

    handlePageClick = (e, index) => {
        //   this.setState({
        //      PageNumber: index
        //   });
        let PageNumber = index;
        this.getRoleList(PageNumber);

    }

    deleteRole = (role_id) => {
        Swal.fire({
            //title: 'Are you sure?',
            text: 'Are you sure want to delete?',
            // icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
            showCloseButton: true
          }).then((result) => {
            if (result.value) {
                this.props.deleteRole(role_id).then(res => {
                                        this.updateList();
                                    });
              
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              
            }
          })
        // swal("Are you sure want to delete?", {
        //     buttons: {
        //         catch: {
        //             text: "Yes",
        //             value: "Yes",
        //         },
        //         cancel: "Cancel",
        //     },
        // })
        //     .then((value) => {
        //         switch (value) {
        //             case "Yes":
        //                 this.props.deleteRole(role_id).then(res => {
        //                     this.updateList();
        //                 });
        //                 break;
        //             default:
        //         }
        //     });
    }

    updateList = () => {
        
        this.setState(Object.assign({}, this.state));
    };

    statusChange(role_id) {
        this.props.changeStatus(role_id).then(res => {
            this.updateList();
        })
    };
    renderPagination = () => {

        let items = [];
        if (this.state.data.data !== [] && this.state.data.data != undefined) {
            // for (let i = 1; i <= this.state.totalPages; i++) {
            //     items.push(
            //                 <Pagination.Item className={this.state.PageNumber === i ? "active" : ""} 
            //                 onClick={e => this.handlePageClick(e, i)} >
            //                 {i}
            //                 </Pagination.Item>
            //               );
            // }
            let j=3;
            for (let i = 1; i <= this.state.totalPages; i++) {
                if(this.state.PageNumber>=i && this.state.PageNumber+3<this.state.totalPages){
                    i=this.state.PageNumber;
                    j=this.state.PageNumber+3;
                }
                if(this.state.PageNumber+3>=this.state.totalPages){
                    if(this.state.totalPages-3>0)
                    {if(i<this.state.totalPages-3)
                        i=this.state.totalPages-3;
                        j=i+3;
                    }
                    else if(i==this.state.totalPages){
                        i=this.state.totalPages;
                    }
                    else{
                        if(i<3-this.state.totalPages){
                            i=3-this.state.totalPages;
                        }
                       }
                }
               
                if(i<j || i==this.state.totalPages)
                {
                    items.push(
                        <Pagination.Item
                            className={this.state.PageNumber === i ? "active" : ""}
                            onClick={e => this.handlePageClick(e, i)} >
                            {i}
                        </Pagination.Item>
                    );

                }
              if(i<j && this.state.totalPages-i==1)
                {
                }
                else
               
                 {
                    if(i==this.state.totalPages-1){
                        items.push(<Pagination.Ellipsis />)
                          }
                  }   
            }
            
            return items;
        }
    }
    renderTableData = () => {

        if (this.state.data.data !== [] && this.state.data.data != undefined) {
            
            return this.state.data.data.map((data) => {
                const { role_id, role_name, is_active, role_type,role_type_id } = data //destructuring
                return (

                    <TableRow key={role_id}>

                        <TableCell scope="row">{role_name}</TableCell>
                        <TableCell scope="row">{role_type}</TableCell>
                       
                        <TableCell> <SafeAnchor onClick={() => this.statusChange(role_id)} >
                            &nbsp;<i className={(is_active) ? `fa fa-toggle-on fa-2x success` : `fa fa-toggle-off fa-2x red`}></i>
                        </SafeAnchor>
                        </TableCell>
                        <TableCell scope="row">
                            <div style={{'display':"flex"}}>
                            <Icon
                             data-tip data-for="edit"
                                className="icons"
                                
                                onClick={() => 
                                    this.setState({ editRoleModalShow: true,
                                    roletypeid : data.role_type_id,    
                                    role: data,
                                    update: true
                                 })}
                            >edit
                                        </Icon>
                                        <StyledReactTooltip id="edit" effect="solid">
                                         <span>Edit</span>
                                        </StyledReactTooltip>
                            <Icon  data-tip data-for="delete" onClick={() =>this.deleteRole(role_id)}>delete</Icon>
                            <StyledReactTooltip id="delete" effect="solid">
                                         <span>delete</span>
                                        </StyledReactTooltip>
                            </div>
                        </TableCell>
                        <TableCell>
                            
                            <>
                                <RoleAttribute className="ManufacturerPopup" role={data} 
                                ></RoleAttribute>
                            </>

                        </TableCell>
                    </TableRow>
                )
            })
        }
    }

    handleChange = (e) => {

        this.setState({
            checked: e.target.checked
        })
    }

    render() {

        const mtt = {
            marginTop: '20px',
            marginTop: '20px',
            width:'100%',
            overflowX: 'auto'
        }


        let addModalClose = () => {
            this.setState({ addModalShow: false });
        }

        let editModalClose = () => {
            this.setState({ editRoleModalShow: false });
        }
        const tableGray = {
            background: '#f1f3f6',

        }
        
        const adduser = {
            clear: 'both',
            float: 'right',
            borderRadius: '20px',
            background: '#01524B',
            color: '#fff',
            fontWeight: 'normal'
        }
        return (
            <React.Fragment>
                <AddRole
                    show={this.state.addModalShow}
                    onHide={addModalClose}
                    update = {this.state.update}
                    updateList = {this.updateList}
                />

                <EditRole
                    show={this.state.editRoleModalShow}
                    onHide={editModalClose}
                    role={this.state.role}
                    update = {this.state.update}
                    updateList = {this.updateList}
                />
                <h2 style={{ 'clear': 'both', 'display': 'inline' }} className="heading">{this.state.pageheading}</h2>

                <Button className="addbutton" style={adduser} update={false}
                    onClick={() => this.setState({ 
                        addModalShow: true,
                        update:false
                     })}
                >Add Role</Button>


                 <div style={mtt}>
                <Table className="table-striped"  >

                    <TableHead style={tableGray}>
                        <TableRow>
                            {
                                <>
                                    <TableCell scope="row">Role Name</TableCell>
                                    <TableCell scope="row">Role Type</TableCell>
                                    <TableCell >Status</TableCell>
                                    <TableCell scope="row">Action</TableCell>
                                    <TableCell scope="row">Assign Bellows Parameters</TableCell>
                                </>
                            }

                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            this.renderTableData()
                        }
                    </TableBody>

                </Table>

                </div>

                <div className="custom-pagination">
                    <Pagination>
                        <Pagination.First onClick={e => this.handlePageClick(e, 1)} />
                        <Pagination.Prev onClick={e => this.handlePageClick(e, this.state.PageNumber > 1 ? this.state.PageNumber - 1 : this.state.PageNumber)} />
                        {this.renderPagination()}
                       
                        {/* <Pagination.Item>{10}</Pagination.Item> */}
                        <Pagination.Next onClick={e => this.handlePageClick(e, this.state.PageNumber < this.state.totalPages ? this.state.PageNumber + 1 : this.state.PageNumber)} />
                        <Pagination.Last onClick={e => this.handlePageClick(e, this.state.totalPages)} />
                    </Pagination>
                </div>
            </React.Fragment>
        )
    }
}


const mapStateToProps = state => {
    return {
        roles: state.role.roles,
        totalPages: state.role.totalRecords
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getRoleList: (data) => {
            return new Promise((resolve, reject) => {
                dispatch(getRoleList(data)).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            });
        },

        changeStatus: (role_id) => {
            return new Promise((resolve, reject) => {
                dispatch(toggleStatusRoleItem(role_id)).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            })
        },
        deleteRole: (id) => {
            return new Promise((resolve, reject) => {
                dispatch(deleteRoleFromList(id)).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        }  
    }
}

ManageRole = connect(mapStateToProps, mapDispatchToProps)(ManageRole);

export default ManageRole;