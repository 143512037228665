import Cookies from 'universal-cookie';
import { constants } from 'crypto';
import { SERVER_ACTIONS, ACTIONS } from './Constants';
import { Store } from 'express-session';

const cookies = new Cookies();

class Auth {
    
    setAccessToken(token) {
        localStorage.setItem("nabellAuth", "true");
        cookies.set('token', token, { path: '/' });
    }
    setPHManId(id) {
        cookies.set('PHManId', id || "0", { path: '/' });
    }

    getPHManId() {
        const PHManId = cookies.get('PHManId', { path: '/' });
        return PHManId;
    }

    setHistoryType(id) {
        cookies.set('HisType', id || "0", { path: '/' });
    }

    getHistoryType() {
        const HistoryType = cookies.get('HisType', { path: '/' });
        return HistoryType;
    }

    setCalculatorTab(id) {
        cookies.set('CalculatorTab', id || {}, { path: '/' });
    }

    getCalculatorTab() {
        const CalculatorTab = cookies.get('CalculatorTab', { path: '/' });
        return CalculatorTab;
    }

    setSavedPartId(id) {
        cookies.set('savedPartId', id || {}, { path: '/' });
    }

    getSavedPartId() {
        const savedPartId = cookies.get('savedPartId', { path: '/' });
        return savedPartId;
    }

    
    setCalculatorId(id) {
        cookies.set('calculatorId', id || {}, { path: '/' });
    }

    getCalculatorId() {
        const calculatorId = cookies.get('calculatorId', { path: '/' });
        return calculatorId;
    }

    setManufacturerId(id) {
        cookies.set('manufacturerId', id || {}, { path: '/' });
    }

    getManufacturerId() {
        const manufacturerId = cookies.get('manufacturerId', { path: '/' });
        return manufacturerId;
    }

    setParams(data) {
        cookies.set('signInData', data || {}, { path: '/' });
    }

    getParams() {
        const params = cookies.get('signInData', { path: '/' });
        return params || 1;
    }

    setCountry(data) {
        cookies.set('countryId', data || 0, { path: '/' });
    }

    getCountry() {
        const countryId = cookies.get('countryId', { path: '/' });
        return countryId;
    }

    setUser(username, user_Id, email, value) {
        
        cookies.set('user', username, { path: '/' });
        cookies.set('user_Id', user_Id, { path: '/' });
        cookies.set('email', email, { path: '/' });
        cookies.set('isAdmin', value, { path: '/' });
        localStorage.setItem("isAdmin", value);
    }
    getUser() {
        const user = cookies.get('user', { path: '/' });
        return user;
    }

    getUserId() {
        const user_Id = cookies.get('user_Id', { path: '/' });
        return user_Id;
    }

    getEmailId() {
        const email = cookies.get('email', { path: '/' });
        return email;
    }

    getUserType() {
        const isAdmin = cookies.get('isAdmin', { path: '/' });
        return isAdmin;
    }
    setUserType(val) {
        cookies.set('isAdmin', val, { path: '/' });
    }
    otherTabLogout(){
        
        if(localStorage.getItem("isAdmin") == "true"){
            cookies.set('isAdmin', true, { path: '/' });
            cookies.remove('user', { path: '/' });
            cookies.remove('token', { path: '/' });
            cookies.remove('user_Id', { path: '/' });
           // cookies.remove('isAdmin', {path: '/'});
            window.location.href = "/#"+ ACTIONS.ADMIN_SIGN_IN;
            window.location.reload();
        }
        else{
            cookies.remove('user', { path: '/' });
            cookies.remove('token', { path: '/' });
            cookies.remove('user_Id', { path: '/' });
            cookies.remove('isAdmin', {path: '/'});
            window.location.reload();
        }
    }

    logout() {
        //localStorage.setItem("IsLogin", "false");
        localStorage.setItem("nabellAuth", "false");
		cookies.remove('PasswordReset', { path: '/' });
        //if(localStorage.getItem("isAdmin") == "true"){
        if(cookies.get('isAdmin', { path: '/' })=="true"){
            
            cookies.remove('user', { path: '/' });
            cookies.remove('token', { path: '/' });
            cookies.remove('user_Id', { path: '/' });
           // cookies.remove('isAdmin', {path: '/'});
            window.location.href = "/#"+ ACTIONS.ADMIN_SIGN_IN;
            window.location.reload();
        }
        else{
            cookies.remove('user', { path: '/' });
            cookies.remove('token', { path: '/' });
            cookies.remove('user_Id', { path: '/' });
            cookies.remove('isAdmin', {path: '/'});
            window.location.reload();
        }

    }

    isLoggedIn() {

        const token = cookies.get('token', { path: '/' });
        const user_Id = cookies.get('user_Id', { path: '/' });
        return token !== null && token !== undefined && user_Id !== null && user_Id !== undefined;
    }


    getAccessToken() {
        return cookies.get('token', { path: '/' });
    }

    setGuestUserEmail(email) {
        return cookies.set('guestEmail', email, { path: '/' });
    }
    getGuestUserEmail() {
        return cookies.get('guestEmail', { path: '/' });
    }
	
    setRegisterComponent(val) {
        cookies.set('RegisterComponent', val || {}, { path: '/' });
    }
    getRegisterComponent() {
        const CalculatorTab = cookies.get('RegisterComponent', { path: '/' });
        return CalculatorTab;
    }
	
	setIsPasswordReset(value) {
        cookies.set('PasswordReset', value , { path: '/' });
    }
    getIsPasswordReset() {
        const PasswordReset = cookies.get('PasswordReset', { path: '/' });
        return PasswordReset == undefined ? "true" : PasswordReset;
    }
}

export default Auth;