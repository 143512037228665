import React, { Component } from 'react';
import { sendAccessToken } from '../../AuthenticationApiActions';
import { connect } from 'react-redux';
import { ErrorMessage, Field, Form, Formik } from "formik";
import Auth from '../../../../utils/Auth';
import Button from '@material-ui/core/Button';
import { TextField } from 'formik-material-ui';
import { CardContent } from '@material-ui/core';
import RequestOTPSchema from './RequestOTPSchema';

let auth = new Auth();
class RequestOTP extends React.Component {

    constructor(props) {
        super(props);

    }

    accessToken = (data, actions) => {
        data.email = auth.getGuestUserEmail();
        this.props.sendvalidAccessToken(data,actions).then(response => {
           
        }, error => {
           
            //TODO: Show message for incorrect credentials
        })
    }

    render() {
        return (
            <React.Fragment>
                <CardContent>
                    <h3 className="subheading">Request for Guest Login</h3>
                    {/* <p>We have sent access code on a requested Email Address, Please check.</p> */}
                    <p>The Access Code has been sent to your Email. Please copy and paste it below.</p>
                    <Formik
                        validationSchema={RequestOTPSchema}
                        onSubmit={this.accessToken}
                        initialValues={{
                            access_code: ''
                        }}
                    >

                        {({ errors, status, touched, isSubmitting, setFieldValue }) => (
                            <Form name="loginForm"  autoComplete="off" noValidate>

                                <fieldset className="form-group position-relative has-icon-left mb-0">
                                    <Field type="text" name="access_code"
                                        placeholder="access code"
                                        id="standard-required"
                                        label="Access Code*"
                                        margin="normal"
                                        component={TextField}
                                        onChange={(e) => setFieldValue("access_code", e.currentTarget.value)}
                                        fullWidth
                                        error />

                                </fieldset>
                                <Button type="submit" color="primary" className="submit-btn">
                                    Submit
                                                    </Button>
                            </Form>
                        )}
                    </Formik>

                </CardContent>
            </React.Fragment>
        );
    }

}
const mapStateToProps = state => {
    return {

    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        sendvalidAccessToken: (data, actions) => {
            return new Promise((resolve, reject) => {
                dispatch(sendAccessToken(data, actions)).then(response => {

                    resolve(response);
                }).catch(error => {

                    reject(error);
                });
            });
        }
    }
}

RequestOTP = connect(mapStateToProps, mapDispatchToProps)(RequestOTP);
export default RequestOTP;