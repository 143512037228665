import { UPDATE_MANUFACTURER, ADD_MANUFACTURER_TO_LIST, UPDATE_MANUFACTURER_IN_LIST, DELETE_MANUFACTURER_FROM_LIST, GET_ALL_MANUFACTURERS } from './ManageManufacturersActions';


const initialState = { manufacturers: [] };

const manufacturer = (state = initialState, action) => {
    switch (action.type) {

        case UPDATE_MANUFACTURER:


            if (action.PageNumber === 0)
                return { ...state, manufacturers: action.manufacturers, totalPages: action.totalPages };
            else {
                // let existingManufacturers = state.manufacturers.filter(disc => !action.manufacturers.some(newManufacturer => newManufacturer.manufacturer_id == disc.manufacturer_id));
                // let currentManufacturers = existingManufacturers.concat(action.manufacturers);
                let currentManufacturers = [];

                currentManufacturers = action.manufacturers;
                return Object.assign({}, state, {
                    manufacturers: currentManufacturers,
                    totalPages: action.totalPages
                });

            }

        case GET_ALL_MANUFACTURERS:
            {

                return Object.assign({}, state, {
                    allManufacturers: action.allManufacturers
                });
            }

        case ADD_MANUFACTURER_TO_LIST:
            const manufacturersToAdd = [action.manufacturer, ...state.manufacturers];
            //console.log({ Test: manufacturersToAdd })
            return Object.assign({}, state, {
                manufacturers: manufacturersToAdd
            });




        case UPDATE_MANUFACTURER_IN_LIST:
            let { manufacturers } = state;
            const index = manufacturers.findIndex(manufacturer => manufacturer.manufacturer_id === action.manufacturer.manufacturer_id);
            if (index !== -1) {
                manufacturers[index] = action.manufacturer;
            }

            return Object.assign({}, state, {
                manufacturers: manufacturers
            });

        case DELETE_MANUFACTURER_FROM_LIST:
            {
                let { manufacturers } = state;
                manufacturers = manufacturers.filter(manufacturer => manufacturer.manufacturer_id != action.manufacturer_id);
                return Object.assign({}, state, {
                    manufacturers: manufacturers
                })
            }

        default:
            return state;
    }
}

export default manufacturer;