import React, { Component } from 'react'
import { Container } from 'react-bootstrap';
import engico from '../../../../images/footer-contact.png';
import phico from '../../../../images/footer-mail-icon.png';
import mailico from '../../../../images/support.png';
import './footer.css';
import {APP_DETAILS}  from '../../../../utils/Constants';
export default class Footer extends Component {
    render() {
        const footer ={
            padding: '10px 0 0',
            background: '#01524b',
            color:'#fff',
            marginTop:'20px'
        }
        return (
            <React.Fragment>
                <div className="main-footer">
            <Container fluid="true" className="footer">
            <Container>
                <div className="row footer-certified">
                    <div className="col-md-4 col-xs-12 certified-col support-border-right">
                        <p><img src={engico} alt="" />&nbsp;&nbsp;Engineering & Design Support</p>
                    </div>
                    <div className="col-md-4 col-xs-12 certified-col support-border-right">
                        <p><img src={phico} alt="" />&nbsp;&nbsp;<a href={`tel:${APP_DETAILS.CONTACT_NUMBER}`}>{APP_DETAILS.CONTACT_NUMBER}</a></p>
                    </div>
                    <div className="col-md-4 col-xs-12 certified-col">
                        <p><img src={mailico} alt="" />&nbsp;&nbsp;<a href={`mailto:${APP_DETAILS.SALES_EMAIL}`}>{APP_DETAILS.SALES_EMAIL}</a></p>
                    </div>
                </div>
                </Container>
            </Container>
            <Container fluid="true" className="footer-copyright">
            <Container>
                <div className="row">
                    <div className="col-lg-12">
                        <p className="footer-copyright">Copyright © {new Date().getFullYear()} {APP_DETAILS.APPLICATION_NAME_FOOTER}, <span>All Rights Reserved</span></p>
                    </div>
                </div>
                </Container>
            </Container>
            </div>
        </React.Fragment>
            // <React.Fragment>
            //     <Container fluid="true">
            //     <div className="row">
            //         <div className="col-lg-12 text-center" style={footer}>
            //             <p>Copyright © 2019 {APP_DETAILS.APPLICATION_NAME_FOOTER}, All Rights Reserved</p>
                        
            //         </div>
            //     </div>
            //     </Container>
            // </React.Fragment>
        )
    }
}
