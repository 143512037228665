import React, { Component } from 'react';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Auth from '../../../utils/Auth';
import { getAllPermissionList } from '../CommonApiActions';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { node } from 'prop-types';
class Permissions extends React.Component {
    constructor(props) {
        super(props);
        this.state = ({
            checked: [],
            permissions: [],
            expanded: [],
            roletypeid: props.roletypeval,
            roleid : props.roleidval,
            update : this.props.update
        });
       
        //this.setState({role_type_id : props.})
    }
 componentDidMount()
 {
    //if(!this.state.update)
    {
        this.setState({checked : []})
    }
    this.props.getPermissionList(this.state.roletypeid,this.state.roleid);
 }

    componentWillReceiveProps(props) {
        this.setState({ checked:[] })
        
         if(props.roletypeval!= this.state.roletypeid){
           this.props.getPermissionList(props.roletypeval,this.state.roleid);
           //this.state.roletypeid = props.roletypeval;
           this.setState({roletypeid : props.roletypeval });

         }
         
    }

    handleValChange = (check) => {
        //this.setState({ checked:e })
        this.props.onPermissionChange(check);     
    }
    handlePerChange = (e) => {
        this.setState({ checked:e })
        this.props.onPermissionChange(e);     
    }
    render() {
        var Nodedata = {
           nodes : []
        };
        var expand = [];
        var check = [];
        
        const AllPermission = this.props.allPermissions.data;
        if(AllPermission && AllPermission.length > 0)
        {
            AllPermission.map(function(item) {        
                var children = [];
                if(item.isselected)
                {
                    check.push(item.id);
                }
                item.submenu.map(function(childItem) {
                    var subchildren=[];
                    if(childItem.isselected)
                    {
                       check.push(childItem.id);
                    }
                    
                    childItem.submenu.map(function(subchild){
                        if(subchild.isselected)
                        {
                           check.push(subchild.id);
                        }
                        subchildren.push({
                            value : subchild.id,
                            label  : subchild.name, 
                        })
                    });
                    if(childItem.submenu.length > 0){
                        children.push({
                        value : childItem.id,
                        label  : childItem.name, 
                        children : subchildren
                        });
                    }
                    else{
                        children.push({
                            value : childItem.id,
                            label  : childItem.name, 
                            });
                    }

                });

                if(item.submenu.length > 0)
                {
                    expand.push(item.id);
                    Nodedata.nodes.push({ 
                        value : item.id,
                        label  : item.name, 
                        children : children
                    });
                }
                else{
                    Nodedata.nodes.push({ 
                        value : item.id,
                        label  : item.name, 
                    });
                }
             });  
        }
        
        //this.state.expanded=expand;
        if(this.state.checked.length == 0)
        {
          this.state.checked=check;
          this.handleValChange(check);
        }

        //const nodes = Nodedata.nodes;        
        // const nodes = [{
        //     value: 'mars',
        //     label: 'Mars',
        //     children: [
        //         { value: 'phobos', label: 'Phobos' },
        //         { value: 'deimos', label: 'Deimos' },
        //     ],
        // }];

        
        return (
            <CheckboxTree
            
            name="RolePermissions"
                nodes={Nodedata.nodes}
                checked={this.state.checked}
                expanded={this.state.expanded}
                //onCheck={checked => this.setState({ checked })}
                onCheck={this.handlePerChange}
                onExpand={expanded => this.setState({ expanded })}
            />
        );
    }
}

const mapProps = (state) => {
   
    return {
        allPermissions: state.common.allPermissions
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getPermissionList: (role_type_id,role_id) => {
            return new Promise((resolve, reject) => {
                dispatch(getAllPermissionList(role_type_id,role_id))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    };
};

export default connect(mapProps, mapDispatchToProps)(Permissions);
