import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField } from 'formik-material-ui';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Modal, Button } from 'react-bootstrap';
import { updatePricingHistoryComment } from '../../ManagePricingsApiActions';
import RoleDropDown from '../../../Common/components/DropDown/RoleDropDown';
// import AddEditUserSchema from '';
import CommentSchema from './CommentSchema';
class EditPricingHistory extends Component {
    constructor(props) {
        super(props);

        let user = props.update ? props.user : {
            iseditdelete: false,
            id: '',
            modifiedon:'',
            modifieduser:'',
            comment:'',
            createdon:'',
            username:'',
            ctype: '',
            htype: ''
        };
         
        this.state = {
            user: user
        }
    }

    saveUser = (data, formAction) => {

        formAction.setSubmitting(true);
        this.props.saveUser(data, formAction).then(response => {
            this.setState({ message: response.message });
            formAction.setSubmitting(false);
            if (this.props.updateList && typeof this.props.updateList === 'function') {
               
                this.props.updateList();
            }
            this.props.onHide();
            // this.handleClose();
        }, error => {
            
            formAction.setSubmitting(false);
        });

    };

    render() {
      
        let stateData = this.props.user;
        return (

            <React.Fragment>

                <Modal className="userPopup"
                    {...this.props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header color="secondary" closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Edit User comment
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Formik
                            validationSchema={CommentSchema}
                            onSubmit={this.saveUser}
                            initialValues={stateData}
                            enableReinitialize={true}
                        >
                            {({ errors, status, touched, isSubmitting, setFieldValue,initialValues }) => (
                                <Form  noValidate>

                        <div className="row">
                            <div className="col-lg-12 com-field">
                                
                            <Field type="textArea"
                                name="comment"                                
                                placeholder="Comments"
                                component = {TextField}
                                id="standard-required"
                                label="Comments"
                                margin="normal"
                                // defaultValue={stateData.comment}
                                fullWidth
                                />
                            </div>
                            
                        </div>
                        <Modal.Footer className="row" centered>
                        <Button variant="contained" type= "submit"  className="save">Save</Button>
                        <Button variant="contained" className="cancle" onClick={this.props.onHide}>Close</Button>
                    </Modal.Footer>
                        </Form>
                            )}

                        </Formik>

                    </Modal.Body>
                    
                </Modal>
            </React.Fragment>
        )
    }
}

const mapProps = state => ({
})

const mapDispatch = (dispatch, ownProps) => {

    return {
        saveUser: (user, formAction) => {

            return new Promise((resolve, reject) => {
                dispatch(updatePricingHistoryComment(user, formAction)).then(response => {

                    resolve(response);
                    // if (response.status == 200 && !response.data.errorCode) {
                    //     window.Message('Saved Successfully', discount.id > 0 ? `${discount.name} Updated....` : `${discount.name} Created....`);
                    // }
                }).catch(error => {
                    reject(error);
                });
            });
        }
    }
}

export default connect(mapProps, mapDispatch)(EditPricingHistory);
