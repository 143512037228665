
import { GET_USER_MANUFACTURERS_IMAGE } from './AccessPageActions';

const initialState = { userManufacturers: [] };

const accessCalculator = (state = initialState, action) => {
    switch (action.type) {
       case GET_USER_MANUFACTURERS_IMAGE:
            {

                return Object.assign({}, state, {
                    userManufacturers: action.userManufacturers
                });
            } 
        
        default:
            return state;
    }
}

export default accessCalculator;