import React, { Component } from 'react'
import { SafeAnchor, Modal } from 'react-bootstrap';
// import { Formik, Form, Field } from 'formik';
import Button from '@material-ui/core/Button';
import { Field, FieldArray, Form, Formik } from 'formik';
import { FormikTextField } from 'formik-material-fields';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { TextField } from 'formik-material-ui';
import { getKRMaterialAttributes, deleteKRMaterialAttribute, addKRMaterial } from '../../components/ManageAttributeApiAction';
import Icon from '@material-ui/core/Icon';
import swal from 'sweetalert';
import{StyledReactTooltip} from '../ToolTip';
import Swal from 'sweetalert2';

const { Header, Body, Footer, Title } = Modal;

export default class EditKRMaterial extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showform: false,
            manufacturer_name: "",

            krmaterial: [{
                KRMaterialID: 0,
                Material: '',
                Description: '',
                PriceFactor: '',
                PartNumberSuffix: ''
            }
            ]


        };

    }
    componentDidMount() {
        //console.log("didmount");
    }
    componentWillReceiveProps(props) {
        this.setState({ manufacturer_id: this.props.manufacturer_id });

    }


    showForm = () => {
        this.setState({ showform: true })
    }


    hideForm = () => {
        this.setState({ showform: false })
    }
    deletekrmaterial = (option, arrayHelpers, i) => {
        if (option.KRMaterialID == 0) {
            arrayHelpers.remove(i);
        }
            else {
                Swal.fire({
                    //title: 'Are you sure?',
                    text: 'This action will delete the record permanently from database, Are you sure?',
                    // icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Cancel',
                    showCloseButton: true
                  }).then((result) => {
                    if (result.value) {
                    
                        deleteKRMaterialAttribute(option.KRMaterialID).then(resp => {
                                                    let { options } = this.state.krmaterial;
                                                    options =this.state.krmaterial.filter(u => u.KRMaterialID != resp.Data.KRMaterialID);
                                                    // return Object.assign({}, this.state, {
                                                    //     rail: options
                                                    this.setState({
                                                        krmaterial:options,
                                                    })
                                                    arrayHelpers.remove(i);
                                                 });
                      
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                      
                    }
                  })
    
        // swal("This action will delete the record permanently from database, Are you sure?", {
        //     buttons: {
        //         catch: {
        //             text: "Yes",
        //             value: "Yes",
        //         },
        //         cancel: "Cancel",
        //     },
        // })
        //     .then((value) => {
        //         switch (value) {
        //             case "Yes":
        //                     deleteKRMaterialAttribute(option.KRMaterialID).then(resp => {
        //                         let { options } = this.state.krmaterial;
        //                         options =this.state.krmaterial.filter(u => u.KRMaterialID != resp.Data.KRMaterialID);
        //                         // return Object.assign({}, this.state, {
        //                         //     rail: options
        //                         this.setState({
        //                             krmaterial:options,
        //                         })
        //                         arrayHelpers.remove(i);
        //                      });
        //                 break;
        //             default:

        //         }
        //     });
        }
    }
    

    getKRMaterial = () => {

        getKRMaterialAttributes(this.props.manufacturer_id).then(res => {

            this.setState({
                krmaterial: res,
                showform: true
            })

        })
    }
    submitFormula = (data, actions) => {
        let found=false;
        for(let i=0;i<data.krmaterial.length;i++){
            let rname=data.krmaterial[i].Material;
        
        let foundDuplicate=data.krmaterial.filter(r=>r.Material==rname).map(duplicate=>(duplicate.Material ))
            if(foundDuplicate.length>=2)
            {found=true;
                actions.setSubmitting(false)
                swal(rname+" attribute is already exist", {
                    buttons: {
                            cancel: "OK",
                    },
                })
                    .then((cancel) => {
                        switch (cancel) {
                            case "Yes":                        
                                break;
                            default:
                        }
                    });
            } 
        }
        if(found==false){
            actions.setSubmitting(true);
            // let updateOptions = data.options.filter(o => (this.state.updatableOptions.indexOf(o.CalulatorformulaeId) > -1 || o.CalulatorformulaeId == 0));
            let KRMaterialAttributeValues = data.krmaterial
    
            addKRMaterial(this.props.manufacturer_id,
                KRMaterialAttributeValues).then(response => {
    
                    if (response.Status && response.StatusCode === 200) {
                        let { krmaterial } = this.state;
    
                        // options = options.map(o => {
                        //     if (updateOptions.map(oo => oo.CalulatorformulaeId == o.CalulatorformulaeId).length > 0) {
                        //         o = updateOptions.filter(o => o.CalulatorformulaeId === o.CalulatorformulaeId)[0];
                        //     }
                        //     return o;
                        // })
    
                        krmaterial = krmaterial.map(krmaterial => {
                            let option2 = KRMaterialAttributeValues.find(o => o.KRMaterialID == krmaterial.KRMaterialID);
                            return option2 ? { ...krmaterial, ...option2 } : krmaterial;
                        })
    
                        //console.log({ KRMaterialAttributeValues: krmaterial });
                        this.setState({
                            krmaterial
                        })
    
                    }
    
                }, this.hideForm())

        }

       

    }



    render() {

        const manufacturer_id = this.props.manufacturer_id;
        const manufacturer_name = this.props.manufacturer_name;
        return (
            <>
            {!this.state.showform ?<>
                <Icon   data-tip data-for="edit" onClick={this.getKRMaterial}>
                    edit
                 </Icon>
                  <StyledReactTooltip id="edit" effect="solid">
                  <span>Edit</span>
                 </StyledReactTooltip></>
                : <>
                <Modal className="calculatorPopup" show={this.state.showform} onHide={this.hideForm}>
                    <Header closeButton>
                        <Title>{manufacturer_name}</Title>
                    </Header>
                    <Body>

                        <Formik
                            onSubmit={this.submitFormula}
                            initialValues={{ krmaterial: this.state.krmaterial }}
                        >
                            {({ values, status, isSubmitting }) => (
                                <Form className="width-500">
                                    <div className="row">
                                        <div className="col-lg-12 popup-heading">
                                            <label >ATTRIBUTE NAME : KR Material</label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-2 col-3">
                                            <label >Material</label>
                                        </div>
                                        <div className="col-lg-2 col-3">
                                            <label >Description </label>
                                        </div>
                                        <div className="col-lg-3 col-2">
                                            <label >Price Factor </label>
                                        </div>
                                        <div className="col-lg-3 col-2">
                                            <label >Part Number Suffix </label>
                                        </div>
                                    </div>
                                    <FieldArray
                                        name="krmaterial"

                                        render={arrayHelpers => (
                                            <div className="clacfor">

                                                {values.krmaterial && values.krmaterial.length > 0 ? values.krmaterial.map((krmaterial, i) => {

                                                    return (<div className="row" key={i}>
                                                        <div className="col-lg-2  col-3">

                                                            <Field

                                                                name={`krmaterial[${i}.Material]`}
                                                                margin="normal"

                                                                fullWidth

                                                                component={TextField}
                                                            // onChange={() => {
                                                            //     this.onFieldChange(option)
                                                            // }}
                                                            />
                                                            { (!krmaterial.Material || krmaterial.Material.length == 0)
                                                                && this.state.error ?
                                                                <span style={{ color: "red" }}>Enter Material Value</span> : ""}
  </div>
                                                        <div className="col-lg-2 col-3">

                                                            <Field

                                                                name={`krmaterial[${i}.Description]`}
                                                                margin="normal"

                                                                fullWidth

                                                                component={TextField}
                                                            // onChange={() => {
                                                            //     this.onFieldChange(option)
                                                            // }}
                                                            />
                                                             {(!krmaterial.Description || krmaterial.Description.length == 0)
                                                                && this.state.error ?
                                                                <span style={{ color: "red" }}>Enter Description Value</span> : ""}
                                                            </div>
                                                        <div className="col-lg-3 col-2">

                                                            <Field

                                                                name={`krmaterial[${i}.PriceFactor]`}
                                                                margin="normal"

                                                                fullWidth

                                                                component={TextField}
                                                            // onChange={() => {
                                                            //     this.onFieldChange(option)
                                                            // }}
                                                            />
                                                            { (!krmaterial.PriceFactor || krmaterial.PriceFactor.length == 0)
                                                                && this.state.error ?
                                                                <span style={{ color: "red" }}>Enter PriceFactor Value</span> : ""}
                                                            </div>
                                                        <div className="col-lg-3  col-2">

                                                            <Field

                                                                name={`krmaterial[${i}.PartNumberSuffix]`}
                                                                margin="normal"

                                                                fullWidth

                                                                component={TextField}
                                                            // onChange={() => {
                                                            //     this.onFieldChange(option)
                                                            // }}
                                                            />


                                                            {(!krmaterial.Material || krmaterial.Material.length == 0)
                                                                && this.state.error ?
                                                                <span style={{ color: "red" }}>Enter Rail Value</span> : ""}


                                                        </div>

                                                        <SafeAnchor className="btn btn-default">
                                                            <i className="fa fa-minus"
                                                                onClick={() => {
                                                                    this.deletekrmaterial(krmaterial, arrayHelpers, i);
                                                                }} /></SafeAnchor>
                                                        <br />
                                                    </div>);
                                                }) : arrayHelpers.push({ KRMaterialID: 0 })}
                                                <SafeAnchor className="btn btn-default">
                                                    <i className="fa fa-plus" onClick={() => {
                                                        if (values.krmaterial && values.krmaterial.length > 0 && values.krmaterial.filter
                                                            (v => (!v.Material || v.Material.length == 0
                                                                || !v.Description || v.Description.length == 0
                                                                || !v.PriceFactor || v.PriceFactor.length == 0)).length == 0) {
                                                            arrayHelpers.push({ KRMaterialID: 0 })
                                                            this.setState({
                                                                error: false
                                                            })
                                                        } else {
                                                            this.setState({ error: true })
                                                        }

                                                    }} /></SafeAnchor>
                                            </div>
                                        )} />
                                    {/* <input type="submit"  className="submit" disabled={isSubmitting} /> */}
                                    <Modal.Footer className="row" centered>
                                        <Button variant="contained" type="submit" className="save" disabled={isSubmitting} >Submit</Button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Body>
                </Modal>

                </>}
            </>

        )
    }
}