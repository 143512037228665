import React, { Component } from 'react';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { getAllAttributeList } from '../../ManageRolesApiActions';
import { node } from 'prop-types';
class AttributesTree extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = ({
            checked: [],
            attributes: [],
            expanded: [],
            roleid : props.roleId,
            calculatorid : props.calculatorId,
            update : this.props.update
        });
    }
 componentDidMount()
 {
    
    {
        this.setState({checked : []})
    }
    this.props.getAttributeList(this.state.roleid,this.state.calculatorid);
 }

    componentWillReceiveProps(props) {
        this.setState({ checked:[] })
        
         if(props.calculatorId != this.state.calculatorid){
           this.props.getAttributeList(this.props.roleId,props.calculatorId);
           
           this.setState({ calculatorid:props.calculatorId })
         }
         
    }

    handleValChange = (check) => {
        //this.setState({ checked:e })
        this.props.onAttributeChange(check);     
    }
    handlePerChange = (e) => {
        this.setState({ checked:e })
        this.props.onAttributeChange(e);     
    }
    render() {
        var Nodedata = {
           nodes : []
        };
        var expand = [];
        var check = [];
        
        const AllPermission = this.props.allAttributes;
        
        if(AllPermission && AllPermission.length > 0)
        {
            AllPermission.map(function(item) {        
                var children = [];
                if(item.IsSelected)
                {
                    check.push(item.AttributeId);
                }
                
                item.submenu.map(function(childItem) {
                    var subchildren=[];
                    if(childItem.IsSelected)
                    {
                       check.push(childItem.AttributeId);
                    }
                    if(childItem.submenu != null){
                    childItem.submenu.map(function(subchild){
                        if(subchild.IsSelected)
                        {
                           check.push(subchild.AttributeId);
                        }
                        subchildren.push({
                            value : subchild.AttributeId,
                            label  : subchild.DisplayName, 
                        })
                    });
                }
                if(childItem.submenu !=null){
                    if(childItem.submenu.length > 0){
                        children.push({
                        value : childItem.AttributeId,
                        label  : childItem.DisplayName, 
                        children : subchildren
                        });
                    }
                }
                    else{
                        children.push({
                            value : childItem.AttributeId,
                            label  : childItem.DisplayName, 
                            });
                    }

                });

                if(item.submenu.length > 0)
                {
                    expand.push(item.id);
                    Nodedata.nodes.push({ 
                        value : item.AttributeId,
                        label  : item.DisplayName, 
                        children : children
                    });
                }
                else{
                    Nodedata.nodes.push({ 
                        value : item.AttributeId,
                        label  : item.DisplayName, 
                    });
                }
             });  
        }
        
        //this.state.expanded=expand;
        if(this.state.checked.length == 0)
        {
          this.state.checked=check;
          this.handleValChange(check);
        }

        //const nodes = Nodedata.nodes;        
        // const nodes = [{
        //     value: 'mars',
        //     label: 'Mars',
        //     children: [
        //         { value: 'phobos', label: 'Phobos' },
        //         { value: 'deimos', label: 'Deimos' },
        //     ],
        // }];

        
        return (
            <CheckboxTree
            
            name="RoleAttributes"
                nodes={Nodedata.nodes}
                checked={this.state.checked}
                expanded={this.state.expanded}
                //onCheck={checked => this.setState({ checked })}
                onCheck={this.handlePerChange}
                onExpand={expanded => this.setState({ expanded })}
            />
        );
    }
}

const mapProps = (state) => {
    return {
        allAttributes: state.role.allAttributes
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getAttributeList: (roleid,calculatorid) => {
            return new Promise((resolve, reject) => {
                dispatch(getAllAttributeList(roleid,calculatorid))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    };
};

export default connect(mapProps, mapDispatchToProps)(AttributesTree);
