import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAllCountryList } from '../../CommonApiActions';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
class CountryDropDown extends React.Component {
    constructor(props) {
        super(props);

        this.state = ({
            allCountries: []
        })
    }

    componentDidMount() {
        this.props.getCountryList();
    }

    render() {
        const selectedValue = this.props.selectedValue;
        const countries = this.props.allCountries;
        const options = countries && countries.length > 0 ? (
            countries.map((country) => {

                return (<MenuItem value={country.country_id}>
                        {country.country_name}
                        </MenuItem>
                   
                );
            })
        ) : (
                <MenuItem>Loading....</MenuItem>
            );
        //console.log({ options })
        return (

            <Select value={selectedValue}
                labelId="demo-simple-select-label"
                id="demo-simple-select" onChange={this.props.onCountryChange}>
                {options}
            </Select>

        );
    }
}

const mapProps = (state) => {

    return {
        allCountries: state.common.allCountries
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getCountryList: () => {
            return new Promise((resolve, reject) => {
                dispatch(getAllCountryList())
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    };
};

export default connect(mapProps, mapDispatchToProps)(CountryDropDown);
