import React, { Component,Text,TextInput } from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import { TextField } from 'formik-material-ui';
// import { Image, StyleSheet, Text, View, TouchableWithoutFeedback, TextInput } from 'react-native';
import TextField from '@material-ui/core/TextField';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { connect } from 'react-redux';
import { getPartDetailList } from '../../CalculatorOutputApiActions';
import { all } from 'q';
import { SafeAnchor, Modal } from 'react-bootstrap';
import calculatorPartNumberOutputSchema from './PartNumberOutputSchema';
import { Collapse } from '@material-ui/core';
import swal from 'sweetalert';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Auth from '../../../../utils/Auth';
import { APP_DETAILS,SERVER_ACTIONS } from '../../../../utils/Constants';
let auth = new Auth();

class PartNumberForm extends React.Component {
    
    constructor(props) {

        super(props);
        this.textPart = React.createRef();
        this.state = {
            id: props.number,
            partNumber: 0,
            calculatorId: props.calculatorId ? props.calculatorId : 0,
            selectedId: 0,
            allPartDetails: [],
            expanded:'panel0',
            UserName:auth.getUser(),
        }
        //console.log(this.state.id);

    }
    handleChange = panel => (event, isExpanded) => {
        this.setState({
            expanded:isExpanded ? panel : false
        })
      };
      
    ExpandPanel = panel => (event, isExpanded) => {
        this.setState({
            expanded:panel
        })
      };
      registercustomer=()=>{
        auth.setRegisterComponent(true);
        auth.logout();
    }
    getDetails = (partNumber, formAction) => {

        let  data ={}
        data.partNumber= partNumber
        data.calculatorId = this.state.calculatorId;
        this.setState({
            partNumber: partNumber
        })
        formAction.setSubmitting(true);
        getPartDetailList(data).then(response => {
            
            this.setState({ allPartDetails: response });
            formAction.setSubmitting(false);
        }, error => {
            formAction.setSubmitting(false);
            this.setState({ allPartDetails: [] });
        });

    }

    renderTableHeader = (OPParam) => {

        if (OPParam != undefined) {
            return OPParam.map((data) => {

                const { DisplayName } = data;
                return (
                    <TableCell>{DisplayName}</TableCell>
                )
            })
        }
    }


    renderTableData = (OPParam) => {

        if (OPParam != undefined) {
            return OPParam.map((data) => {

                const { DisplayName, Value } = data;
                return (
                    <TableCell>{Value}</TableCell>
                )
            })
        }
    }

    renderSecondTableData = (OPPricing,Message,MessageOne) => {
        if (OPPricing != undefined) {
            //if (Message == "") {
                if (MessageOne == "") {
                    return OPPricing.map((data) => {
                        const { DisplayName, Value } = data;
                        return (
                            <TableCell>{Value}</TableCell>
                        )
                    })
                }
                else {
                    return (<TableCell colSpan={OPPricing.length} style={{ color: "red" }}>{MessageOne}</TableCell>)
                }
            // }
            // else {
            //     return (<TableCell colSpan={OPPricing.length} >{Message}</TableCell>)
            // }

            // return OPPricing.map((data) => {

            //     const { DisplayName, Value } = data;
            //     return (
            //         <TableCell>{Value}</TableCell>
            //     )
            // })
        }
    }

    removethisdiv = (e,div)=>{
        // var pnform = document.getElementsByClassName("Partnumberform");
        // if(pnform.length > 1)
        {
        var element = document.getElementById(div);
        element.parentNode.remove();
        }
        //else
        {
            //swal("One Block is required");
        }
    }
    renderSecondTableHeader = (OPPricing) => {
        if (OPPricing != undefined) {
            return OPPricing.map((data) => {

                const { DisplayName, Value } = data;
                return (
                    <TableCell>{DisplayName}</TableCell>
                )
            })
        }
    }
     
    render() {
        
        let allPartDetails = [];
        allPartDetails = this.state.allPartDetails;
        let OPPricing, OPParam, ImagePath, MessageOne, Message, PartNumber,FormulaError,OPParamLenght

        let partDetailsArray = [];
var LoginLimitExceed = false;
        if (allPartDetails != undefined) {

            // partDetailsArray.push( JSON.stringify(allPartDetails));

            // if(this.state.partNumber== allPartDetails.PartNumber){
            LoginLimitExceed = allPartDetails.LoginLimitExceed;
            OPPricing = allPartDetails.OPPricing;
            OPParam = allPartDetails.OPParam;
            if(OPParam != undefined){
            OPParamLenght = OPParam.length;
            }
            else{
                OPParamLenght = 0;
            }
            ImagePath = allPartDetails.ImagePath;
            
            if(ImagePath==undefined){
                ImagePath =  SERVER_ACTIONS.API + "/Media/OutputImages/IKnowLength/JSH45-Bare-Rail.png"
            }
            MessageOne = allPartDetails.MessageOne;
            Message = allPartDetails.Message;
            PartNumber = allPartDetails.PartNumber;
            FormulaError=allPartDetails.FormulaError;

            // }

        }

        return (

            <Formik
                onSubmit={this.getDetails}
                initialValues={this.state.partNumber}
                enableReinitialize={true}
                //validationSchema={calculatorPartNumberOutputSchema}
            >
                {({ values, status, isSubmitting }) => (
                    <Form className="Partnumberform">
                        
                        <div id={"Remove"+this.state.id} className="dotted-border">
                        <ExpansionPanel className="sm-center" expanded={this.state.expanded === 'panel'+this.state.id} onChange={this.handleChange('panel'+this.state.id)}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >

                            <div onClick={event => event.stopPropagation()} className="part-number-form">
                                {/* {this.state.id + ")" + " "} */}
                                <label className="text-left">
                                    Enter Part Number <span>*</span>

                                    <FormControl onClick={event => event.stopPropagation()} className="part-number" contentEditable={false} margin="normal" fullWidth>

                                        <TextField
                                            // label="Part Number"
                                            required
                                            margin="normal"
                                            type="text"
                                            id="standard-required"
                                            name="partNumber"
                                            placeholder="Part Number"
                                            inputRef={(input) =>  this.textPart = input}
                                                  
                                            // component={TextField}
                                            
                                            onChange={e => {  
                                               
                                                var inputPartNumber = e.target.value.replace(/\s+/g, '');
                                              
                                                this.setState({
                                                   // partNumber: e.target.value,
                                                   partNumber:inputPartNumber,
                                                    selectedId: this.state.id,
                                                })
                                                this.textPart.value=inputPartNumber;
                                            }}
                                            onClick={event => event.stopPropagation()}
                                            fullWidth
                                        />
                                    </FormControl>

                                </label>
                                <div className="sm-center">
                                <Button color="primary" type="submit" className="submit-btn" 
                                    onClick={event => {event.stopPropagation()},this.ExpandPanel('panel'+this.state.id)}>
                                    Get Details
                                </Button>
                                </div>
                            </div>
                            
                            {/* <SafeAnchor className="btn btn-default float-right"><i className="fa fa-minus" 
                            
                            onClick={(e) => {
                                                             this.removethisdiv(e,"Remove"+this.state.id);
                                                        }}
                                                         /></SafeAnchor> */}

                           
                   
                       </ExpansionPanelSummary>
                      
                       
                       <ExpansionPanelDetails>
                      
                      { (OPParamLenght>0)?( <table className="responsiveTable"><tr><td className="float-left">Bellows Parameters</td><td className="float-right">Part No.:{PartNumber}</td></tr></table> )
                    :(<></>)    }   
                            <div className="responsiveTable">
                                {(OPParamLenght>0)?(
                                <Table>
                                    <TableHead className="grayheader">

                                        {this.renderTableHeader(OPParam)}


                                    </TableHead>
                                    <TableRow>


                                        {this.renderTableData(OPParam)}
                                    </TableRow>
                                    <TableBody>

                                    </TableBody>
                                </Table>
                ):(<></>)}
                                <label style={{color: "red"}}>{FormulaError}</label> 
                                <Table>
                                    <TableHead className="grayheader">

                                        {this.renderSecondTableHeader(OPPricing)}
                                    </TableHead>
                                    <TableRow>
                                        {this.renderSecondTableData(OPPricing,Message,MessageOne)}
                                    </TableRow>
                                    <TableBody>

                                    </TableBody>
                                </Table>
                                {(LoginLimitExceed == false || LoginLimitExceed == undefined)?(<></>):
//(<p style={{color: "red"}}>  Dear {this.state.UserName}, your guest login count limit is exceeded, to get know about the pricing of specific bellows, please <a onClick={this.registercustomer} className="register" style={{"color":"white"}}>register</a>  or contact on <a href="tel:800-650-2015">800-650-2015</a></p>)
(<p style={{color: "red"}}>  Dear {this.state.UserName}, Are you a Distributor or Manf. Rep.? Become an Authorized User online
or call <a href={`tel:${APP_DETAILS.CONTACT_NUMBER}`}>{APP_DETAILS.CONTACT_NUMBER}</a>.<br></br>
Authorized Users receive multi level pricing, priority shipping and more.To Apply: <a onClick={this.registercustomer} className="register" style={{"color":"white"}}>Register Here</a> </p>)
}
                            </div>
                            <div className="calc-image">
                                    <div hidden id="PartNumber">{PartNumber}</div>
                                    <img className="img-calculator"  src={ImagePath}  alt="output-image"  />
                                </div>
                               </ExpansionPanelDetails>
                               </ExpansionPanel>
                       
                        </div>
                    </Form>
                )}
            </Formik>
        )
    }
}



// const mapProps = (state) => {

//     return {
//         allPartDetails: state.calculatorOutput.allPartDetails
//     }
// };

// const mapDispatchToProps = (dispatch, ownProps) => {
//     return {

//         getPartDetails: (data) => {
//             return new Promise((resolve, reject) => {
//                 dispatch(getPartDetailList(data))
//                     .then((response) => {
//                         resolve(response);
//                     })
//                     .catch((error) => {
//                         reject(error);
//                     });
//             });
//         }
//     };
// };

// export default connect(mapProps, mapDispatchToProps)(PartNumberForm);
export default PartNumberForm;