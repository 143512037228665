import React, { Component } from 'react';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { getBlockBySizeManufacturerList } from '../../CommonApiActions';
import { Field, FieldArray, Form, Formik } from 'formik';
export default class BlockBySizeManufacturerDropDown extends React.Component {
    constructor(props) {
        super(props);
        //console.log(this.props.manufacturerId);
        this.state = ({
            allBlocks: [],
            manufacturerId: this.props.manufacturerId,
            sizeId:this.props.sizeId,
            prevSizeId:0,
            Isloaded:false

        })
    }

    componentWillReceiveProps(props) {

        if (props.manufacturerId != undefined && props.sizeId !=undefined && this.props.sizeId!= this.state.prevSizeId && this.state.Isloaded==false) {
            this.getBlockBySizeManufacturerList(this.props.sizeId);
            // this.state.manufacturerId = props.manufacturerId;
            // this.state.sizeId=props.sizeId;
            this.setState({prevSizeId:this.props.sizeId})
        }
    }

    componentDidMount() {
        //this.props.getBellowList(this.state.railId);
    }
    getBlockBySizeManufacturerList = (sizeId) =>  {
        sizeId = sizeId == undefined ? 0 : sizeId;
        var manufacturerId = this.state.manufacturerId == undefined ? 0 : this.state.manufacturerId;
            const query='?ManufacturerId='+manufacturerId+'&SizeId='+sizeId;          
            getBlockBySizeManufacturerList(query).then(res => {
               
                this.setState({
    
                    
                    allBlocks:res,
                   
                   
                })
                
            })
        }

    render() {
        const selectedValue = this.props.selectedValue;
        // const selectedName = this.props.selectedName;
        
        //console.log(selectedValue);
        const types = this.state.allBlocks;
        const options = types && types.length > 0 ? (
            types.map((type) => {

                return (<MenuItem name={type.BlockType} value={type.BlockID}>
                        {type.BlockType}
                        </MenuItem>
                );
            })
        ) : (
                <MenuItem>Loading....</MenuItem>
            );
        //console.log({ options });
        return (

            <Select value={selectedValue}
                // name= {selectedName}
                labelId="demo-simple-select-label"
                id="demo-simple-select" onChange={this.props.onTypeChange}>
                {options}
            </Select>
        );
    }

}


