export const UPDATE_USER = 'UPDATE_USER';
export const ADD_USER_TO_LIST = 'ADD_USER_TO_LIST';
export const UPDATE_USER_IN_LIST = 'UPDATE_USER_IN_LIST';
export const DELETE_USER_FROM_LIST = 'DELETE_USER_FROM_LIST';

export function updateUsers(user, totalRecords, PageNumber) {
    return {
        type: UPDATE_USER,
        user,
        totalRecords,
        PageNumber
    }
}

export function addUserToList(user) {
    return {
        type: ADD_USER_TO_LIST,
        user
    }
}

export function updateUserInList(user) {
    return {
        type: UPDATE_USER_IN_LIST,
        user
    }
}

export function deleteUserFromListItem(user) {
    return {
        type: DELETE_USER_FROM_LIST,
        user
    }
}

