import * as Yup from "yup";
import { REGEX, REGEX_ERROR } from "../../../../utils/Constants";

const RoleFormSchema = Yup.object().shape({

        role_name: Yup.string()
        .matches(REGEX.ALPHANUMERIC_WITH_SPACE, REGEX_ERROR.ALPHANUMERIC_WITH_SPACE_ERROR)
        .required('Please Enter Role Name'),
                
});

export default RoleFormSchema;