import React, { Component } from 'react'
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import './calculator.css';
import calculatorimg1 from '../../../../../src/images/calculator-img1.jpg';
import partnumbercodinimg from '../../../../../src/images/part-number-coding2.0.png';
import Container from 'react-bootstrap/Container';
import { Field, FieldArray, Form, Formik } from 'formik';
import { SafeAnchor, Modal } from 'react-bootstrap';
import PartNumberForm from './PartNumberForm';
import Auth from '../../../../utils/Auth';

let auth = new Auth();
export default class Partnumbercalculator extends Component {
    
    test=()=>{
    //console.debug("");
    }

    constructor(props){

        super(props);
        this.state = {
            noOfClick: 1,
            calculatorId: auth.getCalculatorId()
        }
    }

    handleAddMoreButtonClick = (event) =>{
        this.setState({
            noOfClick: this.state.noOfClick + 1
        })
        // event.preventDefault();
        // debugger
        // var e = document.createElement('div');
        // e.innerHTML = document.getElementById("TestForm").innerHTML;
        // var node = document.getElementById("displayForm");
        // node.appendChild(this.renderForms());

    }

    renderForms = () => {
        return (
            <PartNumberForm id={this.state.noOfClick}/>
        )
    }

    render() {
        const children = [];

        for (var i = 0; i < this.state.noOfClick; i += 1) {
          children.push(<PartNumberForm key={i} number={i} calculatorId={this.state.calculatorId} />);
}
        return (
            <React.Fragment>
                <Container  id="PrintDiv">
                <div className="row  pb-30">
                    <div className="col-lg-3 col-md-4 cal-sidebar">
                        <Card className="part-number-coding">
                            <CardContent>
                                <div className="parameter dotted-border">
                                    <div className="row">
                                        <p className="calculator-headings">Part Number Coding</p>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <img className="partnumbercodinimg" src={partnumbercodinimg} />

                                        </div>

                                    </div>
                                </div>
                            </CardContent>
                        </Card>

                    </div>
                    <div className="col-lg-9 col-md-8 calculator-rightside">

                        <Card>
                            <CardContent>
                                                        
                                                                  {children}

                                                        <div className="pt-10 text-right">

                                                       <SafeAnchor className="btn btn-default"><i className="fa fa-plus" onClick={(e) => {
                                                             this.handleAddMoreButtonClick(e);
                                                        }} /></SafeAnchor>

                                                      {/* <button style={{marginBottom:10}} className="add-more-btn" onClick={e=>{
                                               
                                               this.handleAddMoreButtonClick(e);
                                             }
                                             }>Add More</button> */}
                                                    </div>
                                                        {/* <div id="displayForm" >
                                                            <div id="TestForm">
                                                        <PartNumberForm id={this.state.noOfClick}/>
                                                        </div>
                                                        </div> */}
                                                    
                            </CardContent>
                        </Card>
                    </div>
                </div>
                </Container>
            </React.Fragment>
        )
    }
}
