export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const ADD_CUSTOMER_TO_LIST = 'ADD_CUSTOMER_TO_LIST';
export const UPDATE_CUSTOMER_IN_LIST = 'UPDATE_CUSTOMER_IN_LIST';
export const DELETE_CUSTOMER_FROM_LIST = 'DELETE_CUSTOMER_FROM_LIST';

export function updateCustomers(customer, totalRecords, PageNumber) {
    return {
        type: UPDATE_CUSTOMER,
        customer,
        totalRecords,
        PageNumber
    }
}

export function addCustomerToList(customer) {
    return {
        type: ADD_CUSTOMER_TO_LIST,
        customer
    }
}

export function updateCustomerInList(customer) {
    return {
        type: UPDATE_CUSTOMER_IN_LIST,
        customer
    }
}

export function deleteCustomerFromListItem(customer) {
    return {
        type: DELETE_CUSTOMER_FROM_LIST,
        customer
    }
}

