import * as Yup from "yup";
import { REGEX, REGEX_ERROR } from "../../../../utils/Constants";

const registerCustomerSchema = Yup.object().shape({
    first_name: Yup.string()
    .matches(REGEX.ALPHABETS_WITH_SPACE, REGEX_ERROR.ALPHABETS_WITH_SPACE_ERROR)
    .required('Please Enter First name'),

    last_name: Yup.string().nullable()
    .matches(REGEX.ALPHABETS_WITH_SPACE, REGEX_ERROR.ALPHABETS_WITH_SPACE_ERROR)
    .required('Please Enter Last name'),

    email: Yup.string()
    .matches(REGEX.EMAIL, REGEX_ERROR.EMAIL_ERROR)
    .required('Please Enter Email'),

    country: Yup.string()
    .required('Please select country'),
    
    password: Yup.string()
    //.matches(REGEX.PASSWORD, REGEX_ERROR.PASSWORD_ERROR)
    .required('Please Enter Password'),

    // newPassword: Yup.string()
    // .matches(REGEX.PASSWORD, REGEX_ERROR.PASSWORD_ERROR)
    // .required('Requires'),

    confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], "Password don't match")
    .required('Please Enter Confirm Password'),

    contact_no: Yup.string()
    .matches(REGEX.PHONE,REGEX_ERROR.PHONE_ERROR)
    .required('Please Enter Phone Number'),

    company_name: Yup.string()
    .matches(REGEX.ALPHANUMERIC_WITH_SPACE, REGEX_ERROR.ALPHANUMERIC_WITH_SPACE_ERROR)
    .required('Please Enter Company Name'),

    //company_type: Yup.string()
    //.matches(REGEX.WHOLE_NUMBER, REGEX_ERROR.WHOLE_OR_DECIMAL_ERROR)
    //.required("Please Select Company Type"),
    company_type: Yup.string()
    .required("Please select company Role"),

    address: Yup.string()
    .matches(REGEX.ALPHANUMERIC_WITH_SPACE, REGEX_ERROR.ALPHANUMERIC_WITH_SPACE_ERROR)
    .required("Please Enter Address"),

    state: Yup.string()
    .required("Please select state"),

    // country: Yup.string()
    // .matches(REGEX.WHOLE_OR_DECIMAL_ERROR, REGEX_ERROR.WHOLE_OR_DECIMAL_ERROR)
    // .required("Required"),

    postal_code: Yup.string()
    .matches(REGEX.WHOLE_NUMBER, REGEX_ERROR.WHOLE_OR_DECIMAL_ERROR)
    .max(6)
    .min(5)
    .required("Please Enter Postal Code"),

    custtype: Yup.string()
    .required("Please select user type"),

});

export default registerCustomerSchema;