import React, { Component } from 'react'
import { SafeAnchor, Modal } from 'react-bootstrap';
// import { Formik, Form, Field } from 'formik';
import Button from '@material-ui/core/Button';
import { Field, FieldArray, Form, Formik } from 'formik';
import { FormikTextField } from 'formik-material-fields';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { TextField } from 'formik-material-ui';
import { getBellowAttributes, deleteBellowAttribute, addBellow } from '../../components/ManageAttributeApiAction';
import Icon from '@material-ui/core/Icon';
import {StyledReactTooltip} from '../ToolTip';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
const { Header, Body, Footer, Title } = Modal;

export default class EditBellow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showform: false,
            manufacturer_name: "",

            bellow: [{
                BellowId: 0,
                Is_New:true,
                BellowValue: '',
                DefaultValue: ''
            }
            ]


        };

    }
    componentDidMount() {
        //console.log("didmount");
    }
    componentWillReceiveProps(props) {
        this.setState({ manufacturer_id: this.props.manufacturer_id });

    }


    showForm = () => {
        this.setState({ showform: true })
    }


    hideForm = () => {
        this.setState({ showform: false })
    }
    deletebellow = (option, arrayHelpers, i) => {
        if (option.BellowId == 0) {
            arrayHelpers.remove(i);
        }
            else {

                Swal.fire({
                    //title: 'Are you sure?',
                    text: 'This action will delete the record permanently from database, Are you sure?',
                    // icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Cancel',
                    showCloseButton: true
                  }).then((result) => {
                    if (result.value) {
                    
                        deleteBellowAttribute(option.BellowId).then(resp => {
                                                   // console.log({ response: resp.Data, list: this.state.bellow })
                                                     arrayHelpers.remove(i);
                                                });
                      
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                      
                    }
                  })
    
        // swal("This action will delete the record permanently from database, Are you sure?", {
        //     buttons: {
        //         catch: {
        //             text: "Yes",
        //             value: "Yes",
        //         },
        //         cancel: "Cancel",
        //     },
        // })
        //     .then((value) => {
        //         switch (value) {
        //             case "Yes":
                   
        //                     deleteBellowAttribute(option.BellowId).then(resp => {
        //                        // console.log({ response: resp.Data, list: this.state.bellow })
        //                          arrayHelpers.remove(i);
        //                     });
                        
        //                 break;
        //             default:

        //         }
        //     });
        }
        }
    

    getBellow = () => {

        getBellowAttributes(this.props.manufacturer_id).then(res => {

            this.setState({

                bellow: res,
                showform: true
            })

        })
    }
    submitFormula = (data, actions) => {
        
        let found=false;
for(let i=0;i<data.bellow.length;i++){
    let rname=data.bellow[i].BellowValue;

let foundDuplicate=data.bellow.filter(r=>r.BellowValue==rname).map(duplicate=>(duplicate.BellowValue ))
    if(foundDuplicate.length>=2)
    {found=true;
        actions.setSubmitting(false)
        swal(rname+" attribute is already exist", {
            buttons: {
                    cancel: "OK",
            },
        })
            .then((cancel) => {
                switch (cancel) {
                    case "Yes":                        
                        break;
                    default:
                }
            });
    } 
}

if(found==false){
    actions.setSubmitting(true);
    // let updateOptions = data.options.filter(o => (this.state.updatableOptions.indexOf(o.CalulatorformulaeId) > -1 || o.CalulatorformulaeId == 0));
    let bellowAttributeValueModel = data.bellow

    addBellow(this.props.manufacturer_id,
        bellowAttributeValueModel).then(response => {

            if (response.Status && response.StatusCode === 200) {
                let { bellow } = this.state;

                bellow = bellow.map(bellow => {
                    let option2 = bellowAttributeValueModel.find(o => o.BellowId == bellow.BellowId);
                    return option2 ? { ...bellow, ...option2 } : bellow;
                })
                this.setState({
                    bellow
                })

            }

        }, this.hideForm())

}

       

    }



    render() {

        const manufacturer_id = this.props.manufacturer_id;
        const manufacturer_name = this.props.manufacturer_name;
        return (
            <>
            {!this.state.showform ?<>

                <Icon data-tip data-for="edit" onClick={this.getBellow}>
                    edit
                   </Icon>
                    <StyledReactTooltip id="edit" effect="solid">
                    <span>Edit</span>
                   </StyledReactTooltip></>
                : <>
                <Modal className="calculatorPopup" show={this.state.showform} onHide={this.hideForm}>
                    <Header closeButton>
                        <Title>{manufacturer_name}</Title>
                    </Header>
                    <Body>

                        <Formik
                            onSubmit={this.submitFormula}
                            initialValues={{ bellow: this.state.bellow }}
                        >
                            {({ values, status, isSubmitting }) => (
                                <Form>
                                    <div className="row">
                                        <div className="col-lg-12 popup-heading">
                                            <label >ATTRIBUTE NAME : BELLOWS</label>

                                        </div>


                                        <div className="col-lg-6 col-6">
                                            <label >Bellows </label>
                                        </div>
                                        <div className="col-lg-2 col-2">
                                            <label >Is New </label>
                                        </div>
                                    </div>
                                    <FieldArray
                                        name="bellow"

                                        render={arrayHelpers => (
                                            <div className="clacfor">

                                                {values.bellow && values.bellow.length > 0 ? values.bellow.map((bellow, i) => {
                                                    //console.log({ bellow: bellow })


                                                    return (<div className="row" key={i}>
                                                        <div className="col-lg-6 col-6">

                                                            <Field

                                                                name={`bellow[${i}.BellowValue]`}
                                                                margin="normal"

                                                                fullWidth

                                                                component={TextField}
                                                            // onChange={() => {
                                                            //     this.onFieldChange(option)
                                                            // }}
                                                            />
                                                            {(!bellow.BellowValue || bellow.BellowValue.length == 0) && this.state.error ?
                                                                <span style={{ color: "red" }}>Enter Bellows Value</span> : ""}


                                                        </div>

                                                        <div className="col-lg-2 col-2">
                                                        <FormControl class="FormulaDropdown">
                                                                    <Field style={{width:60}} component="select" labelId="demo-controlled-open-select-label"
                                                                            id="demo-controlled-open-select" key={i}
                                                                            defaultValue={true}
                                                                            name={`bellow[${i}].Is_New`}
                                                                        >
                                                                            <option value={true} >Yes</option>
                                                                            <option value={false}>No</option>
                                                                        </Field>
                                                                    </FormControl>
                                                            {/* <Field
                                                                name={`bellow[${i}.Is_New]`}
                                                                margin="normal"

                                                                fullWidth

                                                                component={TextField}
                                                            /> */}
                                                            
                                                        </div>



                                                        <SafeAnchor className="btn btn-default">
                                                            <i className="fa fa-minus" onClick={() => { this.deletebellow(bellow, arrayHelpers, i) }} /></SafeAnchor>
                                                        <br />
                                                    </div>);
                                                }) : arrayHelpers.push({ BellowId: 0,Is_New:true })}
                                                <SafeAnchor className="btn btn-default"><i className="fa fa-plus" onClick={() => {

                                                    if (values.bellow && values.bellow.length > 0 && values.bellow.filter(v => (!v.BellowValue || v.BellowValue.length == 0)).length == 0) {
                                                        arrayHelpers.push({ BellowId: 0,Is_New:true })
                                                        this.setState({
                                                            error: false
                                                        })
                                                    } else {
                                                        this.setState({ error: true })
                                                    }



                                                }} /></SafeAnchor>
                                            </div>
                                        )} />
                                    {/* <input type="submit"  className="submit" disabled={isSubmitting} /> */}
                                    <Modal.Footer className="row" centered>
                                        <Button variant="contained" type="submit" className="save" disabled={isSubmitting} >Submit</Button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Body>
                </Modal>

                </>}
            </>

        )
    }
}