import React, { Component } from 'react'
import { Nav, Navbar, NavDropdown, Container } from 'react-bootstrap';

export default class Header extends Component {
    render() {
       
        const navbarshadow = {
            boxShadow: '0 8px 6px -6px #999',

        }
 
        return (
           
            <React.Fragment>
                
                <Navbar collapseOnSelect expand="lg" bg="white" fixed="top" style={navbarshadow}>
                    <div className="MuiContainer-root MuiContainer-maxWidthLg">
                  
                    {(window.location.hash=='#/signin')?<>
                    <Navbar.Brand href="/#/signin"><img style={{'width':'200px'}} className="img-responsive" src={this.props.Logo} alt="logo"/></Navbar.Brand>
                    </>:
                    <>
                    <Navbar.Brand href="/#/admin/signin"><img style={{'width':'200px'}} className="img-responsive" src={this.props.Logo} alt="logo"/></Navbar.Brand>

                    </>}
                   
                    <h3 className="bellows-cal-hed">BellowsCalculator</h3>
                    {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                    </Navbar.Collapse> */}
                    </div>
                    </Navbar>
                    
            </React.Fragment>
        )
    }
}