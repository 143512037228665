import React, { Component } from 'react'
import { Field, Formik, Form, FieldArray } from 'formik'
// import { SafeAnchor } from 'react-bootstrap'
// import { runInThisContext } from 'vm'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import ManufacturerDropDown from '../../../Common/components/DropDown/ManufacturerDropDown';
import './ManageAttribute.css';
import {getAttributeList,getRailsAttributes,getRailBellowAttributes} from '../ManageAttributeApiAction';
import { connect } from 'react-redux';
import EditRails from './EditRails';
import EditBellow from './EditBellow';
import EditSize from './EditSize';
import EditBlock from './EditBlock';
import EditAccessory from './EditAccessory';
import EditMaterial from './EditMaterial';
import EditRailBellow from './EditRailBellow';
import EditKRSeries from './EditKRSeries';
import EditKRMaterial from './EditKRMaterial';
import EditKRRailLength from'./EditKRRailLength';
import EditRailBlock from './EditRailBlock';
import EditKRSeriesMaterial from './EditKRSeriesMaterial';
import EditSizeBlock from './EditSizeBlock';
import EditSizeAccessory from './EditSizeAccessory';
import EditLength from './EditLength';
import EditBellowsMapping from './EditBellowsMapping'
import {getAllManufacturerList} from '../ManageAttribute/EditDropDownListApi';


 class ManageAttribute extends Component{
            constructor(props){
                super(props);
                this.state={
                    pageheading: 'MANAGE ATTRIBUTES',
                   mappingheadding:'MAPPING ATTRIBUTES',
                    manufacturer_id:0,
                    manufacturer_name:"",
                    prevmanufacturer_id:0,
                    prevmanufacturer2_id:0,
                    manufacturerList:[],
                    data:{},
                    rails:{},
                    railBellows:[],
                   
                   
                }
            }

            componentDidMount(){
                 //document.removeEventListener("mousemove","",false); 
                this.getManufacturerList();
               

            }
            componentDidUpdate(){
                const manufacturerValue=this.state.manufacturerList[0]!=undefined?(this.state.manufacturerList[0]):(0);
              
                if(this.state.prevmanufacturer2_id!=manufacturerValue.manufacturer_id)
               {
                //    this.setId(sizeValue.SizeId);
                   this.setState({manufacturer_id:manufacturerValue.manufacturer_id,manufacturer_name:manufacturerValue.display_name});
                   if(this.state.manufacturer_id!=undefined){
                    this.getAttribute(this.state.manufacturer_id);
                   }
                   
                  this.setState({prevmanufacturer2_id:manufacturerValue.manufacturer_id});
                
               }
                if(this.state.prevmanufacturer_id!==this.state.manufacturer_id){
                
                   if(this.state.manufacturer_id!=undefined){
                    this.getAttribute(this.state.manufacturer_id);
                   }
                   
                    this.setState({prevmanufacturer_id:this.state.manufacturer_id})
                }
               
            }
            
            getManufacturerList= () => {
                
                getAllManufacturerList().then(resp => {
                    this.setState({
                        manufacturerList:resp,
                        manufacturer_id:resp[0].manufacturer_id,
                        manufacturer_name:resp[0].name
                    });
    
                });
            }
           
           


            getAttribute = (data) => {
                
                this.props.getAttribute(data).then(resp => {
                    
                    this.setState({

                        data:resp
                    });
    
                }).catch(error => {
                    
                    window.location.href="#/page403"
                    //window.location.reload();
                    });
            }
           

            renderChildComponent=(attribute)=>{

                switch(attribute.AttributeName) {
                    case "Rail":
                        return(
                            <EditRails manufacturer_name={this.state.manufacturer_name} manufacturer_id={this.state.manufacturer_id} ></EditRails>
                        )
                    case "Bellows":
                        return(
                            <EditBellow manufacturer_name={this.state.manufacturer_name} manufacturer_id={this.state.manufacturer_id} ></EditBellow>
                            )
                    case "Size":
                            return(
                                    <EditSize manufacturer_name={this.state.manufacturer_name} manufacturer_id={this.state.manufacturer_id} ></EditSize>
                                    )
                    case "Block":
                            return(
                                    <EditBlock manufacturer_name={this.state.manufacturer_name} manufacturer_id={this.state.manufacturer_id} ></EditBlock>
                                    )
                    case "Accessory":
                            return(
                                    <EditAccessory manufacturer_name={this.state.manufacturer_name} manufacturer_id={this.state.manufacturer_id} ></EditAccessory>
                                    )

                    case "Material":
                            return(
                                    <EditMaterial manufacturer_name={this.state.manufacturer_name} manufacturer_id={this.state.manufacturer_id} ></EditMaterial>
                                    )
                       
                    case "Rail Bellows":
                            return(
                                    <EditRailBellow rail_id_default={0} manufacturer_name={this.state.manufacturer_name} manufacturer_id={this.state.manufacturer_id}  ></EditRailBellow>
                                    )
                    case "KR Series":
                            return(
                                    <EditKRSeries  manufacturer_name={this.state.manufacturer_name} manufacturer_id={this.state.manufacturer_id} ></EditKRSeries>
                                    )

                    case "KR Material":
                            return(
                                    <EditKRMaterial  manufacturer_name={this.state.manufacturer_name} manufacturer_id={this.state.manufacturer_id} ></EditKRMaterial>
                                    )
                                   
                     case "KR Rail Length":
                             return(
                                        <EditKRRailLength  manufacturer_name={this.state.manufacturer_name} manufacturer_id={this.state.manufacturer_id} ></EditKRRailLength>
                                     )

                     case "Rail Block":
                             return(
                                        <EditRailBlock rail_id_default={0}  manufacturer_name={this.state.manufacturer_name} manufacturer_id={this.state.manufacturer_id}  ></EditRailBlock>
                                     )
                               
                     case "KR Series Material":
                             return(
                                        <EditKRSeriesMaterial rail_id_default={0} manufacturer_name={this.state.manufacturer_name} manufacturer_id={this.state.manufacturer_id}  ></EditKRSeriesMaterial>
                                     ) 
                     case "Size Block":
                             return(
                                        <EditSizeBlock rail_id_default={0} manufacturer_name={this.state.manufacturer_name} manufacturer_id={this.state.manufacturer_id}  ></EditSizeBlock>
                                    )
                     case "Size Accessory":
                             return(
                                        <EditSizeAccessory rail_id_default={0} manufacturer_name={this.state.manufacturer_name} manufacturer_id={this.state.manufacturer_id}  ></EditSizeAccessory>
                                    )  
                     case "Length":
                             return(
                                        <EditLength rail_id_default={0} manufacturer_name={this.state.manufacturer_name} manufacturer_id={this.state.manufacturer_id}  ></EditLength>
                                    )                           
                                                                        
                                               
                       
                    default:
                        return(
                            <EditBellowsMapping rail_id_default={0} manufacturer_name={this.state.manufacturer_name} manufacturer_id={this.state.manufacturer_id}  ></EditBellowsMapping>
                        )  
                      // code block
                  }
            
            }
           

            renderTableData = () => {

                const adduser = {
                    clear: 'both',
                    float: 'right',
                    borderRadius: '20px',
                    background: '#01524B',
                    color: '#fff',
                    fontWeight: 'normal'
                }
                if (this.state.data && this.state.data != undefined && this.state.data.length > 0) {
        
                    return this.state.data.map((data, index) => {
                        const {AttributeName,Is_Mapping  } = data //destructuring
                        if(Is_Mapping==false){
                            return (        
        
                                <TableRow key={index}>
                                         
            
                                    <TableCell>{AttributeName}</TableCell>
                                 
                                    <TableCell>
                                        <> 
                                        {this.renderChildComponent({AttributeName})}
                                                                             
                                        </>                            
                                        
            
                                    </TableCell>
            
            
                                </TableRow>
            
                            )

                        }
        
        
                        
                    })
                }
            }


            renderMappingTableData = () => {
                const adduser = {
                    clear: 'both',
                    float: 'right',
                    borderRadius: '20px',
                    background: '#01524B',
                    color: '#fff',
                    fontWeight: 'normal'
                }
                if (this.state.data && this.state.data != undefined && this.state.data.length > 0) {
        
                    return this.state.data.map((data, index) => {
                        const {AttributeName, Is_Mapping } = data //destructuring
                        if(Is_Mapping==true){
                            return (        
        
                                <TableRow key={index}>
                                         
            
                                    <TableCell>{AttributeName}</TableCell>
                                 
                                    <TableCell>
                                        <> 
                                        {this.renderChildComponent({AttributeName})}
                                                                             
                                        </>                            
                                        
            
                                    </TableCell>
            
            
                                </TableRow>
            
                            )

                        }
        
        
                       
                    })
                }
            }


            render(){
                const tableGray = {
                    background: '#f1f3f6',
        
                }
                const mtt = {
                    marginTop: '20px'
                }

                const actioncolwidth = {
                    width:'100px'
                }
                
                return(
                    
                    <React.Fragment>
                        <div className="row">
                    <div className="col-lg-6" style={{padding:15}}>
                        <h2 style={{ 'clear': 'both', 'display': 'inline' }} className="heading">{this.state.pageheading}</h2>
                    </div>
                    
                    <div className="col-lg-6">
                    <Formik
                        onSubmit={this.props.editCalculator}
                        initialValues=''

                    >

                        {({ status, isSubmitting, setFieldValue }) => (
                            <Form className="float-right Manufacturerform" name="manageCalculator" noValidate autoComplete="off">
                               
                                    <FormControl  margin="normal" fullWidth>
                                    <div className="ManufacturerDropDown ">
                                        <InputLabel id="demo-simple-select-label">MANUFACTURER*</InputLabel>
                                        
                                        <ManufacturerDropDown selectedValue={this.state.manufacturer_id}  enabled={true} activeOnly={true} onManufacturerChange={(e, index) => {
                                            setFieldValue("manufacturer_list", e.target.value);
                                            setFieldValue("manufacturer_name", e.target.name);

                                           
                                            this.setState({
                                                manufacturer_id: e.target.value,
                                                manufacturer_name: index.props.name
                                            });
                                            
                                        }
                                        } />
                                        </div>

                                    </FormControl>
                               


                            </Form>
                            


                        )
                        }
                    </Formik>
                    </div>
                    </div>
                       <Table style={mtt} className="table-striped">
                    <TableHead style={tableGray}>
                        <TableRow>
                            {
                                <>
                                    <TableCell >Attributes name</TableCell>
                                    
                                    <TableCell style={actioncolwidth} >Action</TableCell>
                                   
                                </>

                            }

                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            this.renderTableData()
                        }
                    </TableBody>

                </Table>
                

                <div className="row">
                    <div className="col-lg-6">
                        <br/>
                        <h2 style={{ 'clear': 'both', 'display': 'inline' }} className="heading">{this.state.mappingheadding}</h2>
                    </div>
                    <div className="col-lg-4"></div>
                   
                    </div>
                       <Table style={mtt} className="table-striped">
                    <TableHead style={tableGray}>
                        <TableRow>
                            {
                                <>
                                    <TableCell >Mapping Attributes</TableCell>
                                    
                                    <TableCell  style={actioncolwidth}>Action</TableCell>
                                   
                                </>

                            }

                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            this.renderMappingTableData()
                        }
                    </TableBody>

                </Table>

                     </React.Fragment>

                )
            }

 }
 const mapStateToProps = state => {
    return {
        data: state.data
      

    }
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      
        getAttribute: (data) => {
            
                return new Promise((resolve, reject) => {
                    dispatch(getAttributeList(data)).then(response => {
                       
                        resolve(response);
                    }).catch(error => {
                        reject(error);
                    });
                });
            },

           

}
}
ManageAttribute = connect(mapStateToProps, mapDispatchToProps)(ManageAttribute);

export default ManageAttribute




// import React, { Component } from 'react'
// import { Field, Formik, Form, FieldArray } from 'formik'
// import { SafeAnchor } from 'react-bootstrap'

// class ManageAttribute extends Component {
//     constructor(props) {
//         super(props)
//         this.state = {
//             options: [{
//                 id: 0,
//                 name: ''
//             }
//             ]
//         } || props.option;
//     }
//     addOption = () => {
//         let { options } = this.state;
//         options.push({
//             id: 0,
//             name: ''
//         })
//         this.setState({ options: options });
//     }
//     removeOption = (id) => {
//         let { options } = this.state;
//         let removeObject = options[id];
//         //call api to remove options with parameter removeObject.id
//         let newOptions = options.filter(op => op.id != removeObject.id);
//         this.setState({ options: newOptions });
//     }
//     submit = (data, actions) => {
//         console.log({ data })
//     }
//     render() {
//         return (
//             <div>
//                 {/* <ManageAttribute option={}/> */}
//                 <Formik initialValues={{ options: this.state.options }} onSubmit={this.submit}
//                     render={({ values }) => (
//                         <Form >
//                             <FieldArray
//                                 name="options"
//                                 render={arrayHelpers => (
//                                     <div> 
//                                         {values.options && values.options.length > 0 ? values.options.map((option, i) => {
//                                             console.log({ optionlength: values, i, name: option.name })
//                                             return (<div key={i}>
//                                                 <Field name={`options[${i}].name`} key={i} />
//                                                 &nbsp;<Field name={`options[${i}].id`} key={i} />

//                                                 <SafeAnchor className="btn btn-default">
//                                                     <i className="fa fa-minus" onClick={() => arrayHelpers.remove(i)} /></SafeAnchor>
//                                                 <br />
//                                             </div>);
//                                         }) : <></>}
//                                         <SafeAnchor className="btn btn-default"><i className="fa fa-plus" onClick={() => {
//                                             arrayHelpers.push({ id: 0, name: '' })
//                                         }} /></SafeAnchor>
//                                     </div>
//                                 )} />
//                             <input type="submit" />
//                         </Form>
//                     )}
//                 >
//                 </Formik>
//             </div >
//         )
//     }
// }

