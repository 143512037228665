import * as Yup from "yup";
import { REGEX, REGEX_ERROR } from "../../../../utils/Constants";

const ChangePasswordSchema = Yup.object().shape({
    oldpassword: Yup.string()
        .trim()
        .required('Please Enter Existing Password'),
    newpassword: Yup.string()
        //.matches(REGEX.PASSWORD,REGEX_ERROR.PASSWORD_ERROR)
        .required('Please Enter New Password'),
    confirmPassword: Yup.string()
         .oneOf([Yup.ref('newpassword'), null],"New password and confirm new password must be same")// "Password don't match")
        .required('Please Enter Confirm Password')

});

export default ChangePasswordSchema;