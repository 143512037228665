import * as Yup from "yup";
import { REGEX, REGEX_ERROR } from "../../../utils/Constants";


const CalculatorSchema = Yup.object().shape(
    {
        CalculatorName: Yup.string()
        .matches(REGEX.ALPHANUMERIC_WITHOUT_SPACE, REGEX_ERROR.ALPHANUMERIC_WITHOUT_SPACE_ERROR)
        .required('Please Enter Calculator Name'),

        IsDeveloped: Yup.string()
        .required('Requried')
    }
)

export default CalculatorSchema;