import React, { Component } from 'react'
import { SafeAnchor, Modal } from 'react-bootstrap';
// import { Formik, Form, Field } from 'formik';
import Button from '@material-ui/core/Button';
import { Field, FieldArray, Form, Formik } from 'formik';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { TextField } from 'formik-material-ui';
import { connect } from 'react-redux';
import Icon from '@material-ui/core/Icon';
import { getCalculatorNameByManufacturer } from '../../../ManageManufacturers/ManageManufacturersApiActions';
import swal from 'sweetalert';
import AttributesTree from './AttributesTree';
import { saveRoleAttribute } from '../../ManageRolesApiActions';
import MenuItem from '@material-ui/core/MenuItem';
import Swal from 'sweetalert2';

const { Header, Body, Footer, Title } = Modal;

class RoleAttribute extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            showform: false,
            calculatorId : 0,
            roleId:props.role.role_id,
            mfgCalc: [],
            AttributeData: []
        };
        this.handleCalculatorChange = this.handleCalculatorChange.bind(this);
    }
    componentDidMount() {
        this.getCalculator();
    }
    componentWillReceiveProps(props) {
        //this.setState({ roleId: this.props.roleId });
        this.getCalculator();

    }
    componentDidUpdate() {
    
    }
    getCalculator = (manufacturerId) => {
                
        getCalculatorNameByManufacturer().then(res => {

            if (res && res.Data && res.Data.length > 0) {

                this.setState({
                    mfgCalc: res.Data.map(r => {
                        let o = {};
                        o.value = r.CalculatorId;
                        o.key = r.name + ' -- ' + r.CalculatorName;
                        return o;
                    })

                })
                
                this.setState({ calculatorId: res.Data[0].CalculatorId });
            }
        })
    }

    showForm = () => {
        this.setState({ showform: true })
        //this.getBellow(this.state.manufacturer_id)
    }


    hideForm = () => {
        this.setState({ showform: false })
        //this.setState({ roleId: 0 })
        this.setState({ calculatorId: 0,prevcalculatorId:0})
        
    }
    
    handleCalculatorChange = (event) => {
        this.setState({ calculatorId: event.target.value })
    };
    submitAttributes = (data, formAction) => {
        
        data.AttributeIds = this.state.AttributeData;
        data.CalculatorId = this.state.calculatorId;
        data.RoleId = this.props.role.role_id;
        
        formAction.setSubmitting(true);
        this.props.saveroleAttribute(data, formAction).then(response => {
            this.setState({ message: response.message });
            formAction.setSubmitting(false);
            //this.props.onHide();
        }, error => {
             formAction.setSubmitting(false);
        });
    }

    editAttribute = () => {
        //console.log({ test: this.props.calc });
        // getFormulaByCalculatorId(this.props.calc.CalculatorId).then(res => {

            this.setState({ 
                showform: true
            })
        // })
    }
    handleTree = (selectedper) => {
        this.setState({AttributeData: selectedper});
    }
    renderAttributes = () => {    
        return(<AttributesTree onAttributeChange={this.handleTree}
         roleId={this.state.roleId}
         calculatorId={this.state.calculatorId}>
        </AttributesTree>)
    }

    render() {

        const roleid = this.props.roleId;
        const rolename = this.props.role.role_name;
        const adduser = {
            clear: 'both',
            float: 'left',
            borderRadius: '20px',
            background: '#01524B',
            color: '#fff',
            fontWeight: 'normal'
        }
      
        //console.log({ krraillength: this.state.krraillength })
        return (
            <>
                {!this.state.showform ?
                  <Button style={adduser}
                  onClick={this.editAttribute}
              >Assign Bellows Parameters</Button>
                    : <>
                        <Modal className="calculatorPopup" show={this.state.showform} onHide={this.hideForm}>
                            <Header closeButton>
                                <Title>{rolename}</Title>
                            </Header>
                            <Body>

                                <Formik
                                    onSubmit={this.submitAttributes}
                                    //initialValues={{ railBellow: this.state.railBellow }}
                                    enableReinitialize={true}
                                >
                                    {({ values, status, isSubmitting ,setFieldValue}) => (
                                        <Form>
                                            <div className="row">
                                                {/* <div className="col-lg-3">
                                                    <label className="">Select Calculator</label>
                                                </div> */}
                                                <div className="col-lg-4">

                                                <FormControl className="roletypedropdown" fullWidth>  
                                    <InputLabel id="demo-simple-select-label">Select Calculator</InputLabel>                                                                          
                                    <Select

                                    className="role_dropdown"
                                    labelId="demo-controlled-open-select-label"
                                    name="calculators"  labelId="demo-simple-select-label"
                                    key=''
                                    defaultValue={this.state.calculatorId}
                                    data-attribute='CalculatorId' 
                                    onChange = {(e)=>
                                            { 
                                                this.handleCalculatorChange(e);
                                                setFieldValue('CalculatorId', e.target.value) 
                                            }
                                        } 
                                    >
                                     { this.state.mfgCalc!= undefined && this.state.mfgCalc && this.state.mfgCalc.length > 0 ?
                                                this.state.mfgCalc.map(c => {
                                                    return <MenuItem value={c.value}>{c.key}</MenuItem>
                                                })
                                                : ""}
                                    </Select>
                                    </FormControl>
                                                </div> 
                                                
                                                <div className="col-lg-8 pb-10">
                                                    <InputLabel id="demo-simple-select-label">Bellows Parameter</InputLabel>
                                                    {
                                                        this.renderAttributes()
                                                    }
                                                    </div>
                                            </div>
                                            <div className="row">
                                          
                                       
                                       
                                        </div> 
                                            
                                            {/* <input type="submit" className="submit" disabled={isSubmitting} /> */}
                                            <Modal.Footer className="row" centered>
                                              <Button variant="contained" type= "submit"  className="save" disabled={isSubmitting} >Submit</Button>
                                            </Modal.Footer>
                                        </Form>
                                    )}
                                </Formik>
                            </Body>
                        </Modal>

                    </>}
            </>

        )
    }
}

const mapDispatch = (dispatch, ownProps) => {

    return {
        saveroleAttribute: (roleAttribute, formAction) => {

            return new Promise((resolve, reject) => {
                dispatch(saveRoleAttribute(roleAttribute, formAction)).then(response => {

                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        }
    }
}
const mapProps = state => ({
})
export default connect(mapProps, mapDispatch)(RoleAttribute);