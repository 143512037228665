import {SERVER_ACTIONS} from '../../utils/Constants';
import HttpRequest from '../../utils/HttpRequest';
import { updateStrokeLength, updateKrSeriesData, updateDefaultValuesKrSeriesData, updatePartDetailsData } from './CalculatorOutputActions';
// import { assertDeclareInterface } from 'babel-types';
import Auth from '../../utils/Auth';

let auth = new Auth();

export function getStrokeLengthList(data) {

    let length;
    // if(data.railLength>0){
    //     length = data.railLength;
    // }
    // else{
    //     length = data.strokeLength;
    // }
    return (dispatch) => {
        
        let length;
        let params = {};
        params.UserId = data.UserId;
        params.IsAdmin = data.IsAdmin;
        params.CalculatorId =  auth.getCalculatorId();
        params.RailId = data.railId;
        params.BellowId = data.bellowId;
        params.Bellow =  "";
        params.SizeId = data.sizeId;
        params.Size =  "";
        params.MaterialId =  data.materialId;
        params.BlockOneType =  data.BlockOneType; 
        params.BlockOneAccessory = data.BlockOneAccessory;
        params.BlockTwoType  = data.BlockTwoType; 
        params.BlockTwoAccessory =  data.BlockTwoAccessory;
        params.CentralineDist =  data.centerLineValue;
        params.IsRailLength = data.IsRailLength;
        //params.Length = data.IsRailLength ? (data.railLength = (data.railLength == "rail" || data.railLength == "stroke") ? "" ? data.railLength ) : data.strokeLength
        params.Length = data.IsRailLength ? ((data.railLength == "rail" || data.railLength == "stroke") ? "" : data.railLength ) : ((data.strokeLength == "rail" || data.strokeLength == "stroke") ? "" : data.strokeLength )

        params.TwoBellow = data.TwoBellow;

        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_IKNOW_STROKE_LENGTH_OUTPUT}`, 'post', params)
            .requestWithAuth()
            .then(response => {
                
                if (response.status === 200 && !response.data.errorCode) {

                dispatch(updateStrokeLength(response.data.data));
                return Promise.resolve(response.data.data);
                }
                else{
                    return Promise.reject(response.data);
                }
            }, error => {
                return Promise.reject(error);
            });
    }
}

export function getDefaultValuesForStrokeLengthData(manufacturerId,railId) {

    return (dispatch) => {
 
        const queryString =  '?ManufacturerId='+manufacturerId+'&RailId='+railId;
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.POST_DEFAULT_VALUES_FOR_STROKE_LENGTH}${queryString}`, 'post', {})
            .requestWithAuth()
            .then(response => {
              
                if (response.status === 200 && !response.data.errorCode) {

                dispatch(updateDefaultValuesKrSeriesData(response.data.Data));
                return Promise.resolve(response.data.Data);
                }
                else{
                    return Promise.reject(response.data);
                }
            }, error => {
                return Promise.reject(error);
            });
    }
}

export function getKrSeriesData(data) {

    return (dispatch) => {
        let params = {};
      

        params.UserId = 0;
        params.IsAdmin = data.IsAdmin;
        params.CalculatorId =  data.calculatorId;
        params.KRSeriesId =  data.krSeriesid;
        params.KRRaillengthId= data.krlength;
        params.KRMaterialId =  data.krMaterial;

        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_KR_SERIES_OUTPUT}`, 'post', params)
            .requestWithAuth()
            .then(response => {
                
                if (response.status === 200 && !response.data.errorCode) {

                dispatch(updateKrSeriesData(response.data.data));
                return Promise.resolve(response.data.data);
                }
                else{
                    return Promise.reject(response.data);
                }
            }, error => {
                return Promise.reject(error);
            });
    }
}


export function getDefaultValuesKrSeriesData(manufacturerId) {

    return (dispatch) => {
 
        const queryString =  '?ManufacturerId='+manufacturerId;
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.POST_DEFAULT_VALUES_FOR_KRSERIES}${queryString}`, 'post', {})
            .requestWithAuth()
            .then(response => {
                
                if (response.status === 200 && !response.data.errorCode) {

                dispatch(updateDefaultValuesKrSeriesData(response.data.Data));
                return Promise.resolve(response.data.Data);
                }
                else{
                    return Promise.reject(response.data);
                }
            }, error => {
                return Promise.reject(error);
            });
    }
}


export function getPartDetailList(data) {

    // return (dispatch) => {
 
        const queryString =  '?PartNumber='+data.partNumber+'&CalculatorId='+data.calculatorId;
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_PARTNUMBER_OUTPUT}${queryString}`, 'get', {})
            .requestWithAuth()
            .then(response => {
                
                if (response.status === 200 && !response.data.errorCode) {

                // dispatch(updatePartDetailsData(response.data.data));
                return Promise.resolve(response.data.data);
                }
                else{
                    return Promise.reject(response.data);
                }
            }, error => {
                return Promise.reject(error);
            });
    // }
}

export function getDefaultValuesForIKnowPartNumberData(manufacturerId,bellowsId) {

    //return () => {
 
        const queryString =  '?ManufacturerId='+manufacturerId+'&bellowsId='+bellowsId;
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.POST_DEFAULT_VALUES_FOR_IKNOWPARTNUMBER}${queryString}`, 'post', {})
            .requestWithAuth()
            .then(response => {
              
                if (response.status === 200 && !response.data.errorCode) {
                return Promise.resolve(response.data.Data);
                }
                else{
                    return Promise.reject(response.data);
                }
            }, error => {
                return Promise.reject(error);
            });
    //}
}

export function SaveKRSeriesPartNumber(data,PartNumber) {

    return (dispatch) => {
        let params = {};
        params.UserId = 0;
        params.IsAdmin = (auth.getUserType() == 'true');
        params.CalculatorId = parseInt(data.calculatorId);
        params.CalculatorTab =  "KR Series Bellows";
        let childParam = {};

        childParam.KRRaillengthId= data.krlength;
        childParam.KRMaterialId =  data.krMaterial;
        childParam.KRSeriesId =  data.krSeriesid;

        params.InputData = JSON.stringify(childParam);
        params.PartNumber=PartNumber;
        params.IsReviewed = false;
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.POST_SAVEPARTNUMBER}`, 'post', params)
            .requestWithAuth()
            .then(response => {
                if (response.status === 200 && !response.data.errorCode) {
                    window.Message(response.data.Message, 'Success');
                return Promise.resolve(response.data.data);
                }
                else{
                    window.Message(response.data.Message, 'Error',true);
                    return Promise.reject(response.data);
                }
            }, error => {
                return Promise.reject(error);
            });
    }
}

export function SaveIKnowStrokeRailPartNumber(data,PartNumbers) {

    return (dispatch) => {
        let params = {};
        params.UserId = 0;
        params.IsAdmin = (auth.getUserType() == 'true');
        params.CalculatorId = parseInt(data.calculatorId);
        params.CalculatorTab =  "I Know Stroke/Rail Length";
        let childParam = {};

        childParam.RailId = data.railId;
        childParam.BellowId = data.bellowId;
        childParam.SizeId = data.sizeId;
        childParam.MaterialId =  data.materialId;
        childParam.BlockOneType =  data.BlockOneType; 
        childParam.BlockOneAccessory = data.BlockOneAccessory;
        childParam.BlockTwoType  = data.BlockTwoType; 
        childParam.BlockTwoAccessory =  data.BlockTwoAccessory;
        childParam.CentralineDist =  data.centerLineValue;
        childParam.IsRailLength = data.IsRailLength;
        childParam.Length = data.IsRailLength ? data.railLength : data.strokeLength
        childParam.TwoBellow = data.TwoBellow;

        params.InputData = JSON.stringify(childParam);
        params.PartNumber=PartNumbers;
        params.IsReviewed = false;
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.POST_SAVEPARTNUMBER}`, 'post', params)
            .requestWithAuth()
            .then(response => {
                if (response.status === 200 && !response.data.errorCode) {
                    window.Message(response.data.Message, 'Success');
                return Promise.resolve(response.data.data);
                }
                else{
                    window.Message(response.data.Message, 'Error',true);
                    return Promise.reject(response.data);
                }
            }, error => {
                return Promise.reject(error);
            });
    }
}



export function getSavePartNumberDetail(PartnumberId) {

   
        const queryString =  '?PartnumberId='+PartnumberId;
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_SAVED_PART_NUMBER}${queryString}`, 'get', {})
            .requestWithAuth()
            .then(response => {
                
                if (response.status === 200 && !response.data.errorCode) {

                // dispatch(updatePartDetailsData(response.data.data));
                return Promise.resolve(response.data.Data);
                }
                else{
                    return Promise.reject(response.data);
                }
            }, error => {
                return Promise.reject(error);
            });
   
}

//////////////////get stroke length List///////////////////////////
export function getStrokeLengthListForState(data) {

    let length;
        let params = {};
        params.UserId = data.UserId;
        params.IsAdmin = data.IsAdmin;
        params.CalculatorId =  auth.getCalculatorId();
        params.RailId = data.railId;
        params.BellowId = data.bellowId;
        params.Bellow =  "";
        params.SizeId = data.sizeId;
        params.Size =  "";
        params.MaterialId =  data.materialId;
        params.BlockOneType =  data.BlockOneType; 
        params.BlockOneAccessory = data.BlockOneAccessory;
        params.BlockTwoType  = data.BlockTwoType; 
        params.BlockTwoAccessory =  data.BlockTwoAccessory;
        params.CentralineDist =  data.centerLineValue == 0 ? "" : data.centerLineValue;
        params.IsRailLength = data.IsRailLength;
        //params.Length = data.IsRailLength ? (data.railLength = (data.railLength == "rail" || data.railLength == "stroke") ? "" ? data.railLength ) : data.strokeLength
        params.Length = data.IsRailLength ? ((data.railLength == "rail" || data.railLength == "stroke") ? "" : data.railLength ) : ((data.strokeLength == "rail" || data.strokeLength == "stroke") ? "" : data.strokeLength )

        params.TwoBellow = data.TwoBellow;
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_IKNOW_STROKE_LENGTH_OUTPUT}`, 'post', params)
            .requestWithAuth()
            .then(response => {
                if (response.status === 200 && !response.data.errorCode) {
                return Promise.resolve(response.data.data);
                }
                else{
                    return Promise.reject(response.data);
                }
            }, error => {
                return Promise.reject(error);
            });
    
}
/////////////////////////////////List of size by railId and BellowId//////////////////////
export function getAllSizeList(raildId, bellowId) {
   
        const queryString = '?railid='+raildId+'&bellowid='+bellowId;
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_ALL_SIZES}${queryString}`, 'get', {}).requestWithAuth().then(
            (response) => {
                if (response.status === 200 && !response.data.errorCode) {
                    return Promise.resolve(response.data.data);
                    }
                    else{
                        return Promise.reject(response.data);
                    }
            },
            (error) => {
                
                return Promise.reject(error);
            }
        );
}
