import React, { Component } from 'react'
import { SafeAnchor, Modal } from 'react-bootstrap';
// import { Formik, Form, Field } from 'formik';
import Button from '@material-ui/core/Button';
import { Field, FieldArray, Form, Formik } from 'formik';
import { FormikTextField } from 'formik-material-fields';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { TextField } from 'formik-material-ui';
import { getSizeAttributes, deleteSizeAttribute, addSize } from '../../components/ManageAttributeApiAction';
import Icon from '@material-ui/core/Icon';
import RailBellowByManufacturer from '../../../Common/components/DropDown/RailBellowByManufacturerDropDown';
import swal from 'sweetalert';
import {getRailBellowByManufacturerList} from '../ManageAttribute/EditDropDownListApi';
import{StyledReactTooltip} from '../ToolTip';
import Swal from 'sweetalert2';


const { Header, Body, Footer, Title } = Modal;



export default class EditSize extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showform: false,
            manufacturer_name: "",
            railBellow_id: 0,
            railBellow_name: '',
            prev_railBellow_id: 0,
            prev_railBellow_id2: 0,
            prev_railBellow_name: '',
            railBellowList:'',
            size: [{
                SizeId: 0,
                RailBellowId: 0,
                Size: "",
                Pitch: 0,
                OpenPitch: 0,
                FlangeThickness: 0,
                GasketThickness: 0,
                VelcroThickness: 0

            }
            ]


        };

    }
    componentDidMount() {
        //console.log("didmount");
    }
    componentWillReceiveProps(props) {
        this.setState({ manufacturer_id: this.props.manufacturer_id });
        if(this.props.manufacturer_id!=undefined){
        this.getRailBellowList(this.props.manufacturer_id);
        }

    }
    componentDidUpdate() {
        const railValue=this.state.railBellowList[0]!=undefined?(this.state.railBellowList[0]):(0);
        
        const railid=railValue.RailBellowsID
       if(this.state.prev_railBellow2_id!=railValue.RailBellowsID)
       {
        //    this.setId(sizeValue.SizeId);
           this.setState({railBellow_id:railValue.RailBellowsID});
           this.getSize(railid);
          this.setState({prev_railBellow2_id:railValue.RailBellowsID});
        
       }

       else if (this.state.prev_railBellow_id != this.state.railBellow_id) {
            this.getSize(this.state.railBellow_id);
            this.setState({ prev_railBellow_id: this.state.railBellow_id })
        }
    }

    handleChangeRailDropdown = (event) => {
        this.setState({ railBellow_id: event.target.value })
        // this.getKRRailLenth(event.target.value);
    };

    showForm = () => {
        this.setState({ showform: true })
    }
    hideForm = () => {
        this.setState({
            showform: false,
            prev_railBellow_id: 0,
            prev_railBellow2_id: 0,
        })
        this.setState({
            size: [{
                SizeId: 0,
                RailBellowId: 0,
                Size: "",
                Pitch: 0,
                OpenPitch: 0,
                FlangeThickness: 0,
                GasketThickness: 0,
                VelcroThickness: 0

            }
            ]
        })
    }
    deleteSize = (option, arrayHelpers, i) => {
        if (option.SizeId == 0) {
            arrayHelpers.remove(i);
        }
            else {
                Swal.fire({
                    //title: 'Are you sure?',
                    text: 'This action will delete the record permanently from database, Are you sure?',
                    // icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Cancel',
                    showCloseButton: true
                  }).then((result) => {
                    if (result.value) {
                    
                        deleteSizeAttribute(option.SizeId).then(resp => {
                                                    arrayHelpers.remove(i);
                                                });
                      
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                      
                    }
                  })
        // swal("This action will delete the record permanently from database, Are you sure?", {
        //     buttons: {
        //         catch: {
        //             text: "Yes",
        //             value: "Yes",
        //         },
        //         cancel: "Cancel",
        //     },
        // })
        //     .then((value) => {
        //         switch (value) {
        //             case "Yes":
                    
        //                     deleteSizeAttribute(option.SizeId).then(resp => {
        //                         arrayHelpers.remove(i);
        //                     });
                        
        //                 break;
        //             default:

        //         }
        //     });
        }
    }
    getRailBellowList = (manufacturerid) =>  {
        getRailBellowByManufacturerList(manufacturerid).then(res => {
            this.setState({
                railBellowList:res,
            })
            
        })
    }
    getSize = () => {

        getSizeAttributes(this.state.railBellow_id).then(res => {
            this.setState({
                size: res,
              
            })

        })
    }
    submitFormula = (data, actions) => {

          
        let found=false;
for(let i=0;i<data.size.length;i++){
    let rname=data.size[i].Size;

let foundDuplicate=data.size.filter(r=>r.Size==rname).map(duplicate=>(duplicate.Size ))
    if(foundDuplicate.length>=2)
    {found=true;
        actions.setSubmitting(false)
        swal(rname+" attribute is already exist", {
            buttons: {
                    cancel: "OK",
            },
        })
            .then((cancel) => {
                switch (cancel) {
                    case "Yes":                        
                        break;
                    default:
                }
            });
    } 
}

if(found==false){
    actions.setSubmitting(true);
    // let updateOptions = data.options.filter(o => (this.state.updatableOptions.indexOf(o.CalulatorformulaeId) > -1 || o.CalulatorformulaeId == 0));
    let SizeAttributeValueModel = data.size

    addSize(this.props.manufacturer_id, this.state.railBellow_id,
        SizeAttributeValueModel).then(response => {

            if (response.Status && response.StatusCode === 200) {
                let { size } = this.state;

                size = size.map(size => {
                    let option2 = SizeAttributeValueModel.find(o => o.SizeId == size.SizeId);
                    return option2 ? { ...size, ...option2 } : size;
                })

                //console.log({ SizeAttributeValueModel: size });
                this.setState({
                    size
                })

            }

        }, this.hideForm())
}

       

    }

    render() {

        const manufacturer_id = this.props.manufacturer_id;
        const manufacturer_name = this.props.manufacturer_name;
        return (
            <>
            {!this.state.showform ?<>
                <Icon  data-tip data-for="edit" onClick={this.showForm}>
                    edit
</Icon>
 <StyledReactTooltip id="edit" effect="solid">
 <span>Edit</span>
</StyledReactTooltip></>
                : <>
                <Modal className="calculatorPopup" show={this.state.showform} onHide={this.hideForm}>
                    <Header closeButton>
                        <Title>{manufacturer_name}</Title>
                    </Header>
                    <Body>

                        <Formik
                            onSubmit={this.submitFormula}
                            initialValues={{ size: this.state.size }}
                            enableReinitialize={true}
                        >
                            {({ values, status, isSubmitting }) => (
                                <Form>

                                    <div className="row">
                                        <div className="col-lg-12 popup-heading">
                                            <label >ATTRIBUTE NAME : Size</label>
                                        </div>
                                        <div className="col-lg-4">

                                            <FormControl margin="normal" fullWidth>
                                                <div className="ManufacturerDropDown">
                                                    <InputLabel id="demo-simple-select-label">Rail Bellows*</InputLabel>
                                                    <RailBellowByManufacturer selectedValue={this.state.railBellow_id} enabled={true} activeOnly={true} manufacturerId={manufacturer_id} onRailBellowChange={(e, index) => {

                                                        this.handleChangeRailDropdown(e)

                                                        this.setState({
                                                            railBellow_id: e.target.value,
                                                            railBellow_name: index.props.name

                                                        });

                                                    }
                                                    } />
                                                </div>
                                            </FormControl>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-lg-8 col-8">
                                            <label >Size</label>
                                        </div>
                                    </div>
                                    <FieldArray
                                        name="size"

                                        render={arrayHelpers => (
                                            <div className="clacfor">

                                                {values.size && values.size.length > 0 ? values.size.map((size, i) => {
                                                    //console.log({ size: size })


                                                    return (<div className="row" key={i}>

                                                        <div className="col-lg-8 col-8">

                                                            <Field

                                                                name={`size[${i}.Size]`}
                                                                margin="normal"
                                                                fullWidth
                                                                component={TextField}

                                                            />
                                                            {(!size.Size || size.Size.length == 0) && this.state.error ?
                                                                <span style={{ color: "red" }}> Enter Size Value </span> : ""}

                                                        </div>


                                                        <SafeAnchor className="btn btn-default">
                                                            <i className="fa fa-minus"
                                                                onClick={() => {
                                                                    this.deleteSize(size, arrayHelpers, i);
                                                                }} /></SafeAnchor>
                                                        <br />
                                                    </div>);
                                                }) : <></>}
                                                <SafeAnchor className="btn btn-default">
                                                    <i className="fa fa-plus" onClick={() => {
                                                        if (values.size && values.size.length > 0 && values.size.filter(v =>(!v.Size || v.Size.length == 0)).length == 0) {
                                                            arrayHelpers.push({ SizeId: 0, OpenPitch: 0, FlangeThickness: 0, GasketThickness: 0, VelcroThickness: 0 })
                                                            this.setState({
                                                                error: false
                                                            })
                                                        } else {
                                                            arrayHelpers.push({ SizeId: 0, OpenPitch: 0, FlangeThickness: 0, GasketThickness: 0, VelcroThickness: 0 })
                                                            this.setState({ error: true })
                                                        }

                                                    }} /></SafeAnchor>
                                            </div>
                                        )} />
                                    {/* <input type="submit"  className="submit" disabled={isSubmitting} /> */}
                                    <Modal.Footer className="row" centered>
                                        <Button variant="contained" type="submit" className="save" disabled={isSubmitting} >Submit</Button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Body>
                </Modal>

                </>}
            </>

        )
    }
}