import React, { Component } from 'react';
import { connect } from 'react-redux';
import { signInRequest } from '../../AuthenticationApiActions';
import { ErrorMessage, Field, Form, Formik } from "formik";
import SignInSchema from './SignInSchema';
import { TextField } from 'formik-material-ui';
import './SignIn.css';
import { Button } from '@material-ui/core';
import RegisterCustomer from '../Register/registerCustomer';
import ForgotPassword from '../Forgot/ForgotPassword';
import { SafeAnchor } from 'react-bootstrap';
import CardContent from '@material-ui/core/CardContent';
import Auth from '../../../../utils/Auth';
let auth = new Auth();
class SignIn extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            showForgot: false,
            green: false,
            whichComponent:'SignIn',
            buttonDisable:false,
        };

      
    }
componentDidMount=()=>{
    var val = auth.getRegisterComponent();
    if(val!=undefined){
        if(val == "true"){
            this.setState({ visible: true, whichComponent:'RegisterCustomer',
            green: true });
            auth.setRegisterComponent("false");
        }
    }
}
    loginUser = (data, actions) => {

         this.setState({ buttonDisable:true })
        this.props.onLogin(data, actions).then(response => {
            setTimeout(() => this.setState({ buttonDisable:false }), 5000);
        }, error => {
            setTimeout(() => this.setState({ buttonDisable:false }), 5000);
            //TODO: Show message for incorrect credentials
        })
    }

    handleClick = () => {
        
        this.setState({ visible: true, whichComponent:'RegisterCustomer',
        green: true });
    }

    handleForgotPassword = () => {
        this.setState({ showForgot: true })
        this.props.handleChild();
    }



    handleForgotClick = () => {
        this.setState({ showForgot: true });
        this.props.handleChild();
    }

    render() {

        return (
            <React.Fragment>
                <CardContent>
                    {this.state.showForgot ? <ForgotPassword /> : <>
                        {this.state.visible ? <RegisterCustomer color= {this.state.green}/> : <>
                            <h3 className="subheading">Authorized User Login</h3>
                            <Formik
                                validationSchema={SignInSchema}
                                onSubmit={this.loginUser}
                                initialValues={{
                                    username: '',
                                    password: '',
                                    IsAdmin : false,
                                }}
                            >

                                {({ errors, status, touched, isSubmitting, setFieldValue }) => (
                                    //  <h3 className="subheading">Authorized User Login</h3>

                                    <Form name="loginForm" className="form-horizontal form-simple" autoComplete="off" noValidate>


                                        <fieldset className="com-field" >
                                            <Field type="text" name="username" id="standard-required"
                                                label="Email"
                                                margin="normal"
                                                className="mt-0"
                                                fullWidth
                                                component={TextField}
                                                placeholder="Email"
                                                error />

                                            {/* <ErrorMessage name="username" style={{ color: 'red' }} component="div" /> */}

                                        </fieldset>

                                        <fieldset className="com-field"  >

                                            <Field type="password"
                                                component={TextField}
                                                id="standard-password-input"
                                                label="Password"
                                                margin="normal"
                                                fullWidth
                                                placeholder="Password"
                                                name="password" onChange={(e) => setFieldValue("password", e.currentTarget.value)}
                                                error />



                                        </fieldset>


                                        <div className="forgot-pass">
                                            {this.props.showForgot ? <ForgotPassword /> :
                                                <SafeAnchor style={{ 'color': '#01524B' }} onClick={this.handleForgotPassword}>
                                                    Forgot Password
                                        </SafeAnchor>}
                                        <Button type="submit" disabled={this.state.buttonDisable} color="primary" className="submit-btn">
                                                Login
                                        </Button>
                                           
                                        </div>
                                       
                                    </Form>
                                )}
                            </Formik>
                            <p className="regi-para">Authorized Users receive OEM or Distributor level pricing, priority shipping & more 
                           
                                                <SafeAnchor className="register"
                                onClick={this.handleClick}>Register Here</SafeAnchor></p>

                        </>}</>}
                </CardContent>
            </React.Fragment>


        )
        // }
    }
}

const mapStateToProps = state => {
    return {
      
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onLogin: (data, actions) => {
            return new Promise((resolve, reject) => {
                dispatch(signInRequest(data, actions)).then(response => {

                    resolve(response);
                }).catch(error => {

                    reject(error);
                });
            });
        }
    }
}

SignIn = connect(mapStateToProps, mapDispatchToProps)(SignIn);

export default SignIn;