import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAllManufacturerList } from '../../CommonApiActions';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CalculatorList from '../../../ManageCalculator/Components/CalculatorList';
class ManufacturerDropDown extends React.Component {
    constructor(props) {
        super(props);

        this.state = ({
            allManufacturers: []
        })
    }

    componentDidMount() {
        this.props.getManufacturerList();
    }

    render() {
        const selectedValue = this.props.selectedValue;
        const manufacturers = this.props.allManufacturers;
        // var selectedValue = 0;
        // if(manufacturers.length > 0)
        // {
        //     selectedValue = manufacturers[0].manufacturer_id;
        // }
        const options = manufacturers && manufacturers.length > 0 ? (
            
            manufacturers.map((manufacturer, index) => {

                return (                  
                
                <MenuItem key = {index} name={manufacturer.name} value={manufacturer.manufacturer_id} >
                        {manufacturer.name}
                        </MenuItem>
                        
                   
                );
            })
        ) : (
                <MenuItem>Loading....</MenuItem>
            );
        //console.log({ options })
        return (            
            <Select style={{width:160}} value={selectedValue}
                labelId="demo-simple-select-label"
                id="demo-simple-select" onChange={this.props.onManufacturerChange} >
                {options}
                
            </Select>
            
        
            
        );

         
    }
}

const mapProps = (state) => {

    return {
        allManufacturers: state.common.allManufacturers
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getManufacturerList: () => {
            return new Promise((resolve, reject) => {
                dispatch(getAllManufacturerList())
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    };
};

export default connect(mapProps, mapDispatchToProps)(ManufacturerDropDown);
