import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ErrorMessage, Field, Form, Formik } from "formik";
// import {GuestLoginRequest} from '../../AuthenticationApiActions';
import Button from '@material-ui/core/Button';
import { TextField } from 'formik-material-ui';
import CardContent from '@material-ui/core/CardContent';
import SignIn from '../SignIn/SignIn';
 
import {ForgotPasswordRequest} from '../../AuthenticationApiActions'
import ForgotSchema from './ForgotSchema'
class ForgotPassword extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            visible:false,

        }

        // this.setState({this.props.showloginregister})
    }
    handleClick = () => {

        this.setState({ visible: true });
        // window.location.reload();
        this.setState(Object.assign({}, this.state));
    }
    forgotPassword = (data, actions) => {
    
        this.props.onForgotPassword(data, actions).then(response => {
      
        }, error => {
            //TODO: Show message for incorrect credentials
        })
      }
    render() {
        return (
            <React.Fragment>
            {this.state.visible ? <SignIn/>: <>
         
              
                                         <div className="forgot-pass-main"> <h3 className="subheading">Forgot Password</h3>
                                             <Formik
                                              validationSchema={ForgotSchema}
                                                 onSubmit={this.forgotPassword}
                                                 initialValues={{
                                                   email: ''
                                                 }}
                                                 >
     
                                                 {({ errors, status, touched, isSubmitting, setFieldValue }) => (
                                                     <Form name="loginForm" className="form-horizontal form-simple" noValidate>
                                                
                                                         <fieldset className="form-group position-relative has-icon-left mb-0 com-field"> 
                                                             <Field type="text" name="email"
                                                             label="Email"
                                                                 //className="form-control "
                                                                 margin="normal"
                                                                 fullWidth
                                                                 component={TextField}
                                                                 placeholder="Email"
                                                                 error />                                                          
     
                                                         </fieldset>
     
                                                         <div className="form-group for-btn">
                                                        
                                                            <Button type="submit" color="primary" className="submit-btn">
                                                              Submit
                                                         </Button>
                                                          </div>
                                                     </Form>
                                                    
                                                 )}
                                                 
                                             </Formik>
                                             <p>We will send you an email with a link to reset your password. 
                                             <a className="register" href="#" onClick={this.handleClick}>Back to Login</a>
                                                 </p>
                                             </div>
 
                                     </> }
         </React.Fragment>
        )
    }
}
const mapStateToProps = state => {
    return {
        
    }
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onForgotPassword: (data, actions) => {
            return new Promise((resolve, reject) => {
                dispatch(ForgotPasswordRequest(data, actions)).then(response => {
                    resolve(response);
                    window.location.reload();
                }).catch(error => {
                    reject(error);
                });
            });
        }
    }
}

ForgotPassword = connect(mapStateToProps,mapDispatchToProps)(ForgotPassword);
 
export default ForgotPassword
