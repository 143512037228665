import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField } from 'formik-material-ui';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Modal, Button } from 'react-bootstrap';
import { saveUserItem } from '../../ManageUsersApiActions';
import RoleDropDown from '../../../Common/components/DropDown/RoleDropDown';
import AddEditUserSchema from './AddEditUserSchema';

class Edituser extends Component {
    constructor(props) {
        super(props);

        let user = props.update ? props.user : {
            email_id: '',
            first_name: '',
            last_name: '',
            role_id:''
        };
         
        this.state = {
            user: user
        }
    }

    saveUser = (data, formAction) => {

        formAction.setSubmitting(true);
        this.props.saveUser(data, formAction).then(response => {
            this.setState({ message: response.message });
            formAction.setSubmitting(false);
            if (this.props.updateList && typeof this.props.updateList === 'function') {
               
                this.props.updateList();
            }
            this.props.onHide();
            // this.handleClose();
        }, error => {
            
            formAction.setSubmitting(false);
        });

    };

    render() {
      
        let stateData = this.props.user;
        return (

            <React.Fragment>

                <Modal className="userPopup"
                    {...this.props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header color="secondary" closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Edit User
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Formik
                            validationSchema={AddEditUserSchema}
                            onSubmit={this.saveUser}
                            initialValues={stateData}
                            enableReinitialize={true}
                        >
                            {({ errors, status, touched, isSubmitting, setFieldValue }) => (
                                <Form  noValidate>

                        <div className="row">
                            <div className="col-lg-4 com-field">
                                
                            <Field type="text"
                                name="email_id"
                                disabled
                                placeholder="Enter Email"
                                component = {TextField}
                                id="standard-required"
                                label="User Name"
                                margin="normal"
                                defaultValue={stateData.email_id}
                                fullWidth
                                />
                            </div>

                            <div className="col-lg-4 com-field">
                            <Field type="text"
                                name="first_name"
                                placeholder="Enter First Name"
                                component = {TextField}
                                id="standard-required"
                                label="First Name"
                                margin="normal"
                                defaultValue={stateData.first_name}
                                fullWidth
                                />

                            </div>

                            <div className="col-lg-4 com-field">
                            <Field type="text"
                                name="last_name"
                                placeholder="Enter Last Name"
                                component = {TextField}
                                id="standard-required"
                                label="Last Name"
                                margin="normal"
                                defaultValue={stateData.last_name}
                                fullWidth
                                />

                            </div>
                           
                            {/* <div className="col-lg-4">

                            <FormControl margin="normal" fullWidth>
                                             <InputLabel id="demo-simple-select-label">Manufacturer</InputLabel>
                                             <ManufacturerDropDown enabled={true} activeOnly={true} onManufacturerChange={e=>{
                                                 setFieldValue("manufacturer", e.target.value);                                
                                             }
                                             }/>
                                                      

                                        </FormControl>
                            </div> */}

                            <div className="col-lg-4 com-field">

                                       <FormControl margin="normal" fullWidth>
                                             <InputLabel id="demo-simple-select-label">Role</InputLabel>
                                             <RoleDropDown isAdmin={true} isGuest={false} name = "role_id"  value = {stateData.role_id} selectedValue={stateData.role_id} selectedName={stateData.role_name} enabled={true} activeOnly={true} onRoleChange={(e, index)=>{
                                                 setFieldValue("role_id", e.target.value); 
                                                 setFieldValue("role_name", index.props.name);      
                                             }
                                             }/>
                                                      

                                        </FormControl>
                            </div>

                            
                        </div>
                        <Modal.Footer className="row" centered>
                        <Button variant="contained" type= "submit"  className="save">Save</Button>
                        <Button variant="contained" className="cancle" onClick={this.props.onHide}>Close</Button>
                    </Modal.Footer>
                        </Form>
                            )}

                        </Formik>

                    </Modal.Body>
                    
                </Modal>
            </React.Fragment>
        )
    }
}

const mapProps = state => ({
})

const mapDispatch = (dispatch, ownProps) => {

    return {
        saveUser: (user, formAction) => {

            return new Promise((resolve, reject) => {
                dispatch(saveUserItem(user, formAction)).then(response => {

                    resolve(response);
                    // if (response.status == 200 && !response.data.errorCode) {
                    //     window.Message('Saved Successfully', discount.id > 0 ? `${discount.name} Updated....` : `${discount.name} Created....`);
                    // }
                }).catch(error => {
                    reject(error);
                });
            });
        }
    }
}

export default connect(mapProps, mapDispatch)(Edituser);
