
export const GET_ATTRIBUTE = 'GET_ATTRIBUTE';

export function attributeList(attributeList) {
    return {
        type: GET_ATTRIBUTE,
        attributeList,
       
    }
 }
//  export function railsAttributes(attributeList) {
//     return {
//         type: GET_Rails_ATTRIBUTE,
//         attributeList,
       
//     }
//  }