import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { registerCustomerRequest } from '../../AuthenticationApiActions';

import { ErrorMessage, Field, Form, Formik } from "formik";
import RegisterCustomerSchema from './registerCustomerSchema';
import UserTypeDropDown from '../../../Common/components/DropDown/UserTypeDropDown';
import CountryDropDown from '../../../Common/components/DropDown/CountryDropDown';
import StateDropDown from '../../../Common/components/DropDown/StateDropDown';
import CompanyTypeDropDown from '../../../Common/components/DropDown/CompanyTypeDropDown';
import '../SignIn/SignIn.css';
import Auth from '../../../../utils/Auth';
import CardContent from '@material-ui/core/CardContent';
import { TextField } from 'formik-material-ui';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import {  ACTIONS } from '../../../../utils/Constants';
import FormHelperText from "@material-ui/core/FormHelperText";
import swal from 'sweetalert';
import { isNullOrUndefined } from 'util';

let auth = new Auth();
class RegisterCustomer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            country: '',
            setCountry: '',
            setUserType:'',
        }
    }
  componentDidMount(){
      var btnLogin = document.getElementById("signIn");
      var btnRegister = document.getElementById("registerCustomer");
      btnLogin.classList.add("whiteButton");
      btnLogin.classList.remove("greenButton");
      btnRegister.classList.remove("whiteButton");
      btnRegister.classList.add("greenButton");
  }
  
    submitDetails = (data, formAction) => {

        formAction.setSubmitting(true);
        // data.adminId = 1;
        //data.country = auth.getCountry();
        this.props.onRegisterCustomer(data,formAction).then(response => {
            swal(response, {
                buttons: {
                    catch: {
                        text: "Ok",
                        value: "Ok",
                    }
                },
            })
                .then((value) => {
                    switch (value) {
                        case "Ok":
                            window.location.href = "/#"+ ACTIONS.SIGN_IN;
                            window.location.reload();
                            break;
                        default:
                    }
                });
            
        }, error => {
            //TODO: Show message for incorrect credentials
        })
    }

    render() {
        // const handleChange = event => {
        //     this.setState({ value: event.target.value,
        //    });
        // };


        //console.log({ states: states });
        return (
            <React.Fragment>

                <CardContent>

                    <h3 className="subheading">Register</h3>
                    <Formik
                         validationSchema={RegisterCustomerSchema}
                        onSubmit={this.submitDetails}
                       
                        initialValues = {{
                            first_name: '',
                            last_name: '',
                            company_name: '',
                            company_type:'',
                            contact_no: '',
                            email: '',
                            password: '',
                            confirmPassword: '',
                            address: '',
                            country:'',
                            state: '',
                            postal_code: '',
                            custtype: '',
                        }
                            
                        }
                        enableReinitialize={true}
                     
                    >

                        {({ errors, status, touched, isSubmitting, setFieldValue }) => (
                            <Form name="registerForm" noValidate >
                                <div className="row ">

                                    <div className="com-field col-lg-6 ">
                                        <Field type="text" name="first_name"
                                            label="First Name"
                                            className="mt-0"
                                            component={TextField}
                                            margin="normal"
                                            fullWidth
                                            inputProps={{
                                                maxLength:50,
                                              }}
                                            placeholder="First Name" />

                                    </div>

                                    <div className="com-field col-lg-6">
                                        <Field type="text" name="last_name"
                                            component={TextField}
                                            label="Last Name"
                                            placeholder="Last Name"
                                            className="mt-0"
                                            margin="normal"
                                            inputProps={{
                                                maxLength:50,
                                              }}
                                            fullWidth />
                                    </div>

                                    <div className=" com-field col-lg-6">
                                        <Field type="text" name="company_name"
                                            label="Company Name"

                                            component={TextField}
                                            placeholder="Company Name"
                                            margin="normal"
                                            fullWidth
                                            error />

                                    </div>

                                    <div className="com-field col-lg-6">
                                        <FormControl margin="normal" fullWidth error={errors.company_type && touched.company_type}>
                                             <InputLabel id="demo-simple-select-label">Company Role</InputLabel>
                                             <CompanyTypeDropDown  enabled={true} activeOnly={true} onCompanyChange={e=>{
                                                 setFieldValue("company_type", e.target.value);
                                                
                                             }
                                             }/> 
                                             {errors.company_type && touched.company_type && (
                                                   <div className="selecterrormsg">
                                                   {errors.company_type}
                                                   </div>
                                                )}        

                                        </FormControl>
                                    </div>

                                    <div className="com-field col-lg-6">

                                        <Field type="text" name="contact_no"
                                            label="Phone"
                                            component={TextField}
                                            margin="normal"
                                            fullWidth
                                            placeholder="Phone" />

                                       

                                    </div>

                                    <div className="com-field col-lg-6">
                                        <Field type="email"
                                            label="Email"
                                            component={TextField}
                                            margin="normal"
                                            fullWidth
                                            placeholder="Email"
                                            name="email"
                                            error />                                       
                                   
                                    </div>

                                    <div className=" com-field col-lg-6">
                                        <Field type="password"
                                            placeholder="Password"
                                            label="Password"
                                            margin="normal"
                                            fullWidth
                                            component={TextField}
                                            name="password"
                                            error />

                                    </div>

                                    <div className="com-field col-lg-6">
                                        <Field type="password"
                                            placeholder="Confirm Password"
                                            label="Confirm Password"
                                            margin="normal"
                                            fullWidth
                                            component={TextField}
                                            name="confirmPassword"
                                            error />

                                    </div>
                                    <div className="com-field col-lg-6">
                                        <Field type="text"
                                            placeholder="Address"
                                            name="address"
                                            label="Address"
                                            margin="normal"
                                            
                                            fullWidth
                                            component={TextField}
                                            error />

                                    </div>

                                    <div className="com-field col-lg-6">
                                        <FormControl margin="normal" fullWidth error={errors.country && touched.country } >
                                             <InputLabel id="demo-simple-select-label">Country</InputLabel>
                                             <CountryDropDown name="country" enabled={true} activeOnly={true} onCountryChange={e=>{
                                                 setFieldValue("country", e.target.value);
                                                //  auth.setCountry(e.target.value);
                                                 this.state.setCountry = e.target.value;
                                                 setFieldValue("state","");
                                                                           
                                             }
                                             }/>
                                             {errors.country && touched.country && this.state.setCountry=='' &&(
                                                  <div className="selecterrormsg">
                                                  {errors.country}
                                                  </div>
                                                )}   
                                              
                                              {/* { !this.state.setCountry && <FormHelperText>Please select country</FormHelperText>} */}
                                                        {/* <CountryDropDown enabled={true} activeOnly={true} SelectedValue={auth.getCountry()} onChange={(val) => {
                                                             auth.setCountry(val);
                                                             window.location.reload();
                                                             setFieldValue("country", val)
                                                        }} /> */}

                                        </FormControl>
                                    </div>


                                    <div className="com-field col-lg-6">
                                        <FormControl margin="normal" fullWidth error={errors.state && touched.state}>
                                             <InputLabel id="demo-simple-select-label">State</InputLabel>
                                             <StateDropDown  country = {this.state.setCountry} enabled={true} activeOnly={true} onStateChange={e=>{
                                                 setFieldValue("state", e.target.value);
                                             }
                                             }/>
                                              {errors.state && touched.state && (
                                                  <div className="selecterrormsg">
                                                  {errors.state}
                                                  </div>
                                                )} 
                                             
                                        </FormControl>
                                    </div>

                                    <div className=" com-field col-lg-6">
                                        <Field type="text"
                                            placeholder="Postal Code"
                                            name="postal_code"
                                            label="Postal Code"
                                            component={TextField}
                                            margin="normal"
                                            error
                                            fullWidth
                                        />
                                    </div>
                                    <div className="com-field col-lg-6">
                                        <FormControl margin="normal" fullWidth error={errors.custtype && touched.custtype } >
                                             <InputLabel id="demo-simple-select-label">I am a</InputLabel>
                                             <UserTypeDropDown name="custtype" enabled={true} activeOnly={true} onCustTypeChange={e=>{
                                                 setFieldValue("custtype", e.target.value);
                                                 this.state.setUserType = e.target.value;                         
                                             }
                                             }/>
                                             {errors.custtype && touched.custtype && this.state.setUserType=='' &&(
                                                  <div className="selecterrormsg">
                                                  {errors.custtype}
                                                  </div>
                                                )}   
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="regi-btn">
                                <Button  variant="contained" type="submit" color="primary" className="submit-btn" disabled={isSubmitting}> Submit </Button>
                                </div>
                            </Form>

                        )}
                    </Formik>

                </CardContent>
            </React.Fragment>
        )


    }
}

const mapStateToProps = state => {
    return {
         
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onRegisterCustomer: (data,actions) => {
            return new Promise((resolve, reject) => {
                dispatch(registerCustomerRequest(data,actions)).then(response => {
                    
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        }
    }
}

RegisterCustomer = connect(mapStateToProps, mapDispatchToProps)(RegisterCustomer);
export default RegisterCustomer;