import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import logo from '../../../../images/logo.png';
import thkLogo from '../../../../images/thk.png';
import Auth from '../../../../utils/Auth';
import { ACTIONS } from '../../../../utils/Constants';
import Header from '../../../Authentication/components/Navigation/Header';
import Manufacturers from '../../../ManageManufacturers/components/Manufacturers/Manufacturers';
import GuestLogin from '../GuestLogin/GuestLogin';
import RegisterCustomer from '../Register/registerCustomer';
import SignIn from '../SignIn/SignIn';
import './MasterSignin.css';
import Cookies from 'universal-cookie';
import {APP_DETAILS} from '../../../../utils/Constants'
import Footer from '../../../Common/components/Footer/Footer';
const queryString = require('query-string');
const auth=new Auth();
const cookies = new Cookies();
class Mastersignin extends Component {

    constructor(props) {
        super(props);

        // const parsed = queryString.parse(props.location.search);
        // console.log({ parsed })

        let { from } = props.location.state || { from: { pathname: ACTIONS.ACCESS_PAGE } };
        var d = new Date();
        this.state = {
            currentyear :d.getFullYear(),
            visible: true,
            showButton: true,
            isAdmin: auth.getUserType(),
            logo: logo,
            whichComponent: "SignIn",
            thkLogo: thkLogo,
            green: true,
            white: true,
            from
        };

    }
    handleChildClick() {
        this.setState({ showButton: false });
    }
    SignInClick = () =>{
        window.location.href = "/#"+ ACTIONS.SIGN_IN;
        window.location.reload();
        this.setState({ whichComponent: "SignIn",white: !this.state.white, green: !this.state.green});
    }

    RegisterClick= () =>{
        this.setState({ whichComponent: "RegisterCustomer",white: !this.state.white, green: !this.state.green});
    }

    componentDidMount() {

    }

    render() {
        let isloginbtn = this.state.whichComponent == "SignIn" ? true:false;
        let btn_green = isloginbtn == true ? "greenButton" : "whiteButton" ;//this.state.green ? "greenButton" : "whiteButton";
        let btn_white = isloginbtn == false ? "greenButton" : "whiteButton" ;//this.state.white ? "whiteButton" : "greenButton";
        const topheight = {
            padding: '200px 0 30px',

        };

        const roundGreen = {
            borderRadius: '20px 0px 0px 20px'
        }
        const roundWhite = {
            borderRadius: '0px 20px 20px 0px'
        }
       
        if (this.props.isLoggedIn) {
            return <Redirect to="/home"/>
        } 
        else if (this.state.isAdmin){
            cookies.remove('isAdmin', {path: '/'});
            return <Redirect to="/admin/signin"/>
        }
        else {
            if (this.state.whichComponent === "SignIn") {

                return (
                    <React.Fragment>
                        <Header Logo={this.state.logo}></Header>
                        <Container >
                            <h2 className="text-center heading signin-heading">Your Linear Motion - Bellows calculator</h2>
                            <Card>

                                <div className="row">
                                    <div className="col-lg-6 col-sm-12 user-login">
                                        <CardContent>
                                            
                                            {this.state.showButton ?
                                                <div className="text-right btn-toggle-center">
                                                    <Button id="signIn" className={"btn-toggle-login "+  btn_green}
                                                        onClick={this.SignInClick}
                                                    >Login</Button>


                                                    <Button id="registerCustomer"  className={"btn-toggle-register " +btn_white}
                                                        onClick={this.RegisterClick}
                                                        >Register</Button>
                                                </div>
                                                : <></>}
                                            <SignIn data={this.state.whichComponent} handleChild={this.handleChildClick.bind(this)} />
                                        </CardContent>


                                    </div>
                                    <div className="col-lg-6 col-sm-12 guest-login">
                                        <GuestLogin />
                                    </div>
                                </div>

                            </Card>
                        </Container>
                        < Manufacturers />
                        <div className="loader-parent d-none" id="loaderdiv">
                            <div className="loader loadercenter">
                                <i className="fa fa-spinner fa-spin" />
                            </div>
                        </div>
                        <div className="main-footer">
                        <Container fluid="true" className="footer-copyright footer-max-width" >
                <Container>
                    <div className="row">
                        <div className="col-lg-12">
                        <p className="footer-copyright">Copyright © {this.state.currentyear} {APP_DETAILS.APPLICATION_NAME_FOOTER}, <span>All Rights Reserved</span></p>
                        </div>
                    </div>
                </Container>
            </Container>
            </div>
                    </React.Fragment>
                )

            } else if (this.state.whichComponent === "RegisterCustomer") {

                return (
                    <React.Fragment>
                        <Header Logo={this.state.logo}></Header>
                        <Container >

                            <h2  className="text-center heading signin-heading">Your Linear Motion - Bellows calculator</h2>
                            <Card>

                                <div className="row">
                                    <div className="col-lg-6 user-login">
                                        <CardContent>
                                            <div className="text-right  btn-toggle-center">
                                                <Button id="signIn" style={roundGreen}  className={"btn-toggle-login "+  btn_green}
                                                    onClick={this.SignInClick}
                                                >Login</Button>


                                                <Button id="registerCustomer" style={roundWhite}  className={"btn-toggle-register "+  btn_white}
                                                    onClick={this.RegisterClick}
                                                >Register</Button>
                                            </div>

                                            <RegisterCustomer />



                                        </CardContent>
                                    </div>
                                    <div className="col-lg-6 guest-login">
                                        <GuestLogin />
                                    </div>
                                </div>

                            </Card>
                        </Container>
                        < Manufacturers />
                        <div className="loader-parent d-none" id="loaderdiv">
                    <div className="loader loadercenter">
                        <i className="fa fa-spinner fa-spin" />
                    </div>
                </div>
                        <Container fluid="true" className="footer-copyright footer-max-width" >
            <Container>
                <div className="row">
                    <div className="col-lg-12">
                    <p className="footer-copyright">Copyright © {this.state.currentyear} {APP_DETAILS.APPLICATION_NAME_FOOTER}, <span>All Rights Reserved</span></p>
                       
                    </div>
                </div>
               
                </Container>
            </Container>
                    </React.Fragment>
                )
            }

        }
    }
}
const mapStateToProps = state => {
    return {
        isLoggedIn: state.auth.isLoggedIn
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {


    }
}

Mastersignin = connect(mapStateToProps, mapDispatchToProps)(Mastersignin);

export default Mastersignin;