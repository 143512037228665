import * as Yup from "yup";
import { REGEX, REGEX_ERROR } from "../../../../utils/Constants";

const RequestGuestLoginSchema = Yup.object().shape({
        name: Yup.string()
        .required('Please Enter First Name')
        .matches(REGEX.ALPHABETS_WITH_SPACE,REGEX_ERROR.ALPHABETS_WITH_SPACE_ERROR),

        lastname: Yup.string()
        .matches(REGEX.ALPHABETS_WITH_SPACE,REGEX_ERROR.ALPHABETS_WITH_SPACE_ERROR)
        .required('Please Enter Last Name'),

        company: Yup.string()
        .required('Please Enter Company')
        .matches(REGEX.ALPHABETS_WITH_SPACE,REGEX_ERROR.ALPHABETS_WITH_SPACE_ERROR),

        email: Yup.string()
        .required('Please Enter Email')
        .matches(REGEX.EMAIL, REGEX_ERROR.EMAIL_ERROR)
});

export default RequestGuestLoginSchema;