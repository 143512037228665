import { UPDATE_SAVED_PART_NUMBER } from './SavedPartNumberActions';


const initialState = { users: [], savedPartNumber: [], totalRecords: 0 };

const savedPartNumber = (state = initialState, action) => {

    switch (action.type) {

        case UPDATE_SAVED_PART_NUMBER:

            if (action.PageNumber === 0)
                return { ...state, savedPartNumbers: action.savedPartNumber, totalRecords: action.totalRecords };
            else {
                let currentsavedPartNumbers = [];

                currentsavedPartNumbers = action.savedPartNumber;
                return Object.assign({}, state, {
                    savedPartNumber: currentsavedPartNumbers,
                    totalRecords: action.totalRecords,
                });
            }
        default:
            return state;
    }
}

export default savedPartNumber;