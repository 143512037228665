import React, { Component } from 'react'

import { SafeAnchor, Modal } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
const StyledReactTooltip = styled(ReactTooltip)`
  background-color: white !important;
  color: black !important;
  
  &:after {
    border-top-color: white !important;
  }
`;


const { Header, Body, Footer, Title } = Modal;

export default class CalculatorForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showform: false
        }
    }

    componentWillReceiveProps(props) {
        this.setState({ calc: props.calc });
    }

    showForm = () => {
        this.setState({ showform: true })
    }


    hideForm = () => {
        this.setState({ showform: false })
    }

    render() { 
        let stateData = this.props.calc;
        const handleChange = event => {
            this.setState({ value: event.target.value });
        };
        
        return (

            <>

                {!this.state.showform ?<>
                    <Icon data-tip data-for="edit" onClick={this.showForm}>
                       edit
                    </Icon>
                      <StyledReactTooltip id="edit" effect="solid">
                      <span>Edit</span>
                     </StyledReactTooltip></>
                    : <>
                        <Modal className=" calculatorPopup container fixed-head-footer" show={this.state.showform} onHide={this.hideForm}>
                            <Header closeButton>
                                <Title>{stateData.ManufacturerName}</Title>
                            </Header>
                            <Body>
                                <Formik
                                    onSubmit={this.props.editCalculator}
                                    initialValues={this.props.calc}
                                >
                                    {({ status, isSubmitting,setFieldValue }) => (
                                        <Form>
                                             <div className="row">
                                             <div className="col-lg-6">
                                                     <Field type="text" name="CalculatorName"  id="standard-required" 
                                                        label="TITLE"
                                                        margin="normal"
                                                        fullWidth
                                                        component={TextField}
                                                        // placeholder="Calculator Name"
                                                        error />
                                            </div>    
                                            <div className="col-lg-6">                                  
                                            <FormControl style={{ marginTop: 6}}>  
                                                <InputLabel id="demo-simple-select-label">Published</InputLabel>                                                                          
                                                <Select
                                                className="role_dropdown"  defaultValue = {stateData.IsDeveloped}
                                                labelId="demo-controlled-open-select-label"
                                                id="IsDeveloped"
                                                label="Is Developed"
                                                key=''
                                                data-attribute='IsDeveloped'
                                                name='IsDeveloped'
                                                //onChange = { this.handleRoleTypeChange } 
                                                onChange = {(e)=>
                                                    { 
                                                    setFieldValue('IsDeveloped', e.target.value) 
                                                    }
                                                } 
                                                > 
                                                <MenuItem value={true}>Yes</MenuItem>
                                                <MenuItem value={false}>No</MenuItem>

                                                </Select>
                                            </FormControl>
                                         </div>
                                           
                                             </div>                                         
                                                       
                            
                                            {/* <input type="Submit" className="submit" disabled={isSubmitting} /> */}
                                           
                                            <Modal.Footer className="row" centered>
                                              <Button variant="contained" type= "submit"  className="save" disabled={isSubmitting} >Submit</Button>
                                            </Modal.Footer>
                                           
                                        </Form>
                                    )}
                                </Formik>
                            </Body>
                        </Modal>

                    </>}
            </>
        )
    }
}
