import { combineReducers } from 'redux';
import auth from './modules/Authentication/AuthenticationReducer';
import dashboard from './modules/Dashboard/DashboardReducer';
import common from './modules/Common/CommonReducer';
import manageUser from './modules/ManageUsersNew/ManageUsersReducer';
import manageCustomer from './modules/ManageCustomers/ManageCustomersReducer';
import role from './modules/ManageRoles/ManageRolesReducer';
import manufacturer from './modules/ManageManufacturers/ManageManufacturersReducer';
import calculator from './modules/ManageCalculator/Components/CalculatorReducer';
import attribute from './modules/ManageAttributes/components/ManageAttributeReducer';
import managePricing from './modules/ManagePricing/ManagePricingsReducer';
import calculatorOutput from './modules/Calculators/CalculatorOutputReducer';
import accessCalculator from './modules/Accesscalculator/AccessPageReducer';
import savedPartNumber from './modules/Savedpartno/SavedPartNumberReducer';

export default combineReducers({
    auth,
    dashboard,
    common,
    manageUser,
    manageCustomer,
    role,
    manufacturer,
    calculator,
    attribute,
    managePricing,
    calculatorOutput,
    accessCalculator,
    savedPartNumber
});