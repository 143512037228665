import React, { Component } from 'react'
import { TextField } from 'formik-material-ui';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Modal, Button, SafeAnchor, ButtonGroup, Container } from 'react-bootstrap';
import './adduser.css';
import RoleDropDown from '../../../Common/components/DropDown/RoleDropDown';
import { connect } from 'react-redux';
import { saveUserItem } from '../../ManageUsersApiActions';
import AddEditUserSchema from './AddEditUserSchema';
import { fontSize } from '@material-ui/system';

class Adduser extends Component {
    constructor(props) {
        super(props);
        let user = {
            email_id: '',
            first_name: '',
            last_name: '',
            role_id: 0,
            manufacturer: ''
        };

        this.state = {
            user: user
        }
    }


    saveUser = (data, formAction) => {

        data.user_id = 0
        formAction.setSubmitting(true);
        this.props.saveUser(data, formAction).then(response => {
            this.setState({ message: response.message });
            formAction.setSubmitting(false);
            if (this.props.updateList && typeof this.props.updateList === 'function') {
                this.props.updateList();
            }
            this.props.onHide();
        }, error => {
            formAction.setSubmitting(false);
        });

    };

    render() {
        // const handleChange = event => {
        //     this.setState({ value: event.target.value });
        // };
        // const handleChangeCheck = (e) =>{
        //     this.setState({[e.target.name]: e.target.checked})
        // }
        return (

            <React.Fragment>
                <Modal className="userPopup"
                    {...this.props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header color="secondary" closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Add User
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik
                            validationSchema={AddEditUserSchema}
                            initialValues={this.state.user}
                            onSubmit={this.saveUser}
                            enableReinitialize={true}
                        >
                            {({ errors, status, touched, isSubmitting, setFieldValue }) => (
                                <Form autoComplete="off" >
                                    <div className="row">
                                        <div className="col-lg-4 com-field">

                                            <Field type="text"
                                                name="email_id"
                                                placeholder="Enter Email"
                                                component={TextField}
                                                id="standard-required"
                                                label="User Name"
                                                margin="normal"
                                                // defaultValue={data.first_name}
                                                fullWidth
                                            />

                                        </div>

                                        <div className="col-lg-4 com-field">
                                            <Field type="text"
                                                name="first_name"
                                                placeholder="Enter First Name"
                                                component={TextField}
                                                id="standard-required"
                                                label="First Name"
                                                margin="normal"
                                                // defaultValue={data.first_name}
                                                fullWidth
                                            />

                                        </div>

                                        <div className="col-lg-4 com-field">
                                            <Field type="text"
                                                name="last_name"
                                                placeholder="Enter Last Name"
                                                component={TextField}
                                                id="standard-required"
                                                label="Last Name"
                                                margin="normal"
                                                ErrorMessage=""
                                                // defaultValue={data.first_name}
                                                fullWidth
                                            />

                                        </div>


                                        <div className="col-lg-4 com-field">

                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                                                <RoleDropDown isAdmin={true} isGuest={false} enabled={true} activeOnly={true} onRoleChange={e => {
                                                    setFieldValue("role_id", e.target.value);
                                                }
                                                } />
                                                
                                            </FormControl>
                                            <ErrorMessage
                                                    name="role_id"
                                                    style={{ color: "#f44336", fontSize: 11 }}
                                                    component="div"
                                                />
                                        </div>


                                    </div>
                                    <Modal.Footer className="row" centered>
                                        <Button variant="contained" type="submit" className="save">Save</Button>
                                        <Button variant="contained" className="cancle" onClick={this.props.onHide}>Close</Button>
                                    </Modal.Footer>
                                </Form>
                            )}

                        </Formik>
                    </Modal.Body>

                </Modal>
            </React.Fragment>
        )
    }
}

const mapProps = state => ({
})

const mapDispatch = (dispatch, ownProps) => {

    return {
        saveUser: (user, formAction) => {

            return new Promise((resolve, reject) => {
                dispatch(saveUserItem(user, formAction)).then(response => {

                    resolve(response);
                    // if (response.status == 200 && !response.data.errorCode) {
                    //     window.Message('Saved Successfully', discount.id > 0 ? `${discount.name} Updated....` : `${discount.name} Created....`);
                    // }
                }).catch(error => {
                    reject(error);
                });
            });
        }
    }
}
export default connect(mapProps, mapDispatch)(Adduser);