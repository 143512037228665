import {SERVER_ACTIONS} from '../../utils/Constants';
import HttpRequest from '../../utils/HttpRequest';
import { getUserManufacterersImage } from './AccessPageActions';


export function getUserManufacturersImageList(id, isAdmin) {

    return (dispatch) => {
        const queryString =  '?id='+id+'&isAdmin='+isAdmin ;
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_USER_CALCULATORS}${queryString}`, 'get', {})
            .requestWithAuth()
            .then(response => {
                
                if (response.status === 200 && !response.data.errorCode) {

                dispatch(getUserManufacterersImage(response.data.Data));
                return Promise.resolve(response.data.Data);
                }
                else{
                    return Promise.reject(response.data);
                }
            }, error => {
                return Promise.reject(error);
            });
    }
}


