import React, { Component } from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Switch from '@material-ui/core/Switch';
import Pagination from 'react-bootstrap/Pagination';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
//import enUS from 'date-fns/locale/en-US';
//import { enUS } from 'date-fns/locale';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { TextField } from 'formik-material-ui';
import { Formik, Form, Field } from 'formik';
import ManufacturerDropDown from '../../Common/components/DropDown/ManufacturerDropDown';
import Loader from "../../Common/components/Loader";


import { getAllManufacturerList,addCalculator, getCalculatorList, getCalculatorListByManufacturerId,changeCalculatorStatus, editCalculatorById, deleteCalculatorById, getFormulaByCalculatorId } from '../../ManageCalculator/Components/CalculatorApiAction';
import { connect } from 'react-redux';
import './CalculatorList.css';
import { SafeAnchor, Row, DropdownButton } from 'react-bootstrap';
import CalculatorForm from './CalculatorForm';
import AddCalculator from './AddCalculator';
import ManageFormula from './ManageFormula';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import Moment from 'moment';
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
const StyledReactTooltip = styled(ReactTooltip)`
  background-color: white !important;
  color: black !important;
  
  &:after {
    border-top-color: white !important;
  }
`;


class CalculatorList extends Component {

    constructor(props) {
       
        super(props);
        this.state = {
            prevmanufacturer_id:0,           
            prevmanufacturer2_id:0,
            manufacturer_name:"",
            manufacturer_id:0,
            manufacturerList:[],
            active: false,
            checked: true,
            addModalShow: false,
            defaultPageSize: 10,
            PageNumber: 1,
            totalPages: 1,
            pageheading: 'Manage Calculator',
            data: {},
            loading: true
        }



    }

    // componentWillReceiveProps(props) {
       
    //     this.setState({
    //         manufacturer_id:props.manufact
    //             });
    // }

    componentDidMount() {
        this.props.getManufacturerList().then(resp => {
            
            this.setState({
              
                 manufacturer_id:resp[0].manufacturer_id,

            });

        });
        this.getCalculatorList(this.state.PageNumber);

    }
    componentDidUpdate(){
    //     const manufacturerValue=this.state.manufacturerList[0]!=undefined?(this.state.manufacturerList[0]):(0);
    //            
    //     const manufacturerId=manufacturerValue.manufacturer_id
    //    if(this.state.prevmanufacturer2_id!=manufacturerValue.manufacturer_id)
    //    {
    //     //    this.setId(sizeValue.SizeId);
    //        this.setState({manufacturer_id:manufacturerValue.manufacturer_id,manufacturer_name:manufacturerValue.display_name});
    //        this.getCalculatorList(this.state.PageNumber);
    //       this.setState({prevmanufacturer2_id:manufacturerValue.manufacturer_id});
        
    //    }
    //     else
     if(this.state.prevmanufacturer_id!==this.state.manufacturer_id){
            this.setState({loading: true})
            this.getCalculatorList(this.state.PageNumber);
            this.setState({prevmanufacturer_id:this.state.manufacturer_id,loading: false})
        }
            
        
        
    }

    componentWillReceiveProps(props) {
        //console.log({ props });
    }
    // getManufacturerList= () => {
                
    //     getAllManufacturerList().then(resp => {
            
    //         this.setState({
    //             manufacturer_id:resp[0]!=undefined?(resp[0].manufacturer_id):(0),
    //             manufacturerList:resp,
               
    //         });

    //     });
    // }
   
    updateList = () => {
       this.getCalculatorList(1);
        this.setState(Object.assign({}, this.state));
    };
    
    toggleStatus = (calculatorId) => {

        this.props.changeCalculatorStatus(calculatorId).then(resp => {
           // console.log({ response: this.props });
            this.updateList();
        });

    }
    addCalculator = (data) => {

        this.props.addCalculator(data).then(resp => {
            getCalculatorList(this.state.PageNumber);
            this.updateList();
        });

    }
    editCalculator = (data, action) => {

        this.props.editCalculator(data, action).then(resp => {

            this.updateList();
        });

    }



    deleteCalculator = (calculatorId) => {

        Swal.fire({
            //title: 'Are you sure?',
            text: 'Are you sure want to delete?',
            // icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
            showCloseButton: true
          }).then((result) => {
            if (result.value) {
                this.props.deleteCalculator(calculatorId, 1).then(resp => {
                        
                                    //console.log({ response: this.props });
                                    this.updateList();
                                    //getCalculatorList(this.state.PageNumber);
                                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              
            }
          })

        // swal("Are you sure want to delete?", {
        //     buttons: {
        //         catch: {
        //             text: "Yes",
        //             value: "Yes",
        //         },
        //         cancel: "Cancel",
        //         // close: "X",
        //     },
        // })
        //     .then((value) => {
        //         switch (value) {
        //             case "Yes":
                   
        //             this.props.deleteCalculator(calculatorId, 1).then(resp => {
                        
        //                 //console.log({ response: this.props });
        //                 this.updateList();
        //                 //getCalculatorList(this.state.PageNumber);
        //             });
                        
        //                 break;
        //             default:

        //         }
        //     });

        


    }
    getFormulaList = (calculatorId) => {

        this.props.getFormulaList(calculatorId).then(resp => {

            this.updateList();
        });

    }
    handlePageClick = (e, index) => {
        //   this.setState({
        //      PageNumber: index
        //   });
        let PageNumber = index;
        this.getCalculatorList(PageNumber);

    }


    getCalculatorList(PageNumber) {

        const { defaultPageSize } = this.state;
        this.state.PageNumber = PageNumber;

        if (PageNumber > 0) {
            const query = 'manufacturerId=' + this.state.manufacturer_id + '&PageNumber=' + PageNumber + '&PageSize=' + defaultPageSize + (this.state.query !== '' ? '&q=' + this.state.query : '');
            this.props.getCalcList({query, PageNumber, defaultPageSize }).then(resp => {
                const numberOfRecords = this.props.calculatorList.totalrecord;

                const totalPages = this.props.calculatorList.totalpages;


                let data = this.props.calculatorList.data;
                this.setState({
                    numberOfRecords,
                    totalPages,
                    data: data.data
                });

            }).catch(error => {
                window.location.href="#/page403"
                //window.location.reload();
                });
        }

    }

    renderPagination = () => {

        let items = [];
        if (this.state.data !== [] && this.state.data != undefined) {
            // for (let i = 1; i <= this.state.totalPages; i++) {

            //     items.push(<Pagination.Item className={this.state.PageNumber === i ? "active" : ""} onClick={e => this.handlePageClick(e, i)} >{i}</Pagination.Item>);

            // }
            let j=3;
            for (let i = 1; i <= this.state.totalPages; i++) {
                if(this.state.PageNumber>=i && this.state.PageNumber+3<this.state.totalPages){
                    i=this.state.PageNumber;
                    j=this.state.PageNumber+3;
                }
                if(this.state.PageNumber+3>=this.state.totalPages){
                    if(this.state.totalPages-3>0)
                    {if(i<this.state.totalPages-3)
                        i=this.state.totalPages-3;
                        j=i+3;
                    }
                    else if(i==this.state.totalPages){
                        i=this.state.totalPages;
                    }
                    else{
                        if(i<3-this.state.totalPages){
                            i=3-this.state.totalPages;
                        }
                       }
                }
               
                if(i<j || i==this.state.totalPages)
                {
                    items.push(
                        <Pagination.Item
                            className={this.state.PageNumber === i ? "active" : ""}
                            onClick={e => this.handlePageClick(e, i)} >
                            {i}
                        </Pagination.Item>
                    );

                }
              if(i<j && this.state.totalPages-i==1)
                {
                }
                else
               
                 {
                    if(i==this.state.totalPages-1){
                        items.push(<Pagination.Ellipsis />)
                          }
                  }   
            }
            return items;
        }
    }


    renderTableData = () => {

        const adduser = {
            clear: 'both',
            float: 'right',
            borderRadius: '20px',
            background: '#01524B',
            color: '#fff',
            fontWeight: 'normal'
        }
        if (this.state.data && this.state.data != undefined && this.state.data.length > 0) {

            return this.state.data.map((data, index) => {
                const { CalculatorName, ManufacturerName, CalculatorId, Is_Active, ManufacturerId, Created_On, IsDeveloped } = data //destructuring


                return (


                    <TableRow key={CalculatorId}>

                        <TableCell>{index + 1}</TableCell>

                        <TableCell>{CalculatorName}</TableCell>
                        <TableCell>{ManufacturerName}</TableCell>
                        {/* <TableCell>{new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit',hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(Created_On)}</TableCell> */}
                        {/* <TableCell>{Created_On}</TableCell> */}
                        <TableCell>{Moment(Created_On).format('YYYY-MM-DD HH:mm')}</TableCell>
                        <TableCell>{IsDeveloped ? <span>Yes</span> : <span>No</span>}</TableCell>
                        <TableCell>
                            <> 
                                <SafeAnchor onClick={() => this.toggleStatus(CalculatorId)}>
                                    {Is_Active ? <i className="fa fa-toggle-on fa-2x success"></i>
                                        : <i className="fa fa-toggle-off fa-2x red"></i>}
                                </SafeAnchor> 
                            </>
                        </TableCell>
                        <TableCell scope="row">
                        <div style={{'display':"flex"}}>
                        <CalculatorForm  calc={data} editCalculator={this.editCalculator} ></CalculatorForm>
                       
                            {/* <Icon
                                className="icons"
                                onClick={() => 
                                    this.setState({ edituserModalShow: true,
                                    user: data,
                                    update: true
                                 })}
                            >edit
                                        </Icon> */}
                            {/* <Icon onClick={() =>this.deleteUser(user_id, modified_by)}>delete</Icon> */}
                            <Icon  data-tip data-for="delete"  onClick={() => this.deleteCalculator(CalculatorId)}>delete
                                  
                                </Icon>
                                <StyledReactTooltip id="delete" effect="solid">
                                         <span>delete</span>
                                        </StyledReactTooltip>
                               
                            </div>
                        </TableCell>
                        <TableCell>
                            {/* <Button style={adduser}
                            onClick={() => this.getFormulaList(1) }
                        >Manage Formula</Button> */}
                            <>
                                <ManageFormula className="ManufacturerPopup" calc={data} 
                                ></ManageFormula>
                            </>

                        </TableCell>


                    </TableRow>

                )
            })
        }
    }

    handleChange = (e) => {

        this.setState({
            checked: e.target.checked
        })
    }



    handleChangeDropdown = (event, index) => {
       
    };

    render() {
      
        // if (this.state.loading) 
        // {
        //     return <Loader />;
        // }
        
        let addModalClose = () => {
            this.setState({ addModalShow: false });
        }
        const tableGray = {
            background: '#f1f3f6',

        }
        const mtt = {
            marginTop: '20px',
            marginTop: '20px',
            width:'100%',
            overflowX: 'auto'
        }

        const adduser = {
            clear: 'both',
            float: 'right',
            borderRadius: '20px',
            background: '#01524B',
            color: '#fff',
            fontWeight: 'normal'
        }

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-5"  style={{padding:15}}>
                        <h2 style={{ 'clear': 'both', 'display': 'inline' }} className="heading">{this.state.pageheading}</h2>
                    </div>
                    <div className="col-lg-3">
                    <Formik
                        onSubmit={this.props.editCalculator}
                        initialValues={{
                            manufacturer_name: '',
                             
                        }}

                    >

                        {({ status, isSubmitting, setFieldValue }) => (
                            <Form className="Manufacturerform pt-1" name="manageCalculator" noValidate autoComplete="off"> 
                                    <FormControl margin="normal" fullWidth>
                                    <div className="ManufacturerDropDown">
                                        <InputLabel id="demo-simple-select-label">MANUFACTURER*</InputLabel>
                                        
                                        <ManufacturerDropDown  selectedValue={this.state.manufacturer_id} enabled={true} activeOnly={true}  enabled={true} activeOnly={true} onManufacturerChange={(e, index) => {
                                            setFieldValue("manufacturer_list", e.target.value);
                                            setFieldValue("manufacturer_name", e.target.name);
                                            this.handleChangeDropdown(e, index);
                                            this.setState({
                                                manufacturer_id: e.target.value,
                                                manufacturer_name: index.props.name
                                            });
                                            // this.getCalculatorList(1);
                                        }
                                        } />
                                    </div>

                                    </FormControl> 
                            </Form>
                        )
                        }
                    </Formik>
                    </div>
                    <div className="col-lg-4" style={{padding:15}}>
                        <AddCalculator 
                            show={this.state.addModalShow}
                            onHide={addModalClose}
                            update = {this.state.update}
                            updateList = {this.updateList}
                            manufacturer_name={this.state.manufacturer_name} 
                            manufacturer_id={this.state.manufacturer_id} 
                            addCalculator={this.addCalculator} 

                        />
                    </div>
                </div>


                {/* <Button style={adduser}
                    onClick={() => this.setState({ addModalShow: true })}
                >Add Calculator</Button> */}
                 <div style={mtt}>


                <Table className="table-striped listingTable">
                    <TableHead style={tableGray}>
                        <TableRow>
                            {
                                <>
                                    <TableCell >Sr.No.</TableCell>
                                    <TableCell >Title</TableCell>
                                    <TableCell >Manufacturer</TableCell>
                                    <TableCell >Created Date</TableCell>
                                    <TableCell >Published</TableCell>
                                    <TableCell >Status</TableCell>
                                    <TableCell >Action</TableCell>
                                    <TableCell ></TableCell>
                                </>

                            }

                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            this.renderTableData()
                        }
                    </TableBody>

                </Table>
                </div>

                <div className="custom-pagination">
                    <Pagination>
                        <Pagination.First onClick={e => this.handlePageClick(e, 1)} />
                        <Pagination.Prev onClick={e => this.handlePageClick(e, this.state.PageNumber > 1 ? this.state.PageNumber - 1 : this.state.PageNumber)} />
                        {this.renderPagination()}
                       
                        {/* <Pagination.Item>{10}</Pagination.Item> */}
                        <Pagination.Next onClick={e => this.handlePageClick(e, this.state.PageNumber < this.state.totalPages ? this.state.PageNumber + 1 : this.state.PageNumber)} />
                        <Pagination.Last onClick={e => this.handlePageClick(e, this.state.totalPages)} />
                    </Pagination>
                </div>



            </React.Fragment>
        )
    }




}

const mapStateToProps = state => {
    
    return {
        
        manufacturer_id:state.calculator.manufacturerList,
        calculatorList: state.calculator.calculatorList,
        totalPages: state.calculator.totalPages,
        // manufacturerList:state.calculator.manufacturerList,
       

    }
};


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        // getCalcList: (data) => {
        //     return new Promise((resolve, reject) => {
        //         dispatch(getCalculatorList(data)).then(response => {
        //             resolve(response);
        //         }).catch(error => {
        //             reject(error);
        //         });
        //     });
        // },
        getManufacturerList: () => {
            return new Promise((resolve, reject) => {
                dispatch(getAllManufacturerList()).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },

        getCalcList: (data) => {
                return new Promise((resolve, reject) => {
                    
                    dispatch(getCalculatorListByManufacturerId(data)).then(response => {
                        resolve(response);
                    }).catch(error => {
                        reject(error);
                    });
                });
            },


        addCalculator: (data) => {
            return new Promise((resolve, reject) => {
                dispatch(addCalculator(data.ManufacturerId, data.CalculatorName, 1,data.IsDeveloped)).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },

        changeCalculatorStatus: (data) => {
            return new Promise((resolve, reject) => {
                dispatch(changeCalculatorStatus(data)).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });


            });


        },
        editCalculator: (data, action) => {
            return new Promise((resolve, reject) => {
                dispatch(editCalculatorById(data.CalculatorId, data.ManufacturerId, data.CalculatorName, 1, data.IsDeveloped)).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });


            });


        },
        deleteCalculator: (calculatorId) => {
            return new Promise((resolve, reject) => {
                dispatch(deleteCalculatorById(calculatorId, 1)).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });


            });


        }
    }
}

CalculatorList = connect(mapStateToProps, mapDispatchToProps)(CalculatorList);
export default CalculatorList;