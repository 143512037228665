import * as Yup from "yup";
import { REGEX, REGEX_ERROR } from "../../../../utils/Constants";
const SetPasswordSchema = Yup.object().shape({
    password: Yup.string()
    //.matches(REGEX.PASSWORD, REGEX_ERROR.PASSWORD_ERROR)
    .required('Please Enter Password'),
    confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], "Password don't match")
    .required('Please Enter Confirm Password')
});

export default SetPasswordSchema;