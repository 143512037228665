import React, { Component } from 'react';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { getBlockTypeList } from '../../CommonApiActions';

class BlockTypeDropDown extends React.Component {
    constructor(props) {
        super(props);
        //console.log(this.props.railId);
        this.state = ({
            allTypes: [],
            railId: this.props.railId
        })
    }

    componentWillReceiveProps(props) {
        if(props.railId!= undefined && props.railId !=this.state.railId){
          this.props.getBlockTypeLists(props.railId);
          this.state.railId = props.railId;
        }
    }

    componentDidMount() {
        this.props.getBlockTypeLists(this.state.railId);
    }

    render() {
        const selectedValue = this.props.selectedValue;
        // const selectedName = this.props.selectedName;
        
        //console.log(selectedValue);
        const types = this.props.allTypes;
        const options = types && types.length > 0 ? (
            types.map((type) => {

                return (<MenuItem name={type.BlockType} value={type.BlockID}>
                        {type.BlockType}
                        </MenuItem>
                );
            })
        ) : (
                <MenuItem>Loading....</MenuItem>
            );
        //console.log({ options });
        return (

            <Select value={selectedValue}
                // name= {selectedName}
                labelId="demo-simple-select-label"
                id="demo-simple-select" onChange={this.props.onTypeChange}>
                {options}
            </Select>
        );
    }
}

const mapProps = (state) => {
   
    return {
        allTypes: state.common.allTypes
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getBlockTypeLists: (railId) => {
            return new Promise((resolve, reject) => {
                dispatch(getBlockTypeList(railId))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    };
};

export default connect(mapProps, mapDispatchToProps)(BlockTypeDropDown);
