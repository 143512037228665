import { func } from "prop-types";

export const UPDATE_MANUFACTURER = 'UPDATE_MANUFACTURER';
export const ADD_MANUFACTURER_TO_LIST = 'ADD_MANUFACTURER_TO_LIST';
export const UPDATE_MANUFACTURER_IN_LIST = 'UPDATE_MANUFACTURER_IN_LIST';
export const DELETE_MANUFACTURER_FROM_LIST = 'DELETE_MANUFACTURER_FROM_LIST';
export const GET_ALL_MANUFACTURERS = 'GET_ALL_MANUFACTURERS';

export function updateManufacturers(manufacturers, totalPages, currentPage) {
    return {
        type: UPDATE_MANUFACTURER,
        manufacturers,
        totalPages,
        currentPage
    }
}

export function getAllManufacterers(allManufacturers){
    return{
        type: GET_ALL_MANUFACTURERS,
        allManufacturers
    }
}
export function addManufacturerToList(manufacturer) {
    return {
        type: ADD_MANUFACTURER_TO_LIST,
        manufacturer
    }
}

export function updateManufacturerInList(manufacturer) {
    return {
        type: UPDATE_MANUFACTURER_IN_LIST,
        manufacturer
    }
}

export function deleteManufacturerFromListItem(manufacturer) {
    return {
        type: DELETE_MANUFACTURER_FROM_LIST,
        manufacturer
    }
}


