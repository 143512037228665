import { SERVER_ACTIONS } from '../../utils/Constants';
import HttpRequest from '../../utils/HttpRequest';
import { getAllManufacterers, updateManufacturers, addManufacturerToList, updateManufacturerInList, deleteManufacturerFromListItem } from './ManageManufacturersActions';


// export function addManufacturer(manufacturerId, name, created_By) {
//     return (dispatch) => {
//         const parameters = {};
//         //parameters.CalculatorId =0;
//         parameters.manufacturer_id = manufacturerId;
//         parameters.name = name;
//         parameters.created_on = '';
//         parameters.created_by = created_By;
//         parameters.modified_on = '';
//         parameters.modified_by = '';
//         parameters.is_active = 1;
//         parameters.is_logical_deleted = 0;

//         return new HttpRequest(`${SERVER_ACTIONS.API + SERVER_ACTIONS.POST_ADD_CALCULATOR}`, 'post', parameters)
//             .requestWithAuth()
//             .then(response => {

//                 if (response.status === 200 && !response.data.errorCode) {
//                     // dispatch(calculatorStatus(response.data.data));
//                     dispatch(addManufacturerToList(response.data.data, 5, 1));
//                     return Promise.resolve(response.data);
//                 }
//                 else {
//                     return Promise.reject(response.data);
//                 }

//             }, error => {
//                 return Promise.reject(error);


//             });


//     }

// }
export function getManufacturerList({ query, currentPage, defaultPageSize }) {

    return (dispatch) => {
        const queryString = (query && query.trim().length > 0) ? `?${query}` : '';
        
        return new HttpRequest(`${SERVER_ACTIONS.API + SERVER_ACTIONS.GET_MANUFACTURERS}${queryString}`, 'get', {})
            .requestWithAuth()
            .then(response => {
        
                if (response.status === 200 && !response.data.errorCode) {

                    dispatch(updateManufacturers(response.data.data.data, Math.ceil(response.data.totalrecord / defaultPageSize), currentPage));
                    return Promise.resolve(response.data);
                }
                else {
                    return Promise.reject(response.data);
                }
            }, error => {
                return Promise.reject(error);
            });
    }
}


export function getAllManufacturersList() {

    return (dispatch) => {
        
        return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.GET_ALL_MANUFACTURERS, 'get', {})
            .requestWithAuth()
            .then(response => {
        
                if (response.status === 200 && !response.data.errorCode) {

                    dispatch(getAllManufacterers(response.data));
                    return Promise.resolve(response.data);
                }
                else {
                    return Promise.reject(response.data);
                }
            }, error => {
        
                return Promise.reject(error);
            });
    }
}

export function getCalculatorNameByManufacturer() {
    
    return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.GET_MANUFACTURER_CALC, 'get').requestWithAuth().then(
        (response) => {
            
            if (response.status === 200 && !response.data.errorCode) {
                return Promise.resolve(response.data);
            }
            else {
                return Promise.reject(response.data);
            }
        }, error => {
           
            return Promise.reject(error);
        });

}

// export function getActiveManufacturerCalculator() {
    
//     return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.GET_ACTIVE_MANUFACTURER_CALC, 'get').requestWithAuth().then(
//         (response) => {
            
//             if (response.status === 200 && !response.data.errorCode) {
//                 return Promise.resolve(response.data);
//             }
//             else {
//                 return Promise.reject(response.data);
//             }
//         }, error => {
           
//             return Promise.reject(error);
//         });

// }

export function saveManufacturerItem(manufacturer, formAction) {
    formAction.setSubmitting(true)

    return (dispatch) => {
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(manufacturer.manufacturer_id > 0 ? SERVER_ACTIONS.PUT_MANUFACTURERS : SERVER_ACTIONS.POST_MANUFACTURERS, manufacturer.manufacturer_id > 0 ? 'put' : 'post', manufacturer)
            .requestWithAuth()
            .then(response => {
                formAction.setSubmitting(false);
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                    
                    window.Message(response.data.message, 'Success');

                    if (manufacturer.manufacturer_id == 0) {

                        dispatch(addManufacturerToList(response.data.data));

                    } else {
                        dispatch(updateManufacturerInList(response.data.data));
                    }

                    return Promise.resolve(response);
                } else {
                    
                    formAction.setStatus({ msg: response.data.message });
                    return Promise.reject(response);
                }
            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (error.response.data) {
                    formAction.setStatus({ msg: error.response.data.message });
                }
                formAction.setSubmitting(false);
                return Promise.reject(error);
            });
    }
}


export function toggleStatusManufacturerItem(id, modified_by, status) {
    let params = {};
    params.id = id;
    params.modified_by = modified_by;
    params.status = status;

    return (dispatch) => {
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.POST_STATUS_MANUFACTURER, 'POST', params)
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                    window.Message(response.data.message, 'Success');

                    dispatch(updateManufacturerInList(response.data.data));
                    return Promise.resolve(response);
                }
                else {
                    return Promise.reject(response.data);
                }

            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);
            });
    }

}
// export function editManufacturerById(ManufacturerId,Thumbnail,Name,DisplayName,modified_By)
// {
//     return(dispatch)=>{
//         const parameters = {};
//         parameters.manufacturer_id = ManufacturerId;
//         parameters.name=Name;
//         parameters.thumbnail=Thumbnail;
//         parameters.display_name=DisplayName;
//         parameters.created_on='';
//         parameters.created_by=0;
//         parameters.modified_on='';
//         parameters.modified_by=modified_By;
//         parameters.Is_Active=1;
//         parameters.Is_Logical_Deleted=1;

//         return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.PUT_EDIT_CALCULATOR}`,'put',parameters)
//         .requestWithAuth()
//         .then(response=>{

//             if(response.status===200 && !response.data.errorCode)
//             {
//                 dispatch(updateManufacturerInList(response.data.data));
//                 return Promise.resolve(response.data);
//             }
//             else{
//                 return Promise.reject(response.data);
//             }

//             },error=>{return Promise.reject(error);


//         });


//     }

// }

export function deleteManufacturerFromList(id, modified_by) {
    return (dispatch) => {
        let params = {};
        params.id = id;
        params.modified_by = modified_by;
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(SERVER_ACTIONS.API + SERVER_ACTIONS.DELETE_MANUFACTURER, 'DELETE', params).requestWithAuth().then(
            (response) => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                    window.Message(response.data.message, 'Success');
                    dispatch(deleteManufacturerFromListItem(id));
                }
                return Promise.resolve(response);
            },
            (error) => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);
            }
        );
    };
}
