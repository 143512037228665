import React, { Component } from 'react';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { getAllMaterialList,getIKnowPartNumberMaterials } from '../../CommonApiActions';

class IKnowPartNumberMaterial extends React.Component {
    constructor(props) {
        super(props);
        //console.log(this.props.manufacturerId);
        this.state = ({
            allMaterials:[],
            manufacturerId: this.props.manufacturerId
        })
    }

    componentWillReceiveProps(props) {
        if(props.manufacturerId!= undefined && props.manufacturerId !=this.state.manufacturerId){
          //this.props.getMaterialList(props.manufacturerId);
          getIKnowPartNumberMaterials(this.state.manufacturerId).then(
            res => {
                this.setState({
                    //bellowsId : props.bellowsId,
                    allMaterials : res
                })
            }
        )
          //this.state.manufacturerId = props.manufacturerId;
         }
        // else{
        //     this.setState({
        //         //bellowsId : props.bellowsId,
        //         allMaterials : this.state.allMaterials
        //     })
        // }
    }
    componentDidMount() {
        //this.props.getMaterialList(this.state.manufacturerId);
        getIKnowPartNumberMaterials(this.state.manufacturerId).then(
            res => {
                this.setState({
                    //bellowsId : props.bellowsId,
                    allMaterials : res
                })
            }
        )
    }

    render() {
        const selectedValue = this.props.selectedValue;
        // const selectedName = this.props.selectedName;
        
        //console.log(selectedValue);
        const materials = this.state.allMaterials;

        const options = materials && materials.length > 0 ? (
            materials.map((material) => {
                return (<MenuItem value={material.PartNumberSuffix}>
                        {material.PartNumberSuffix}
                        </MenuItem>
                );
            })
        ) : (
                <MenuItem>Loading....</MenuItem>
            );
        //console.log({ options })
        return (

            <Select component="select" value={selectedValue}  
                labelId="demo-simple-select-label"
                id="demo-simple-select" onChange={this.props.onMaterialChange}>
                {options}
            </Select>
        );
    }

}

const mapProps = (state) => {
   
    return {
        allMaterials: state.common.allMaterials
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getMaterialList: (manufacturerId) => {
            return new Promise((resolve, reject) => {
                dispatch(getIKnowPartNumberMaterials(manufacturerId))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    };
};

export default connect(mapProps, mapDispatchToProps)(IKnowPartNumberMaterial);
