import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { changePasswordRequest } from '../../AuthenticationApiActions';
import Logo from '../../../Common/components/Logo';
import { ErrorMessage, Field, Form, Formik } from "formik";
import ChangePasswordSchema from './changePasswordSchema';
import { ACTIONS } from '../../../../utils/Constants';
import { TextField } from 'formik-material-ui';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import changePasswordImage from '../../../../images/change-password-banner.png';
import '../SignIn/SignIn.css';
import Auth from '../../../../utils/Auth';


let auth = new Auth();
class ChangePassword extends Component {


    submitDetails = (data, formAction) => {
        formAction.setSubmitting(true);
        data.email = auth.getEmailId();
        this.props.onChangePassword(data).then(response => {
            formAction.setSubmitting(false);
            auth.setIsPasswordReset("true");
        }, error => {
            window.Message(error.response.data.Message, "Error" ,true );
            formAction.setSubmitting(false);
            //TODO: Show message for incorrect credentials
        })
    }

    render() {
        const img = {
            width: '100%'
        }

        return (
            <CardContent>
                <h3 className="heading">Change Your Password</h3>


                <Formik
                     validationSchema={ChangePasswordSchema}
                    onSubmit={this.submitDetails}
                    enableReinitialize={true}
                    initialValues={{ 
                        oldpassword : '',
                        newpassword : '',
                        confirmPassword : ''
                    }}
                >

                    {({ errors, status, touched, isSubmitting }) => (
                        <Form name="loginForm" className="form-horizontal form-simple">
                            <div className="row">
                            <div className="col-lg-1"></div>
                                <div className="col-lg-5 change-pass">
                                    <img className="mt-2" style={img} src={changePasswordImage} alt="" />
                                    
                                </div>
                                
                                <div className="col-lg-5 com-field">
                                    <Field type="password" name="oldpassword"
                                        component={TextField}
                                        placeholder="Old Password"
                                        id="standard-old-password"
                                        label="Old Password"
                                        margin="normal"
                                        type="password"
                                        fullWidth
                                    />
                                 

                                    <Field type="password" name="newpassword"
                                        component={TextField}
                                        placeholder="New Password"
                                        id="standard-new-password"
                                        label="New Password"
                                        margin="normal"
                                        type="password"
                                        fullWidth
                                    />
                                   
                                    <Field type="password" name="confirmPassword"
                                        component={TextField}
                                        placeholder="Confirm New Password"
                                        id="standard-confirm-new-password"
                                        label="Confirm New Password"
                                        margin="normal"
                                        type="password"
                                        fullWidth
                                    />
                                  


                                    <div className="btn-container">
                                    <div className="update-profile">
                                        {/* <Link to="/" className="submit-btn mt-3">
                                                                Cancel
                                                            </Link>
                                                           &nbsp; */}
                                        <Button type="submit" color="primary" className="submit-btn">
                                            Save
                        </Button>
                                    </div>
                                    </div>



                                </div>
                                <div className="col-lg-1"></div>
                            </div>




                        </Form>
                    )}
                </Formik>

            </CardContent>
        )
    }

}

const mapStateToProps = state => {
    return {

    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onChangePassword: (data) => {
            return new Promise((resolve, reject) => {
                dispatch(changePasswordRequest(data)).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        }
    }
}

ChangePassword = connect(mapStateToProps, mapDispatchToProps)(ChangePassword);

export default ChangePassword;