import React, { Component } from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from 'react-bootstrap/Pagination';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Auth from '../../../utils/Auth';
import { getSavedPartNumberList } from '../../Savedpartno/SavedPartNumberApiActions';
import { connect } from 'react-redux';
import '../../ManageCustomers/components/manageGuestCustomer/addGuestCustomer.css';
import { SafeAnchor } from 'react-bootstrap';
import Moment from 'moment';

let auth = new Auth();

class SavedPartNumber extends Component {
    constructor(props) {
        super(props)
        this.state = {
            active: false,
            checked: true,
            defaultPageSize: 10,
            PageNumber: 1,
            totalPages: 1,
            pageheading: 'Saved Part Number',
            data: {},
            editcustomerModalShow: false,
            update: true,
            calculatorId:0,
            calculatorTab:"",
        }
    }

    componentDidMount() {
        this.getSavedPartNumberList(this.state.PageNumber);
    }

    getSavedPartNumberList(PageNumber) {
        const { defaultPageSize } = this.state;
        this.state.PageNumber = PageNumber;

        if (PageNumber > 0) {
            const query = 'PageNumber=' + PageNumber + '&PageSize=' + defaultPageSize + (this.state.query !== '' ? '&q=' + this.state.query : '');
            this.props.getSavedPartNumberList({ query, PageNumber, defaultPageSize }).then(resp => {
                const numberOfRecords = resp.totalrecord;

                const totalPages = resp.totalpages;

                let data = resp.data;
                this.setState({
                    numberOfRecords,
                    totalPages,
                    data: data,
                    PageNumber
                });

            });
        }
    }

    handlePageClick = (e, index) => {
        this.setState({
            PageNumber: index
        });
        let PageNumber = index;
        this.getSavedPartNumberList(PageNumber);

    }


    renderPagination = () => {

        let items = [];

        if (this.state.data.data !== [] && this.state.data.data != undefined) {
            //console.log({ totalPages: this.state.totalPages });
         
            // for (let i = 1; i <= this.state.totalPages; i++) {

            //     items.push(<Pagination.Item className={this.state.PageNumber === i ? "active" : ""}
            //         onClick={e => this.handlePageClick(e, i)} >{i}</Pagination.Item>);

            // }
            let j=3;
            for (let i = 1; i <= this.state.totalPages; i++) {
                if(this.state.PageNumber>=i && this.state.PageNumber+3<this.state.totalPages){
                    i=this.state.PageNumber;
                    j=this.state.PageNumber+3;
                }
                if(this.state.PageNumber+3>=this.state.totalPages){
                    if(this.state.totalPages-3>0)
                    {if(i<this.state.totalPages-3)
                        i=this.state.totalPages-3;
                        j=i+3;
                    }
                    else if(i==this.state.totalPages){
                        i=this.state.totalPages;
                    }
                    else{
                        if(i<3-this.state.totalPages){
                            i=3-this.state.totalPages;
                        }
                       }
                }
               
                if(i<j || i==this.state.totalPages)
                {
                    items.push(
                        <Pagination.Item
                            className={this.state.PageNumber === i ? "active" : ""}
                            onClick={e => this.handlePageClick(e, i)} >
                            {i}
                        </Pagination.Item>
                    );

                }
              if(i<j && this.state.totalPages-i==1)
                {
                }
                else
               
                 {
                    if(i==this.state.totalPages-1){
                        items.push(<Pagination.Ellipsis />)
                          }
                  }   
            }
            
            return items;
        }
    }

    renderTableData = () => {

        if (this.state.data.data !== [] && this.state.data.data != undefined) {
            return this.state.data.data.map((data, index) => {
                const { Id, UserName,CalculatorTab, PartNumber, CreatedOn,CalculatorId,ManufacturerId,InputData } = data //destructuring
                //console.debug('data', data);
                return (

                    <TableRow key={Id}>

                        <TableCell>
                            {((this.state.PageNumber - 1) * this.state.defaultPageSize) + index + 1}
                        </TableCell>
                        <TableCell scope="roe">{UserName}</TableCell>
                        <TableCell scope="row">{CalculatorTab}</TableCell>
                        {/* <TableCell scope="row">{PartNumber}</TableCell> */}
                        <TableCell>
                        <a href= "/#/Railcalculator"   onClick={() => {
                                        this.setState({
                                        calculatorId: {CalculatorId},
                                        manufacturerId: {ManufacturerId},
                                        calculatorTab:{CalculatorTab},
                                        })
                                        
                                        auth.setCalculatorTab(CalculatorTab)
                                        auth.setSavedPartId(Id)
                                        auth.setCalculatorId(CalculatorId);
                                        auth.setManufacturerId(ManufacturerId);
                                    }} >{PartNumber}</a> 
                        </TableCell>
                        <TableCell>{Moment(CreatedOn).format('YYYY-MM-DD HH:mm')}</TableCell>
                    </TableRow>
                )
            })
        }
    }

    handleChange = (e) => {

        this.setState({
            checked: e.target.checked
        })
    }

    render() {

        const mtt = {
            marginTop: '20px',
            marginTop: '20px',
            width: '100%',
            overflowX: 'auto'
        }


        let editModalClose = () => {
            this.setState({ editcustomerModalShow: false });
        }
        const tableGray = {
            background: '#f1f3f6',

        }

        return (
            <React.Fragment>


                <h2 style={{ 'clear': 'both', 'display': 'inline' }} className="heading">{this.state.pageheading}</h2>

                <div style={mtt}>
                    <Table className="table-striped">

                        <TableHead style={tableGray}>
                            <TableRow>
                                {
                                    <>
                                    <TableCell scope="row">Sr No</TableCell>
                                    <TableCell scope="row">Customer Name</TableCell>
                                    <TableCell scope="row">CalculatorTab</TableCell>
                                    <TableCell scope="row">Part No</TableCell>
                                    <TableCell scope="row" >Date</TableCell>
                                    {/* <TableCell scope="row"> 
                                       View
                                    </TableCell> */}

                                    </>
                                }

                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {
                                this.renderTableData()
                            }
                        </TableBody>

                    </Table>

                </div>

                <div className="custom-pagination">
                    <Pagination>
                        <Pagination.First onClick={e => this.handlePageClick(e, 1)} />
                        <Pagination.Prev onClick={e => this.handlePageClick(e, this.state.PageNumber > 1 ? this.state.PageNumber - 1 : this.state.PageNumber)} />
                        {this.renderPagination()}
                        
                        {/* <Pagination.Item>{10}</Pagination.Item> */}
                        <Pagination.Next onClick={e => this.handlePageClick(e, this.state.PageNumber < this.state.totalPages ? this.state.PageNumber + 1 : this.state.PageNumber)} />
                        <Pagination.Last onClick={e => this.handlePageClick(e, this.state.totalPages)} />
                    </Pagination>
                </div>
            </React.Fragment>
        )
    }
}


const mapStateToProps = state => {
    return {
         savedPartNumbers: state.savedPartNumber.savedPartNumbers,
         totalPages: state.savedPartNumber.totalPages
    }
};


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getSavedPartNumberList: (data) => {
            return new Promise((resolve, reject) => {

                dispatch(getSavedPartNumberList(data)).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },

    }
}

SavedPartNumber = connect(mapStateToProps, mapDispatchToProps)(SavedPartNumber);

export default SavedPartNumber;

