import React, { Component } from 'react'
import { SafeAnchor, Modal } from 'react-bootstrap';
// import { Formik, Form, Field } from 'formik';
import Button from '@material-ui/core/Button';
import { Field, FieldArray, Form, Formik } from 'formik';
import { FormikTextField } from 'formik-material-fields';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { TextField } from 'formik-material-ui';
import {getLengthAttributes,deleteLengthAttribute,addLength} from '../../components/ManageAttributeApiAction';
import Icon from '@material-ui/core/Icon';
import SizeByManufacturer from '../../../Common/components/DropDown/SizeByManufacturer';



const { Header, Body, Footer, Title } = Modal;

export default class EditLength extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showform: false,
            manufacturer_name: "",
            manufacturer_id: this.props.manufacturer_id,
            length_id: 0,
            length_name: '',
            prev_length_id: 0,
            length:  [{
                LengthId:0,
                Length:0,
                UnitCost:0,
                THKStandardCost:0
                        
                        }
                   ]
        };

    }
    componentDidMount() {
        //console.log("didmount");
    }
    componentWillReceiveProps(props) {
        this.setState({ manufacturer_id: this.props.manufacturer_id });

    }
    componentDidUpdate() {

        if(this.state.prev_length_id!==this.state.length_id){
            this.getLength(this.state.length_id);
            this.setState({prev_length_id:this.state.length_id})
        }



    }

    showForm = () => {
        this.setState({ showform: true })
    }


    hideForm = () => {
        this.setState({ showform: false, 
            length:  [{
            LengthId:0,
            Length:0,
            UnitCost:0,
            THKStandardCost:0
                    }
               ] })
    }
    deleteLength=(option, arrayHelpers, i)=>{
        if (option.LengthId == 0) {
            arrayHelpers.remove(i);
        }
        else {


            deleteLengthAttribute(option.LengthId).then(resp => {
               
                arrayHelpers.remove(i);
               

             });
        }
    }
    getLength = (length_id) =>  {
            const query='?ManufacturerId='+this.state.manufacturer_id+'&SizeId='+length_id;          
            getLengthAttributes(query).then(res => {
                
                this.setState({
    
                    
                    length:res,
                   
                   
                })
                
            })
        }
    handleChangeRailDropdown = (event) => {
        this.setState({ length_id: event.target.value })
        // this.getKRRailLenth(event.target.value);
    };
    submitFormula = (data, actions) => {
       
        actions.setSubmitting(true);
        // let updateOptions = data.options.filter(o => (this.state.updatableOptions.indexOf(o.CalulatorformulaeId) > -1 || o.CalulatorformulaeId == 0));
        let LengthAttributeValueModel= data.length

        addLength(this.props.manufacturer_id,
            LengthAttributeValueModel).then(response => {

                if (response.Status && response.StatusCode === 200) {
                    let { length } = this.state;
         

                    length = length.map(length => {
                        let option2 = LengthAttributeValueModel.find(o => o.LengthId == length.LengthId);
                        return option2 ? { ...length, ...option2 } : length;
                    })
                 
                    this.setState({
                        length
                    })

                }

            }, this.hideForm())

    }

    render() {

        const manufacturer_id = this.props.manufacturer_id;
        const manufacturer_name = this.props.manufacturer_name;
        //console.log({ krraillength: this.state.krraillength })
        return (
            <>
                {!this.state.showform ?
                    <Icon onClick={this.showForm}>
                        edit
                 </Icon>
                    : <>
                        <Modal className="calculatorPopup" show={this.state.showform} onHide={this.hideForm}>
                            <Header closeButton>
                                <Title>{manufacturer_name}</Title>
                            </Header>
                            <Body>

                                <Formik
                                    onSubmit={this.submitFormula}
                                    initialValues={{ length: this.state.length}}
                                    enableReinitialize={true}
                                >
                                    {({ values, status, isSubmitting }) => (
                                        <Form>
                                            <div className="row">
                                                <div className="col-lg-12 popup-heading">
                                                    <label >ATTRIBUTE NAME :LENGTH</label>
                                                </div>
                                                <div className="col-lg-4">
                                               
                                                    <FormControl  margin="normal" fullWidth>
                                                    <div className="ManufacturerDropDown">
                                                        <InputLabel id="demo-simple-select-label">Size*</InputLabel>
                                                        <SizeByManufacturer  enabled={true} activeOnly={true} manufacturerId={manufacturer_id} onSizeChange={(e, index) => {
                                                            // setFieldValue("rail_id", e.target.value);
                                                            // setFieldValue("rail_name", e.target.name);
                                                            // this.handleChangeDropdown(e, index);

                                                           this.handleChangeRailDropdown(e)
                                                          
                                                            this.setState({
                                                               length_id: e.target.value,
                                                               length_name: index.props.name
                                                                
                                                            });
                                                            
                                                        }
                                                        } />
                                                        </div>
                                                    </FormControl>
                                                </div> 
                                                </div>

<table className="fixed" >
    <thead>
        <tr>
                                    <th style={{width:100}}>
                Length
            </th>
            <th style={{width:100}}>
            Unit Cost
            </th>
            <th style={{width:100}}>
            THK Standard Cost
            </th>
            <th  style={{width:70}}></th>
        </tr>
    </thead>
                                            

                                            {/* <div className="row">
                                        <div className="col-lg-2">
                                        <label >Length</label>
                                        </div> 
                                        <div className="col-lg-2">
                                        <label >Unit Cost</label>
                                        </div> 
                                        <div className="col-lg-4">
                                        <label >THK Standard Cost</label>
                                        </div> 
                                       
                                        </div>  */}
                                            <FieldArray
                                                name="length"

                                                render={arrayHelpers => (
                                                    <tbody className="clacfor">

                                                        {values.length && values.length.length > 0 ? values.length.map((length, i) => {

                                                            return (<tr  key={i}>
                                                                <td >     
                                                                <FormikTextField                            
                                                                    name={`length[${i}].Length`}
                                                                    defaultValue={length.Length}
                                                                    margin="normal"
                                                                    fullWidth                  
                                                                />
                                                            </td>   
                                                             <td >        
                                                                <FormikTextField                            
                                                                    name={`length[${i}].UnitCost`}
                                                                    defaultValue={length.UnitCost}
                                                                    margin="normal"
                                                                    fullWidth                  
                                                                />
                                                            </td>
                                                            <td >        
                                                                <FormikTextField                            
                                                                    name={`length[${i}].THKStandardCost`}
                                                                    defaultValue={length.THKStandardCost}
                                                                    margin="normal"
                                                                    fullWidth                  
                                                                />
                                                            </td>
                                                            <td>
                                                                <SafeAnchor className="btn btn-default">
                                                                    <i className="fa fa-minus" onClick={() => { this.deleteLength(length, arrayHelpers, i) }} /></SafeAnchor>
                                                                <br />
                                                                </td>
                                                            </tr>);
                                                        }) : arrayHelpers.push({ LengthId: 0 })}
                                                        <tr>
                                                            <td colSpan="4">
                                                        <SafeAnchor className="btn btn-default" onClick={e => {
                                                           
                                                            arrayHelpers.push({LengthId: 0 })
                                                        }}><i className="fa fa-plus" /></SafeAnchor>
                                                        </td>
                                                        </tr>
                                                    </tbody>
                                                )} />
                                                </table>  
                                            {/* <input type="submit" className="submit" disabled={isSubmitting} /> */}
                                            <Modal.Footer className="row" centered>
                                              <Button variant="contained" type= "submit"  className="save" disabled={isSubmitting} >Submit</Button>
                                            </Modal.Footer>
                                        </Form>
                                    )}
                                </Formik>
                            </Body>
                        </Modal>

                    </>}
            </>

        )
    }
}