import React, { Component } from 'react'
import { SafeAnchor, Modal } from 'react-bootstrap';
// import { Formik, Form, Field } from 'formik';
import Button from '@material-ui/core/Button';
import { Field, FieldArray, Form, Formik } from 'formik';
import { FormikTextField } from 'formik-material-fields';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {getBellowsMappingAttributes,deleteBellowsMappingAttribute,addBellowsMapping} from '../ManageAttributeApiAction';
import Icon from '@material-ui/core/Icon';
import BellowsByManufacturerDropDown from '../../../Common/components/DropDown/BellowsByManufacturerDropDown';
import {getNewBellowsByManufacturerList,getOldBellowsByManufacturerList} from './EditDropDownListApi';
import swal from 'sweetalert';
import{StyledReactTooltip} from '../ToolTip';
import Swal from 'sweetalert2';

const { Header, Body, Footer, Title } = Modal;

export default class EditBellowsMapping extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showform: false,
            manufacturer_name: "",
            manufacturer_id:this.props.manufacturer_id,
            bellows:[{
                BellowsId:0,
                BellowsValue:""
            }],
            Newbellows:[{
                BellowsId:0,
                BellowsValue:""
            }],
            Oldbellows:[{
                BellowsId:0,
                BellowsValue:""
            }],
            bellowsMapping:[{
                Id:0,
                NewBellowsId:"",
                OldBellowsId:""
            }],
        };
    }
    componentDidMount() {
       
    }
    componentWillReceiveProps(props) {
        this.setState({ manufacturer_id: this.props.manufacturer_id });
        if(this.props.manufacturer_id!=undefined){
        this.getNewBellows(this.props.manufacturer_id);
        this.getOldBellows(this.props.manufacturer_id);
        }
    }

    componentDidUpdate() {
        
    }
    
    getNewBellows = (manufacturerId) => {
        getNewBellowsByManufacturerList(manufacturerId).then(resp => {
            this.setState({
                Newbellows:resp
            });
        });
    }
    getOldBellows = (manufacturerId) => {
        getOldBellowsByManufacturerList(manufacturerId).then(resp => {
            this.setState({
                Oldbellows:resp
            });
        });
    }

    showForm = () => {
        this.getBellowMapping();
        this.setState({ showform: true })
        this.getNewBellows(this.state.manufacturer_id)
        this.getOldBellows(this.state.manufacturer_id)
    }


    hideForm = () => {
        this.setState({ showform: false })
        this.setState({ 
            bellowsMapping: [{
                Id: 0,
                NewBellowId:"",
                OldBellowId:"",
            }]
        })
    }
    
    deleteBellowsMapping = (option, arrayHelpers, i) => {
        if (option.Id == 0) {
            arrayHelpers.remove(i);
        }
        else {
            Swal.fire({
                //title: 'Are you sure?',
                text: 'This action will delete the record permanently from database, Are you sure?',
                // icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel',
                showCloseButton: true
              }).then((result) => {
                if (result.value) {
                
                    deleteBellowsMappingAttribute(option.Id).then(resp => { 
                                            arrayHelpers.remove(i);
                                        });
                  
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                  
                }
              })
        }
    }

    getBellowMapping = () =>  {
        var manufacturerId = this.state.manufacturer_id == undefined ? 0 : this.state.manufacturer_id;
            const query='?ManufacturerId='+manufacturerId;          
            getBellowsMappingAttributes(query).then(res => {
                this.setState({
                    railBellow:res,
                })
            })
    }

    submitFormula = (data, actions) => {
        
        let found=false;
        for (let i = 0; i < data.railBellow.length; i++) {
            let OldBellowsId = data.railBellow[i].OldBellowsId;

            let foundDuplicate = data.railBellow.filter(r => r.OldBellowsId == OldBellowsId)
                .map(duplicate => (duplicate.OldBellowsId))

            if (foundDuplicate.length >= 2) {
                found = true;
                actions.setSubmitting(false)
                swal("Attribute is already exist", {
                    buttons: {
                        cancel: "OK",
                    },
                })
                    .then((cancel) => {
                        switch (cancel) {
                            case "Yes":
                                break;
                            default:
                        }
                    });
            }
        }
        //if(found==false){
        if(1==1){
            actions.setSubmitting(true);
            // let updateOptions = data.options.filter(o => (this.state.updatableOptions.indexOf(o.CalulatorformulaeId) > -1 || o.CalulatorformulaeId == 0));
            let railBellowAttributeValueModel= data.railBellow
           
            addBellowsMapping(this.props.manufacturer_id,
                railBellowAttributeValueModel).then(response => {
    
                    if (response.Status && response.StatusCode === 200) {
                        let { bellowsMapping } = this.state;
             
                        bellowsMapping = bellowsMapping.map(railBellow => {
                            let option2 = railBellowAttributeValueModel.find(o => o.Id == railBellow.Id);
                            return option2 ? { ...railBellow, ...option2 } : railBellow;
                        })
                        this.setState({
                            bellowsMapping
                        })
                    }
                }, this.hideForm(),this.getBellowMapping())
        }
    }

    render() {

        const manufacturer_name = this.props.manufacturer_name;

        const Oldbellow=this.state.Oldbellows;
        const Newbellow=this.state.Newbellows;
        let defaultNewBellows=0;
        let defaultOldBellows=0;

        if(this.state.Oldbellows.length>0)
        {
            defaultOldBellows =this.state.Oldbellows[0].BellowId!=undefined?
            (this.state.Oldbellows[0].BellowId):(0);
        }
        if(this.state.Newbellows.length>0)
        {
            defaultNewBellows =this.state.Newbellows[0].BellowId!=undefined?
            (this.state.Newbellows[0].BellowId):(0);
        }
        const OldBellowsOptions = Oldbellow && Oldbellow.length > 0 ? (
            Oldbellow.map((bellow) => {

                return (<option   value={bellow.BellowId}>
                    {bellow.BellowValue}
                </option>
                );
            })
        ) : (
                <option>Loading....</option>
            );
            const NewBellowsOptions = Newbellow && Newbellow.length > 0 ? (
                Newbellow.map((bellow) => {
    
                    return (<option   value={bellow.BellowId}>
                        {bellow.BellowValue}
                    </option>
                    );
                })
            ) : (
                    <option>Loading....</option>
                );
        return (
            <>
                {!this.state.showform ?<>
                    <Icon  data-tip data-for="edit" onClick={this.showForm}>
                        edit
                 </Icon>
                  <StyledReactTooltip id="edit" effect="solid">
                  <span>Edit</span>
                 </StyledReactTooltip></>
                    : <>
                        <Modal className="calculatorPopup" show={this.state.showform} onHide={this.hideForm}>
                            <Header closeButton>
                                <Title>{manufacturer_name}</Title>
                            </Header>
                            <Body>

                                <Formik
                                    onSubmit={this.submitFormula}
                                    initialValues={{ railBellow : this.state.railBellow }}
                                    enableReinitialize={true}
                                >
                                    {({ values, status, isSubmitting ,setFieldValue}) => (
                                        <Form>
                                            <div className="row">
                                                <div className="col-lg-12 popup-heading">
                                                    <label >ATTRIBUTE NAME : BELLOWS MAPPING</label>
                                                </div>
                                                </div>
                                          
                                            <div className="row">
                                        <div className="col-lg-4 col-sm-4 col-4">
                                        <label >Old Bellows</label>
                                        </div> 
                                        <div className="col-lg-4 col-sm-4  col-4">
                                        <label >New Bellows</label>
                                        </div> 
                                       
                                        </div> 
                                         
                                            <FieldArray
                                                name="railBellow"

                                                render={arrayHelpers => (
                                                    <div className="clacfor">

                                                        {values.railBellow && values.railBellow.length > 0 ? values.railBellow.map((railBellow, i) => {

                                                            return (<div className="row" key={i}>
                                                                <div className="col-lg-4 col-sm-4  col-4">
                                                                <FormControl  className="FormulaDropdown" margin="normal" fullWidth>
                                                                <div className="ManufacturerDropDown">
                                                            <Field component="select" style={{width:140}} defaultValue={railBellow.OldBellowsId} 
                                                             name={`railBellow[${i}.OldBellowsId]`}
                                                             labelId="demo-simple-select-label"
                                                             id="demo-simple-select" onChange={(e, index) => {
                                                             setFieldValue(`railBellow[${i}.OldBellowsId]`, e.target.value);
                                                             defaultOldBellows=e.target.value;
                                                             }
                                                            }>
                                                           {OldBellowsOptions}
                                                        </Field>
                                                        </div>
                                                    </FormControl>
                                                                </div>
                                                                <div className="col-lg-4  col-sm-4  col-6">

                                                                <FormControl  className="FormulaDropdown" margin="normal" fullWidth>
                                                                <div className="ManufacturerDropDown">
                                                            <Field component="select" style={{width:140}} defaultValue={railBellow.NewBellowsId} 
                                                             name={`railBellow[${i}.NewBellowsId]`}
                                                             labelId="demo-simple-select-label"
                                                             id="demo-simple-select" onChange={(e, index) => {
                                                             setFieldValue(`railBellow[${i}.NewBellowsId]`, e.target.value);
                                                             defaultNewBellows=e.target.value;
                                                             }
                                                            }>
                                                           {NewBellowsOptions}
                                                        </Field>
                                                        </div>
                                                    </FormControl>
                                                               
                                                            </div>   
                                                                
                                                                <SafeAnchor className="btn btn-default">
                                                                    <i className="fa fa-minus" onClick={() => { this.deleteBellowsMapping(railBellow, arrayHelpers, i) }} /></SafeAnchor>
                                                                <br />
                                                            </div>);
                                                        }) : arrayHelpers.push({ Id: 0 ,NewBellowsId:defaultNewBellows,OldBellowsId:defaultOldBellows})}
                                                        <SafeAnchor className="btn btn-default" onClick={e => {
                                                            arrayHelpers.push({ Id: 0 ,NewBellowsId:defaultNewBellows,OldBellowsId:defaultOldBellows })
                                                        }}><i className="fa fa-plus" /></SafeAnchor>
                                                    </div>
                                                )} />
                                            {/* <input type="submit" className="submit" disabled={isSubmitting} /> */}
                                            <Modal.Footer className="row" centered>
                                              <Button variant="contained" type= "submit"  className="save" disabled={isSubmitting} >Submit</Button>
                                            </Modal.Footer>
                                        </Form>
                                    )}
                                </Formik>
                            </Body>
                        </Modal>

                    </>}
            </>

        )
    }
}