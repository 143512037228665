import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField } from 'formik-material-ui';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Modal, Button } from 'react-bootstrap';
import { saveCustomerItem } from '../../ManageCustomersApiActions';
import CompanyTypeDropDown from '../../../Common/components/DropDown/CompanyTypeDropDown';
import RoleDropDown from '../../../Common/components/DropDown/RoleDropDown';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import RegisteredCustomerSchema from '../manageRegisteredCustomer/RegisteredCustomerSchema';
import GuestCustomerSchema from './GuestCustomerSchema';
import Calculators from '../../../Common/components/Calculators';


class EditGuestCustomer extends Component {
    constructor(props) {
        super(props);

        let guestCustomer = props.update ? props.guestCustomer : {
            email: '',
            first_name: '',
            last_name: '',
            role_id:''
        };
         
        this.state = {
            guestCustomer: guestCustomer,
            is_guest: true,
            calculatorData : []
        } 
    }

    handleUserTypeChange = (e) => {
        this.setState({ is_guest : e.target.value })
    }

    saveGuestCustomer = (data, formAction) => {
        data.CustomerCalculator  = this.state.calculatorData;
        formAction.setSubmitting(true);
        this.props.saveGuestCustomer(data, formAction).then(response => {
            this.setState({ message: response.message });
            formAction.setSubmitting(false);
            if (this.props.updateList && typeof this.props.updateList === 'function') {
                this.props.updateList();
            }
            this.props.onHide();
            if(data.is_guest == false){
                window.location.reload();
            }
            // this.handleClose();
        }, error => {
            formAction.setSubmitting(false);
        });
    };
    
    handleTree = (selectedper) => {
        this.setState({calculatorData: selectedper});
    }
    
    renderCalculators = (customer_id) => {      
        return(<Calculators id={customer_id} onCalculatorChange={this.handleTree} calculator_id={this.state.calculator_id} roleidval={this.state.role_id}>
        </Calculators>)
    }

    render() {
      
        let stateData = this.props.customer;
        return (

            <React.Fragment>

                <Modal className="container"
                    {...this.props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header color="secondary" closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Edit Guest Customer
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Formik
                            validationSchema={GuestCustomerSchema}
                            onSubmit={this.saveGuestCustomer}
                            initialValues={stateData}
                            enableReinitialize={true}
                        >
                            {({ errors, status, touched, isSubmitting, setFieldValue }) => (
                                <Form  noValidate>

                        <div className="row">
                            <div className="col-lg-4">                                
                            <Field type="text"
                                name="email"
                                disabled
                                placeholder="Enter Email"
                                component = {TextField}
                                id="standard-required"
                                label="User Name"
                                margin="normal"
                                defaultValue={stateData.email}
                                fullWidth
                                />
                            </div>

                            <div className="col-lg-4 com-field">
                            <Field type="text"
                                name="first_name"
                                placeholder="Enter First Name"
                                component = {TextField}
                                id="standard-required"
                                label="First Name"
                                margin="normal"
                                defaultValue={stateData.first_name}
                                fullWidth
                                />
                            </div>

                            <div className="col-lg-4 com-field">
                                <Field type="text"
                                    name="last_name"
                                    placeholder="Enter Last Name"
                                    component = {TextField}
                                    id="standard-required"
                                    label="Last Name"
                                    margin="normal"
                                    defaultValue={stateData.last_name}
                                    fullWidth
                                    />
                            </div>
                            <div className="col-lg-4  com-field">
                                <Field type="text"
                                    name="company_name"
                                    placeholder="Enter Company Name"
                                    component={TextField}
                                    id="standard-required"
                                    label="Company Name"
                                    margin="normal"
                                    // defaultValue={data.first_name}
                                    fullWidth
                                />
                            </div>
                            <div className="col-lg-4">
                                <FormControl margin="normal" fullWidth>
                                        <InputLabel id="demo-simple-select-label">Company Role</InputLabel>
                                        <CompanyTypeDropDown enabled={true} isAdmin={false} activeOnly={true} 
                                        selectedValue={stateData.company_type} 
                                        onCompanyChange={e=>{
                                            setFieldValue("company_type", e.target.value);     
                                            stateData.company_type = e.target.value;                          
                                        }
                                        }/>
                                </FormControl>
                            </div>
                            <div className="col-lg-4">
                            <Field type="text"
                                name="contact_no"
                                placeholder="Enter Phone"
                                component = {TextField}
                                id="standard-required"
                                label="Phone"
                                margin="normal"
                                fullWidth
                                />
                            </div>
                            <div className="col-lg-4">
                            <Field type="text"
                                name="address"
                                placeholder="Enter Address"
                                component = {TextField}
                                id="standard-required"
                                label="Address"
                                margin="normal"
                                // defaultValue={data.first_name}
                                fullWidth
                                />
                            </div>
                            <div className="col-lg-4">
                           {/* <FormControl class="FormulaDropdown" fullWidth>   */}
                            <FormControl margin="normal" fullWidth>
                                    <InputLabel id="demo-simple-select-label">User Type</InputLabel>                                                                          
                                    <Select
                                    className="role_dropdown"
                                    defaultValue = {stateData.is_guest}
                                    labelId="demo-controlled-open-select-label"
                                    id="is_guest"
                                    key=''
                                    data-attribute='is_guest' name='is_guest'
                                    onChange = {(e, index)=>{ this.handleUserTypeChange(e)
                                    setFieldValue('is_guest', e.target.value);
                                    }}
                                    >
                                     <MenuItem value={true}>Guest</MenuItem>
                                     <MenuItem value={false} >Register</MenuItem>

                                    </Select>
                                    </FormControl>
                            </div> 
                               
                            {/* <div className="col-lg-4  hidden">
                                            <Field type="text"
                                                name="contact_no"
                                                placeholder="Enter Phone number"
                                                component={TextField}
                                                id="standard-required"
                                                label="Phone"
                                                margin="normal"
                                               
                                                fullWidth
                                            />
                                        </div>
                                        <div className="col-lg-4 hidden">
                                            <Field type="text"
                                                name="postal_code"
                                                placeholder="Enter Postal Code"
                                                component={TextField}
                                                id="standard-required"
                                                label="Postal Code"
                                                margin="normal"
                                                fullWidth
                                            />
                                        </div> */}

                            <div className="col-lg-4">
                                       <FormControl margin="normal" fullWidth>
                                             <InputLabel id="demo-simple-select-label">Role</InputLabel>
                                             <RoleDropDown isAdmin={false} isGuest={this.state.is_guest} name = "role_id"  value = {stateData.role_id} selectedValue={stateData.role_id} selectedName={stateData.role_name} enabled={true} activeOnly={true} onRoleChange={(e, index)=>{
                                                 setFieldValue("role_id", e.target.value); 
                                                 setFieldValue("role_name", index.props.name);      
                                             }
                                             }/>
                                        </FormControl>
                            </div>        


                            <div className="col-lg-4">
                            <InputLabel id="demo-simple-select-label">MFG Calculators</InputLabel>   
                            {/* Permissions */}

                            {
                             this.renderCalculators(stateData.customer_id)
                            }
                            </div>


                        </div>
                        <Modal.Footer className="row"  centered>
                        {/* <Button variant="contained" type= "submit" onClick={this.props.onHide} className="save">Save</Button> */}
                        <Button variant="contained" type= "submit"  className="save">Save</Button>
                        <Button variant="contained" className="cancle" onClick={this.props.onHide}>Close</Button>
                    </Modal.Footer>
                        </Form>
                            )}

                        </Formik>

                    </Modal.Body>
                    
                </Modal>
            </React.Fragment>
        )
    }
}

const mapProps = state => ({
})

const mapDispatch = (dispatch, ownProps) => {

    return {
        saveGuestCustomer: (guestCustomer, formAction) => {

            return new Promise((resolve, reject) => {
                dispatch(saveCustomerItem(guestCustomer, formAction)).then(response => {

                    resolve(response);
                 
                }).catch(error => {
                    reject(error);
                });
            });
        }
    }
}

export default connect(mapProps, mapDispatch)(EditGuestCustomer);
