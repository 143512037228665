  import * as Yup from "yup";
  import { REGEX, REGEX_ERROR } from "../../../../utils/Constants";

  const UpdateAdminProfileSchema = Yup.object().shape({
    first_name: Yup.string()
    .matches(REGEX.ALPHABETS_WITH_SPACE, REGEX_ERROR.ALPHABETS_WITH_SPACE_ERROR)
    .required('Please Enter First Name')
    .max(100),
    last_name: Yup.string()
    .matches(REGEX.ALPHABETS_WITH_SPACE, REGEX_ERROR.ALPHABETS_WITH_SPACE_ERROR)
    .required('Please Enter Last Name')
    .max(100),
     email: Yup.string()
    .matches(REGEX.EMAIL, REGEX_ERROR.EMAIL_ERROR)
    .required('Please Enter Email'),
     contact_no: Yup.string()
    .matches(REGEX.PHONE,REGEX_ERROR.PHONE_ERROR)
    .required('Please Enter Contact Number'),
     company_name: Yup.string()
     .matches(REGEX.ALPHANUMERIC_WITH_SPACE, REGEX_ERROR.ALPHANUMERIC_WITH_SPACE_ERROR)
     .required('Please Enter Company Name'),
     address: Yup.string()
     .matches(REGEX.ALPHANUMERIC_WITH_SPACE, REGEX_ERROR.ALPHANUMERIC_WITH_SPACE_ERROR)
     .required('Please Enter Address'),
     postal_code: Yup.string()
     
     .min(5)
     .max(6)
     .matches(REGEX.WHOLE_NUMBER, REGEX_ERROR.WHOLE_OR_DECIMAL_ERROR)
     .required('Please Enter Postal Code')

  })
  export default UpdateAdminProfileSchema;