
export const UPDATE_CALCULATOR = 'UPDATE_CALCULATOR';
export const UPDATE_CALCULATOR_STATUS_IN_LIST='UPDATE_CALCULATOR_STATUS';
export const EDIT_CALCULATOR_BY_ID='EDIT_CALCULATOR_BY_ID';
export const DELETE_CALCULATOR_BY_ID='DELETE_CALCULATOR_BY_ID';
export const ADD_CALCULATOR_IN_LIST='ADD_CALCULATOR_IN_LIST';
export const FORMULA_BY_CALCULATOR_ID='FORMULA_BY_CALCULATOR_ID';
export const MANUFACTURER_LIST='MANUFACTURER_LIST';
export function calculatorlist(calculatorList, totalRecords, PageNumber) {
    return {
        type: UPDATE_CALCULATOR,
        calculatorList,
        totalRecords,
        PageNumber
    }
 }
 export function addCalculatorInList(data){
    return{
        type:ADD_CALCULATOR_IN_LIST,
        data 
    }
    

 }

 export function calculatorStatus(calculatorList){
    return{
        type: UPDATE_CALCULATOR_STATUS_IN_LIST,
        calculatorList 
    }
    

 }
 
 export function deleteCalculator(calculator){
    return{
        type:DELETE_CALCULATOR_BY_ID,
        calculator
        

    }

 }

 export function formulaList(calculator){
    return{
        type:FORMULA_BY_CALCULATOR_ID,
        calculator 
    }
    

 }
 export function manufacturerList(manufacturerList){
    return{
        type:MANUFACTURER_LIST,
        manufacturerList
    }
    

 }

 



 
