import { GET_ALL_USERTYPE, GET_ALL_COUNTRIES, GET_ALL_COMPANY_TYPES, GET_ALL_STATES, GET_ALL_ROLES, GET_ALL_MANUFACTURERS, 
    GET_ALL_PERMISSIONS ,GET_ALL_CALCULATORS, GET_ALL_RAILS, GET_ALL_BELLOWS, GET_ALL_SIZES,GET_MANUFACTURER_SIZES, GET_ALL_MATERIALS,
    GET_ALL_SERIES, GET_ALL_TYPES, GET_ALL_RAIL_LENGTH, GET_ALL_KR_MATERIALS,GET_ALL_ACCESSORIES,GET_ALL_RAILBELLOW,
    GET_BELLOWS_SIZES} from './CommonActions';

import { stat } from 'fs';

const initialState = { allUserTypes: [],allCountries: [], allCompanyTypes: [], allStates: [], allManufacturers: [],allPermissions : [],
     allRoles: [], allRails: [], allSizes: [], manufacturerSizes: [],allMaterials: [] ,allSeries:[], allTypes: [], allRailLength: [],
     allKrMaterials: [],allBellows:[],allAccessories:[],allRailBellows:[],bellowsSizes:[]};

const CommonReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_USERTYPE:
            {
                return Object.assign({}, state, {
                    allUserTypes: action.allUserTypes
                });
            }

        case GET_ALL_COUNTRIES:
            {
                return Object.assign({}, state, {
                    allCountries: action.allCountries
                });
            }
        case GET_ALL_COMPANY_TYPES:
            {
                return Object.assign({}, state, {
                    allCompanyTypes: action.allCompanyTypes
                });
            }

        case GET_ALL_STATES:
            {

                return Object.assign({}, state, {
                    allStates: action.allStates
                });
            }
            
        case GET_ALL_ROLES:
            {
                return Object.assign({}, state, {
                    allRoles: action.allRoles
                });
            }

        case GET_ALL_RAILS:
            {

                return Object.assign({}, state, {
                    allRails: action.allRails
                });
            } 
        case GET_ALL_SERIES:
            {
                return Object.assign({}, state, {
                    allSeries: action.allSeries
                });
            } 

        case GET_ALL_MANUFACTURERS: 
        {
            return Object.assign({}, state,
                {
                    allManufacturers: action.allManufacturers
                });
        }

        case GET_ALL_PERMISSIONS: 
        {
            return Object.assign({}, state,
                {
                    allPermissions: action.allPermissions
                });
        }
            
        case GET_ALL_CALCULATORS: 
                {

                return Object.assign({}, state, {
                    allCalculators: action.allCalculators
                });
                }   


        case GET_ALL_BELLOWS:
            {
                return Object.assign({}, state,  {
                    allBellows: action.allBellows
                })
            }

        case GET_ALL_SIZES:
            {
                return Object.assign({}, state,  {
                    allSizes: action.allSizes
                })
            }
            case GET_MANUFACTURER_SIZES:
                {
                    return Object.assign({}, state,  {
                        manufacturerSizes: action.manufacturerSizes
                    })
                }
        case GET_ALL_MATERIALS:
            {
                return Object.assign({}, state,  {
                    allMaterials: action.allMaterials
                })
            }

        case GET_ALL_KR_MATERIALS:
            {

                return Object.assign({}, state, {

                    allkrMaterials: action.allkrMaterials
                })
            }
            
        case GET_ALL_TYPES: 
            {
                return Object.assign({}, state, {

                    allTypes: action.allTypes
                })
            }

        case GET_ALL_RAIL_LENGTH:
            {
                return Object.assign({}, state, {

                    allRailLength: action.allRailLength
                })
            }
        case GET_ALL_ACCESSORIES:
            {
                return Object.assign({}, state, {

                    allAccessories: action.allAccessories
                })
            }

         case GET_ALL_RAILBELLOW:
             {
                   return Object.assign({},state,{
                   allRailBellow:action.allRailBellows
                  })
              }
              case GET_BELLOWS_SIZES:
             {
                   return Object.assign({},state,{
                    bellowsSizes:action.bellowsSizes
                  })
              }
              
        default:
            return state;
    }
}
export default CommonReducer;