import * as Yup from "yup";
import { REGEX, REGEX_ERROR } from "../../../../utils/Constants";

export  const managePricingSchema = Yup.object().shape({
   

    OEMRatio: Yup.string()
    .matches(REGEX.WHOLE_OR_DECIMAL, REGEX_ERROR.WHOLE_OR_DECIMAL_ERROR)
    .required('Please Enter Value'),

    ListRatio: Yup.string()
    .matches(REGEX.WHOLE_OR_DECIMAL, REGEX_ERROR.WHOLE_OR_DECIMAL_ERROR)
    .required('Please Enter Value'),

    DistributorRatio: Yup.string()
    .matches(REGEX.WHOLE_OR_DECIMAL, REGEX_ERROR.WHOLE_OR_DECIMAL_ERROR)
    .required('Please Enter Value'),

    SalesRatio: Yup.string()
    .matches(REGEX.WHOLE_OR_DECIMAL, REGEX_ERROR.WHOLE_OR_DECIMAL_ERROR)
    .required('Please Enter Value'),

    Setup: Yup.string()
    .matches(REGEX.WHOLE_OR_DECIMAL, REGEX_ERROR.WHOLE_OR_DECIMAL_ERROR)
    .required('Please Enter Value'),

});


export  const managePricingSize = Yup.object().shape({
    FlangeThickness: Yup.string()
    .matches(REGEX.WHOLE_OR_DECIMAL, REGEX_ERROR.WHOLE_OR_DECIMAL_ERROR)
    .required('Please Enter Value'),

    GasketThickness: Yup.string()
    .matches(REGEX.WHOLE_OR_DECIMAL, REGEX_ERROR.WHOLE_OR_DECIMAL_ERROR)
    .required('Please Enter Value'),

    VelcroThickness: Yup.string()
    .matches(REGEX.WHOLE_OR_DECIMAL, REGEX_ERROR.WHOLE_OR_DECIMAL_ERROR)
    .required('Please Enter Value'),

    Pitch: Yup.string()
    .matches(REGEX.WHOLE_OR_DECIMAL, REGEX_ERROR.WHOLE_OR_DECIMAL_ERROR)
    .required('Please Enter Value'),

    OpenPitch: Yup.string()
    .matches(REGEX.WHOLE_OR_DECIMAL, REGEX_ERROR.WHOLE_OR_DECIMAL_ERROR)
    .required('Please Enter Value')


});

export  const managePricingRailBellowProperty = Yup.object().shape({

    Cost: Yup.string()
    .matches(REGEX.WHOLE_OR_DECIMAL, REGEX_ERROR.WHOLE_OR_DECIMAL)
    .required('Please Enter Value'),


    LMin: Yup.string()
    .matches(REGEX.WHOLE_OR_DECIMAL, REGEX_ERROR.WHOLE_OR_DECIMAL)
    .required('Please Enter Value'),

    Fold: Yup.string()
    .matches(REGEX.WHOLE_OR_DECIMAL, REGEX_ERROR.WHOLE_OR_DECIMAL)
    .required('Please Enter Value')
});

