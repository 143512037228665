import React, { Component } from 'react';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { getBellowByManufacturerList } from '../../CommonApiActions';
import { Field, FieldArray, Form, Formik } from 'formik';
class BellowsByManufacturerDropDown extends React.Component {
    constructor(props) {
        super(props);
        //console.log(this.props.manufacturerId);
        this.state = ({
            allBellows: [],
            manufacturerId: this.props.manufacturerId,
            Isloaded:false
        })
    }

    componentWillReceiveProps(props) {
        if (props.manufacturerId != undefined && this.state.Isloaded==false) {
            this.props.getBellowList(props.manufacturerId).then(res => {
                this.setState({
                    allBellows: res,
                    Isloaded:true
                })
            })
            this.state.manufacturerId = props.manufacturerId;
        }
    }

    componentDidMount() {
        //if (props.manufacturerId != undefined && this.state.Isloaded==false) {
            this.props.getBellowList(this.state.manufacturerId).then(res => {
                this.setState({
                    allBellows: res,
                    Isloaded:true
                })
            })
            //this.state.manufacturerId = props.manufacturerId;
        //}
        //this.props.getBellowList(this.state.railId);
    }

    render() {
        const selectedValue = this.props.selectedValue;
        const dropdownname=this.props.dropdownname;
        const bellows = this.state.allBellows;
        const options = bellows && bellows.length > 0 ? (
            bellows.map((bellow) => {
                return (<MenuItem selected={bellow.BellowId == selectedValue}  value={bellow.BellowId}>
                    {bellow.BellowValue}
                </MenuItem>
                );
            })
        ) : (
            <MenuItem>Loading....</MenuItem>
            );
        return (

            <Select component="select" defaultValue={selectedValue} 
                labelId="demo-simple-select-label"
                id="demo-simple-select" onChange={this.props.onBellowChange}>
                {options}
            </Select>
        );
    }
}

const mapProps = (state) => {
    return {
        allBellows: state.common.allBellows
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getBellowList: (manufacturerId) => {
            
            return new Promise((resolve, reject) => {
                dispatch(getBellowByManufacturerList(manufacturerId))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    };
};

export default connect(mapProps, mapDispatchToProps)(BellowsByManufacturerDropDown);