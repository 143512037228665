import React, { Component } from 'react'
import { SafeAnchor, Modal } from 'react-bootstrap';
// import { Formik, Form, Field } from 'formik';
import Button from '@material-ui/core/Button';
import { Field, FieldArray, Form, Formik } from 'formik';
import { FormikTextField } from 'formik-material-fields';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { TextField } from 'formik-material-ui';
import { getMaterialAttributes, deleteMaterialAttribute, addMaterial } from '../../components/ManageAttributeApiAction';
import Icon from '@material-ui/core/Icon';
import { textAlign } from '@material-ui/system';
import swal from 'sweetalert';
import{StyledReactTooltip} from '../ToolTip';
import Swal from 'sweetalert2';

const { Header, Body, Footer, Title } = Modal;

export default class EditMaterial extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showform: false,
            manufacturer_name: "",

            material: [{
                MaterialId: 0,
                Material: '',
                Description: '',
                PartNumberSuffix: ''
            }
            ]


        };

    }
    componentDidMount() {
        //console.log("didmount");
    }
    componentWillReceiveProps(props) {
        this.setState({ manufacturer_id: this.props.manufacturer_id });

    }


    showForm = () => {
        this.setState({ showform: true })
    }


    hideForm = () => {
        this.setState({ showform: false })
    }
    deleteMaterial = (option, arrayHelpers, i) => {
        if (option.MaterialId == 0) {
            arrayHelpers.remove(i);
        }
            else {
                Swal.fire({
                    //title: 'Are you sure?',
                    text: 'This action will delete the record permanently from database, Are you sure?',
                    // icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Cancel',
                    showCloseButton: true
                  }).then((result) => {
                    if (result.value) {
                    
                        deleteMaterialAttribute(option.MaterialId).then(resp => {

                                    arrayHelpers.remove(i);
                    
                                                });
                      
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                      
                    }
                  })
    


        // swal("This action will delete the record permanently from database, Are you sure?", {
        //     buttons: {
        //         catch: {
        //             text: "Yes",
        //             value: "Yes",
        //         },
        //         cancel: "Cancel",
        //     },
        // })
        //     .then((value) => {
        //         switch (value) {
        //             case "Yes":
                   
                           
        //     deleteMaterialAttribute(option.MaterialId).then(resp => {

        //         arrayHelpers.remove(i);

        //                     });
                        
        //                 break;
        //             default:

        //         }
        //     });     
        } 
}

    getMaterial = () => {

        getMaterialAttributes(this.props.manufacturer_id).then(res => {

            this.setState({

                material: res,
                showform: true
            })

        })
    }
    submitFormula = (data, actions) => {

        let found=false;
        for(let i=0;i<data.material.length;i++){
            let rname=data.material[i].Material;
        
        let foundDuplicate=data.material.filter(r=>r.Material==rname).map(duplicate=>(duplicate.Material ))
            if(foundDuplicate.length>=2)
            {found=true;
                actions.setSubmitting(false)
                swal(rname+" attribute is already exist", {
                    buttons: {
                            cancel: "OK",
                    },
                })
                    .then((cancel) => {
                        switch (cancel) {
                            case "Yes":                        
                                break;
                            default:
                        }
                    });
            } 
        }
        if(found==false){
            actions.setSubmitting(true);
            // let updateOptions = data.options.filter(o => (this.state.updatableOptions.indexOf(o.CalulatorformulaeId) > -1 || o.CalulatorformulaeId == 0));
            let materialAttributeValueModel = data.material
    
            addMaterial(this.props.manufacturer_id,
                materialAttributeValueModel).then(response => {
    
                    if (response.Status && response.StatusCode === 200) {
                        let { material } = this.state;
    
                        material = material.map(material => {
                            let option2 = materialAttributeValueModel.find(o => o.MaterialId == material.MaterialId);
                            return option2 ? { ...material, ...option2 } : material;
                        })
    
    
                        this.setState({
                            material
                        })
    
                    }
    
                }, this.hideForm())

        }

       

    }



    render() {

        const manufacturer_id = this.props.manufacturer_id;
        const manufacturer_name = this.props.manufacturer_name;
        return (
            <>
            {!this.state.showform ?<>
                <Icon  data-tip data-for="edit" onClick={this.getMaterial}>
                    edit
                 </Icon>
                  <StyledReactTooltip id="edit" effect="solid">
                  <span>Edit</span>
                 </StyledReactTooltip></>
                : <>
                <Modal className="calculatorPopup" show={this.state.showform} onHide={this.hideForm}>
                    <Header closeButton>
                        <Title>{manufacturer_name}</Title>
                    </Header>
                    <Body>

                        <Formik
                            onSubmit={this.submitFormula}
                            initialValues={{ material: this.state.material }}
                        >
                            {({ values, status, isSubmitting }) => (
                                <Form className="width-500">
                                    <div className="row">
                                        <div className="col-lg-12 popup-heading">
                                            <label >ATTRIBUTE NAME : MATERIAL</label>

                                        </div>


                                        <div className="col-lg-3 col-sm-3  col-3">
                                            <label >Material</label>

                                        </div>
                                        <div className="col-lg-4 col-sm-4  col-4">
                                            <label >Description</label>

                                        </div>
                                        <div className="col-lg-3 col-sm-3  col-3">
                                            <label >Part Number Suffix</label>

                                        </div>
                                    </div>

                                    <FieldArray
                                        name="material"

                                        render={arrayHelpers => (
                                            <div className="clacfor">

                                                {values.material && values.material.length > 0 ? values.material.map((material, i) => {



                                                    return (<div className="row" key={i}>
                                                        <div className="col-lg-3 col-sm-3  col-3">

                                                            <Field

                                                                name={`material[${i}.Material]`}
                                                                margin="normal"

                                                                fullWidth

                                                                component={TextField}

                                                            />
                                                            {material.MaterialId == 0 && (!material.Material || material.Material.length == 0)
                                                                && this.state.error ?
                                                                <span style={{ color: "red"}}>Enter Material Value</span> : ""}

                                                        </div>
                                                        <div className="col-lg-4 col-sm-4  col-4">

                                                            <Field

                                                                name={`material[${i}.Description]`}
                                                                margin="normal"

                                                                fullWidth

                                                                component={TextField}

                                                            />
                                                            {(!material.Description || material.Description.length == 0)
                                                                && this.state.error ?
                                                                <span style={{ color: "red"}}>Enter Description Value</span> : ""}

                                                        </div>
                                                        <div className="col-lg-3 col-sm-3  col-3">

                                                            <Field

                                                                name={`material[${i}.PartNumberSuffix]`}
                                                                margin="normal"

                                                                fullWidth

                                                                component={TextField}

                                                            />
                                                            

                                                        </div>




                                                        <SafeAnchor className="btn btn-default">
                                                            <i className="fa fa-minus"
                                                             onClick={() => { this.deleteMaterial(material, arrayHelpers, i);
                                                              }} /></SafeAnchor>
                                                        <br />
                                                    </div>);
                                                }) : arrayHelpers.push({ MaterialId: 0 })}
                                                <SafeAnchor className="btn btn-default"><i className="fa fa-plus" onClick={() => {

                                                    if (values.material && values.material.length > 0 && values.material.filter
                                                        (v =>(!v.Material || v.Material.length == 0  || !v.Description ||
                                                             v.Description.length == 0)).length ==0)
                                                  {
                                                    arrayHelpers.push({ MaterialId: 0 });
                                                    this.setState({
                                                        error: false
                                                    })
                                                  } else {
                                                      this.setState({ error : true})
                                                  }
                                                    
                                                }} /></SafeAnchor>
                                            </div>
                                        )} />
                                    {/* <input type="submit"  className="submit" disabled={isSubmitting} /> */}
                                    <Modal.Footer className="row" centered>
                                        <Button variant="contained" type="submit" className="save" disabled={isSubmitting} >Submit</Button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Body>
                </Modal>

                </>}
            </>

        )
    }
}