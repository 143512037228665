import { SERVER_ACTIONS } from '../../../../utils/Constants';
import HttpRequest from '../../../../utils/HttpRequest';
import Auth from '../../../../utils/Auth';
// import { calculatorlist, calculatorStatus, editCalculator, deleteCalculator, formulaList } from './CalculatorActions';


const auth = new Auth();


export function getBlockByManufacturerList(manufacturerId) {
    manufacturerId = manufacturerId == undefined ? 0 : manufacturerId;
    const queryString = '?ManufacturerId='+manufacturerId;
    return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_BLOCK_ATTRIBUTES}${queryString}`, 'get', {})
            .requestWithAuth()
            .then(response => {
               
                if (response.status === 200 ) {
                
                    
                    return Promise.resolve(response.data.data.BlockAttributeValueModel);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
               
                return Promise.reject(error);
            });
}

export function getBellowByManufacturerList(manufacturerId) {
    manufacturerId = manufacturerId == undefined ? 0 : manufacturerId;
    const queryString = '?ManufacturerId='+manufacturerId;
    return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_BELLOW_ATTRIBUTES}${queryString}`, 'get', {})
            .requestWithAuth()
            .then(response => {
                
                if (response.status === 200) {
                
                    
                    return Promise.resolve(response.data.data.BellowAttributeValueModel);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
               
                return Promise.reject(error);


            });
        
}

export function getKRMaterialByManufacturerList(manufacturerId) {
    manufacturerId = manufacturerId == undefined ? 0 : manufacturerId;
    const queryString = '?ManufacturerId='+manufacturerId;
    return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_KRMATERIAL_ATTRIBUTES}${queryString}`, 'get', {})
            .requestWithAuth()
            .then(response => {
                
                if (response.status === 200 ) {
                
                    
                    return Promise.resolve(response.data.data.KRMaterialAttributeValues);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
               
                return Promise.reject(error);


            });
        
}

export function getOldBellowsByManufacturerList(manufacturerId) {
    manufacturerId = manufacturerId == undefined ? 0 : manufacturerId;
    const queryString = '?ManufacturerId='+manufacturerId;
    return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_OLD_BELLOW_ATTRIBUTES}${queryString}`, 'get', {})
            .requestWithAuth()
            .then(response => {
                if (response.status === 200) {   
                    return Promise.resolve(response.data.data);
                }
                else {
                    return Promise.reject(response.data);
                }
            }, error => {
                return Promise.reject(error);
            });        
}
export function getNewBellowsByManufacturerList(manufacturerId) {
    manufacturerId = manufacturerId == undefined ? 0 : manufacturerId;
    const queryString = '?ManufacturerId='+manufacturerId;
    return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_NEW_BELLOW_ATTRIBUTES}${queryString}`, 'get', {})
            .requestWithAuth()
            .then(response => {
                if (response.status === 200) {   
                    return Promise.resolve(response.data.data);
                }
                else {
                    return Promise.reject(response.data);
                }
            }, error => {
                return Promise.reject(error);
            });        
}

////////////////////////////sizebymanufacturer///////////
export function getSizeListByManufacturer(manufacturerId) {
    manufacturerId = manufacturerId == undefined ? 0 : manufacturerId;
    const queryString = '?ManufacturerId='+manufacturerId;
    return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_SIZES_BY_MANUFACTURER}${queryString}`, 'get', {})
            .requestWithAuth()
            .then(response => {
                
                if (response.status === 200 ) {
                
                    
                    return Promise.resolve(response.data.data);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
               
                return Promise.reject(error);


            });
}


export function getAllRailsList(manufacturerId) {
    manufacturerId = manufacturerId == undefined ? 0 : manufacturerId;
    const queryString = '?ManufacturerId='+manufacturerId;
    return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_ALL_RAILS}${queryString}`, 'get', {})
            .requestWithAuth()
            .then(response => {
                
                if (response.status === 200 ) {
                
                    
                    return Promise.resolve(response.data.data);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
               
                return Promise.reject(error);


            });
}


export function getAllAccessoryByManufacturerList(manufacturerId) {
    
    manufacturerId = manufacturerId == undefined ? 0 : manufacturerId;
    const queryString = '?ManufacturerId='+manufacturerId;
    return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_ACCESSORY_ATTRIBUTES}${queryString}`, 'get', {})
            .requestWithAuth()
            .then(response => {
                
                if (response.status === 200 ) {
                
                    
                    return Promise.resolve(response.data.data.AccessoryAttributeValueModel);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
               
                return Promise.reject(error);


            });
}

export function getAllKRSeries(manufacturerId) {
    manufacturerId = manufacturerId == undefined ? 0 : manufacturerId;
    const queryString = '?ManufacturerId='+manufacturerId;
    return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_ALL_KRSERIES}${queryString}`, 'get', {})
            .requestWithAuth()
            .then(response => {
                
                if (response.status === 200 ) {
                
                    
                    return Promise.resolve(response.data.data);
                }
                else {
                    return Promise.reject(response.data.data);
                }


            }, error => {
               
                return Promise.reject(error);


            });
}

export function getRailBellowByManufacturerList(manufacturerId) {
    manufacturerId = manufacturerId == undefined ? 0 : manufacturerId;
    const queryString = '?ManufacturerId='+manufacturerId;
    return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_ALL_RAILBELLOW_BY_MANUFACTURER}${queryString}`, 'get', {})
            .requestWithAuth()
            .then(response => {
                
                if (response.status === 200 ) {
                
                    
                    return Promise.resolve(response.data.data);
                }
                else {
                    return Promise.reject(response.data.data);
                }


            }, error => {
               
                return Promise.reject(error);


            });
} 


export function getAllManufacturerList(manufacturerId) {

   const queryString = '';//'?ManufacturerId='+manufacturerId;
    return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_ALL_MANUFACTURERS}${queryString}`, 'get', {})
            .requestWithAuth()
            .then(response => {
                
               
                if (response.status === 200) {
                
                    
                    return Promise.resolve(response.data);
                }
                else {
                    return Promise.reject(response.data);
                }


            }, error => {
               
                return Promise.reject(error);


            });
} 




