import Button from '@material-ui/core/Button';
import { Field, FieldArray, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import { Modal, SafeAnchor } from 'react-bootstrap';
import { createCalculatorFormula, deleteCalculatorFormulaById, getFormulaByCalculatorId } from '../../ManageCalculator/Components/CalculatorApiAction';
import './CalculatorList.css';
import { FormikTextField } from 'formik-material-fields';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


//import TableContainer from '@material-ui/core/TableContainer';
import swal from 'sweetalert';
import Swal from 'sweetalert2';


const { Header, Body, Footer, Title } = Modal;

const useStyles = makeStyles({
    table: {
      minWidth: 1000
    }
  });
//   const classes = useStyles();
 
class ManageFormula extends React.Component {
  
   
    constructor(props) {
       
        super(props);
        this.state = {
            Israil: '',
            displayinoutput: '',
            calculatorFormulaId: '',
            showform: false,
            getCalculatorFormula: [],
            options: [],
            updatableOptions: [],
            formula: []
        }
     

    }
   
    
    componentDidMount() {
        // this.props.getFormulaList(1);
    }

    componentWillReceiveProps(props) {
        this.setState({ calc: props.calc, updatableOptions: [] });

    }

    deleteFormula = (option, arrayHelpers, i) => {

        Swal.fire({
            //title: 'Are you sure?',
            text: 'Are you sure want to delete?',
            // icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
            showCloseButton: true
          }).then((result) => {
            if (result.value) {
                if (option.CalulatorformulaeId == 0 ) {
                                        arrayHelpers.remove(i);
                                    }
                                    else
                                     {
                                        deleteCalculatorFormulaById(option.CalulatorformulaeId).then(resp => {
                                            //console.log({ response: resp.data });
                                            let { options } = this.state;
                                            options = options.filter(u => u.CalulatorformulaeId != resp.Data.CalulatorformulaeId);
                                            this.setState({
                                                options: options
                                            })
                                            arrayHelpers.remove(i);
                                        });
                                    }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              
            }
          })



        // swal("Are you sure want to delete?", {
        //     buttons: {
        //         catch: {
        //             text: "Yes",
        //             value: "Yes",
        //         },
        //         cancel: "Cancel",
        //     },
        // })
        //     .then((value) => {
        //         switch (value) {
        //             case "Yes":

        //                 if (option.CalulatorformulaeId == 0 ) {
        //                     arrayHelpers.remove(i);
        //                 }
        //                 else
        //                  {
        //                     deleteCalculatorFormulaById(option.CalulatorformulaeId).then(resp => {
        //                         //console.log({ response: resp.data });
        //                         let { options } = this.state;
        //                         options = options.filter(u => u.CalulatorformulaeId != resp.Data.CalulatorformulaeId);
        //                         this.setState({
        //                             options: options
        //                         })
        //                         arrayHelpers.remove(i);
        //                     });
        //                 }
        //                 break;
        //             default:
        //         }
        //     });
    }

    showForm = () => {
        this.setState({ showform: true })
    }


    hideForm = () => {
        this.setState({ showform: false })
    }

    editFormula = () => {
        //console.log({ test: this.props.calc });
        getFormulaByCalculatorId(this.props.calc.CalculatorId).then(res => {

            this.setState({
                options: res.CalculatorAttributeValueModel,
                showform: true
            })
        })


    }
    handleRoleTypeChange(e) {
        this.state.role.role_type_id = e.target.value;
    }
    // onDropDownFieldChange(e){

    //    e.target.name=e.target.value;

    //     // let { updatableOptions } = this.state;

    //     // updatableOptions.push(val);

    //     // this.setState({
    //     //     updatableOptions
    //     // })
    // }


    onFieldChange = (calculatorFormulaId) => {

        let { updatableOptions } = this.state;

        updatableOptions.push(calculatorFormulaId);

        this.setState({
            updatableOptions
        })
    }
    ForTabChange = (event)=> {

        var ParentRow = document.getElementsByName(event.target.name)[0].parentElement.parentElement.parentElement;
        document.getElementsByName(event.target.name)[0].defaultValue = event.target.value;
        ParentRow.classList.remove("optionundefined");
        ParentRow.classList.remove("option0");
        ParentRow.classList.remove("option1");
        ParentRow.classList.remove("option2");
        ParentRow.classList.add("option" + event.target.value);
        console.log("test select list change");
    }

    filterrowsbytab = (event)=>{
        this.HideElementByName('option0');
        this.HideElementByName('option1');
        this.HideElementByName('option2');
        if(event.target.value == "-1")
        {
            this.ShowElementByName('option0');
            this.ShowElementByName('option1');
            this.ShowElementByName('option2');
        }
        else if(event.target.value=="0"){
            this.ShowElementByName('option0');
        }
        else if(event.target.value=="1"){
            this.ShowElementByName('option1');
        }
        else if(event.target.value=="2"){
            this.ShowElementByName('option2');
        }
    }
    HideElementByName = (elementname)=>{
        var elements = document. getElementsByClassName(elementname);
            for(var i=0; i < elements.length; i++ ){
                elements[i].style.display = "none";
            }
    }
    ShowElementByName = (elementname)=>{
        var elements = document. getElementsByClassName(elementname);
            for(var i=0; i < elements.length; i++ ){
                elements[i].style.display = "";
            }
    }
    

    submitFormula = (data, actions) => {
        
        actions.setSubmitting(true);
        // let updateOptions = data.options.filter(o => (this.state.updatableOptions.indexOf(o.CalulatorformulaeId) > -1 || o.CalulatorformulaeId == 0));
        let updateOptions= data.options

        createCalculatorFormula(this.props.calc.CalculatorId,
            updateOptions, actions).then(response => {

                if (response.Status && response.StatusCode === 200) {
                    let { options } = this.state;

                    // options = options.map(o => {
                    //     if (updateOptions.map(oo => oo.CalulatorformulaeId == o.CalulatorformulaeId).length > 0) {
                    //         o = updateOptions.filter(o => o.CalulatorformulaeId === o.CalulatorformulaeId)[0];
                    //     }
                    //     return o;
                    // })

                    options = options.map(option => {
                        let option2 = updateOptions.find(o => o.CalulatorformulaeId == option.CalulatorformulaeId);
                        return option2 ? { ...option, ...option2 } : option;
                    })

                    //console.log({ updatedOptions: options });
                    this.setState({
                        options
                    })

                }

            }, this.hideForm())

    }





    render() {
        //console.log("qqq" + this.props.test)
        const handleChange = event => {
            this.setState({ value: event.target.value });
        };
        
        const adduser = {
            clear: 'both',
            float: 'right',
            borderRadius: '20px',
            background: '#01524B',
            color: '#fff',
            fontWeight: 'normal'
        }
        const tableGray = {
            background: '#f1f3f6',
            whiteSpace:'unset'

        }
        const mtt = {
            marginTop: '20px',
            marginTop: '20px',
            width:'100%',
            overflowX: 'auto'
        }
       

        return (
            <>
                {!this.state.showform ?
                    <Button style={adduser}
                        onClick={this.editFormula}
                    >Manage Formula</Button>
                    : <>
                        <Modal className="container  formulaModel fixed-head-footer"  show={this.state.showform} onHide={this.hideForm}>
                           
                            <Header closeButton>
                                <Title>{this.props.calc.ManufacturerName}-{this.props.calc.CalculatorName}</Title>
                            </Header>
                            <Body>
                                <Formik initialValues={{ options: this.state.options }} onSubmit={this.submitFormula}
                                    
                                    render={({ values, isSubmitting, setFieldValue }) => (

                                       // <TableContainer className={classes.container}>
                                        <Form >

                                                    <div className="row mb-1">
                                                            <div className="col-md-1">
                                                                <label>Select Tab : </label>
                                                            </div>
                                                            <div className="col-md-10">
                                                                <FormControl class="">
                                                                        <Field component="select" labelId="demo-controlled-open-select-label"
                                                                            id="demo-controlled-open-select"
                                                                            name={`optionstabfor`}
                                                                            onChange={this.filterrowsbytab.bind(this)}
                                                                        >
                                                                            <option value="-1">All Tabs</option>
                                                                            <option value="0">I Know Stroke/Rail</option>
                                                                            <option value="1">I Know Part No.</option>
                                                                            <option value="2">KR Series Bellows </option>
                                                                        </Field>
                                                                    </FormControl>
                                                                    </div>
                                                </div>
                                                <div className="manageFormula" >
                                                {/* <ReactTableContainer 
                                                scrollbarStyle={{
            background: { backgroundColor: "transparent" },
            backgroundFocus: { backgroundColor: "#f0f0f0" },
            foreground: { backgroundColor: "#6080c5" },
            foregroundFocus: { backgroundColor: "#6080c5" }
          }}> */}
                                                {/* <Table stickyHeader aria-label="sticky" className="table-striped"> */}
                                                <Table className={useStyles.table} >
                                                <TableHead style={tableGray} >
                                                <TableRow  >
                                                <TableCell scope="row" style={{ minWidth: 100, maxWidth: 100 }}>For Tab</TableCell>
                                                <TableCell scope="row" style={{ minWidth: 100, maxWidth: 100 }}>Formula For</TableCell>
                                                
                                                <TableCell scope="row" style={{ minWidth: 100, maxWidth: 100 }}>Display <br/>Name</TableCell>
                                                <TableCell scope="row" style={{ minWidth: 100, maxWidth: 100 }}>Attr Name</TableCell>
                                                <TableCell scope="row" style={{minWidth:300,maxWidth:300}}>Formula</TableCell>
                                                {/* <TableCell scope="row" style={{ minWidth: 100, maxWidth: 100 }}>Sort Order</TableCell> */}
                                                <TableCell scope="row" style={{ minWidth: 100, maxWidth: 100 }}>Round By</TableCell>
                                                <TableCell scope="row" style={{ minWidth: 100, maxWidth: 100 }}>Round Upto<br/> Decimal</TableCell>
                                                <TableCell scope="row" style={{ minWidth: 100, maxWidth: 100 }} >Rail<br/> Length</TableCell>
                                                <TableCell style={{ minWidth: 100, maxWidth: 100 }}>Display <br/>Order</TableCell>
                                                <TableCell scope="row" style={{ minWidth: 100, maxWidth: 100 }}>Display In <br/>O/P</TableCell>
                                                
                                                </TableRow >
                                                </TableHead>

<TableBody>
    {
       
       
                                            <FieldArray
                                                name="options"
                                                formula="options"
                                                render={arrayHelpers => (
                                                   <>

                                                        {values.options && values.options.length > 0 ? values.options.map((option, i) => {

                                                            if(option.ForTab == undefined){
                                                                option.TabFor="0";
                                                            }
                                                            else{
                                                                option.TabFor=option.ForTab;
                                                            }

                                                            return (
                                                               <TableRow className={`row option${option.TabFor}`} key={i}>
                                                               <TableCell scope="row">

                                                                        <FormControl className="FormulaDropdown">
                                                                            <Field component="select" labelId="demo-controlled-open-select-label"
                                                                                id="demo-controlled-open-select" key={i}
                                                                                defaultValue={option.ForTab}
                                                                                name={`options[${i}].ForTab`}

                                                                                onChange={(e) => {
                                                                                    this.ForTabChange(e);
                                                                                    setFieldValue(`options[${i}.ForTab]`, e.target.value);
                                                                                }}

                                                                            >
                                                                                <option value={0}>I Know Stroke/Rail</option>
                                                                                <option value={1}>I Know Part No.</option>
                                                                                <option value={2}>KR Series Bellows </option>

                                                                            </Field>
                                                                        </FormControl>
                                                                    </TableCell>
                                                                
                                                                
                                                                        <TableCell scope="row">

                                                                            <FormControl className="FormulaDropdown">
                                                                                <Field component="select" labelId="demo-controlled-open-select-label"
                                                                                    id="demo-controlled-open-select" key={i}
                                                                                    defaultValue={option.FormulaFor}
                                                                                    name={`options[${i}].FormulaFor`}


                                                                                >
                                                                                    <option value={0} >Bellows Parameter</option>
                                                                                    <option value={1} >Bellows Price</option>
                                                                                    <option value={2} >MB Price </option>
                                                                                    <option value={3} >MB Parameter</option>

                                                                                </Field>
                                                                            </FormControl>
                                                                        </TableCell>
                                                                        
                                                                
                                                                    <TableCell scope="row">
                                                                    <FormikTextField
                                                                        name={`options[${i}].DisplayName`}
                                                                        key={i}
                                                                        margin="normal"

                                                                        onChange={() => {
                                                                            this.onFieldChange(option)
                                                                        }}
                                                                    />
                                                                 </TableCell>
                                                                 <TableCell scope="row">

                                                                    <Field type="text" name={`options[${i}].AttrName`} key={i}
                                                                        margin="normal"
                                                                        onChange={() => {
                                                                            this.onFieldChange(option)
                                                                        }}
                                                                        component={TextField} />
                                                                </TableCell>
                                                                <TableCell scope="row"  >
                                                                    <Field style={{width:"100%"}} type="text" name={`options[${i}].Formula`} key={i}
                                                                        margin="normal"
                                                                        onChange={() => {
                                                                            this.onFieldChange(option)
                                                                        }}
                                                                        component={TextField} />
                                                                 </TableCell>
                                                                 {/* <TableCell scope="row">
                                                                    <Field type="text" name={`options[${i}].SortOrder`} key={i}
                                                                        margin="normal"
                                                                        onChange={() => {
                                                                            this.onFieldChange(option)
                                                                        }}
                                                                        component={TextField} />
                                                                </TableCell> */}
                                                                <TableCell scope="row">
                                                                    

                                                                    <FormControl class="FormulaDropdown">
                                                                        <Field component="select" labelId="demo-controlled-open-select-label"
                                                                            id="demo-controlled-open-select" key={i}
                                                                            defaultValue={option.RoundBy}
                                                                            name={`options[${i}].RoundBy`}
                                                                        >
                                                                            <option value="0">select</option>
                                                                            <option value="1" >Round</option>
                                                                            <option value="2" >Round Up</option>
                                                                            <option value="3" >Round Down</option>
                                                                        </Field>
                                                                    </FormControl>
                                                                    </TableCell>

                                                                    <TableCell scope="row">
                                                                    <Field type="text" name={`options[${i}].RoundUptoDecimal`} key={i}
                                                                        margin="normal"
                                                                        onChange={() => {
                                                                            this.onFieldChange(option)
                                                                        }}
                                                                        component={TextField} />
                                                               </TableCell>
                                                               <TableCell scope="row">

                                                                    {/* <Field type="text" name={`options[${i}].IsRailLength`} key={i}
                                                                        margin="normal"                                                                        
                                                                        onChange={() => {
                                                                            this.onFieldChange(option)
                                                                        }}
                                                                        component={TextField} /> */}
                                                                    <FormControl class="FormulaDropdown">
                                                                    <Field component="select" labelId="demo-controlled-open-select-label"
                                                                            id="demo-controlled-open-select" key={i}
                                                                            defaultValue={option.IsRailLength}
                                                                            name={`options[${i}].IsRailLength`}
                                                                        >
                                                                            <option value={false} >No</option>
                                                                            <option value={true}>Yes</option>
                                                                        </Field>
                                                                    </FormControl>

                                                                    </TableCell>
                                                                    <TableCell >
                                                                    <FormikTextField
                                                                    
                                                                        name={`options[${i}].DisplayOrder`}
                                                                        margin="normal"
                                                                        
                                                                        onChange={() => {
                                                                            this.onFieldChange(option)
                                                                        }}
                                                                    />
                                                                    </TableCell>
                                                                    <TableCell scope="row">
                                                                <FormControl className="FormulaDropdown">
                                                                <Field component="select" labelId="demo-controlled-open-select-label"
                                                                            id="demo-controlled-open-select" key={i}
                                                                            defaultValue={option.DisplayInOutput}
                                                                            name={`options[${i}].DisplayInOutput`}                                                                           
                                                                        >
                                                                            <option value={false}>No</option>
                                                                            <option value={true} >Yes</option>

                                                                        </Field>
                                                                    </FormControl>
                                                                    </TableCell>
                                                                    
                                                                 



                                                                <SafeAnchor className="btn btn-default">
                                                                    <i className="fa fa-minus" onClick={() => { this.deleteFormula(option, arrayHelpers, i) }} /></SafeAnchor>
                                                                <br />
                                                                </TableRow>
                                                            );
                                                        }) :arrayHelpers.push({ CalulatorformulaeId: 0 })}
                                                        <SafeAnchor className="btn btn-default"><i className="fa fa-plus" onClick={() => {
                                                            arrayHelpers.push({ CalulatorformulaeId: 0 })
                                                        }} /></SafeAnchor>
                                                    </>
                                                )} />
                                               
                                                 
                                            }
                                            
                                            </TableBody>
                                            {/* <input type="submit"  className="submit" disabled={isSubmitting} /> */}
                                            <Modal.Footer className="row" centered>
                                              <Button variant="contained" type= "submit"  className="save" disabled={isSubmitting} >Submit</Button>
                                            </Modal.Footer>
                                           
                                            </Table>
                                           
                                            {/* </ReactTableContainer> */}
                                            </div>
                                            
                                        </Form>
                                       
                                    )}
                                >
                                </Formik>
                            </Body>
                        
                        </Modal>

                    </>}
            </>
        )
    }
}



export default ManageFormula;