
import React, { Component } from 'react';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { getSizeListByManufacturer } from '../../CommonApiActions';

class SizeByManufacturer extends React.Component {
    constructor(props) {
        super(props);
     
        this.state = ({
            manufacturerSizes: [],
            manufacturerId: this.props.manufacturerId,
            selectedValue:0
        })
    }

    componentWillReceiveProps(props) {
        if(props.manufacturerId!= undefined && props.manufacturerId !=this.state.manufacturerId){
          this.props.getSizeList(props.manufacturerId);
          this.state.manufacturerId = props.manufacturerId;
         
         // console.log("will receive");
        }
        // else if(props.bellowId!= undefined && props.railId!= undefined && props.railId !=this.state.railId){
        //     this.state.railId = props.railId;
        //     this.state.bellowId = 0;
           
        // }

    }

    componentDidMount() {
        this.props.getSizeList(this.state.manufacturerId);
        //console.log("receive");
    }


    render() {
        const selected = this.props.manufacturerSizes[0];
        // const selectedValue=selected!=undefined ?(selected.SizeId):(0);
        const selectedValue=this.props.selectedValue;
        
       // console.log(selectedValue);
        const sizes = this.props.manufacturerSizes;
        const options = sizes && sizes.length > 0 ? (
            sizes.map((size) => {

                return (<MenuItem name={size.SizeId} value={size.SizeId}>
                        {size.RailValue}-{size.BellowsValue}  {size.SizeValue}
                        </MenuItem>
                );
                
            })
            
        ) : (
                <MenuItem>Loading....</MenuItem>
            );
        //console.log({ options })
       
        return (
<>
            <Select style={{width:160}}  defaultValue={selectedValue}
                // name= {selectedName}
                labelId="demo-simple-select-label"
                id="demo-simple-select" onChange={this.props.onSizeChange}>
                {options}
            </Select>
            </>
        );
    }

}

const mapProps = (state) => {
   
    return {
        manufacturerSizes: state.common.manufacturerSizes
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getSizeList: (manufacturerId) => {
            return new Promise((resolve, reject) => {
                dispatch(getSizeListByManufacturer(manufacturerId))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    };
};

export default connect(mapProps, mapDispatchToProps)(SizeByManufacturer);