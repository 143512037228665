import React, { Component } from 'react';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { getBlockByManufacturerList } from '../../CommonApiActions';
import { Field, FieldArray, Form, Formik } from 'formik';
class BlockByManufacturerDropDown extends React.Component {
    constructor(props) {
        super(props);
        //console.log(this.props.manufacturerId);
        this.state = ({
            allBlocks: [],
            manufacturerId: this.props.manufacturerId,
            Isloaded:false

        })
    }

    componentWillReceiveProps(props) {

        if (props.manufacturerId != undefined && this.state.Isloaded==false) {
            this.props.getBlockList(props.manufacturerId).then(res => {
                this.setState({
                    allBlocks: res,
                    Isloaded:true
                })

            })
            this.state.manufacturerId = props.manufacturerId;
        }
    }

    componentDidMount() {
        //this.props.getBellowList(this.state.railId);
    }


    render() {
        const selectedValue = this.props.selectedValue;
        const dropdownname=this.props.dropdownname;
        // const selectedName = this.props.selectedName;


        const blocks = this.state.allBlocks;
        //console.log({ selectedValue, blocks });
        const options = blocks && blocks.length > 0 ? (
            blocks.map((blocks) => {

                return (<option selected={blocks.BlockID == selectedValue}  value={blocks.BlockID}>
                    {blocks.BlockType}
                </option>
                );
            })
        ) : (
                <option>Loading....</option>
            );
        //console.log({ options })
        return (

            <Field component="select" style={{width:140}} defaultValue={selectedValue} 
                 name={dropdownname}
                labelId="demo-simple-select-label"
                id="demo-simple-select" onChange={this.props.onBlockChange}>
                {options}
            </Field>
        );
    }

}

const mapProps = (state) => {

    return {
        allBlocks: state.common.allBlocks
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getBlockList: (manufacturerId) => {
            
            return new Promise((resolve, reject) => {
                dispatch(getBlockByManufacturerList(manufacturerId))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    };
};

export default connect(mapProps, mapDispatchToProps)(BlockByManufacturerDropDown);
