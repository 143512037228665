import { SERVER_ACTIONS } from '../../utils/Constants';
import HttpRequest from '../../utils/HttpRequest';
import { updateMenu, updateCustomerCount, updateTopCustomers, updateOrderCount, updateRecentOrders, updateFormResponse } from './DashboardActions';

export function getUserMenuRequest(IsAdmin , UserId ) {
    return (dispatch) => {
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_USER_MENU}?UserId=${UserId}&IsAdmin=${IsAdmin}`, 'get')
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                dispatch(updateMenu(response.data.data));
                return Promise.resolve(response.data.data);
            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);
            });

    }
}

export function getCustomerCount() {
    return (dispatch) => {
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(`${SERVER_ACTIONS.GET_CUSTOMER_COUNT}`, 'get')
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {

                    dispatch(updateCustomerCount(response.data.data));
                }
                return Promise.resolve(response.data.data);
            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);
            });
    }
}

export function getTopCustomers() {
    return (dispatch) => {
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(`${SERVER_ACTIONS.GET_TOP_CUSTOMERS}`, 'get')
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {

                    dispatch(updateTopCustomers(response.data.data));
                }
                return Promise.resolve(response.data.data);
            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);
            });
    }
}

export function getOrderCount() {
    return (dispatch) => {
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(`${SERVER_ACTIONS.GET_ORDER_COUNT}`, 'get')
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {

                    dispatch(updateOrderCount(response.data.data));
                }
                return Promise.resolve(response.data.data);
            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);
            });
    }
}

export function getRecentOrders() {
    return (dispatch) => {
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(`${SERVER_ACTIONS.GET_RECENT_ORDER}`, 'get')
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {

                    dispatch(updateRecentOrders(response.data.data));
                }
                return Promise.resolve(response.data.data);
            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);
            });
    }
}

export function getFormResponseCount(type) {
     
    document.getElementById("loaderdiv").classList.remove("d-none");
    return new HttpRequest(`${SERVER_ACTIONS.GET_FORM_RESPONSE_COUNT}?type=${type}`, 'get')
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {

                    return Promise.resolve(response.data.data);
                } else {
                    return Promise.reject(response);
                }

            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);
            });
     
}


