import React, { Component } from 'react'
import Select from '@material-ui/core/Select';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { TextField } from 'formik-material-ui';
import InputLabel from '@material-ui/core/InputLabel';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import ManufacturerDropDownForCalculator from '../../../Common/components/DropDown/ManufacturerDropDownForCalculator'
import SizeDropDown from '../../../Common/components/DropDown/SizeDropDown';
import RailsDropdown from '../../../Common/components/DropDown/RailsDropDown';
import BellowsDropDown from '../../../Common/components/DropDown/BellowsDropDown';
import MaterialDropdown from '../../../Common/components/DropDown/MaterialDropDown';
import {getSizeDetailValues, saveSizeItem, getRailBellowValues, savePropertyItem, getMarkupsValues, saveMarkupsItem} from '../../CalculatorOutputApiActions';
import Auth from '../../../../utils/Auth';
import calculatorOutputSchema from './CalculatorOutputSchema';
import './calculator.css';
import Railcalculator from './Railcalculator';
import Partnumbercalculator from './Partnumbercalculator';
import IKnowPartNumbercalculator from './IKnowPartNumbercalculator';
import KrSeriesbellows from './KrSeriesbellows';
import Cookies from 'universal-cookie';
import MenuItem from '@material-ui/core/MenuItem';
import { Route, Link, HashRouter as Router, Switch } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
const cookies = new Cookies();
const queryString = require('query-string');
let auth = new Auth();
export default class CalculatorOutput extends Component {

    constructor(props) {
        super(props)
        
        this.state = {
            manufacturerId: auth.getManufacturerId(),
            calculatorId: auth.getCalculatorId(),
            savedPartNumberId: auth.getSavedPartId(),

            whichComponent: auth.getCalculatorTab() == undefined ? "first" 
            : (auth.getCalculatorTab() == "I Know Stroke/Rail Length" ? "first" :  
                (auth.getCalculatorTab() == "KR Series Bellows" ? "third" : "second")),
            clss:""
            //SetTab : 1 
        }
    }

    renderTab =(whichComponent)=>{
        if(whichComponent=="first"){
            return (
                <>
            <Button className="main-calc-btn" onClick={()=>{
                this.setState({
                whichComponent: "first"
            }); auth.setCalculatorTab("I Know Stroke/Rail Length")
            }}>I know stroke/rail</Button>
            <Button className="calc-tab"   onClick={()=>{
                this.setState({
                whichComponent: "second",    
                }); auth.setCalculatorTab("I Know Part No")
            }}>I know part no</Button>
            <Button className="calc-tab"  onClick={()=>{this.setState({
                whichComponent: "third"
            }); auth.setCalculatorTab("KR Series Bellows")
            }}>KR series bellows</Button>
            </>
            )
        }
        else if(whichComponent=="second"){
            return (
                <>
            <Button className="calc-tab"  onClick={()=>{this.setState({
                whichComponent: "first"
            }); auth.setCalculatorTab("I Know Stroke/Rail Length")
            }}>I know stroke/rail</Button>
            <Button className="main-calc-btn"  onClick={()=>{this.setState({
                whichComponent: "second"
            }); auth.setCalculatorTab("I Know Part No")
            }}>I know part no</Button>
            <Button className="calc-tab"  onClick={()=>{this.setState({
                whichComponent: "third"
            }); auth.setCalculatorTab("KR Series Bellows")
            }}>KR series bellows</Button>
            </>
            )
        }
        else if(whichComponent=="third"){
            return (
                <>
            <Button className="calc-tab"  onClick={()=>{this.setState({
                whichComponent: "first"
            }); auth.setCalculatorTab("I Know Stroke/Rail Length")
            }}>I know stroke/rail</Button>
            <Button className="calc-tab"   onClick={()=>{this.setState({
                whichComponent: "second"
            }); auth.setCalculatorTab("I Know Part No")
            }}>I know part no</Button>
            <Button className="main-calc-btn" onClick={()=>{this.setState({
                whichComponent: "third"
            }); auth.setCalculatorTab("KR Series Bellows")
            }}>KR series bellows</Button>
            </>
            )
        }
    }

    renderComponent= (whichComponent) =>{
        if(whichComponent=="first"){
            return (
                <Railcalculator manufacturerId={this.state.manufacturerId} calculatorId={this.state.calculatorId} />
            )
        }
        else if(whichComponent=="second"){
            return (
                // <Partnumbercalculator manufacturerId={this.state.manufacturerId} calculatorId={this.state.calculatorId} />
                <IKnowPartNumbercalculator manufacturerId={this.state.manufacturerId} calculatorId={this.state.calculatorId} />
            )
        }
        else if(whichComponent=="third"){
            return (
                <KrSeriesbellows manufacturerId={this.state.manufacturerId} calculatorId={this.state.calculatorId}/>
            )
        }
    }

    handleChangeManufacturerDropdown = (e) =>{
        
          this.setState({
              manufacturerId: e.target.value.split("-")[0],
              calculatorId: e.target.value.split("-")[1]
          })
          auth.setCalculatorId(e.target.value.split("-")[1]);
          auth.setManufacturerId( e.target.value.split("-")[0]);
        //   cookies.remove('calculatorId', { path: '/' });
         window.location.reload();
          this.renderComponent(this.state.whichComponent);
    }

    render(){
        
        return (
            
            <React.Fragment>
            
            <div className="col-lg-12">
              <div className="row">
                  <div className="col-lg-12">
                  <div className="container">
                 <div className="row  pt-100">
                 <div className="col-lg-3 col-md-4 cal-sidebar"></div>
                 <div className="col-lg-9 calcOutput">
                    <div className="row">
                        <div className="col-lg-9 main-btn float-left">
                        {this.renderTab(this.state.whichComponent)}

                        </div>

                        <div className="col-lg-3 float-right">
                            <div className="product-line">
                            <FormControl margin="normal" fullWidth>
                            <InputLabel id="demo-simple-select-label">SELECT MANUFACTURER</InputLabel>
                                        
                                        <ManufacturerDropDownForCalculator  selectedValue={this.state.manufacturerId+"-"+this.state.calculatorId}  enabled={true} activeOnly={true} onManufacturerChange={(e, index) => {
                                            // setFieldValue("manufacturerId", e.target.value);
                                            // setFieldValue("manufacturer_name", e.target.name);
                                            this.setState({
                                                manufacturerId: e.target.value.split("-")[0],
                                                calculatorId: e.target.value.split("-")[1]
                                               
                                            });
                                            this.handleChangeManufacturerDropdown(e);
                                        }
                                        } />
                            </FormControl>
                            </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                        <div className="p-0 col-lg-12">
                        
                        {this.renderComponent(this.state.whichComponent)}

                        {/* <Railcalculator disabled={!(this.state.first)}/>
                        <Partnumbercalculator disabled={!(this.state.second)}/>
                        <KrSeriesbellows disabled={!(this.state.third)}/> */}

                            {/* <Switch>
                                <Route exact path="/Railcalculator" component={Railcalculator} />
                                <Route exact path="/Partnumbercalculator" component={Partnumbercalculator} />
                                 <Route exact path="/KrSeriesbellows" component={KrSeriesbellows} />
                                
                            {/* </Switch> */}
     
                       
                    </div>
                    </div>
            
                   
            
           
            </div>
            
            </React.Fragment>
        )
    }
}

