
import React, { Component } from 'react';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { getSizeListByBellows } from '../../CommonApiActions';
import {getSizeListByBellowid} from '../../CommonApiActions';
class SizeByBellows extends React.Component {
    constructor(props) {
        super(props);
        this.state = ({
            bellowsSizes: [],
            manufacturerId: this.props.manufacturerId,
            bellowsId: this.props.bellowsId,
            selectedValue : this.props.selectedValue
        })
    }

    componentWillReceiveProps(props) {
        if(props.bellowsId!= undefined &&( props.bellowsId !=this.state.bellowsId)){
            getSizeListByBellowid(props.bellowsId,this.state.manufacturerId).then(
                res => {
                    this.setState({
                        bellowsId : props.bellowsId,
                        bellowsSizes : res
                    })
                }
            )
        //   this.props.getSizeList(props.bellowsId,this.state.manufacturerId).then(res => {
        //     this.setState({
        //         bellowsId : props.bellowsId
        //     })
        // })
         // this.state.bellowsId = props.bellowsId;
        }
    }

    componentDidMount() {
        //this.props.getSizeList(this.state.bellowsId,this.state.manufacturerId);
        // this.props.getSizeList(this.state.bellowsId,this.state.manufacturerId).
        // then(res => {
        //     this.setState({
        //         //bellowsSizes: res,
        //     })
        // })
        getSizeListByBellowid(this.state.bellowsId,this.state.manufacturerId).then(
            res => {
                this.setState({
                    bellowsId : this.state.bellowsId,
                    bellowsSizes : res
                })
            }
        )
    }

     render() {

         const selectedValue=this.props.selectedValue;

        const sizes = this.state.bellowsSizes;
        const options = sizes && sizes.length > 0 ? (
            sizes.map((size) => {
                return (<MenuItem value={size.SizeID} selected={size.SizeID == selectedValue}>
                        {size.SizeValue}
                        </MenuItem>
                );  
            })
        ) : (
                <MenuItem>Loading....</MenuItem>
            );
        return (
<>
            <Select component="select" value={selectedValue} defaultValue={selectedValue} 
                labelId="demo-simple-select-label"
                id="demo-simple-select" onChange={this.props.onSizeChange}>
                {options}
            </Select>
            </>
        );
    }

}

const mapProps = (state) => {
    return {
        bellowsSizes: state.common.bellowsSizes
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getSizeList: (bellowsId,manufacturerId) => {
            return new Promise((resolve, reject) => {
                dispatch(getSizeListByBellows(bellowsId,manufacturerId))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    };
};

export default connect(mapProps, mapDispatchToProps)(SizeByBellows);