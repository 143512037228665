import * as Yup from "yup";
import { REGEX, REGEX_ERROR } from "../../../../utils/Constants";

const CommentSchema = Yup.object().shape({
    comment: Yup.string()
    //.matches(REGEX.EMAIL, REGEX_ERROR.EMAIL_ERROR)
    .required('Please Enter Comment')
    
})

export default CommentSchema;
