import React, { Component } from 'react'
import { SafeAnchor, Modal } from 'react-bootstrap';
// import { Formik, Form, Field } from 'formik';
import Button from '@material-ui/core/Button';
import { Field, FieldArray, Form, Formik } from 'formik';
import { FormikTextField } from 'formik-material-fields';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { TextField } from 'formik-material-ui';
import {getRailBlockAttributes,deleteRailBlockAttribute,addRailBlock} from '../../components/ManageAttributeApiAction';
import Icon from '@material-ui/core/Icon';
import RailDropDown from '../../../Common/components/DropDown/RailsDropDown';
import BlockByManufacturerDropDown from '../../../Common/components/DropDown/BlockByManufacturerDropDown'
import {getBlockByManufacturerList,getAllRailsList} from '../ManageAttribute/EditDropDownListApi';
import swal from 'sweetalert';
import{StyledReactTooltip} from '../ToolTip';
import Swal from 'sweetalert2';
const { Header, Body, Footer, Title } = Modal;


export default class EditRailBlock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showform: false,
            manufacturer_name: "",
            manufacturer_id:this.props.manufacturer_id,
            raillist:[],
            blocks:[{
                RailBlockId:0,               
                BlockID:""}],
            rail_id: 0,
            rail_name: '',
            prevrail_id: 0,
            prev_rail2_id:0,
            rail_id: 0,
            railBlock:  [{
                RailBlockId: 0,
                BellowId:"",
                DefaultSelection:"",
                        
                        }
                   ]


        };

    }
    componentDidMount() {
        console.log("didmount");
    }
    componentWillReceiveProps(props) {      
     
        this.setState({ manufacturer_id: this.props.manufacturer_id });
        if(this.props.manufacturer_id!=undefined){
            this.getBlock(this.props.manufacturer_id );
            this.getRailList(this.props.manufacturer_id );
        }
       


    }
    componentDidUpdate() {
        const railValue=this.state.raillist[0]!=undefined?(this.state.raillist[0]):(0);
        const railid=railValue.RailId
       if(this.state.prev_rail2_id!=railValue.RailId && railValue.RailId!=undefined)
       {
        //    this.setId(sizeValue.SizeId);
           this.setState({rail_id:railValue.RailId});
           this.getRailBlock(railid);
          this.setState({prev_rail2_id:railValue.RailId});
        
       }
        if(this.state.prev_rail_id!==this.state.rail_id){
            this.getRailBlock(this.state.rail_id);
            this.setState({prev_rail_id:this.state.rail_id})
        }



    }
    getBlock = (manufacturerId) => {
                
        getBlockByManufacturerList(manufacturerId).then(resp => {
     
            this.setState({

                blocks:resp
            });

        });
    }
    showForm = () => {
        this.setState({ showform: true });
        this.getBlock(this.state.manufacturer_id);
    }


    hideForm = () => {
        this.setState({ showform: false,prev_rail2_id:0,rail_id: 0,
            railBlock:  [{
                RailBlockId: 0,
                BellowId:"",
                DefaultSelection:"",
                        
                        }
                   ] })
    }

    deleteRailBlock = (option, arrayHelpers, i) => {
        if (option.RailBlockId == 0) {
            arrayHelpers.remove(i);
        }
        else {
            Swal.fire({
                //title: 'Are you sure?',
                text: 'This action will delete the record permanently from database, Are you sure?',
                // icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel',
                showCloseButton: true
              }).then((result) => {
                if (result.value) {
                
                    deleteRailBlockAttribute(option.RailBlockId).then(resp => { 
                                                arrayHelpers.remove(i);
                                             });
                  
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                  
                }
              })


        // swal("This action will delete the record permanently from database, Are you sure?", {
        //     buttons: {
        //         catch: {
        //             text: "Yes",
        //             value: "Yes",
        //         },
        //         cancel: "Cancel",
        //     },
        // })
        //     .then((value) => {
        //         switch (value) {
        //             case "Yes":
                        
        //                     deleteRailBlockAttribute(option.RailBlockId).then(resp => { 
        //                         arrayHelpers.remove(i);
        //                      });
                        
        //                 break;
        //             default:

        //         }
        //     });
        }
    }

    // deleteRailBlock=(option, arrayHelpers, i)=>{
    //     if (option.RailBlockId == 0) {
    //         arrayHelpers.remove(i);
    //     }
    //     else {
    //         deleteRailBlockAttribute(option.RailBlockId).then(resp => { 
    //             arrayHelpers.remove(i);
    //          });
    //     }
    // }
    getRailList = (manufacturerid) =>  {
        getAllRailsList(manufacturerid).then(res => {
            this.setState({
                raillist:res,
            })
            
        })
    }
    getRailBlock = (rail_id) =>  {
        rail_id = rail_id == undefined ? 0 : rail_id;
       var manufacturerId = this.state.manufacturer_id == undefined ? 0 : this.state.manufacturer_id;
            const query='?ManufacturerId='+manufacturerId+'&RailId='+rail_id;          
            getRailBlockAttributes(query).then(res => {
                
                this.setState({
    
                    
                    railBlock:res,
                   
                   
                })
                
            })
        }
    handleChangeRailDropdown = (event) => {
        this.setState({ rail_id: event.target.value })
        // this.getKRRailLenth(event.target.value);
    };
    submitFormula = (data, actions) => {
        let found=false;
        for(let i=0;i<data.railBlock.length;i++){
            let rname=data.railBlock[i].BlockId;
        
        let foundDuplicate=data.railBlock.filter(r=>r.BlockId==rname).map(duplicate=>(duplicate.BlockId ))
            if(foundDuplicate.length>=2)
            {found=true;
                actions.setSubmitting(false)
                swal(rname+" attribute is already exist", {
                    buttons: {
                            cancel: "OK",
                    },
                })
                    .then((cancel) => {
                        switch (cancel) {
                            case "Yes":                        
                                break;
                            default:
                        }
                    });
            } 
        }
        if(found==false){
            actions.setSubmitting(true);
            // let updateOptions = data.options.filter(o => (this.state.updatableOptions.indexOf(o.CalulatorformulaeId) > -1 || o.CalulatorformulaeId == 0));
            let railBlockAttributeValueModel= data.railBlock
    
            addRailBlock(this.props.manufacturer_id,this.state.rail_id,
                railBlockAttributeValueModel).then(response => {
    
                    if (response.Status && response.StatusCode === 200) {
                        let { railBlock } = this.state;
             
    
                        railBlock = railBlock.map(railBlock => {
                            let option2 = railBlockAttributeValueModel.find(o => o.RailBlockId == railBlock.RailBlockId);
                            return option2 ? { ...railBlock, ...option2 } : railBlock;
                        })
                     
                        this.setState({
                            railBlock
                        })
    
                    }
    
                }, this.hideForm())
        }
       
       

    }

    render() {

        const manufacturer_id = this.props.manufacturer_id;
        const manufacturer_name = this.props.manufacturer_name;
        const blocks=this.state.blocks;      
        let defaultBlockType=0;
        if(this.state.blocks.length>0)
        {
         defaultBlockType=this.state.blocks[0].BlockID!=undefined?(this.state.blocks[0].BlockID):(0);

        }
        const options = blocks && blocks.length > 0 ? (
            blocks.map((blocks) => {

                return (<option   value={blocks.BlockID}>
                    {blocks.BlockType}
                </option>
                );
            })
        ) : (
                <option>Loading....</option>
            );
        //console.log({ krraillength: this.state.krraillength })
        return (
            <>
                {!this.state.showform ?<>
                    <Icon data-tip data-for="edit" onClick={this.showForm}>
                        edit
                 </Icon>
                 <StyledReactTooltip id="edit" effect="solid">
                 <span>Edit</span>
                </StyledReactTooltip></>
                    : <>
                        <Modal className="calculatorPopup" show={this.state.showform} onHide={this.hideForm}>
                            <Header closeButton>
                                <Title>{manufacturer_name}</Title>
                            </Header>
                            <Body>

                                <Formik
                                    onSubmit={this.submitFormula}
                                    initialValues={{ railBlock: this.state.railBlock }}
                                    enableReinitialize={true}
                                >
                                    {({ values, status, isSubmitting,setFieldValue }) => (
                                        <Form>
                                            <div className="row">
                                                <div className="col-lg-12 popup-heading">
                                                    <label >ATTRIBUTE NAME :RAIL-BLOCK</label>
                                                </div>
                                                <div className="col-lg-4">
                                              
                                                    <FormControl  margin="normal" fullWidth>
                                                    <div className="ManufacturerDropDown">
                                                        <InputLabel id="demo-simple-select-label">Rail*</InputLabel>
                                                        <RailDropDown selectedValue={this.state.rail_id} enabled={true} activeOnly={true} manufacturerId={manufacturer_id} onRailChange={(e, index) => {
                                                            // setFieldValue("rail_id", e.target.value);
                                                            // setFieldValue("rail_name", e.target.name);
                                                            // this.handleChangeDropdown(e, index);

                                                           this.handleChangeRailDropdown(e)
                                                          
                                                            this.setState({
                                                                rail_id: e.target.value,
                                                                rail_name: index.props.name
                                                                
                                                            });
                                                            
                                                        }
                                                        } />
                                                        </div>
                                                    </FormControl>
                                                </div> 
                                                </div>
                                           

                                            <div className="row">
                                        <div className=" col-lg-4  col-sm-4  col-6">
                                        <label >Block Type</label>
                                        </div> 
                                        </div> 
                                            <FieldArray
                                                name="railBlock"

                                                render={arrayHelpers => (
                                                    <div className="clacfor">

                                                        {values.railBlock && values.railBlock.length > 0 ? values.railBlock.map((railBlock, i) => {

                                                            return (<div className="row" key={i}>
                                                                <div className="col-lg-4  col-sm-4  col-6">
                                                                <FormControl  className="FormulaDropdown" margin="normal" fullWidth>
                                                                <div className="ManufacturerDropDown">
                                                       
                                                                {/* <BlockByManufacturerDropDown  dropdownname={`railBlock[${i}.BlockId]`}
                                                                 selectedValue={railBlock.BlockId} enabled={true} 
                                                                 activeOnly={true}  manufacturerId={manufacturer_id} 
                                                                 onBlockChange={(e, index) => {
                                                                  setFieldValue(`railBlock[${i}.BlockId]`, e.target.value);
                                                        }
                                                        } /> */}
                                                        <Field component="select" style={{width:140}} defaultValue={railBlock.BlockId} 
                                                        name={`railBlock[${i}.BlockId]`}
                                                        labelId="demo-simple-select-label"
                                                          id="demo-simple-select" onChange={(e, index) => {
                                                            setFieldValue(`railBlock[${i}.BlockId]`, e.target.value);
                                                            defaultBlockType=e.target.value;
                                                  }
                                                  }>
                                                           {options}
                                                        </Field>
                                                        </div>
                                                    </FormControl>
                      
                                                                                                
                                                                {/* <FormikTextField                            
                                                                    name={`railBlock[${i}].BlockId`}
                                                                    defaultValue={railBlock.BlockId}
                                                                    margin="normal"
                                                                    fullWidth                  
                                                                /> */}
                                                            </div>
                                                                <SafeAnchor className="btn btn-default">
                                                                    <i className="fa fa-minus" onClick={() => { this.deleteRailBlock(railBlock, arrayHelpers, i) }} /></SafeAnchor>
                                                                <br />
                                                            </div>);
                                                        }) : arrayHelpers.push({ RailBlockId: 0,BlockId:defaultBlockType })}
                                                        <SafeAnchor className="btn btn-default" onClick={e => {
                                                            
                                                            arrayHelpers.push({ RailBlockId: 0,BlockId:defaultBlockType })
                                                        }}><i className="fa fa-plus" /></SafeAnchor>
                                                    </div>
                                                )} />
                                            {/* <input type="submit" className="submit" disabled={isSubmitting} /> */}
                                            <Modal.Footer className="row" centered>
                                              <Button variant="contained" type= "submit"  className="save" disabled={isSubmitting} >Submit</Button>
                                            </Modal.Footer>
                                        </Form>
                                    )}
                                </Formik>
                            </Body>
                        </Modal>

                    </>}
            </>

        )
    }
}