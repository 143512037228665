import React, { Component } from "react";
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import Mastersignin from './modules/Authentication/components/MasterSignin/mastersignin';
import Layout from './modules/Layout/component';
import PageNotFound from './modules/ErrorPages/PageNotFound';
import { ACTIONS } from './utils/Constants';
import RegisterCustomer from "./modules/Authentication/components/Register/registerCustomer";
//import ManageRole from "./modules/ManageRoles/components/ManageRole/ManageRole";

import AdminSignin from "./modules/Authentication/components/AdminSignin/adminSignin";
import ManageAttribute from "./modules/ManageAttributes/components/ManageAttribute/ManageAttribute";
import SetPassword from './modules/Authentication/components/Forgot/SetPassword';
import SetAdminPassword from './modules/Authentication/components/ForgotAdmin/SetAdminPassword';

import logo from './images/logo.png';
import Navigation from '../src/modules/Authentication/components/Navigation/Navigation';

import Footer from '../src/modules/Common/components/Footer/Footer';

export default class Routes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            logo: logo
        }
    }

    render() {
        return (
            <Router>
                <Navigation Logo={this.state.logo}></Navigation>
                <Switch>
                {/* <Route exact path={ACTIONS.MANAGE_ATTRIBUTE} render={(props) => <ManageAttribute menu="*" {...props} />} /> */}
                <Route exact path={ACTIONS.SET_PASSWORD} render={(props) => <SetPassword menu="*" {...props} />} />
                    <Route exact path={ACTIONS.SET_ADMIN_PASSWORD} render={(props) => <SetAdminPassword menu="*" {...props} />} />

                    <Route exact path={ACTIONS.SIGN_IN} render={(props) => <Mastersignin menu="*" {...props} />} />

                    <Route exact path={ACTIONS.ADMIN_SIGN_IN} render={(props) => <AdminSignin menu="*" {...props} />} />

                    {/* <Route exact path={ACTIONS.REGISTER_PROFILE} render={(props) => <RegisterCustomer menu="*" {...props} />} />

                {/* <Route exact path={ACTIONS.MANAGE_USERS} render={(props) => <ManageUser menu="*" {...props} />} /> */}

                    {/* <Route exact path={ACTIONS.REGISTERED_CUSTOMERS} render={(props) => <RegisteredCustomer menu="*" {...props} />} />*/}


                    {/* <Route exact path={ACTIONS.MANAGE_ROLES} render={(props) => <ManageRole menu="*" {...props} />} /> */}

                    {/* <Route exact path={ACTIONS.MANAGE_MANUFACTURERS} render={(props) => <ManageManufacturer menu="*" {...props} />} />
                 */}
                    <Route exact path="*" render={(props) => <Layout menu="*" {...props} />} />
                   
                   
                </Switch>
            </Router>)
    }

}
