import React, { Component } from 'react'
import { TextField } from 'formik-material-ui';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { Formik, Form,FieldArray, Field, ErrorMessage } from 'formik';
import { Modal, Button, SafeAnchor, ButtonGroup, Container } from 'react-bootstrap';
 import './ManageRole.css';
import { connect } from 'react-redux';
import { saveRoleItem } from '../../ManageRolesApiActions';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Permissions from '../../../Common/components/Permissions';
//import { importDeclaration } from 'babel-types';
import RoleFormSchema from './RoleFormSchema';
import objectAssign from 'object-assign';

class EditRole extends Component {
    constructor(props) {
        
        super(props);
       // let role = props.role;
         let role = props.update ? props.role : {
             role_id: '',
             role_name: '',
             role_type_id: '',
             role_type:'',
             PermisionsData: []
         };
      
         this.state = {
            role_id: 0,
             role_name: '',
             role_type_id: 1,
             role_type:'',
             PermisionsData: []
         }
         
        this.handleRoleTypeChange = this.handleRoleTypeChange.bind(this);
    }


componentDidMount()
{
    
}
    // componentDidUpdate(){
        
    //     this.setState({ role_type_id : this.props.role_type_id })
    // }

    saveRole = (data, formAction) => {

        data.PermisionsData = this.state.PermisionsData;
        
        formAction.setSubmitting(true);
        this.props.saverole(data, formAction).then(response => {
            this.setState({ message: response.message });
            formAction.setSubmitting(false);
            if (this.props.updateList && typeof this.props.updateList === 'function') {
                this.props.updateList();
            }
            this.props.onHide();
        }, error => {
            formAction.setSubmitting(false);
        });

    };

    handleChange = (selectedOption) => {
        this.setState({ selectedOption });
    }



    handleRoleTypeChange(e) {
        if(e.target.value == 1){
            document.getElementsByClassName("permissions")[0].style.display = "block";
        }
        else{
            document.getElementsByClassName("permissions")[0].style.display = "none";//classList.add("d-none");
        }
        this.setState({ role_type_id : e.target.value })
    }

    handleTree = (selectedper) => {
        this.setState({PermisionsData: selectedper});
        
    }

    renderPermissons = () => {    
       
                   return(<Permissions onPermissionChange={this.handleTree}
                    roletypeval={this.state.role_type_id}
                    roleidval={this.props.role.role_id}>
                   </Permissions>)
    }

    componentWillReceiveProps(props)
    {
        this.setState({role_type_id : props.role.role_type_id})
    }

    render() {
      
        let stateData = this.props.role;
        //let drole = stateData.role_type_id != 1 ? "none":"";    
        const drole = {
            display: stateData.role_type_id != 1 ? "none":"",
        }
        return (

            <React.Fragment>

                <Modal className="userPopup"
                    {...this.props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header color="secondary" closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Edit Role
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Formik
                             validationSchema={RoleFormSchema}
                            onSubmit={this.saveRole}
                            initialValues={stateData}
                            enableReinitialize={true}
                        >
                            {({ errors, status, touched, isSubmitting, setFieldValue }) => (
                                <Form  noValidate>

                        <div className="row">
                            <div className="col-lg-4">
                                
                            <Field type="text"
                                name="role_name"
                                placeholder="Enter Email"
                                component = {TextField}
                                id="standard-required"
                                label="Role Name"
                                margin="normal"
                                defaultValue={stateData.role_name}
                                fullWidth
                                />
                            </div>


                            <div className="col-lg-2">
                            <FormControl className="roletypedropdown" fullWidth>  
                                    <InputLabel id="demo-simple-select-label">Role Type</InputLabel>                                                                          
                                    <Select
                                    className="role_dropdown"
                                    defaultValue = {stateData.role_type_id}
                                    labelId="demo-controlled-open-select-label"
                                    id="role_type_id"
                                    key=''
                                    data-attribute='role_type_id' name='role_type_id'
                                    onChange = {(e)=>
                                            { 
                                            this.handleRoleTypeChange(e);
                                            setFieldValue('role_type_id', e.target.value) 
                                            }
                                        } 
                                    >
                                     <MenuItem value={1}>Admin</MenuItem>
                                     <MenuItem value={2}>Register Customer</MenuItem>
                                     <MenuItem value={3}>Guest Customer</MenuItem>
                                    </Select>
                                    </FormControl>

                                       {/* <FormControl margin="normal" fullWidth>
                                             <InputLabel id="demo-simple-select-label">Role Typr</InputLabel>

                                             <RoleDropDown name = "role_id"  value = {stateData.role_id} selectedValue={stateData.role_id}
                                              selectedName={stateData.role_name}
                                               enabled={true} 
                                               activeOnly={true} 
                                             onRoleChange={(e, index)=>{
                                                 setFieldValue("role_id", e.target.value); 
                                                 setFieldValue("role_name", index.props.name);      
                                             }
                                             }/>
                                        </FormControl> */}
                            </div>
<div className="col-lg-4 permissions" style={drole}>
<InputLabel id="demo-simple-select-label">Permissions</InputLabel>
{
                             this.renderPermissons()
                            }
</div>
                            
                        </div>
                        <Modal.Footer className="row" centered>
                        <Button variant="contained" type= "submit" className="save">Save</Button>
                        <Button variant="contained" className="cancle" onClick={this.props.onHide}>Close</Button>
                    </Modal.Footer>
                        </Form>
                            )}

                        </Formik>

                    </Modal.Body>
                    
                </Modal>
            </React.Fragment>
        )
    }
}

const mapProps = state => ({
})

const mapDispatch = (dispatch, ownProps) => {

    return {
        saverole: (user, formAction) => {

            return new Promise((resolve, reject) => {
                dispatch(saveRoleItem(user, formAction)).then(response => {

                    resolve(response);
                    // if (response.status == 200 && !response.data.errorCode) {
                    //     window.Message('Saved Successfully', discount.id > 0 ? `${discount.name} Updated....` : `${discount.name} Created....`);
                    // }
                }).catch(error => {
                    reject(error);
                });
            });
        }
    }
}

export default connect(mapProps, mapDispatch)(EditRole);
