import React, { Component } from 'react';
import { connect } from 'react-redux';
import { editProfileRequest, editAdminProfileRequest } from '../../AuthenticationApiActions';
import { showProfileDataRequest, showAdminProfileDataRequest } from '../../AuthenticationApiActions';
import { Field, Form, Formik } from "formik";
import EditProfileSchema from './updateProfileSchema';
import EditGuestProfileSchema from './UpdateGuestProfileSchema';
import UpdateAdminProfileSchema from './UpdateAdminProfileSchema';
import { ACTIONS } from '../../../../utils/Constants';
import CardContent from '@material-ui/core/CardContent';
import '../SignIn/SignIn.css';
import Auth from '../../../../utils/Auth';
import { TextField } from 'formik-material-ui';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import CountryDropDown from '../../../Common/components/DropDown/CountryDropDown';
import StateDropDown from '../../../Common/components/DropDown/StateDropDown';
import CompanyTypeDropDown from '../../../Common/components/DropDown/CompanyTypeDropDown';
import UserTypeDropDown from '../../../Common/components/DropDown/UserTypeDropDown';
let auth = new Auth();

class UpdateProfile extends React.Component {

    constructor(props) {
        super(props)

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);

        let profileData = props.update ? props.profileData : {
            email: '', first_name: '', last_name: '',
            middleName: '', contact_no: '', company_name: '',
            company_type: '', address: '', country: '', state: '', city: '', postal_code: '', uiDefault: false, customer_id: 0,
            custtype:''
        };

        let adminProfileData = props.update ? props.adminProfileData : {
            email:'',  first_name: '', last_name: '',  contact_no: ''
        }

        this.state = {
            show: false,
            message: '',
            profileData: profileData,
            adminProfileData: adminProfileData,
            isAdmin: auth.getUserType()
        };
    }
   
    saveProfileData = (data, formAction) => {

        formAction.setSubmitting(true);
        data.customer_id = auth.getUserId();
        this.props.saveProfileData(data).then(response => {

            this.setState({ message: response.message });
            formAction.setSubmitting(false);
            if ( this.props.updateList && typeof this.props.updateList === 'function') {

                this.props.updateList();
            }
            // setTimeout(() => {
            //     window.location.href = ACTIONS.UPDATE_CUSTOMER;
            // }, 1000);
            // this.props.history.push(ACTIONS.DASHBOARD);

        }, error => {
            formAction.setSubmitting(false);
        });

    };

    saveAdminProfileData = (data, formAction) => {

        formAction.setSubmitting(true);
        data.user_id = auth.getUserId();
        this.props.saveAdminProfileData(data).then(response => {

            this.setState({ message: response.Message });
            formAction.setSubmitting(false);
            if ( this.props.updateList && typeof this.props.updateList === 'function') {

                this.props.updateList();
            }
            // setTimeout(() => {
            //     window.location.href = ACTIONS.UPDATE_CUSTOMER;
            // }, 1000);
            // this.props.history.push(ACTIONS.DASHBOARD);

        }, 
        error => {
            formAction.setSubmitting(false);
        });

    };

    handleClose() {
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true, message: '' });
    }

    componentDidMount() {

        if(this.state.isAdmin=="true"){

            this.showAdminProfileRequest();

        }
        else{
            this.showProfileDataRequest();
        }
    }

    showProfileDataRequest() {
        const query = 'id=' + auth.getUserId();
        this.props.showProfileDataRequest(query).then(data => {
            data = data.data[0];
            this.setState({
                profileData: {
                    email: data.email, first_name: data.first_name, last_name: data.last_name,
                    middleName: data.middleName, contact_no: data.contact_no, id: data.id, company_name: data.company_name,
                    company_type: data.company_type, address: data.address, country: data.country, state: data.state, city: data.city, 
                    postal_code: data.postal_code, custtype:data.custtype
                }
            })
        });
    }

    showAdminProfileRequest() {
        const query = 'id=' + auth.getUserId();
        this.props.showAdminProfileDatasRequest(query).then(data => {

            data = data.data[0];
            this.setState({
                adminProfileData: {
                    email: data.email, first_name: data.first_name, last_name: data.last_name, contact_no: data.contact_no
                }
            })
        })
    }

    updateList = () => {
        this.setState(Object.assign({}, this.state));
    };

    render() {

        let profile = this.props.profile;
        let stateData = this.state.profileData;    
        let adminProfileData = this.state.adminProfileData;


        //console.log({ profile })
        profile.country=102;

        //for Admin
        if(this.state.isAdmin=="true"){
            return (
                <React.Fragment>
                    <CardContent>
                        <h3 className="heading">Personal Information</h3>
                        <Formik
                             validationSchema={EditGuestProfileSchema}
                            onSubmit={this.saveAdminProfileData}
                            initialValues={adminProfileData}
                           enableReinitialize={true}
                        >
    
                            {({ errors, status, touched, isSubmitting, setFieldValue }) => (
    
                                    // stateData.map(data =>
                                <Form name="loginForm" className="form-horizontal form-simple" noValidate>
    
                                    <div className="row">
                                        <div className="col-lg-6 com-field">
                                            <Field name="first_name"
                                                placeholder="First Name"
                                                id="standard-required"
                                                label="First Name"
                                                margin="normal"
                                                fullWidth
                                                 component={TextField}
                                                error />
    
                                        </div>
    
                                        <div className="col-lg-6 com-field">
                                            <Field type="text" name="last_name"
                                                placeholder="Last Name"
                                                id="standard-required"
                                                label="Last Name"
                                                margin="normal"
                                                fullWidth
                                                component={TextField}
                                                error />
    
                                        </div>
    
                                        <div className="col-lg-6 com-field">
                                            <Field type="text" name="email"
                                                placeholder="Email"
                                                id="standard-required"
                                                label="Email"
                                                margin="normal"
                                                fullWidth
                                                component={TextField}
                                                error />
    
                                        </div>
    
                                        <div className="col-lg-6">
                                            <Field type="text" name="contact_no"
                                                placeholder="Contact Number"
                                                id="standard-required"
                                                label="Contact Number"
                                                margin="normal"
                                                fullWidth
                                                component={TextField}
                                                error />
                                        </div>
    
                                        <Field type="hidden" name="id" />
                                    </div>
                                    <div className="btn-container">
                                    <div className="col-xs-12 update-profile">
                                   
                                 
                                       <Button type="submit" color="primary" className="submit-btn ">
                                            Submit
                            </Button> 
                                    </div>
                                    </div>
    
                                </Form>
                                // )
                                                        
                            )}
                        </Formik>
    
                    </CardContent>
                </React.Fragment>
            )
        }

        else{

        return (
            <React.Fragment>
                <CardContent>
                    <h3 className="heading">Personal Information</h3>
                    <Formik
                     validationSchema={EditProfileSchema}
                        onSubmit={this.saveProfileData}
                        initialValues={stateData}
                       enableReinitialize={true}
                    >

                        {({ errors, status, touched, isSubmitting, setFieldValue }) => (

                                // stateData.map(data =>
                            <Form name="loginForm" className="form-horizontal form-simple" noValidate>

                                <div className="row">
                                    <div className="col-lg-6 com-field">
                                        <Field name="first_name"
                                            placeholder="First Name"
                                            id="standard-required"
                                            label="First Name"
                                            margin="normal"
                                            fullWidth
                                             component={TextField}
                                            error />

                                    </div>

                                    <div className="col-lg-6 com-field">
                                        <Field type="text" name="last_name"
                                            placeholder="Last Name"
                                            id="standard-required"
                                            label="Last Name"
                                            margin="normal"
                                            fullWidth
                                            component={TextField}
                                            error />

                                    </div>

                                    <div className="col-lg-6 com-field">
                                        <Field type="text" name="email"
                                            placeholder="Email"
                                            id="standard-required"
                                            label="Email"
                                            margin="normal"
                                            fullWidth
                                            component={TextField}
                                            error />

                                    </div>

                                    <div className="col-lg-6 com-field">
                                        <Field type="text" name="contact_no"
                                            placeholder="Contact Number"
                                            id="standard-required"
                                            label="Contact Number"
                                            margin="normal"
                                            fullWidth
                                            component={TextField}
                                            error />
                                    </div>


                                    <div className="col-lg-6 com-field">
                                        <Field type="text" name="company_name"
                                            placeholder="Company Name"
                                            id="standard-required"
                                            label="Company Name"
                                            margin="normal"
                                            fullWidth
                                            component={TextField}
                                            error />
                                    </div>

                                    <div className="col-lg-6 com-field">
                                        <FormControl margin="normal" fullWidth>
                                            <InputLabel id="demo-simple-select-label">Company Role</InputLabel>
                                            <CompanyTypeDropDown enabled={true} name="company_type" activeOnly={true} selectedValue={stateData.company_type} 
                                            onCompanyChange={e => {
                                                setFieldValue("company_type", e.target.value); 
                                                this.state.company_type = e.target.value;
                                                stateData.company_type = e.target.value;    
                                            }
                                            } />
                                            {errors.company_type && touched.company_type && (
                                                   <div className="selecterrormsg">
                                                   {errors.company_type}
                                                   </div>
                                                )}        
                                        </FormControl>
                                    </div>

                                    <div className="col-lg-6 com-field">
                                        <Field type="text" name="address"
                                            placeholder="Address"
                                            id="standard-required"
                                            label="Address"
                                            margin="normal"
                                            fullWidth
                                            component={TextField}
                                            error />
                                    </div>

                                    <div className="col-lg-6 com-field">
                                        <FormControl margin="normal" fullWidth>
                                            <InputLabel id="demo-simple-select-label">Country</InputLabel>
                                            <CountryDropDown enabled={true} activeOnly={true}  selectedValue={stateData.country} 
                                                onCountryChange={e => {
                                                    setFieldValue("country", e.target.value);
                                                    // auth.setCountry(e.target.value);
                                                    this.state.setCountry = e.target.value;
                                                    stateData.country = e.target.value;
                                                }
                                                } />
                                            {errors.country && touched.country && (
                                                   <div className="selecterrormsg">
                                                   {errors.country}
                                                   </div>
                                                )}        

                                        </FormControl>
                                    </div>


                                    <div className="col-lg-6 com-field">
                                        <FormControl margin="normal" fullWidth>
                                            <InputLabel id="demo-simple-select-label">State</InputLabel>
                                            <StateDropDown country={stateData.country} selectedValue={stateData.state}  enabled={true} activeOnly={true} onStateChange={e => {
                                                setFieldValue("state", e.target.value);
                                                    stateData.state = e.target.value;
                                            }
                                            } />
                                            {errors.state && touched.state && (
                                                   <div className="selecterrormsg">
                                                   {errors.state}
                                                   </div>
                                                )}        
                                        </FormControl>
                                    </div>


                                    <div className="col-lg-6 com-field">
                                        <Field type="text" name="postal_code"
                                            placeholder="Postal Code"
                                            id="standard-required"
                                            label="Postal Code"
                                            margin="normal"
                                            fullWidth
                                            component={TextField}
                                            error />

                                    </div>
                                    <div className="col-lg-6 com-field">
                                        <FormControl margin="normal" fullWidth>
                                            <InputLabel id="demo-simple-select-label">I Am A</InputLabel>
                                            <UserTypeDropDown enabled={true} name="custtype" activeOnly={true} selectedValue={stateData.custtype} 
                                            onCustTypeChange={e => {
                                                setFieldValue("custtype", e.target.value); 
                                                this.state.custtype = e.target.value;
                                                stateData.custtype = e.target.value;    
                                            }
                                            } />
                                            {errors.custtype && touched.custtype && (
                                                   <div className="selecterrormsg">
                                                   {errors.custtype}
                                                   </div>
                                                )}        
                                        </FormControl>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6 col-12 text-center text-md-left">
                                            <fieldset>
                                                <div className="icheckbox_square-blue" style={{ position: 'relative' }}>
                                                    <input type="checkbox" id="remember-me" className="chk-remember"
                                                        style={{ position: 'absolute', opacity: 0 }} />
                                                    <ins className="iCheck-helper" style={{
                                                        position: 'absolute',
                                                        top: '0%',
                                                        left: '0%',
                                                        display: 'block',
                                                        width: '100%',
                                                        height: '100%',
                                                        margin: '0px',
                                                        padding: '0px',
                                                        background: 'rgb(255, 255, 255)',
                                                        border: '0px',
                                                        opacity: 0
                                                    }} />
                                                </div>
                                                {/* <label htmlFor="remember-me"> Remember Me</label> */}
                                            </fieldset>
                                        </div>
                                        {/* <div className="col-md-6 col-12 text-center text-md-right"><a
                                                            href="recover-password.html" className="card-link">Forgot Password?</a>
                                                        </div> */}
                                    </div>
                                    <Field type="hidden" name="id" />
                                </div>
                                <div className="btn-container update-profile">
                             
                         <Button type="submit" color="primary" className="submit-btn">
                                        Submit
                        </Button> 
                                </div>


                            </Form>
                            // )
                                                    
                        )}
                    </Formik>

                </CardContent>
            </React.Fragment>
        )

        }

    }
}

const mapStateToProps = state => {
 
    return {
        profile: state.auth.profile,
        adminProfile: state.auth.adminProfile
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
 
    return {
        saveProfileData: (data) => {
            return new Promise((resolve, reject) => {
                dispatch(editProfileRequest(data)).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },

        saveAdminProfileData: (data) => {
            return new Promise((resolve, reject) => {
                dispatch(editAdminProfileRequest(data)).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },

        showProfileDataRequest: (data) => {
            return new Promise((resolve, reject) => {
                dispatch(showProfileDataRequest(data)).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        },

        showAdminProfileDatasRequest: (data) => {

            return new Promise((resolve, reject) => {
                dispatch(showAdminProfileDataRequest(data)).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })

        }

    }
}

UpdateProfile = connect(mapStateToProps, mapDispatchToProps)(UpdateProfile);

export default UpdateProfile;