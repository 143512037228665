import { UPDATE_USER, ADD_USER_TO_LIST, UPDATE_USER_IN_LIST, DELETE_USER_FROM_LIST } from './ManageUsersActions';


const initialState = { users: [] };

const manageUser = (state = initialState, action) => {
    switch (action.type) {
       
        case UPDATE_USER:
            
            if (action.PageNumber === 0)
                return { ...state, users: action.user, totalRecords: action.totalRecords };
            else {
                let currentUsers = [];
                // if( state.users.length != 0){
                //     let existingUsers = state.users.data.data.filter(disc => !action.user.data.data.some(newUser => newUser.user_id == disc.user_id));
                //     currentUsers= existingUsers.concat(action.user.data.data);
                // }
               
                 currentUsers = action.user;
                return Object.assign({}, state, {
                    users: currentUsers,
                    totalRecords: action.totalRecords,
                    // totalPages: 
                });

            }

        case ADD_USER_TO_LIST:
                state.users.data.data = [action.user, ...state.users.data.data];
            const usersToAdd =  state.users;
            return Object.assign({}, state, {
                users: usersToAdd
            });

        case UPDATE_USER_IN_LIST:
       
            let { users } = state;
            const index = users.data.data.findIndex(user => user.user_id === action.user.user_id);
            if (index !== -1) {
                users.data.data[index] = action.user;
            }

            return Object.assign({}, state, {
                users: users
            });

        case DELETE_USER_FROM_LIST:
                {
                    let  {users}  = state;
                    users.data.data = users.data.data.filter(user => user.user_id != action.user.user_id);
                    return Object.assign({}, state, {
                        users: users
                    })
                }
            
        default:
            return state;
    }
}

export default manageUser;