import React, { Component } from 'react';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { getAllMaterialListForCalculator } from '../../CommonApiActions';

class MaterialDropDownForCalculator extends React.Component {
    constructor(props) {
        super(props); 
        this.state = ({
            sizeId: this.props.sizeId
        })
    }

    componentWillReceiveProps(props) { 
        if (props.sizeId != undefined && props.sizeId != this.state.sizeId) {
            this.props.getMaterialList(props.sizeId);
            this.state.sizeId = props.sizeId;
        }
        
    }
    componentDidMount() {
        this.props.getMaterialList(this.state.sizeId);
    }
    
    render() {
 //       debugger
        const selectedValue = this.props.selectedValue; 
       
        const materials = this.props.allMaterials;

        const options = materials && materials.length > 0 ? (
            materials.map((material) => {
                return (<MenuItem name={material.Material} value={material.MaterialId} >
                    {material.Material + " (" + material.Description + ")"}  
                </MenuItem>
                );
            })
        ) : (
                <MenuItem>Loading....</MenuItem>
            ); 
        return (
            <>
           
                <Select value={selectedValue}
                  
                    labelId="demo-simple-select-label"
                    id="demo-simple-select" onChange={this.props.onMaterialChange}>
                    {options}
                </Select>
            </>
        );
    }

}

const mapProps = (state) => {

    return {
        allMaterials: state.common.allMaterials
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getMaterialList: (sizeId) => {
            return new Promise((resolve, reject) => {
                dispatch(getAllMaterialListForCalculator(sizeId))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    };
};

export default connect(mapProps, mapDispatchToProps)(MaterialDropDownForCalculator);
