import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAllManufacturerCalculatorsList } from '../../CommonApiActions';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CalculatorList from '../../../ManageCalculator/Components/CalculatorList';
import Auth from '../../../../utils/Auth';

let auth = new Auth();
class ManufacturerDropDownForCalculator extends React.Component {
    constructor(props) {
        super(props);

        this.state = ({
            allManufacturers: [],
            isAdmin: auth.getUserType(),
            userId: auth.getUserId()
        })
    }
   

    componentDidMount() {
        this.props.getManufacturerList(this.state.userId,this.state.isAdmin);
    }

    render() {
        
        const selectedValue = this.props.selectedValue;
        const manufacturers = this.props.allManufacturers;
        const options = manufacturers && manufacturers.length > 0 ? (
            
            manufacturers.map((manufacturer, index) => {
                if(manufacturer.IsDeveloped){
                return (                  
                <MenuItem key = {index} name={manufacturer.ManufacturerName} value={manufacturer.ManufacturerId+ "-" +manufacturer.CalculatorId} >
                        {manufacturer.ManufacturerName+" - "+manufacturer.CalculatorName}
                </MenuItem>
                );}
            })
        ) : (
                <MenuItem>Loading....</MenuItem>
            );
        //console.log({ options })
        return (            
            <Select style={{"width" : "100%"}} defaultValue={selectedValue}
                labelId="demo-simple-select-label"
                id="demo-simple-select" onChange={this.props.onManufacturerChange} >
                {options}
                
            </Select>
            
        
            
        );

         
    }
}

const mapProps = (state) => {

    return {
        allManufacturers: state.common.allManufacturers
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getManufacturerList: (userId, isAdmin) => {
            return new Promise((resolve, reject) => {
                dispatch(getAllManufacturerCalculatorsList(userId, isAdmin))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    };
};

export default connect(mapProps, mapDispatchToProps)(ManufacturerDropDownForCalculator);
