import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAllUserTypeList } from '../../CommonApiActions';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
class UserTypeDropDown extends React.Component {
    constructor(props) {
        super(props);

        this.state = ({
            allUserTypes: []
        })
    }

    componentDidMount() {
        this.props.getUserTypeList();
    }

    render() {
        const selectedValue = this.props.selectedValue;
        const usertypes = this.props.allUserTypes;
        const options = usertypes && usertypes.length > 0 ? (
            usertypes.map((usertype) => {
                return (<MenuItem value={usertype.id}>
                    {usertype.name}
                    </MenuItem>
                );
            })
        ) : (
                <MenuItem>Loading....</MenuItem>
            );
        //console.log({ options })
        return (

            <Select value={selectedValue}
                labelId="demo-simple-select-label"
                id="demo-simple-select" onChange={this.props.onCustTypeChange}>
                {options}
            </Select>

        );
    }
}

const mapProps = (state) => {

    return {
        allUserTypes: state.common.allUserTypes
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getUserTypeList: () => {
            return new Promise((resolve, reject) => {
                dispatch(getAllUserTypeList())
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    };
};

export default connect(mapProps, mapDispatchToProps)(UserTypeDropDown);
