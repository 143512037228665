import CalculatorList from "../ManageCalculator/Components/CalculatorList";

export const SIGN_IN = 'SIGN_IN';
export const REGISTER_CUSTOMER = 'REGISTER_CUSTOMER';
export const GUEST_SIGN_IN = 'GUEST_SIGN_IN';
export const GUEST_REGISTER = 'GUEST_REGISTER';
export const SENT_ACCESS_TOKEN = 'SENT_ACCESS_TOKEN';
export const EDIT_PROFILE = 'EDIT_PROFILE';
export const SHOW_PROFILE_DATA = 'SHOW_PROFILE_DATA';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const SET_PASSWORD = 'SET_PASSWORD';
export const EDIT_ADMIN_PROFILE = 'EDIT_ADMIN_PROFILE';


export function signIn(isLoggedIn) {
    return {
        type: SIGN_IN,
        isLoggedIn
    }
}
 
export function editProfile(profile) {
    return {
        type: EDIT_PROFILE,
        profile
    }
}

export function editAdminProfile(adminProfile) {
    return {
        type: EDIT_ADMIN_PROFILE,
        adminProfile
    } 
}

// export function calculatorNameManufacturer(data){
//  return {
//      type: Calculator
//  }
// }
