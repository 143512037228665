import * as Yup from "yup";
import {REGEX, REGEX_ERROR } from "../../../../utils/Constants";

const CustomerRegisterSchema = Yup.object().shape({

    email: Yup.string()
    .matches(REGEX.EMAIL, REGEX_ERROR.EMAIL_ERROR)
    .required('Please Enter Email'),

    first_name: Yup.string()
    .matches(REGEX.ALPHABETS_WITH_SPACE, REGEX_ERROR.ALPHABETS_WITH_SPACE_ERROR)
    .required('Please Enter First Name'),
    
    last_name: Yup.string()
    // .nullable()
    .matches(REGEX.ALPHANUMERIC_WITH_SPACE, REGEX_ERROR.ALPHABETS_WITH_SPACE_ERROR)
    .required('Please Enter Last Name'),
    
    contact_no: Yup.string()
    .matches(REGEX.PHONE,REGEX_ERROR.PHONE_ERROR)
    .nullable()
    .required('Please Enter Contact Number'),

    address: Yup.string()
    .nullable()
    .matches(REGEX.ALPHANUMERIC_WITH_SPACE, REGEX_ERROR.ALPHANUMERIC_WITH_SPACE_ERROR)
    .required("Please Enter Address"),

    postal_code: Yup.string()
    .matches(REGEX.WHOLE_NUMBER, REGEX_ERROR.WHOLE_OR_DECIMAL_ERROR)
    .max(6)
    .min(5)
    .nullable()
    .required('Please Enter Postal Code'),
   
    company_name: Yup.string()
    .matches(REGEX.ALPHANUMERIC_WITH_SPACE, REGEX_ERROR.ALPHANUMERIC_WITH_SPACE)
    .required('Please Enter Company Name'),

    role_id: Yup
    .mixed()
    .required('Please Select Role')
    .test('registered value', 'Please Select Role', value => value && !isNaN(value) && parseInt(value) > 0), 

    custtype: Yup
    .mixed()
    .required('Please Select User Type')
     .test('registered cut value', 'Please Select User Type', value => value && !isNaN(value) && parseInt(value) > 0), 

     company_type: Yup
    .mixed()
    .test('registered value', 'Please Select Company Role', value => value && !isNaN(value) && parseInt(value) > 0), 

    country: Yup
    .mixed()
    .test('registered value', 'Please Select Country', value => value && !isNaN(value) && parseInt(value) > 0), 

    state: Yup
    .mixed()
    .test('registered value', 'Please Select State', value => value && !isNaN(value) && parseInt(value) > 0), 

    custtype: Yup
    .mixed()
    .test('registered value', 'Please Select Customer Type', value => value && !isNaN(value) && parseInt(value) > 0), 
})

export default CustomerRegisterSchema;