import React, {Component} from 'react';
import './App.css';
import Routes from './routes';
import Auth from './utils/Auth';
let auth = new Auth();
export default class App extends Component {

  constructor(props) {
    super(props);
   
  }
  render(){
  if(auth.getAccessToken()!=''||undefined){
    
    return (
      <React.Fragment>  
           
        <Routes />
        
        </React.Fragment> 
    );
  }
  else{
    return(
      <>
      <test/>
      </>
    )
  }
}
 
}


