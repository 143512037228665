import React, { Component } from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Switch from '@material-ui/core/Switch';
import Pagination from 'react-bootstrap/Pagination';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { connect } from 'react-redux';
import EditManufacturer from './editmanufacturer';
import './addmanufacturer.css';
import Form from './Form';
     
import Addmanufacturer from './addmanufacturer';
import ManufacturerForm from './Form';
import { SafeAnchor } from 'react-bootstrap';
import swal from 'sweetalert';
import Swal from 'sweetalert2';

import { getManufacturerList, toggleStatusManufacturerItem, editManufacturerById, deleteManufacturerFromList } from '../../ManageManufacturersApiActions';
import ReactTooltip from "react-tooltip";
import styled from "styled-components";

// box-shadow: 0px 2px 20px lightgray;
const StyledReactTooltip = styled(ReactTooltip)`
  background-color: white !important;
  color: black !important;
  
  &:after {
    border-top-color: white !important;
  }
`;
class ManageManufacturer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            checked: true,
            addModalShow: false,
            defaultPageSize:10,
            PageNumber: 1,
            totalPages: 1,
            pageheading: 'Manage Manufacturer',
            data: {},
            editmanufacturerModalShow: false,
            pageCount:1
        }
    }

    componentDidMount() {

        this.getManufacturerList(this.state.PageNumber);

    }

    getManufacturerList = (PageNumber) => {

        const { defaultPageSize } = this.state;
        this.state.PageNumber = PageNumber;

        if (PageNumber > 0) {
            const query = 'PageNumber=' + PageNumber + '&PageSize=' + defaultPageSize + (this.state.query !== '' ? '&q=' + this.state.query : '');
            this.props.getManufacturerList({ query, PageNumber, defaultPageSize }).then(resp => {
                //console.log({ response: this.props });
                const numberOfRecords = this.props.manufacturers.length;

                const totalPages = this.props.totalPages;


                let data = this.props.manufacturers;
                this.setState({
                    numberOfRecords,
                    totalPages,
                    data: data,
                    pageCount:totalPages
                });

            }).catch(error => {
                window.location.href="#/page403"
                //window.location.reload();
                });
        }
    }

    handlePageClick = (e, index) => {
        //   this.setState({
        //      PageNumber: index
        //   });
        let PageNumber = index;
        this.getManufacturerList(PageNumber);

    }

    deleteManufacturer = (manufacturer_id, modified_by) => {

        Swal.fire({
            //title: 'Are you sure?',
            text: 'Are you sure want to delete?',
            // icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
            showCloseButton: true
          }).then((result) => {
            if (result.value) {
                this.props.deleteManufacturer(manufacturer_id, modified_by).then(res => {
                                        this.updateList();
                                    });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              
            }
          })
        // swal("Are you sure want to delete?", {
        //     buttons: {
        //         catch: {
        //             text: "Yes",
        //             value: "Yes",
        //         },
        //         cancel: "Cancel",
        //     },
        // })
        //     .then((value) => {
        //         switch (value) {
        //             case "Yes":
        //                 this.props.deleteManufacturer(manufacturer_id, modified_by).then(res => {
        //                     this.updateList();
        //                 });
        //                 break;
        //             default:
        //         }
        //     });
    }

    updateList = () => {
        this.getManufacturerList(1);
        this.setState(Object.assign({}, this.state));

    };

    addManufacturer = (data) => {

        this.props.addManufacturer(data).then(resp => {

            this.updateList();
        });

    }

    // editManufacturer = (data,action) => {

    //     this.props.editManufacturer(data,action).then(resp => {

    //         this.updateList();
    //     });

    // }
    statusChange(manufacturer_id, modified_by) {
        this.props.changeStatus(manufacturer_id, modified_by).then(res => {
            this.updateList();
        })
    };

    renderPagination = () => {

        let items = [];

        if (this.props.manufacturers && this.props.manufacturers.length > 0) {
        let j=3;
            for (let i = 1; i <= this.state.totalPages; i++) {
                if(this.state.PageNumber>=i && this.state.PageNumber+3<this.state.totalPages){
                    i=this.state.PageNumber;
                    j=this.state.PageNumber+3;
                }
                if(this.state.PageNumber+3>=this.state.totalPages){
                    if(this.state.totalPages-3>0)
                    {if(i<this.state.totalPages-3)
                        i=this.state.totalPages-3;
                        j=i+3;
                    }
                    else if(i==this.state.totalPages){
                        i=this.state.totalPages;
                    }
                    else{
                        if(i<3-this.state.totalPages){
                            i=3-this.state.totalPages;
                        }
                       }
                }
               
                if(i<j || i==this.state.totalPages)
                {
                    items.push(
                        <Pagination.Item
                            className={this.state.PageNumber === i ? "active" : ""}
                            onClick={e => this.handlePageClick(e, i)} >
                            {i}
                        </Pagination.Item>
                    );

                }
              if(i<j && this.state.totalPages-i==1)
                {
                }
                else
               
                 {
                    if(i==this.state.totalPages-1){
                        items.push(<Pagination.Ellipsis />)
                          }
                  }   
            }
            return items;
        }
    }



    renderTableData = () => {

        if (this.props.manufacturers && this.props.manufacturers.length > 0) {
            //if (this.state.data.data !== [] && this.state.data.data != undefined) {
            return this.props.manufacturers.map((data, index) => {
                const { manufacturer_id, name, thumbnail, thumbnail_alt, is_active, short_description, long_description, display_name, modified_by } = data //destructuring

                return (


                    <TableRow key={manufacturer_id}>
                        {/* <TableCell>{this.state.data.length}</TableCell> */}

                        <TableCell>
                            {((this.state.PageNumber - 1) * this.state.defaultPageSize) + index+1}
                        </TableCell>
                        <TableCell>{name}</TableCell>
                        <TableCell>{display_name}</TableCell>
                        <TableCell><img src={thumbnail}></img></TableCell>
                        {/* <TableCell>{thumbnail}</TableCell> */}

                        <TableCell> <SafeAnchor onClick={() => this.statusChange(manufacturer_id, modified_by)} >
                            &nbsp;<i className={(is_active) ? `fa fa-toggle-on fa-2x success` : `fa fa-toggle-off fa-2x red`}></i>
                        </SafeAnchor>
                        </TableCell>

                        <TableCell scope="row">
                            <div style={{ 'display': "flex" }}>
                                {/* <ManufacturerForm calc={data} editManufacturer={this.editManufacturer} ></ManufacturerForm>
                              */}
                                <Icon  data-tip data-for="edit"
                                    className="icons"
                                    onClick={() =>
                                        this.setState({
                                            editmanufacturerModalShow: true,
                                            manufacturer: data
                                        })}
                                >edit
                                        </Icon>
                                        <StyledReactTooltip id="edit" effect="solid">
                                         <span>Edit</span>
                                        </StyledReactTooltip>
                                <Icon   data-tip data-for="delete" onClick={() =>
                                    this.deleteManufacturer(manufacturer_id, modified_by)}>delete</Icon>
                                      <StyledReactTooltip id="delete" effect="solid">
                                         <span>Delete</span>
                                        </StyledReactTooltip>
                            </div>
                        </TableCell>
                    </TableRow>

                )
            })
        }
    }


    handleChange = (e) => {

        this.setState({
            checked: e.target.checked
        })

    }
    render() {

        const mtt = {
            marginTop: '20px',
            marginTop: '20px',
            width: '100%',
            overflowX: 'auto'
        }

        let addModalClose = () => {
            this.setState({ addModalShow: false });
        }
        let editModalClose = () => {
            this.setState({ editmanufacturerModalShow: false });
        }
        const tableGray = {
            background: '#f1f3f6',

        }

        const addmanufacturer = {
            clear: 'both',
            float: 'right',
            padding:'5px 0px !important',
            borderRadius: '20px',
            background: '#01524B',
            color: '#fff',
            fontWeight: 'normal'
        }
        return (
            <React.Fragment>
                <Addmanufacturer
                    show={this.state.addModalShow}
                    onHide={addModalClose}
                    update={this.state.update}
                    updateList={this.updateList}
                />

                <EditManufacturer
                    show={this.state.editmanufacturerModalShow}
                    onHide={editModalClose}
                    editmanufacturers={this.state.editmanufacturer}
                    manufacturer={this.state.manufacturer}
                    update={true}
                />
                <h2 style={{ 'clear': 'both', 'display': 'inline' }} className="heading">{this.state.pageheading}</h2>

                <Button className="addbutton" style={addmanufacturer} update={false}
                    onClick={() => this.setState({
                        addModalShow: true,
                        update: false
                    })}
                >Add Manufacturer</Button>
                <div className="col-lg-4">
                    <Addmanufacturer addManufacturer={this.addManufacturer} updateList={this.updateList} ></Addmanufacturer>

                </div>



                <div style={mtt}>
                    <Table className="table-striped">
                        <TableHead style={tableGray}>
                            <TableRow>
                                {
                                    <>
                                        <TableCell className="W10">Sr No</TableCell>
                                        <TableCell  className="W30">Name</TableCell>
                                        <TableCell  className="W30">Display Name</TableCell>
                                        <TableCell  className="W20">Thumbnail </TableCell>
                                        <TableCell className="W10" >Status</TableCell>
                                        <TableCell  className="W10">Action</TableCell>

                                    </>

                                }

                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {
                                this.renderTableData()
                            }
                        </TableBody>

                    </Table>
                </div>
                {/* {(this.state.pageCount>1)? */}
                <div className="custom-pagination">
                    <Pagination>
                        <Pagination.First onClick={e => this.handlePageClick(e, 1)} />
                        <Pagination.Prev onClick={e => this.handlePageClick(e, this.state.PageNumber > 1 ? this.state.PageNumber - 1 : this.state.PageNumber)} />
                        {this.renderPagination()}
                       
                        {/* <Pagination.Item>{10}</Pagination.Item> */}
                        <Pagination.Next onClick={e => this.handlePageClick(e, this.state.PageNumber < this.state.totalPages ? this.state.PageNumber + 1 : this.state.PageNumber)} />
                        <Pagination.Last onClick={e => this.handlePageClick(e, this.state.totalPages)} />
                    </Pagination>
                </div>
                {/* :<></>} */}
               

            </React.Fragment>
        )
    }
}


const mapStateToProps = state => {
    return {
        manufacturers: state.manufacturer.manufacturers,
        totalPages: state.manufacturer.totalPages

    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getManufacturerList: (data) => {

            return new Promise((resolve, reject) => {
                dispatch(getManufacturerList(data)).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },

        changeStatus: (manufacturer_id, modified_by) => {
            return new Promise((resolve, reject) => {
                dispatch(toggleStatusManufacturerItem(manufacturer_id, modified_by)).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            })
        },
        deleteManufacturer: (id, modified_by) => {
            return new Promise((resolve, reject) => {
                dispatch(deleteManufacturerFromList(id, modified_by)).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        }
    }
}


ManageManufacturer = connect(mapStateToProps, mapDispatchToProps)(ManageManufacturer);
export default ManageManufacturer;