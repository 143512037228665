import React, { Component } from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from 'react-bootstrap/Pagination';
import {getSearchedPricingHistoryList, getPricingHistoryList,deletePricingHistory } from '../../ManagePricingsApiActions';
import { connect } from 'react-redux';
import Auth from '../../../../utils/Auth';
import Moment from 'moment';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Icon from '@material-ui/core/Icon';
import Edituser from './editPricingHistory';
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import Swal from 'sweetalert2';
//import SearchBar from "material-ui-search-bar";
import { TextField } from 'formik-material-ui';
import { Formik, Form, Field, ErrorMessage } from 'formik';
let auth = new Auth();
const StyledReactTooltip = styled(ReactTooltip)`
  background-color: white !important;
  color: black !important;
  
  &:after {
    border-top-color: white !important;
  }
`;

class PricingHistory extends Component {
    constructor(props) {
        super(props)
        this.state = {
            
            active: false,
            checked: true,
            defaultPageSize: 10,
            PageNumber: 1,
            totalPages: 1,
            pageheading: 'PRICING HISTORY',
            data: {},
            update:true,
            htype:auth.getHistoryType(),
            manufacturerId:auth.getPHManId(),
            editPricingHistoryModalShow:false,
            update:true,
            user:{},
            action:'Action',
            query:'',
            iseditdel:true,
            searchval:'3'
        }
    }

    componentDidMount() {  
      
        this.getPricingHistoryList(this.state.PageNumber);
    }
   
    ModifyType(value){
        if(value!=this.state.htype){
            auth.setHistoryType(value)
            this.setState({
                htype: value,
            })
            //getPricingHistoryList(1)
            window.location.reload()  
        }
    }


    deleteUser = (user_id, modified_by) => {

        Swal.fire({
            //title: 'Are you sure?',
            text: 'Are you sure want to delete?',
            // icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
            showCloseButton: true
          }).then((result) => {
            if (result.value) {
                this.props.deleteUser(user_id, modified_by).then(res =>{
                                    this.updateList();
                                });
              
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              
            }
        })
        
    }
    handleSubmit = (data) => {
      //debugger
        //let srchInput = this.refs.srch;
        //this.setState({query:document.getElementById("search").value});
        this.getPricingHistoryList(1);
    }

    // seachstring=()=>{
    //     this.getPricingHistoryList(1);
    // }
    handleInputChange = event => {
        this.getPricingHistoryList(1);
    }
    // cancelstring=()=>{
    //     this.setState({
    //         searchval: "",
    //     })
    //     this.getPricingHistoryList(1);
    // }

    getPricingHistoryList(PageNumber) {
        const { defaultPageSize } = this.state;
        this.state.PageNumber = PageNumber;
        if (PageNumber > 0) {
            const query = 'manufacturerId='+this.state.manufacturerId+'&ctype='
             + this.state.htype + '&PageNumber=' + PageNumber + '&PageSize=' + defaultPageSize +
                ('&SearchString='+(document.getElementById("search").value == undefined ? '' : document.getElementById("search").value));
              //(this.state.searchval !==undefined ? '&SearchString=' + this.state.searchval : '');
            this.props.getPricingHistoryList({ query, PageNumber, defaultPageSize }).then(resp => {
                const numberOfRecords = this.props.history.Data.data.length;

                const totalPages = this.props.history.Data.totalpages;

                let data = this.props.history.Data;
                this.setState({
                    numberOfRecords,
                    totalPages,
                    data: data,
                    iseditdel:(data.data.length>0 ? data.data[0].iseditdelete : false)
                });

            }).catch(error => {
                window.location.href="#/page403"
                //window.location.reload();
                });
        }
    }
    
    handlePageClick = (e, index) => {
        let PageNumber = index;
        this.getPricingHistoryList(PageNumber);
    }

    updateList = () => {
        this.setState(Object.assign({}, this.state));
    };
    
    renderPagination = () => {

        let items = [];
        if (this.state.data.data !== [] && this.state.data.data != undefined) {
            // for (let i = 1; i <= this.props.totalPages; i++) {

            //     items.push(<Pagination.Item className={this.state.PageNumber === i ? "active" : ""} onClick={e => this.handlePageClick(e, i)} >{i}</Pagination.Item>);

            // }
            let j=3;
            for (let i = 1; i <= this.state.totalPages; i++) {
                if(this.state.PageNumber>=i && this.state.PageNumber+3<this.state.totalPages){
                    i=this.state.PageNumber;
                    j=this.state.PageNumber+3;
                }
                if(this.state.PageNumber+3>=this.state.totalPages){
                    if(this.state.totalPages-3>0)
                    {if(i<this.state.totalPages-3)
                        i=this.state.totalPages-3;
                        j=i+3;
                    }
                    else if(i==this.state.totalPages){
                        i=this.state.totalPages;
                    }
                    else{
                        if(i<3-this.state.totalPages){
                            i=3-this.state.totalPages;
                        }
                       }
                }
               
                if(i<j || i==this.state.totalPages)
                {
                    items.push(
                        <Pagination.Item
                            className={this.state.PageNumber === i ? "active" : ""}
                            onClick={e => this.handlePageClick(e, i)} >
                            {i}
                        </Pagination.Item>
                    );

                }
              if(i<j && this.state.totalPages-i==1)
                {
                }
                else
               
                 {
                    if(i==this.state.totalPages-1){
                        items.push(<Pagination.Ellipsis />)
                          }
                  }   
            }
            
            return items;
        }
    }

    renderTableData = () => {

        if (this.state.data.data !== [] && this.state.data.data != undefined) {
            return this.state.data.data.map((data) => {
                const {comment,username,createdon,htype,modified_by } = data //destructuring
                return (
                    <TableRow>
                    <TableCell scope="row">{comment}</TableCell>
                {this.state.htype=="0"? <TableCell scope="row">{htype}</TableCell>:<></>}
                    <TableCell scope="row">{username}</TableCell>
                    <TableCell>{null == createdon ? "":Moment(createdon).format('YYYY-MM-DD HH:mm')}</TableCell> 

                    <TableCell scope="row">
                            <div style={{'display':"flex"}}>
                        
                                        {/* <Icon  data-tip data-for="delete">delete</Icon> */}
                            {data.iseditdelete==true?<>
                                                           
                                <Icon
                             data-tip data-for="edit"
                                className="icons"
                                
                                onClick={() => 
                                    this.setState({ editPricingHistoryModalShow: true,
                                    user:data,
                                    update: true
                                 })}
                            >edit
                                        </Icon>
                                        <StyledReactTooltip id="edit" effect="solid">
                                         <span>Edit</span>
                                        </StyledReactTooltip>


                             <Icon  data-tip data-for="delete" onClick={() =>this.deleteUser(data.id,modified_by)}>delete</Icon>
                             <StyledReactTooltip id="delete" effect="solid">
                                          <span>delete</span>
                                         </StyledReactTooltip></>
                            : <></> }
                           
                            </div>
                        </TableCell>




                </TableRow>
                )
            })
        }
    }

    render() {
        
        const mtt = {
            marginTop: '20px',
            marginTop: '20px',
            width:'100%',
            overflowX: 'auto'
        }

        const tableGray = {
            background: '#f1f3f6',

        }
        
        let editModalClose = () => {
            this.setState({ editPricingHistoryModalShow: false });
        }
        
        return (
            <React.Fragment>
                  <Edituser
                    show={this.state.editPricingHistoryModalShow}
                    onHide={editModalClose}
                    user={this.state.user}
                    update = {this.state.update}
                    updateList = {this.updateList}
                />
<div className="row pricinghistory">
<div className="col-lg-4" style={{padding:15}}>
        <h2 className="heading">{this.state.pageheading}</h2>
                    </div>
                    <div className="col-lg-4" style={{padding:15}}>
                    <FormControl>                   

                        <div className="ManufacturerDropDown float-right">
                            <InputLabel id="demo-simple-select-label">Type</InputLabel>
                            <Select style={{width:160}} defaultValue={this.state.htype}
                            labelId="demo-simple-select-label"
                            onChange={e => {
                                this.ModifyType(e.target.value)
                            }}
                            >
                            <MenuItem value="0" >All</MenuItem>
                            <MenuItem value="1" >Properties</MenuItem>
                            <MenuItem value="2" >Material</MenuItem>
                            <MenuItem value="3" >Markup</MenuItem>
                            </Select>
                        </div>
                    </FormControl>
                        </div>
     
                    <div className="col-lg-4 searchbar" style={{padding:15}}>
                    {/* <SearchBar
                    style={{
                        margin: '0 auto',
                        maxWidth: 800
                      }}
    value={this.state.searchval}
    onChange={(newValue) => this.setState({ searchval: newValue })}
    onRequestSearch={() => this.searchstring()}
    onCancelSearch={() => this.cancelstring()}
  /> */}
  
                    { <form onSubmit={this.handleSubmit}  autoComplete="off">
                        <div className="d-flex">
                        <input onChange={this.handleInputChange} ref="srch" className="form-control" id="search"  placeholder="Search..." />
                        {/* <button className="btn btn-primary btn-search" type="submit"><i id="searchicon" class="fa fa-search" aria-hidden="true"></i></button> */}
                        </div>
                    </form> }
       </div>
                      
                   
                        </div>
                {/* <h2 style={{ 'clear': 'both', 'display': 'inline' }} className="heading">{this.state.pageheading}</h2> */}

                 <div style={mtt}>
                <Table  className="table-striped">

                    <TableHead style={tableGray}>
                        <TableRow>
                            {
                                <>
                                    <TableCell scope="row">Comment</TableCell>
                                   {this.state.htype=="0"? <TableCell scope="row">Type</TableCell>:<></>}
                                    <TableCell scope="row">Created By</TableCell>
                                    <TableCell scope="row">Created On</TableCell>
                                    {(this.state.iseditdel==true?<>
                                    <TableCell scope="row">
                                        Action
                                        </TableCell></>
                                        :<TableCell scope="row"></TableCell>)}
                                     {/* <TableCell scope="row">Action</TableCell> */}
                                </>
                            }

                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            this.renderTableData()
                        }
                    </TableBody>

                </Table>

                </div>

                <div className="custom-pagination">
                    <Pagination>
                        <Pagination.First onClick={e => this.handlePageClick(e, 1)} />
                        <Pagination.Prev onClick={e => this.handlePageClick(e, this.state.PageNumber > 1 ? this.state.PageNumber - 1 : this.state.PageNumber)} />
                        {this.renderPagination()}
                       
                        {/* <Pagination.Item>{10}</Pagination.Item> */}
                        <Pagination.Next onClick={e => this.handlePageClick(e, this.state.PageNumber < this.state.totalPages ? this.state.PageNumber + 1 : this.state.PageNumber)} />
                        <Pagination.Last onClick={e => this.handlePageClick(e, this.state.totalPages)} />
                    </Pagination>
                </div>
            </React.Fragment>
        )
    }
}


const mapStateToProps = state => {
    return {
        history: state.managePricing.history,
        totalPages: state.managePricing.totalPages
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getPricingHistoryList: (data) => {
            return new Promise((resolve, reject) => {
                dispatch(getPricingHistoryList(data)).then(response => {

                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        }, 
        getSearchPricingHistoryList: (data) => {
            return new Promise((resolve, reject) => {
                dispatch(getSearchedPricingHistoryList(data)).then(response => {

                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        }, 
        deleteUser: (id, modified_by) => {
            return new Promise((resolve, reject) => {
                dispatch(deletePricingHistory(id, modified_by)).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        }                
    }
}

PricingHistory = connect(mapStateToProps, mapDispatchToProps)(PricingHistory);

export default PricingHistory;