
export const UPDATE_SIZES = 'UPDATE_SIZES';
export const UPDATE_RAIL_BELLOWS = 'UPDATE_RAIL_BELLOWS';
export const UPDATE_MARKUPS = 'UPDATE_MARKUPS';
export const UPDATE_HISTORY = 'UPDATE_HISTORY';
export const ADD_PHistory_TO_LIST='ADD_PHistory_TO_LIST';
export const DELETE_FROM_PRICING_LIST='DELETE_FROM_PRICING_LIST';

export function updatePricingHistory(history, totalRecords, PageNumber) {
    return {
        type: UPDATE_HISTORY,
        history,
        totalRecords,
        PageNumber
    }
}

export function updateSizeDetails(sizes) {
    return {
        type: UPDATE_SIZES,
        sizes
    }
}

export function updateRailBellowDetails(properties) {
    return {
        type: UPDATE_RAIL_BELLOWS,
        properties
    }
}

export function updateMarkupsDetails(markups) {
    return {
        type: UPDATE_MARKUPS,
        markups
    }
}
export function editPricingHistory(user) {
    return {
        type: ADD_PHistory_TO_LIST,
        user
    }
}
export function deletePricingHistoryFromList(user) {
    return {
        type: DELETE_FROM_PRICING_LIST,
        user
    }
}



