export const UPDATE_ROLE = 'UPDATE_ROLE';
export const ADD_ROLE_TO_LIST = 'ADD_ROLE_TO_LIST';
export const UPDATE_ROLE_IN_LIST = 'UPDATE_ROLE_IN_LIST';
export const DELETE_ROLE_FROM_LIST = 'DELETE_ROLE_FROM_LIST';
export const GET_ROLE_ATTRIBUTE_LIST = 'GET_ROLE_ATTRIBUTE_LIST';

export function updateRoles(role, totalRecords, PageNumber) {
    return {
        type: UPDATE_ROLE,
        role,
        totalRecords,
        PageNumber
    }
}

export function addRoleToList(role) {
    return {
        type: ADD_ROLE_TO_LIST,
        role
    }
}

export function updateRoleInList(role) {
    return {
        type: UPDATE_ROLE_IN_LIST,
        role
    }
}


export function deleteRoleFromListItem(role) {
    
    return {
        type: DELETE_ROLE_FROM_LIST,
        role
    }
}

export function getAllAttribute(Attributes)  {
    return {
        type: GET_ROLE_ATTRIBUTE_LIST,
        Attributes
    }
}