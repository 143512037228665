import React, { Component } from 'react'
import { SafeAnchor, Modal } from 'react-bootstrap';
// import { Formik, Form, Field } from 'formik';
import Button from '@material-ui/core/Button';
import { Field, FieldArray, Form, Formik } from 'formik';
import { FormikTextField } from 'formik-material-fields';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { TextField } from 'formik-material-ui';
import { getBlockAttributes, deleteBlockAttribute, addBlock } from '../../components/ManageAttributeApiAction';
import Icon from '@material-ui/core/Icon';
import{StyledReactTooltip} from '../ToolTip';
import Swal from 'sweetalert2';

import swal from 'sweetalert';

const { Header, Body, Footer, Title } = Modal;

export default class EditRails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showform: false,
            manufacturer_name: "",

            block: [{
                BlockID: 0,
                BlockType: ''
            }
            ]


        };

    }
    componentDidMount() {
        //console.log("didmount");
    }
    componentWillReceiveProps(props) {
        this.setState({ manufacturer_id: this.props.manufacturer_id });

    }


    showForm = () => {
        this.setState({ showform: true })
    }


    hideForm = () => {
        this.setState({ showform: false })
    }
    deleteblock = (option, arrayHelpers, i) => {
        if (option.BlockID == 0) {
            arrayHelpers.remove(i);
        }
        else {
            Swal.fire({
                //title: 'Are you sure?',
                text: 'This action will delete the record permanently from database, Are you sure?',
                // icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel',
                showCloseButton: true
              }).then((result) => {
                if (result.value) {
                
                    deleteBlockAttribute(option.BlockID).then(resp => {
                                            arrayHelpers.remove(i);
                                        });
                  
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                  
                }
              })

        // swal("This action will delete the record permanently from database, Are you sure?", {
        //     buttons: {
        //         catch: {
        //             text: "Yes",
        //             value: "Yes",
        //         },
        //         cancel: "Cancel",
        //     },
        // })
        // .then((value) => {
        //     switch (value) {
        //         case "Yes":
        //                 deleteBlockAttribute(option.BlockID).then(resp => {
        //                     arrayHelpers.remove(i);
        //                 });
        //             break;
        //         default:

        //     }
        // });
        }
    }

    getBlock = () => {

        getBlockAttributes(this.props.manufacturer_id).then(res => {

            this.setState({


                block: res,
                showform: true
            })

        })
    }
    submitFormula = (data, actions) => {
        let found=false;
for(let i=0;i<data.block.length;i++){
    let rname=data.block[i].BlockType;

let foundDuplicate=data.block.filter(r=>r.BlockType==rname).map(duplicate=>(duplicate.BlockType ))
    if(foundDuplicate.length>=2)
    {found=true;
        actions.setSubmitting(false)
        swal(rname+" attribute is already exist", {
            buttons: {
                    cancel: "OK",
            },
        })
            .then((cancel) => {
                switch (cancel) {
                    case "Yes":                        
                        break;
                    default:
                }
            });
    } 
}
if(found==false){
    actions.setSubmitting(true);
        // let updateOptions = data.options.filter(o => (this.state.updatableOptions.indexOf(o.CalulatorformulaeId) > -1 || o.CalulatorformulaeId == 0));
        let blockAttributeValueModel = data.block

        addBlock(this.props.manufacturer_id,
            blockAttributeValueModel).then(response => {

                if (response.Status && response.StatusCode === 200) {
                    let { block } = this.state;

                    // options = options.map(o => {
                    //     if (updateOptions.map(oo => oo.CalulatorformulaeId == o.CalulatorformulaeId).length > 0) {
                    //         o = updateOptions.filter(o => o.CalulatorformulaeId === o.CalulatorformulaeId)[0];
                    //     }
                    //     return o;
                    // })

                    block = block.map(block => {
                        let option2 = blockAttributeValueModel.find(o => o.BlockID == block.BlockID);
                        return option2 ? { ...block, ...option2 } : block;
                    })

                    //console.log({ blockAttributeValueModel: block });
                    this.setState({
                        block
                    })

                }

            }, this.hideForm())

}

    }



    render() {

        const manufacturer_id = this.props.manufacturer_id;
        const manufacturer_name = this.props.manufacturer_name;
        return (
            <>
            {!this.state.showform ?<>

                <Icon   data-tip data-for="edit" onClick={this.getBlock}>
                    edit
                 </Icon>
                  <StyledReactTooltip id="edit" effect="solid">
                  <span>Edit</span>
                 </StyledReactTooltip></>
                : <>
                <Modal className="calculatorPopup" show={this.state.showform} onHide={this.hideForm}>
                    <Header closeButton>
                        <Title>{manufacturer_name}</Title>
                    </Header>
                    <Body>

                        <Formik
                            onSubmit={this.submitFormula}
                            initialValues={{ block: this.state.block }}
                        >
                            {({ values, status, isSubmitting }) => (
                                <Form>
                                    <div className="row">
                                        <div className="col-lg-12 popup-heading">
                                            <label >ATTRIBUTE NAME : BLOCK</label>


                                        </div>


                                        <div className="col-lg-8  col-8">
                                            <label >Block Type</label>

                                        </div>
                                    </div>
                                    <FieldArray
                                        name="block"

                                        render={arrayHelpers => (
                                            <div className="clacfor">

                                                {values.block && values.block.length > 0 ? values.block.map((block, i) => {

                                                    return (<div className="row" key={i}>
                                                        <div className="col-lg-8  col-8">

                                                            <Field

                                                                name={`block[${i}.BlockType]`}
                                                                margin="normal"

                                                                fullWidth

                                                                component={TextField}
                                                            // onChange={() => {
                                                            //     this.onFieldChange(option)
                                                            // }}
                                                            />
                                                            {(!block.BlockType || block.BlockType.length == 0 )
                                                            && this.state.error ? <span style={{color: "red"}}> Enter Block Value </span> : ""}

                                                        </div>




                                                        <SafeAnchor className="btn btn-default">
                                                            <i className="fa fa-minus"
                                                             onClick={() => { this.deleteblock(block, arrayHelpers, i);
                                                              }} /></SafeAnchor>
                                                        <br />
                                                    </div>);
                                                }) : arrayHelpers.push({ BlockID: 0 })}
                                                <SafeAnchor className="btn btn-default">
                                                <i className="fa fa-plus" onClick={() => {

                                                    if (values.block && values.block.length > 0 && values.block.filter 
                                                    (v =>(!v.BlockType || v.BlockType.length == 0 )).length ==0) 
                                                    {
                                                        arrayHelpers.push({ BlockID: 0 });
                                                        this.setState({
                                                            error: false
                                                        })
                                                    }  else {
                                                        this.setState({ error: true })
                                                    }
                                                   
                                                }} /></SafeAnchor>
                                            </div>
                                        )} />
                                    {/* <input type="submit"  className="submit" disabled={isSubmitting} /> */}
                                    <Modal.Footer className="row" centered>
                                        <Button variant="contained" type="submit" className="save" disabled={isSubmitting} >Submit</Button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Body>
                </Modal>

                </>}
            </>

        )
    }
}