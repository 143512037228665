import React, { Component } from 'react';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { getAllAccessoryByManufacturerList } from '../../CommonApiActions';
import { Field, FieldArray, Form, Formik } from 'formik';

class AccessoryByManufacturerDropDown extends React.Component {
    constructor(props) {
        super(props);
        //console.log(this.props.manufacturerId);
        this.state = ({
            allAccessories: [],
            manufacturerId: this.props.manufacturerId,
            Isloaded:false
        })
    }

    componentWillReceiveProps(props) {

        
        if (props.manufacturerId != undefined && this.state.Isloaded==false) {
            this.props.getAccessoryList(props.manufacturerId).then(res => {
                this.setState({
                    allAccessories: res,
                    Isloaded:true
                })

            })
            this.state.manufacturerId = props.manufacturerId;
        }
    }

    componentDidMount() {
        // this.props.getAllAccessoryByManufacturerList(this.state.manufacturerId);
    }


    render() {
        const selectedValue = this.props.selectedValue;
        const accessories = this.props.allAccessories;
        const dropdownname=this.props.dropdownname;
        const options = accessories && accessories.length > 0 ? (
            accessories.map((accessory) => {

                return (<option selected={accessory.AccessoryId == selectedValue}  value={accessory.AccessoryId}>
                    {accessory.AccessoryValue}
                </option>
                );
            })
        ) : (
                <option>Loading....</option>
            );
        //console.log({ options })
        return (

            <Field component="select" style={{width:140}}defaultValue={selectedValue}
                 name={dropdownname}
                labelId="demo-simple-select-label"
                id="demo-simple-select" onChange={this.props.onAccessoryChange}>
                {options}
            </Field>
        );
    }

}

const mapProps = (state) => {
   
    return {
        
        allAccessories: state.common.allAccessories
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getAccessoryList: (manufacturerId) => {
            return new Promise((resolve, reject) => {
                dispatch(getAllAccessoryByManufacturerList(manufacturerId))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    };
};

export default connect(mapProps, mapDispatchToProps)(AccessoryByManufacturerDropDown);
