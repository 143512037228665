
import React, { Component } from 'react';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Link, Route, Switch, HashRouter as Router } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import { getUserMenuRequest } from '../../DashboardApiActions';
import Auth from '../../../../utils/Auth';
import './SideTabNavigationStyle.css';

let auth = new Auth();
class SideTabNavigation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            
            isAdmin: auth.getUserType(),
            userId: auth.getUserId()
        }
    }
    
    handleClick(item,index) {
        this.setState(prevState => (
            { [item]: !prevState[item] }
        ))
       // setSelectedIndex(index);
    }

    componentDidMount() {
        this.props.getMenuList(this.state.isAdmin, this.state.userId);
    }
    handler(submenu) {
        const { state } = this
       // const [selectedIndex, setSelectedIndex] = React.useState(1);
        return submenu.map((subOption,index) => {
            if(subOption.notifications == "")
            {
                delete subOption.notifications;
            }
            if (!subOption.submenu || subOption.submenu.length == 0) {
                
                return (

                    <div className="LeftNavigation" key={subOption.name}>
                       <Link  to={subOption.url}  >
                        <ListItem
                            button
                            key={subOption.name}>
                            <Icon>{subOption.icon}</Icon>
                                <ListItemText

                                    primary={subOption.name}
                                    secondary={ subOption.notifications != undefined ? <span className="menunotification">{subOption.notifications}</span>: ''}
                                    
                                />
                           
                        </ListItem>
                        </Link>
                        {/* <Switch>
                            <Route exact path={subOption.url}
                                render={()=>{return<Personalinformation/>}}/>
                        </Switch> */}
                    </div>

                )
            }
            else {


                return (
                    
                    <div className="LeftNavigation" key={subOption.name}>
                        <ListItem
                            //selected={selectedIndex === 0}
                            button
                            onClick={() => this.handleClick(subOption.name,index)}
                            to={subOption.url}>
                            <Icon>{subOption.icon}</Icon>  {/*these are the icons fetch from the json */}
                            <ListItemText
                                primary={subOption.name} 
                                secondary={ subOption.notifications != undefined ? <span className="menunotification">{subOption.notifications}</span>: ''}
                                />

                            {state[subOption.name] ?
                                <ExpandLess /> :
                                <ExpandMore />
                            }
                        </ListItem>
                        <Collapse
                        
                        in={!state[subOption.name]}
                            timeout="auto"
                            unmountOnExit
                        >
                            {this.handler(subOption.submenu)}
                        </Collapse>
                    </div>
                )
            }
        })

    }


    render() {
        
        //const { classes, drawerOpen, menuOptions } = this.props
        if (this.props.menu && this.props.menu.length >= 1) {
            return (
                <React.Fragment>

                    <Card>
                        <CardContent>
                            <List>
                                <ListItem
                                    key="menuHeading"
                                    divider
                                    disableGutters
                                >
                                    <ListItemText


                                        primary="Dashboard"
                                    />
                                </ListItem>
                                {this.handler(this.props.menu)}
                            </List>
                        </CardContent>
                    </Card>

                </React.Fragment>
            )
        }
        else {
            return (
                <>
                </>
            );

        }
    }
}


const mapStateToProps = state => {
    return {
       
        menu: state.dashboard.menu
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getMenuList: (isAdmin, userId) => {
            return new Promise((resolve, reject) => {
                dispatch(getUserMenuRequest(isAdmin, userId))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    }
}



SideTabNavigation = connect(mapStateToProps, mapDispatchToProps)(SideTabNavigation);

export default SideTabNavigation;

