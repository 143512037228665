import { SERVER_ACTIONS } from '../../utils/Constants';
import HttpRequest from '../../utils/HttpRequest';
import Auth from '../../utils/Auth';
import { getAllUserType, getAllCountries, getAllBellows, getAllCompanyTypes, getAllStates, getAllRoles, 
    getAllManufacturers ,getAllPermissions,getAllCalculators, getAllRails, getAllSizes, getManufacturerSizes,getBellowsSizes,getAllMaterials,getAllSeries,
    getAllBlockTypes, getAllAccessories, getAllRailLengths, getAllKrMaterials ,getAllRailBellowTypes} from './CommonActions';


let auth = new Auth();

// *UserType List data
export function getAllUserTypeList() {
    return (dispatch) => {
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_ALL_USERTYPE}`, 'get', {}).requestWithAuth().then(
            (response) => {
                dispatch(getAllUserType(response.data));
                return Promise.resolve(response.data);
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    };
}

// *Country List data
export function getAllCountryList() {
    return (dispatch) => {
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_ALL_COUNTRIES}`, 'get', {}).requestWithAuth().then(
            (response) => {
                dispatch(getAllCountries(response.data));
                return Promise.resolve(response.data);
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    };
}

// *Manufacturer List data
export function getAllManufacturerList() {
    return (dispatch) => {
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_ALL_MANUFACTURERS}`, 'get', {}).requestWithAuth().then(
            (response) => {
                dispatch(getAllManufacturers(response.data));
                return Promise.resolve(response.data);
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    };
}

export function getAllManufacturerCalculatorsList(userId, isAdmin) {
    return (dispatch) => {

        const queryString = '?id='+userId+'&isAdmin='+isAdmin;
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_ALL_MANUFACTURERS_CALCULATORS}${queryString}`, 'get', {}).requestWithAuth().then(
            (response) => {
                dispatch(getAllManufacturers(response.data.Data));
                return Promise.resolve(response.data.Data);
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    };
}

// *Company Type List data
export function getAllCompanyTypeList() {
    return (dispatch) => {
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_ALL_COMPANY_TYPES}`, 'get', {}).requestWithAuth().then(
            (response) => {
                
                dispatch(getAllCompanyTypes(response.data));
                return Promise.resolve(response.data);
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    };
}

// *States List data
export function getAllStateList(query) {

    return (dispatch) => {
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_ALL_STATES}${'?countryid='+query}`, 'get', {}).requestWithAuth().then(
            (response) => {

                dispatch(getAllStates(response.data));
                return Promise.resolve(response.data);

            },
            (error) => {
                return Promise.reject(error);
            }
        );
    };
}

export function getAllCalculatorList(query) {

    return (dispatch) => {
        
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_ALL_CALCULATOR_LIST}${'?id='+query}`, 'get', {}).requestWithAuth().then(
            (response) => {
                
                dispatch(getAllCalculators(response.data));
                return Promise.resolve(response.data);

            },
            (error) => {
                
                return Promise.reject(error);
            }
        );
    };
}



export function getAllRoleList(IsAdmin, IsGuest) {
    return (dispatch) => {
        const queryString = '?IsAdmin='+IsAdmin+'&IsGuest='+IsGuest;
      
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_ALL_ROLES}${queryString}`, 'get', {}).requestWithAuth().then(
            (response) => {
      
                dispatch(getAllRoles(response.data));
                return Promise.resolve(response.data);
            },
            (error) => {
      
                return Promise.reject(error);
            }
        );
    };
}


export function getIKnowPartNumberMaterials(manufacturer) {
    //return (dispatch) => {
        const queryString = '?ManufacturerId='+manufacturer;
        
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_ALL_IKNOWPARTNUMBER_MATERIAL}${queryString}`, 'get', {}).requestWithAuth().then(
            (response) => {
                
                //dispatch(getAllMaterials(response.data.data));
                return Promise.resolve(response.data.data);
            },
            (error) => {
                
                return Promise.reject(error);
            }
        );
    //};
}

export function getAllMaterialList(manufacturer) {
    return (dispatch) => {
        const queryString = '?ManufacturerId='+manufacturer;
        
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_ALL_MATERIALS}${queryString}`, 'get', {}).requestWithAuth().then(
            (response) => {
                
                dispatch(getAllMaterials(response.data.data));
                return Promise.resolve(response.data.data);
            },
            (error) => {
                
                return Promise.reject(error);
            }
        );
    };
}

export function getAllMaterialListForCalculator(sizeId) {
    return (dispatch) => {
        const queryString = '?SizeId='+sizeId;
        
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_ALL_MATERIALS_FOR_CALCULATOR}${queryString}`, 'get', {}).requestWithAuth().then(
            (response) => {
                
                dispatch(getAllMaterials(response.data.data));
                return Promise.resolve(response.data.data);
            },
            (error) => {
                
                return Promise.reject(error);
            }
        );
    };
}

export function getAllRailsList(manufacturer) {
    return (dispatch) => {
        const queryString = '?ManufacturerId='+manufacturer;
        
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_ALL_RAILS}${queryString}`, 'get', {}).requestWithAuth().then(
            (response) => {
                
                dispatch(getAllRails(response.data.data));
                return Promise.resolve(response.data.data);
            },
            (error) => {
                
                return Promise.reject(error);
            }
        );
    };
}

export function getAllKRSeries(manufacturer) {
    return (dispatch) => {
        const queryString = '?ManufacturerId='+manufacturer;
        
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_ALL_KRSERIES}${queryString}`, 'get', {}).requestWithAuth().then(
            (response) => {
                
                dispatch(getAllSeries(response.data.data));
                return Promise.resolve(response.data.data);
            },
            (error) => {
                
                return Promise.reject(error);
            }
        );
    };
}


export function getAllBellowList(raildId) {
    return (dispatch) => {
        const queryString = '?RailId='+raildId;
        
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_ALL_BELLOWS}${queryString}`, 'get', {}).requestWithAuth().then(
            (response) => {
                
                dispatch(getAllBellows(response.data.data));
                return Promise.resolve(response.data.data);
            },
            (error) => {
                
                return Promise.reject(error);
            }
        );
    };
}


export function getBellowByManufacturerList(manufacturerId) {
    
    return (dispatch) => {
        const queryString = '?ManufacturerId='+manufacturerId;
        
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_BELLOW_ATTRIBUTES}${queryString}`, 'get', {}).requestWithAuth().then(
            (response) => {
                
                dispatch(getAllBellows(response.data.data.BellowAttributeValueModel));
                return Promise.resolve(response.data.data.BellowAttributeValueModel);
            },
            (error) => {
                
                return Promise.reject(error);
            }
        );
    };
}

export function getAllSizeList(raildId, bellowId) {
    return (dispatch) => {
        
        const queryString = '?railid='+raildId+'&bellowid='+bellowId;
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_ALL_SIZES}${queryString}`, 'get', {}).requestWithAuth().then(
            (response) => {
                
                dispatch(getAllSizes(response.data.data));
                return Promise.resolve(response.data.data);
            },
            (error) => {
                
                return Promise.reject(error);
            }
        );
    };
}

export function getAllSizeListByManufacturer(manufacturer) {
    return (dispatch) => {
        const queryString = '?ManufacturerId='+manufacturer;
        
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_ALL_SIZES_BY_MANUFACTURER}${queryString}`, 'get', {}).requestWithAuth().then(
            (response) => {
                
                dispatch(getAllSizes(response.data.data.SizeAttributeValueModel));
                return Promise.resolve(response.data.data);
            },
            (error) => {
                
                return Promise.reject(error);
            }
        );
    };
}

//////////////////////////////////////////////////////////////////////////////////////
export function getSizeListByManufacturer(manufacturer) {
    return (dispatch) => {
        const queryString = '?ManufacturerId='+manufacturer;
        
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_SIZES_BY_MANUFACTURER}${queryString}`, 'get', {}).requestWithAuth().then(
            (response) => {
                
                dispatch(getManufacturerSizes(response.data.data));
                return Promise.resolve(response.data.data);
            },
            (error) => {
                
                return Promise.reject(error);
            }
        );
    };
}
///////////////////////////////////////////////////////////////////////

export function getSizeListByBellows(BellowsId,manufacturer) {
    return (dispatch) => {
        const queryString = '?BellowsId='+BellowsId+'&ManufacturerId='+manufacturer;
        
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_SIZES_BY_BELLOWS}${queryString}`, 'get', {}).requestWithAuth().then(
            (response) => {
                dispatch(getBellowsSizes(response.data.data));
                return Promise.resolve(response.data.data);
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    };
}

export function getSizeListByBellowid(BellowsId,manufacturer) {
    
        const queryString = '?BellowsId='+BellowsId+'&ManufacturerId='+manufacturer;
        
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_SIZES_BY_BELLOWS}${queryString}`, 'get', {}).requestWithAuth().then(
            (response) => {
                //dispatch(getBellowsSizes(response.data.data));
                return Promise.resolve(response.data.data);
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    
}
/////////////////////////////////////For Test////////////////////////////////////////////////

// export function getSizeListByManufacturer(manufacturerId) {
//     const queryString = '?ManufacturerId='+manufacturerId;
//     return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_SIZES_BY_MANUFACTURER}${queryString}`, 'get', {})
//             .requestWithAuth()
//             .then(response => {
               
//                 if (response.status === 200 && !response.data.errorCode) {
//                 console.log({api:response.data})
//                     debugger
//                     return Promise.resolve(response.data.data);
//                 }
//                 else {
//                     return Promise.reject(response.data);
//                 }


//             }, error => {
               
//                 return Promise.reject(error);


//             });
// }

/////////////////////////////////////////////////////////////////////////////
export function getBlockTypeList(raildId) {
    return (dispatch) => {
        const queryString = '?RailId='+raildId;
        
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_ALL_BLOCK_TYPE}${queryString}`, 'get', {}).requestWithAuth().then(
            (response) => {
                
                dispatch(getAllBlockTypes(response.data.data));
                return Promise.resolve(response.data.data);
            },
            (error) => {
                
                return Promise.reject(error);
            }
        );
    };
}


export function getBlockByManufacturerList(manufacturerId) {
    return (dispatch) => {
        
        const queryString = '?ManufacturerId='+manufacturerId;
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_BLOCK_ATTRIBUTES}${queryString}`, 'get', {}).requestWithAuth().then(
            (response) => {
                
                dispatch(getAllBlockTypes(response.data.data.BlockAttributeValueModel));
                return Promise.resolve(response.data.data.BlockAttributeValueModel);
            },
            (error) => {
                
                return Promise.reject(error);
            }
        );
    };
}


export function getRailBellowByManufacturerList(manufacturerId) {
    return (dispatch) => {
        
        const queryString = '?ManufacturerId='+manufacturerId;
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_ALL_RAILBELLOW_BY_MANUFACTURER}${queryString}`, 'get', {}).requestWithAuth().then(
            (response) => {
                
                dispatch(getAllRailBellowTypes(response.data.data));
                return Promise.resolve(response.data.data);
            },
            (error) => {
                
                return Promise.reject(error);
            }
        );
    };
}

// krseries calculator page
export function getAllRailLengthList(krSeriesId) {
    return (dispatch) => {
        const queryString = '?KRSeriesID='+krSeriesId;
        
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_ALL_RAIL_LENGTH}${queryString}`, 'get', {}).requestWithAuth().then(
            (response) => {
                
                dispatch(getAllRailLengths(response.data.data));
                return Promise.resolve(response.data.data);
            },
            (error) => {
                
                return Promise.reject(error);
            }
        );
    };
}

export function getAllKrMaterialList(krSeriesId) {
    return (dispatch) => {
        
        const queryString = '?KRSeriesId='+krSeriesId;
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_ALL_KR_MATERIALS}${queryString}`, 'get', {}).requestWithAuth().then(
            (response) => {
              
                dispatch(getAllKrMaterials(response.data.data));
                return Promise.resolve(response.data.data);
            },
            (error) => {
                
                return Promise.reject(error);
            }
        );
    };
}

export function getKRMaterialByManufacturerList(manufacturer) {
    return (dispatch) => {
        const queryString = '?ManufacturerId='+manufacturer;
        
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_KRMATERIAL_ATTRIBUTES}${queryString}`, 'get', {}).requestWithAuth().then(
            (response) => {
                
                dispatch(getAllSizes(response.data.data.KRMaterialAttributeValues));
                return Promise.resolve(response.data.data.KRMaterialAttributeValues);
            },
            (error) => {
                
                return Promise.reject(error);
            }
        );
    };
}

// export function getBlockTypeList(raildId) {
//     return (dispatch) => {
//         const queryString = '?RailId='+raildId;
//         return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_ALL_BLOCK_TYPE}${queryString}`, 'get', {}).requestWithAuth().then(
//             (response) => {
//                 dispatch(getAllBlockTypes(response.data.data));
//                 return Promise.resolve(response.data.data);
//             },
//             (error) => {
//                 return Promise.reject(error);
//             }
//         );
//     };
// }

//Get ALL Permission By ROLETYPE and ROLE_Id
export function getAllPermissionList(Role_Type,Role_Id) {
    return (dispatch) => {
         let isadmin = +Role_Type == 1 ? true : false;
        const queryString = '?RoleId='+Role_Id+'&IsAdmin='+isadmin;
        
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_ROLE_ACCESSPERMISSIONS}${queryString}`, 'get', {}).requestWithAuth().then(
            (response) => {
                
                dispatch(getAllPermissions(response.data));
                return Promise.resolve(response.data);
            },
            (error) => {
                
                return Promise.reject(error);
            }
        );
    };
}


export function getAllAccessoryList(sizeId){

    return (dispatch) => {
        const queryString = '?SizeId='+sizeId;
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_ALL_ACCESSORIES}${queryString}`, 'get', {}).requestWithAuth().then(
            (response) => {
                dispatch(getAllAccessories(response.data.data));
                return Promise.resolve(response.data.data);
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    };
}
export function getAllAccessoryByManufacturerList(manufacturer_Id){

    return (dispatch) => {
        const queryString = '?ManufacturerId='+manufacturer_Id;
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_ACCESSORY_ATTRIBUTES}${queryString}`, 'get', {}).requestWithAuth().then(
            (response) => {
                //console.log({model:response})
                dispatch(getAllAccessories(response.data.data.AccessoryAttributeValueModel));
                return Promise.resolve(response.data.data.AccessoryAttributeValueModel);
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    };
}

////////////////////////////////////////Block by size and Manufacturer Id////////////////////////////////

export function getBlockBySizeManufacturerList(query) {
    
     
        
        return new HttpRequest(`${SERVER_ACTIONS.API + SERVER_ACTIONS.GET_ALL_BLOCK_BY_SIZE_MANUFACTURER}${query}`, 'get', {}).requestWithAuth().then(
            (response) => {
                
              
                return Promise.resolve(response.data.data);
            },
            (error) => {
                
                return Promise.reject(error);
            }
        );
    
}
////////////////////////////Get Accessory list by manufacturere and sizeId

export function getAllAccessoryListbymanufacturersize(manufacturerId,sizeId){

   
        const queryString = '?ManufacturerId='+manufacturerId+'&SizeId='+sizeId;
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.GET_ALL_ACCESSORIES_BY_MANUFACTURER_SIZE}${queryString}`, 'get', {}).requestWithAuth().then(
            (response) => {
                // dispatch(getAllAccessories(response.data.data));
                return Promise.resolve(response.data.data);
            },
            (error) => {
                return Promise.reject(error);
            }
        );
   
}


