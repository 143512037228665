import React, { Component } from 'react'
import { TextField } from 'formik-material-ui';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { Formik, Form,FieldArray, Field, ErrorMessage } from 'formik';
import { Modal, Button, SafeAnchor, ButtonGroup, Container } from 'react-bootstrap';
 import './ManageRole.css';
import { connect } from 'react-redux';
import { saveRoleItem } from '../../ManageRolesApiActions';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Permissions from '../../../Common/components/Permissions';
//import { importDeclaration } from 'babel-types';
import RoleFormSchema from './RoleFormSchema';

class AddRole extends Component {
    constructor(props) {
        super(props);
       
        this.state = {
            role_id: 0,
            role_name: '',
            role_type_id: 1,
            role_type:'',
            PermisionsData: []
        }
        this.handleRoleTypeChange = this.handleRoleTypeChange.bind(this);
    }
    
    componentDidMount()
    {
        this.renderPermissons();
    }
    saverole = (data, formAction) => {

        data.role_id = 0
        formAction.setSubmitting(true);
        this.props.saverole(data, formAction).then(response => {
            this.setState({ message: response.message });
            formAction.setSubmitting(false);
            
            if (this.props.updateList && typeof this.props.updateList === 'function') {
                this.props.updateList();   
            }
            this.props.onHide();
        }, error => {
            formAction.setSubmitting(false);
        });

    };
    

    handleChange = (selectedOption) => {
        this.setState({ selectedOption });
      }



    handleRoleTypeChange(e) {
        if(e.target.value == 1){
            document.getElementsByClassName("permissions")[0].classList.remove("d-none");
        }
        else{
            document.getElementsByClassName("permissions")[0].classList.add("d-none");
        }
        if(document.getElementsByName("role_name").length>0){
        this.setState({role_name: document.getElementsByName("role_name")[0].defaultValue });
        }
        this.setState({ role_type_id : e.target.value })
    }

    handleTree = (selectedper) => {
        if(document.getElementsByName("role_name").length>0){
        this.setState({role_name: document.getElementsByName("role_name")[0].defaultValue });
        }
        this.setState({PermisionsData: selectedper});
    }

    renderPermissons = () => {      
                   return(<Permissions update={false} onPermissionChange={this.handleTree}
                    roletypeval={this.state.role_type_id}
                     roleidval={this.state.role_id}>
                   </Permissions>)
    }

    render() {
        const roleState = this.state;
        return (

            <React.Fragment>
                <Modal className="userPopup"
                    {...this.props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header color="secondary" closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Add Role
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Formik
                            validationSchema={RoleFormSchema}
                            initialValues={roleState}
                            onSubmit={this.saverole}
                            enableReinitialize={true}
                        >
                            {({ errors, status, touched, isSubmitting, setFieldValue }) => (
                                <Form autoComplete="off" noValidate>
                        <div className="row">
                            <div className="col-lg-4">
                           
                            <Field type="text"
                                name="role_name"
                                placeholder="Enter Role Name"
                                component = {TextField}
                                id="standard-required"
                                label="Role Name"
                                margin="normal"
                                fullWidth
                                />
                              </div>
                            
                            <div className="col-lg-2">
                            <FormControl className="roletypedropdown">  
                                    <InputLabel id="demo-simple-select-label">Role Type</InputLabel>                                                                          
                                    <Select
                                    className="role_dropdown"
                                    defaultValue = {1}
                                    labelId="demo-controlled-open-select-label"
                                    id="role_type_id"
                                    label="Role Type"
                                    key=''
                                    data-attribute='role_type_id'
                                    name='role_type_id'
                                    //onChange = { this.handleRoleTypeChange } 
                                    onChange = {(e)=>
                                        { 
                                        this.handleRoleTypeChange(e);
                                        setFieldValue('role_type_id', e.target.value) 
                                        }
                                    } 
                                    >
                                     <MenuItem value={1}>Admin</MenuItem>
                                     <MenuItem value={2}>Register Customer</MenuItem>
                                     <MenuItem value={3}>Guest Customer</MenuItem>

                                    </Select>
                                    </FormControl>

                            </div>
                            <div className="col-lg-4  mt-1 permissions">
                            <InputLabel id="demo-simple-select-label">Permission</InputLabel>   
                            {/* Permissions */}

                            {
                             this.renderPermissons()
                            }
                            </div>
                        </div>
                        <Modal.Footer className="row" centered>
                        <Button variant="contained" type="submit"  className="save mt-1 mr-1">Save</Button>
                        <Button variant="contained" className="cancle  mt-1 mr-1"onClick={this.props.onHide}>Close</Button>
                    </Modal.Footer>
                        </Form>
                            )}

                        </Formik>
                    </Modal.Body>
                    
                </Modal>
            </React.Fragment>
        )
    }
}

const mapProps = state => ({
})

const mapDispatch = (dispatch, ownProps) => {

    return {
        saverole: (role, formAction) => {

            return new Promise((resolve, reject) => {
                dispatch(saveRoleItem(role, formAction)).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        }
    }
}
export default connect(mapProps, mapDispatch)(AddRole);