import React, { Component } from 'react';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { getRailBellowByManufacturerList } from '../../CommonApiActions';
import { Field, FieldArray, Form, Formik } from 'formik';
class RailBellowByManufacturerDropDown extends React.Component {
    constructor(props) {
        super(props);
        //console.log(this.props.manufacturerId);
        this.state = ({
            allRailBellow: [],
            manufacturerId: this.props.manufacturerId,
           

        })
    }

    
    componentWillReceiveProps(props) {

        if(props.manufacturerId!= undefined && props.manufacturerId !=this.state.manufacturerId){
          this.props.getRailBellowList(props.manufacturerId);
          this.state.manufacturerId = props.manufacturerId;
        }
    }

    componentDidMount() {
        this.props.getRailBellowList(this.state.manufacturerId);
    }


    render() {
        const selectedValue = this.props.selectedValue;
        const railBellows = this.props.allRailBellow;
      
//console.log({railBellows})
        const options = railBellows && railBellows.length > 0 ? (
            railBellows.map((railBellows,index) => {

                return (<MenuItem key={index}  name={railBellows.RailValue} value={railBellows.RailBellowsID}>
                    {railBellows.RailValue}-{railBellows.BellowsValue}
                </MenuItem>
                );
            })
        ) : (
                <MenuItem>Loading....</MenuItem>
            );
        //console.log({ options })
        return (

            <Select style={{width:160}} defaultValue={selectedValue} 
                 //name={dropdownname}
                 labelId="demo-simple-select-label"
                id="demo-simple-select" onChange={this.props.onRailBellowChange}>
                {options}
            </Select>
        );
    }

}

const mapProps = (state) => {

    return {
        allRailBellow: state.common.allRailBellow
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getRailBellowList: (manufacturerId) => {
            
            return new Promise((resolve, reject) => {
                dispatch(getRailBellowByManufacturerList(manufacturerId))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    };
};

export default connect(mapProps, mapDispatchToProps)(RailBellowByManufacturerDropDown);
