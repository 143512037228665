import React, { Component } from 'react'
import { SafeAnchor, Modal } from 'react-bootstrap';
// import { Formik, Form, Field } from 'formik';
import Button from '@material-ui/core/Button';
import { Field, FieldArray, Form, Formik } from 'formik';
import { FormikTextField } from 'formik-material-fields';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { TextField } from 'formik-material-ui';
import {getKRSeriesMaterialAttributes,deleteKRSeriesMaterialAttribute,addKRSeriesMaterial} from '../../components/ManageAttributeApiAction';
import Icon from '@material-ui/core/Icon';
import KRSeriesDropDown from '../../../Common/components/DropDown/KRSeriesDropDown';
import KRMaterialByManufacturerDropDown from '../../../Common/components/DropDown/KRMaterialByManufacturerDropDown';
import {getKRMaterialByManufacturerList,getAllKRSeries} from '../ManageAttribute/EditDropDownListApi';
import swal from 'sweetalert';
import{StyledReactTooltip} from '../ToolTip';
import Swal from 'sweetalert2';


const { Header, Body, Footer, Title } = Modal;

export default class EditKRSeriesMaterial extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showform: false,
            manufacturer_name: "",
            manufacturer_id:this.props.manufacturer_id,
            allkrMaterials:[{KRSeriesMaterialID: 0,
                KRMaterialID:""}],
            krseries_id: 0,
            krseries_name: '',
            prevkrseries_id: 0,
            prevkrseries2_id:0,
            allKrSeriesList:[],
            krseries:  [{
                KRSeriesMaterialID: 0,
                KRMaterialID:"",
                        
                        }
                   ]
        };

    }
    componentDidMount(props) {
        //console.log("didmount");
      
    }
    componentWillReceiveProps(props) {
        this.setState({ manufacturer_id: this.props.manufacturer_id });
        if(this.props.manufacturer_id!=undefined){
       
        this.getKRSeriesDropDownList(this.props.manufacturer_id);
        }
        
    }
    componentDidUpdate() {
        const seriesValue=this.state.allKrSeriesList[0]!=undefined?(this.state.allKrSeriesList[0]):(0);
      
        const seriesid=seriesValue.KRSeriesID
       if(this.state.prevkrseries2_id!=seriesValue.KRSeriesID && seriesValue.KRSeriesID!=undefined)
       {
        //    this.setId(sizeValue.SizeId);
           this.setState({sizeAccessory_Id:seriesValue.KRSeriesID});
           this.getKrSeries(seriesid);
          this.setState({prevkrseries2_id:seriesValue.KRSeriesID});
        
       }

        else if(this.state.prevkrseries_id!==this.state.krseries_id){
            this.getKrSeries(this.state.krseries_id);
            this.setState({prevkrseries_id:this.state.krseries_id})
        }
    }
    getKRMaterial= (manufacturerId) => {
        manufacturerId = manufacturerId == undefined ? 0 : manufacturerId;     
        getKRMaterialByManufacturerList(manufacturerId).then(resp => {
            
            this.setState({

                allkrMaterials:resp
            });

        });
    }
    getKRSeriesDropDownList= (manufacturerId) => {
                
        getAllKRSeries(manufacturerId).then(resp => {
            
            this.setState({

                allKrSeriesList:resp
            });

        });
    }


    showForm = () => {
        this.setState({ showform: true })
        this.getKRMaterial(this.props.manufacturer_id);
    }


    hideForm = () => {
        this.setState({ showform: false, prevkrseries2_id:0,prevkrseries_id:0,
            krseries:  [{
            KRSeriesMaterialID: 0,
            KRMaterialID:"",
                    }
               ] })
    }
    deleteKrSeries = (option, arrayHelpers, i) => {
        if (option.KRSeriesMaterialID == 0) {
            arrayHelpers.remove(i);
        }
        else {

            Swal.fire({
                //title: 'Are you sure?',
                text: 'This action will delete the record permanently from database, Are you sure?',
                // icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel',
                showCloseButton: true
              }).then((result) => {
                if (result.value) {
                
                    deleteKRSeriesMaterialAttribute(option.KRSeriesMaterialID).then(resp => {
                                                arrayHelpers.remove(i);
                                             });
                  
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                  
                }
              })

        // swal("This action will delete the record permanently from database, Are you sure?", {
        //     buttons: {
        //         catch: {
        //             text: "Yes",
        //             value: "Yes",
        //         },
        //         cancel: "Cancel",
        //     },
        // })
        //     .then((value) => {
        //         switch (value) {
        //             case "Yes":
        //                     deleteKRSeriesMaterialAttribute(option.KRSeriesMaterialID).then(resp => {
        //                         arrayHelpers.remove(i);
        //                      });
        //                 break;
        //             default:

        //         }
        //     });
        }
    }
    // deleteKrSeries=(option, arrayHelpers, i)=>{
    //     if (option.KRSeriesMaterialID == 0) {
    //         arrayHelpers.remove(i);
    //     }
    //     else {
    //         deleteKRSeriesMaterialAttribute(option.KRSeriesMaterialID).then(resp => {
    //             arrayHelpers.remove(i);
    //          });
    //     }
    // }
    getKrSeries = (krseries_id) =>  {
        var manufacturerId = this.state.manufacturer_id ==undefined ? 0 : this.state.manufacturer_id;
        krseries_id = krseries_id ==undefined ? 0 : krseries_id;
            const query='?ManufacturerId='+this.state.manufacturer_id+'&KRSeriesId='+krseries_id;          
            getKRSeriesMaterialAttributes(query).then(res => {
                
                this.setState({
    
                    
                    krseries:res,
                   
                   
                })
                
            })
        }
    handleChangeRailDropdown = (event) => {
        this.setState({ rail_id: event.target.value })
        // this.getKRRailLenth(event.target.value);
    };
    submitFormula = (data, actions) => {
        let found=false;
        for(let i=0;i<data.krseries.length;i++){
            let rname=data.krseries[i].KRMaterialID;
        
        let foundDuplicate=data.krseries.filter(r=>r.KRMaterialID==rname).map(duplicate=>(duplicate.KRMaterialID ))
            if(foundDuplicate.length>=2)
            {found=true;
                actions.setSubmitting(false)
                swal(rname+" attribute is already exist", {
                    buttons: {
                            cancel: "OK",
                    },
                })
                    .then((cancel) => {
                        switch (cancel) {
                            case "Yes":                        
                                break;
                            default:
                        }
                    });
            } 
        }
        if(found==false){

            actions.setSubmitting(true);
            // let updateOptions = data.options.filter(o => (this.state.updatableOptions.indexOf(o.CalulatorformulaeId) > -1 || o.CalulatorformulaeId == 0));
            let KRSeriesMaterialValues= data.krseries
    
            addKRSeriesMaterial(this.props.manufacturer_id,this.state.krseries_id,
                KRSeriesMaterialValues).then(response => {
    
                    if (response.Status && response.StatusCode === 200) {
                        let { krseries } = this.state;
             
    
                        krseries = krseries.map(krseries => {
                            let option2 = KRSeriesMaterialValues.find(o => o.KRSeriesMaterialID == krseries.KRSeriesMaterialID);
                            return option2 ? { ...krseries, ...option2 } : krseries;
                        })
                     
                        this.setState({
                            krseries
                        })
    
                    }
    
                }, this.hideForm())
        }
       
      

    }

    render() {

        const manufacturer_id = this.props.manufacturer_id;
        const manufacturer_name = this.props.manufacturer_name;

        const krMaterials=this.state.allkrMaterials;
        let defaultBlockType=0;
        if(this.state.allkrMaterials.length>0)
        {
         defaultBlockType=this.state.allkrMaterials[0].KRMaterialID!=undefined?(this.state.allkrMaterials[0].KRMaterialID):(0);

        }
        const options = krMaterials && krMaterials.length > 0 ? (
            krMaterials.map((krMaterials) => {

                return (<option   value={krMaterials.KRMaterialID}>
                    {krMaterials.Material}
                </option>
                );
            })
        ) : (
                <option>Loading....</option>
            );
        //console.log({ krraillength: this.state.krraillength })
        return (
            <>
                {!this.state.showform ?<>
                    <Icon  data-tip data-for="edit" onClick={this.showForm}>
                        edit
                 </Icon>
                  <StyledReactTooltip id="edit" effect="solid">
                  <span>Edit</span>
                 </StyledReactTooltip></>
                    : <>
                        <Modal className="calculatorPopup"show={this.state.showform} onHide={this.hideForm}>
                            <Header closeButton>
                                <Title>{manufacturer_name}</Title>
                            </Header>
                            <Body>

                                <Formik
                                    onSubmit={this.submitFormula}
                                    initialValues={{ krseries: this.state.krseries }}
                                    enableReinitialize={true}
                                >
                                    {({ values, status, isSubmitting, setFieldValue }) => (
                                        <Form>
                                            <div className="row ">
                                                <div className="col-lg-12 popup-heading">
                                                    <label >ATTRIBUTE NAME :KR-SERIES MATERIAL</label>
                                                </div>
                                                
                                                <div className="col-lg-6">
                                                    <FormControl  margin="normal" fullWidth>
                                                    <div className="ManufacturerDropDown">
                                                        <InputLabel id="demo-simple-select-label">KRSeries</InputLabel>
                                                        <KRSeriesDropDown selectedValue={this.state.sizeAccessory_Id} enabled={true} activeOnly={true} manufacturerId={manufacturer_id} onKRSeriesChange={(e, index) => {
                                                           
                                                           this.handleChangeRailDropdown(e)
                                                                                                                     
                                                            this.setState({
                                                                krseries_id: e.target.value,
                                                              
                                                                
                                                            });
                                                            
                                                        }
                                                        } />
                                                        </div>
                                                    </FormControl>
                                                
                                                </div>
                                            </div>

                                            <div className="row">
                                        <div className="col-lg-4  col-sm-4  col-6">
                                        <label>KR Material</label>
                                        </div> 
                                        </div> 
                                            <FieldArray
                                                name="krseries"

                                                render={arrayHelpers => (
                                                    <div className="clacfor">

                                                        {values.krseries && values.krseries.length > 0 ? values.krseries.map((krseries, i) => {

                                                            return (<div className="row" key={i}>
                                                                <div className="col-lg-4  col-sm-4  col-6">
                                                                <FormControl  className="FormulaDropdown" margin="normal" fullWidth>
                                                                <div className="ManufacturerDropDown">
                                                       
                                                                {/* <KRMaterialByManufacturerDropDown  dropdownname={`krseries[${i}.KRMaterialID]`}
                                                                 selectedValue={krseries.KRMaterialID} enabled={true} 
                                                                 activeOnly={true}  manufacturerId={manufacturer_id} 
                                                                 onKRMaterialChange={(e, index) => {
                                                                  setFieldValue(`krseries[${i}.KRMaterialID]`, e.target.value);
                                                                
                                                            
                                                        }
                                                        } /> */}

                                                            <Field component="select" style={{width:140}} defaultValue={krseries.KRSeriesMaterialID} 
                                                             name={`krseries[${i}.KRMaterialID]`}
                                                             labelId="demo-simple-select-label"
                                                             id="demo-simple-select" onChange={(e, index) => {
                                                             setFieldValue(`krseries[${i}.KRMaterialID]`, e.target.value);
                                                             defaultBlockType=e.target.value;
                                                             }
                                                            }>
                                                           {options}
                                                        </Field>
                                                        </div>
                                                    </FormControl>
                      
                                                                                                
                                                                {/* <FormikTextField                            
                                                                    name={`krseries[${i}].KRMaterialID`}
                                                                    defaultValue={krseries.KRMaterialID}
                                                                    margin="normal"
                                                                    fullWidth                  
                                                                /> */}
                                                            </div>   
                                                                <SafeAnchor className="btn btn-default">
                                                                    <i className="fa fa-minus" onClick={() => { this.deleteKrSeries(krseries, arrayHelpers, i) }} /></SafeAnchor>
                                                                <br />
                                                            </div>);
                                                        }) : arrayHelpers.push({ KRSeriesMaterialID: 0 ,KRMaterialID:defaultBlockType})}
                                                        <SafeAnchor className="btn btn-default" onClick={e => {
                                                         
                                                            arrayHelpers.push({ KRSeriesMaterialID: 0 ,KRMaterialID:defaultBlockType})
                                                        }}><i className="fa fa-plus" /></SafeAnchor>
                                                    </div>
                                                )} />
                                            {/* <input type="submit" className="submit" disabled={isSubmitting} /> */}
                                            <Modal.Footer className="row" centered>
                                              <Button variant="contained" type= "submit"  className="save" disabled={isSubmitting} >Submit</Button>
                                            </Modal.Footer>
                                        </Form>
                                    )}
                                </Formik>
                            </Body>
                        </Modal>

                    </>}
            </>

        )
    }
}