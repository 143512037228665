import React, { Component } from 'react'
import { TextField } from 'formik-material-ui';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Modal, Button, SafeAnchor, ButtonGroup, Container } from 'react-bootstrap';
import './addRegisteredCustomer.css';
import RoleDropDown from '../../../Common/components/DropDown/RoleDropDown';
import { connect } from 'react-redux';
import { saveCustomerItem } from '../../ManageCustomersApiActions';
import Calculators from '../../../Common/components/Calculators';
import CompanyTypeDropDown from '../../../Common/components/DropDown/CompanyTypeDropDown';
import CountryDropDown from '../../../Common/components/DropDown/CountryDropDown';
import StateDropDown from '../../../Common/components/DropDown/StateDropDown';
import UserTypeDropDown from '../../../Common/components/DropDown/UserTypeDropDown';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import RegisteredCustomerSchema from './RegisteredCustomerSchema'

class AddRegisteredCustomer extends Component {
    constructor(props) {
        super(props);
        let registeredCustomer = {
            email: '',
            first_name: '',
            last_name: '',
            role_id: 0,
            company_name: '',
            company_type: '',
            contact_no:'',
            address:'',
            country:'',
            state:'',
            postal_code:'',
            is_active:true,
            custtype:''
        };

        this.state = {
            country:'',
            state:'',
            registeredCustomer: registeredCustomer,
            calculatorData: []
        }
    }


    saveRegisteredCustomer = (data, formAction) => {

        data.customer_id = 0
        data.CustomerCalculator = this.state.calculatorData;
        
        if(data.CustomerCalculator.length == 0){
            document.getElementById('CalculatorError').innerHTML = 'Please select calculator';
            return false;
        }
        else{
            document.getElementById('CalculatorError').innerHTML = '';
        }
        formAction.setSubmitting(true);
        this.props.saveRegisteredCustomer(data, formAction).then(response => {
            this.setState({ message: response.message });
            formAction.setSubmitting(false);
            if (this.props.updateList && typeof this.props.updateList === 'function') {
                this.props.updateList();
            }
            this.props.onHide();

        }, error => {
            formAction.setSubmitting(false);
        });

    };


    handleTree = (selectedper) => {
        this.setState({ calculatorData: selectedper });
    }

    renderCalculators = () => {
        return (<Calculators id={0} onCalculatorChange={this.handleTree} calculator_id={this.state.calculator_id} roleidval={this.state.role_id}>
        </Calculators>)
    }

    render() {

        return (

            <React.Fragment>
                <Modal className="container"
                    {...this.props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header color="secondary" closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Add Customer
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik
                            validationSchema={RegisteredCustomerSchema}
                            initialValues={this.state.registeredCustomer}
                            onSubmit={this.saveRegisteredCustomer}
                            enableReinitialize={true}
                        >
                            {({ errors, status, touched, isSubmitting, setFieldValue }) => (
                                <Form autoComplete="off" noValidate>
                                    <div className="row">
                                        <div className="col-lg-4  com-field">

                                            <Field type="text"
                                                name="email"
                                                placeholder="Enter Email"
                                                component={TextField}
                                                id="standard-required"
                                                label="User Name"
                                                margin="normal"
                                                // defaultValue={data.first_name}
                                                fullWidth
                                            />

                                        </div>

                                        <div className="col-lg-4  com-field">
                                            <Field type="text"
                                                name="first_name"
                                                placeholder="Enter First Name"
                                                component={TextField}
                                                id="standard-required"
                                                label="First Name"
                                                margin="normal"
                                                // defaultValue={data.first_name}
                                                fullWidth
                                            />

                                        </div>

                                        <div className="col-lg-4 com-field">
                                            <Field type="text"
                                                name="last_name"
                                                placeholder="Enter Last Name"
                                                component={TextField}
                                                id="standard-required"
                                                label="Last Name"
                                                margin="normal"
                                                // defaultValue={data.first_name}
                                                fullWidth
                                            />
                                        </div>
                                        
                                        <div className="col-lg-4  com-field">
                                            <Field type="text"
                                                name="company_name"
                                                placeholder="Enter Company Name"
                                                component={TextField}
                                                id="standard-required"
                                                label="Company Name"
                                                margin="normal"
                                                // defaultValue={data.first_name}
                                                fullWidth
                                            />
                                        </div>

                                        <div className="col-lg-4 com-field">

                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel id="demo-simple-select-label">Company Role</InputLabel>
                                                <CompanyTypeDropDown enabled={true} isAdmin={false} activeOnly={true} onCompanyChange={e => {
                                                    setFieldValue("company_type", e.target.value);
                                                }
                                                } />
                                                <ErrorMessage
                                                name="company_type"
                                                style={{ color: "#f44336", fontSize: 11 }}
                                                component="div"
                                            />
                                            </FormControl>
                                    
                                        </div>
                                        <div className="col-lg-4 com-field">
                                            <Field type="text"
                                                name="contact_no"
                                                placeholder="Enter Phone"
                                                component = {TextField}
                                                id="standard-required"
                                                label="Phone"
                                                margin="normal"
                                                fullWidth
                                                />
                                        </div>
                                        <div className="col-lg-4 com-field">
                            <Field type="text"
                                name="address"
                                placeholder="Enter Address"
                                component = {TextField}
                                id="standard-required"
                                label="Address"
                                margin="normal"
                                // defaultValue={data.first_name}
                                fullWidth
                                />
                            </div>

                            <div className="col-lg-4 com-field">

                                       <FormControl margin="normal" fullWidth>
                                             <InputLabel id="demo-simple-select-label">Country</InputLabel>
                                             <CountryDropDown enabled={true} activeOnly={true}  selectedValue={this.state.country} 
                                                onCountryChange={e => {
                                                    setFieldValue("country", e.target.value);
                                                    // auth.setCountry(e.target.value);
                                                    this.setState({country:e.target.value})
                                                    this.setState({state:''})
                                                    setFieldValue("state", e.target.value);
                                                }
                                                } />
                                                <ErrorMessage
                                                name="country"
                                                style={{ color: "#f44336", fontSize: 11 }}
                                                component="div"
                                            />
                                        </FormControl>
                            </div>
                            <div className="col-lg-4 com-field">

                                       <FormControl margin="normal" fullWidth>
                                             <InputLabel id="demo-simple-select-label">State</InputLabel>
                                             <StateDropDown country={this.state.country} selectedValue={this.state.state}  enabled={true} activeOnly={true} onStateChange={e => {
                                                setFieldValue("state", e.target.value);
                                               this.setState({state:e.target.value})
                                            }
                                            } />
                                            <ErrorMessage
                                                name="state"
                                                style={{ color: "#f44336", fontSize: 11 }}
                                                component="div"
                                            />
                                        </FormControl>
                            </div>

                            <div className="col-lg-4 com-field">
                                <Field type="text"
                                    name="postal_code"
                                    placeholder="Enter Postal Code"
                                    component = {TextField}
                                    id="standard-required"
                                    label="Postal Code"
                                    margin="normal"
                                    // defaultValue={data.first_name}
                                    fullWidth
                                    />
                            </div>

                                        <div className="col-lg-4 com-field">

                                            <FormControl margin="normal"  error={errors.role_id} fullWidth>
                                                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                                                <RoleDropDown enabled={true} isAdmin={false} isGuest={false} activeOnly={true} onRoleChange={e => {
                                                    setFieldValue("role_id", e.target.value);
                                                }
                                                } />

                                            </FormControl>
                                            <ErrorMessage
                                                name="role_id"
                                                style={{ color: "#f44336", fontSize: 11 }}
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-lg-4  com-field">
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel id="demo-simple-select-label">Active</InputLabel>
                                                <Select
                                                    className="role_dropdown"
                                                    defaultValue={true}
                                                    labelId="demo-controlled-open-select-label"
                                                    id="is_active"
                                                    key=''
                                                    data-attribute='is_active' name='is_active'
                                                    onChange={(e, index) => {
                                                        setFieldValue('is_active', e.target.value);
                                                    }}
                                                >
                                                    <MenuItem value={true}>Yes</MenuItem>
                                                    <MenuItem value={false} >No</MenuItem>

                                                </Select>
                                            </FormControl>

                                        </div>
                                        <div className="col-lg-4 com-field">
                                            <FormControl margin="normal"  error={errors.custtype} fullWidth>
                                                <InputLabel id="demo-simple-select-label">I AM A</InputLabel>
                                                <UserTypeDropDown enabled={true} name="custtype"  activeOnly={true} onCustTypeChange={e => {
                                                    setFieldValue("custtype", e.target.value);
                                                }
                                                } />

                                            </FormControl>
                                            <ErrorMessage
                                                name="custtype"
                                                style={{ color: "#f44336", fontSize: 11 }}
                                                component="div"
                                            />
                                        </div>
                                        <div className="col-lg-4">
                                            <InputLabel id="demo-simple-select-label mt-1">MFG Calculators <label className="red">*</label></InputLabel>
                                            {/* Permissions */}
                                            {
                                                this.renderCalculators()
                                            }
                                            <label id="CalculatorError" className="red"></label>
                                        </div>

                                    </div>
                                    <Modal.Footer className="row" centered>
                                        <Button variant="contained" type="submit" className="save">Save</Button>
                                        <Button variant="contained" className="cancle" onClick={this.props.onHide}>Close</Button>
                                    </Modal.Footer>
                                </Form>
                            )}

                        </Formik>
                    </Modal.Body>

                </Modal>
            </React.Fragment>
        )
    }
}

const mapProps = state => ({
})

const mapDispatch = (dispatch, ownProps) => {

    return {
        saveRegisteredCustomer: (registeredCustomer, formAction) => {

            return new Promise((resolve, reject) => {
                dispatch(saveCustomerItem(registeredCustomer, formAction)).then(response => {

                    resolve(response);
                    // if (response.status == 200 && !response.data.errorCode) {
                    //     window.Message('Saved Successfully', discount.id > 0 ? `${discount.name} Updated....` : `${discount.name} Created....`);
                    // }
                }).catch(error => {
                    reject(error);
                });
            });
        }
    }
}
export default connect(mapProps, mapDispatch)(AddRegisteredCustomer);