import {SERVER_ACTIONS} from '../../utils/Constants';
import HttpRequest from '../../utils/HttpRequest';
import Auth from '../../utils/Auth';
import { updateUsers, addUserToList, updateUserInList, deleteUserFromListItem } from './ManageUsersActions';

const auth  = new Auth();

export function getUserList({query, PageNumber, defaultPageSize}) {

    return (dispatch) => {
        const queryString = (query && query.trim().length > 0) ? `?${query}` : '';
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.POST_USERS}${queryString}`, 'post', {})
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {

                dispatch(updateUsers(response.data, Math.ceil(response.data.totalrecord / defaultPageSize), PageNumber));
                return Promise.resolve(response.data);
                }
                else{
                    return Promise.reject(response.data);
                }
            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);
            });
    }
}

export function saveUserItem(user, formAction) {
    formAction.setSubmitting(true)
    return (dispatch) => {
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(`${SERVER_ACTIONS.API+SERVER_ACTIONS.POST_UPDATE_USER}`, 'post', user)
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                formAction.setSubmitting(false);
                if (response.status === 200 && !response.data.errorCode) {
                    window.Message(response.data.message, 'Success');

                    if (user.user_id == 0) {

                        dispatch(addUserToList(response.data.data));

                    } else {
                        dispatch(updateUserInList(response.data.data));
                    }

                    return Promise.resolve(response);
                } else {
                    formAction.setStatus({ msg: response.data.message });
                    return Promise.reject(response);
                }
            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (error.response.data) {
                    formAction.setStatus({ msg: error.response.data.message });
                }
                window.Message(error.response.data.message, 'Error',true);
                formAction.setSubmitting(false);
                return Promise.reject(error);
            });
    }
}


export function toggleStatusUsertItem(id, modified_by) {
    let params = {};
    params.id = id;
    params.modified_by = modified_by;

    return (dispatch) => {
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(SERVER_ACTIONS.API+SERVER_ACTIONS.POST_STATUS_USER, 'POST', params)
            .requestWithAuth()
            .then(response => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                    window.Message(response.data.message, 'Success');
                    dispatch(updateUserInList(response.data.data));
                }

                return Promise.resolve(response);
            }, error => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);
            });
    }
}

export function deleteUserFromList(id, modified_by) {
    return (dispatch) => {
        let params ={};
        params.id =id;
        params.modified_by = modified_by;
        document.getElementById("loaderdiv").classList.remove("d-none");
        return new HttpRequest(SERVER_ACTIONS.API+SERVER_ACTIONS.DELETE_USER, 'DELETE', params).requestWithAuth().then(
            (response) => {
                document.getElementById("loaderdiv").classList.add("d-none");
                if (response.status === 200 && !response.data.errorCode) {
                    window.Message(response.data.message, 'Success');
                    dispatch(deleteUserFromListItem(response.data.data));
                }

                return Promise.resolve(response.data);
            },
            (error) => {
                document.getElementById("loaderdiv").classList.add("d-none");
                return Promise.reject(error);
            }
        );
    };
}
